
export class DateUtil {

  public static formatDateAsHumanReadable(date: Date): string {

    const dayOfWeek = date.toLocaleDateString(['pt', 'en'], { weekday: 'long' });
    const day = date.toLocaleDateString(['pt', 'en'], { day: 'numeric' });
    const month = date.toLocaleDateString(['pt', 'en'], { month: 'long' });
    const year = date.getFullYear();
    const hour = date.toLocaleTimeString(['pt', 'en'], { hour: '2-digit', minute: '2-digit' }).split(':')[0]; // Get just the hour
    const minutes = date.toLocaleTimeString(['pt', 'en'], { hour: '2-digit', minute: '2-digit' }).split(':')[1]; // Get just the minutes

    return `${dayOfWeek}, ${day} ${month} ${year}, ${hour}:${minutes}`;
  }


  public static getPastDateAsHumanReadable(date: Date): string {

    // If the date is in the same day as today, return simple the hour and minutes
    // If the date is less than a year, return the month and day
    // If the date is more than a year ago, return the year

    const today = new Date();

    // If the date is in the same day as today, return simply the hour and minutes
    if (date.getFullYear() === today.getFullYear() &&
      date.getMonth() === today.getMonth() &&
      date.getDate() === today.getDate()) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }

    // If the date is within the same year, return the month and day
    if (date.getFullYear() === today.getFullYear()) {
      return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
    }

    // If the date is more than a year ago, return the year
    return date.getFullYear().toString();
  }
}
