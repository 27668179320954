import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { GuidUtil } from '../../../../../../common/util/guid/guid';
import { useTranslationCommonFrontEnd } from '../../../../../translations/translation';
import { VerticalListComponent } from '../../../../verticalList/verticalList';
import { CustomElement, CustomElementTypesEnum, CustomElementUserQuery, CustomElementUserQueryVersion } from '../../../types';
import { ElementCreationRef } from '../../elementSettingsConfig';
import { ElementSettingsCreationProps } from '../../elementSettingsCreationProps';
import { ElementConfigurationSnippetTextInputComponent } from '../../snippets/elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput';

export const ElementCreationUserQueryComponent = forwardRef<ElementCreationRef, ElementSettingsCreationProps>((props, ref): JSX.Element => {

  const t = useTranslationCommonFrontEnd();

  const [name, setName] = useState<string>("");

  useEffect(() => {

    const canBeCreated = name.length > 0;
    props.elementCanBeCreated(canBeCreated);

  }, [name]);

  // Function that needs to be called from the parent component
  const internalCreateElement = (): CustomElement | undefined => {

    const userQueryElement: CustomElementUserQuery = {
      type: CustomElementTypesEnum.UserQuery,
      version: CustomElementUserQueryVersion,
      userQueryId: GuidUtil.GenerateNewGuid(),
      name,
      children: [{ text: '' }],
    };

    return userQueryElement;
  }

  useImperativeHandle(ref, () => ({
    createElement: internalCreateElement,
  }));

  return (
    <div className="element-creation-url-component">

      <VerticalListComponent>
        <ElementConfigurationSnippetTextInputComponent
          caption={t('title')}
          placeholder={t('clickHere')}
          value={name}
          onChange={
            (value) => setName(value)
          }
        />
      </VerticalListComponent>
    </div>
  );
});
