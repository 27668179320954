import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { VerticalListComponent } from '../../../../verticalList/verticalList';
import { CustomElement, CustomElementHorizontalLine } from '../../../types';
import { ElementEditRef } from '../../elementSettingsConfig';
import { ElementSettingsEditProps } from '../../elementSettingsEditProps';
import { ElementConfigurationSnippetColorComponent } from '../../snippets/elementConfigurationSnippetColor/elementConfigurationSnippetColor';
import { ElementConfigurationSnippetLineWidthComponent } from '../../snippets/elementConfigurationSnippetLineWidth/elementConfigurationSnippetLineWidth';
import { useTranslationCommonFrontEnd } from '../../../../../translations/translation';

export const ElementEditHorizontalLineComponent = forwardRef<ElementEditRef, ElementSettingsEditProps>(
  ({
    element,
    elementCanBeUpdated
  }, ref): JSX.Element => {

    const t = useTranslationCommonFrontEnd();

    const elementHorizontalLine = element as CustomElementHorizontalLine;

    const [color, setColor] = useState<string>(elementHorizontalLine.color);
    const [width, setWidth] = useState<string>(elementHorizontalLine.width.toString());

    useImperativeHandle(ref, () => ({
      updateElement: (element: CustomElement) => {
        const updatedElement = element as CustomElementHorizontalLine;
        updatedElement.color = color;
        updatedElement.width = parseInt(width);
        return updatedElement;
      },
    }));

    useEffect(() => {

      const widthAsNumber = parseInt(width);

      elementCanBeUpdated(color.length > 0 && widthAsNumber > 0 && widthAsNumber <= 100);
    }, [color, width]);

    return (
      <div className="element-edit-horizontal-line-component">

        <VerticalListComponent>

          <ElementConfigurationSnippetColorComponent
            caption={t('color')}
            value={color}
            onChange={setColor}
          />

          <ElementConfigurationSnippetLineWidthComponent
            caption={t('width')}
            value={parseInt(width)}
            onChange={(value) => setWidth(value.toString())}
          />

        </VerticalListComponent>
      </div>
    );
  }
);