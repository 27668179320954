import { useEffect, useState } from "react";
import { ProductStoreNavigation } from "../../../../common-app/sitePagesStores/models/productStoreNavigation";
import { useForceRefresh } from "../../../hooks/refresh/forceRefresh";
import { useTranslationCommonFrontEnd } from "../../../translations/translation";
import { HorizontalListComponent } from "../../horizontalList/horizontalList";
import { Spanner } from "../../micro/spanner/spanner";
import { PriceComponent } from "../../price/price";
import { VerticalListComponent } from "../../verticalList/verticalList";
import { VerticalWhiteSpaceComponent } from "../../verticalWhiteSpace/verticalWhiteSpace";
import { ButtonGoToCheckoutComponent } from "./components/buttonGoToCheckout/buttonGoToCheckout";
import { ProductCartItemComponent } from "./components/productCartItem/productCartItem";
import { DynamicPageStoreProvider } from "./provider/dynamicPageStoreProvider";

import './dynamicPageStoreCart.css';

interface DynamicPageStoreCartProps {
  dataProvider: DynamicPageStoreProvider;
}

export function DynamicPageStoreCartComponent({
  dataProvider
}: DynamicPageStoreCartProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const [, forceRefresh] = useForceRefresh();

  const [products, setProducts] = useState<ProductStoreNavigation[]>([]);
  const [cartTotal, setCartTotal] = useState<number>(0);

  const itemsInCart = dataProvider.getProductsInCart();

  const handleRemoveItem = (productId: string) => () => {
    dataProvider.removeProductFromCart(productId);
    dataProvider.getCartTotal().then(total => setCartTotal(total));
    forceRefresh();
  }

  const handleChangeQuantity = (productId: string, quantity: number) => {
    dataProvider.updateProductInCart(productId, quantity);
    dataProvider.getCartTotal().then(setCartTotal);
    forceRefresh();
  }

  useEffect(() => {
    dataProvider.getProductsStoreNavigation().then(setProducts);
    dataProvider.getCartTotal().then(setCartTotal);
  }, [dataProvider]);

  return (

    <div>
      <div className="group-label">{t('cart')}</div>

      {itemsInCart.length === 0 &&
        <div className="no-results">{t('emptyCart')}</div>
      }

      {itemsInCart.length > 0 &&
        <>
          <VerticalListComponent>
            {itemsInCart.map(itemInCart => {

              const product = products.find(product => product.id === itemInCart.productId);

              if (!product) {
                return <div>{t('productNotFound')}</div>
              }

              return <ProductCartItemComponent
                cartItem={itemInCart}
                product={product}
                handleRemoveItem={handleRemoveItem(itemInCart.productId)}
                handleChangeQuantity={(quantity) => handleChangeQuantity(itemInCart.productId, quantity)}
                key={itemInCart.productId}
              />
            })}
          </VerticalListComponent>

          <VerticalListComponent>

            <div className="total-row">
              <div className="total-label">
                {t('total')}
              </div>
              <Spanner />
              <div className="total-price">
                <PriceComponent value={cartTotal} />
              </div>
            </div>

            <HorizontalListComponent>
              <Spanner />
              <ButtonGoToCheckoutComponent />
            </HorizontalListComponent>
          </VerticalListComponent>
          <VerticalWhiteSpaceComponent amount={3} />
        </>
      }
    </div>
  )
}