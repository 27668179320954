import { ButtonCopyToClipboardComponent } from "../../../../../common-frontend/components/buttonCopyToClipboard/buttonCopyToClipboard";
import { LinkNewWindow } from "../../../../../common-frontend/components/linkNewWindow/linkNewWindow";

import './locationDisplay.css';

interface LocationDisplayComponentProps {
  url: string;
}

export function LocationDisplayComponent({
  url
}: LocationDisplayComponentProps): JSX.Element {

  return <div className="location-display-component">
    <LinkNewWindow url={url} />
    <div>
      <ButtonCopyToClipboardComponent
        valueToCopy={url}
        size="sm"
      />
    </div>
  </div>
}