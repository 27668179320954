import { BlogPostAdminUpdate } from "./blogPostAdminUpdate";

export class BlogPostAdmin {
  id: string = "";
  pageId: string = "";
  title: string = "";
  subTitle: string | undefined;
  content: string | undefined;
  category: string | undefined;
  visible: boolean = false;
  creatorId: string = "";
  creationDate: Date = new Date();

  public static copyFromBlogPostAdminUpdate(blogPost: BlogPostAdmin, blogPostUpdate: BlogPostAdminUpdate): void {
    blogPost.title = blogPostUpdate.title;
    blogPost.subTitle = blogPostUpdate.subTitle;
    blogPost.content = blogPostUpdate.content;
    blogPost.category = blogPostUpdate.category;
    blogPost.visible = blogPostUpdate.visible;
  }
}
