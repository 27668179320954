import { RequestBody } from "../../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";

export class UpdateSiteRouteDef extends IRouteDefinition {
  constructor() {
    super("PUT");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(siteId: string): string {
    return `sites/update-site?` + QueryParamsUtil.build({ "site-id": siteId });
  }

  public routeTemplate(): string {
    return 'sites/update-site';
  }
}

export const enum UpdateSiteQueryParams {
  siteId = 'site-id',
}

export class UpdateSiteRequestBody extends RequestBody {
  constructor(
    public name: string,
    public domainName: string,
    public isActive: boolean,
    public homePageId: string
  ) {
    super();
  }
}

