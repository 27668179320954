import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ServiceFeatureAnalytics } from "../../services/features/analytics/serviceFeatureAnalytics";

export function usePageTracking() {
    const location = useLocation();

    useEffect(() => {
        ServiceFeatureAnalytics.saveRouteVisited(location.pathname);
    }, [location]);
};
