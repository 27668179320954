import { Product } from "../../../../../../common-app/sitePagesStores/models/product";
import { PriceComponent } from "../../../../price/price";
import { VerticalListComponent } from "../../../../verticalList/verticalList";
import { DynamicPageStoreProvider } from "../../provider/dynamicPageStoreProvider";
import { ButtonAddToCart } from "../buttonAddToCart/buttonAddToCart";
import { ButtonViewCartComponent } from "../buttonViewCart/buttonViewCart";

import "./productPriceAddToCart.css";

interface ProductPriceAddToCartProps {
  dataProvider: DynamicPageStoreProvider;
  product: Product;
}

export function ProductPriceAddToCartComponent({
  dataProvider,
  product
}: ProductPriceAddToCartProps): JSX.Element {

  const handleAddToCart = () => {
    dataProvider.addProductToCart(product.id, 1, product.price);
  }

  return (
    <div className="price-and-add-to-cart-component">
      <VerticalListComponent>
        <PriceComponent value={product.price} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "var(--size-4)",
            flexWrap: "wrap",
          }}>
          <ButtonAddToCart
            product={product}
            handleAddToCart={handleAddToCart}
          />
          <ButtonViewCartComponent />
        </div>
      </VerticalListComponent>
    </div>
  );
}