import './logo.css';

export function LogoComponent(): JSX.Element {
    return (
        <div className="component-logo">
            <div className="component-logo-square">
                <div className="component-logo-text-pixel">
                    Pixel
                </div>
            </div>
            <div className="component-logo-text-vaidoso">
                Vaidoso
            </div>
        </div>
    );
}