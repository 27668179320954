import { RequestBody } from "../../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";
import { SitePageType } from "../../enums/sitePageType";

export class CreateSitePageRouteDef extends IRouteDefinition {
  constructor() {
    super("POST");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(): string {
    return `sites/create-site-page`;
  }

  public routeTemplate(): string {
    return 'sites/create-site-page';
  }
}

export class CreateSitePageRequestBody extends RequestBody {
  constructor(
    public siteId: string,
    public pageId: string,
    public pageType: SitePageType,
    public pageName: string,
    public anchorPageId: string | undefined
  ) {
    super();
  }
}
