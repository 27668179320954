import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { HorizontalListComponent } from '../../../common-frontend/components/horizontalList/horizontalList';
import { VerticalListComponent } from '../../../common-frontend/components/verticalList/verticalList';

import './codeGenActions.css';

interface RouteParameter {
  name: string;
  type: string;
}

interface QueryParameter {
  name: string;
  urlName: string;
  type: string;
}

interface Property {
  name: string;
  type: string;
}

function nameToURLName(input: string): string {
  return input
    .replace(/([A-Z])/g, '-$1') // Add hyphen before each uppercase letter
    .toLowerCase()              // Convert the entire string to lowercase
    .replace(/^-/, '');         // Remove any leading hyphen
}

export function CodeGenActions(): JSX.Element {

  // State variables for inputs
  const [module, setModule] = useState('');
  const [action, setAction] = useState('');
  const [httpMethod, setHttpMethod] = useState('GET');
  const [routePath, setRoutePath] = useState('');
  const [routeTemplate, setRouteTemplate] = useState('');
  const [requiresSiteOwnershipPermissions, setRequiresSiteOwnershipPermissions] = useState(false);
  const [className, setClassName] = useState('');
  const [routeParameters, setRouteParameters] = useState<RouteParameter[]>([]);
  const [queryParameters, setQueryParameters] = useState<QueryParameter[]>([]);
  const [hasRequestBody, setHasRequestBody] = useState(false);
  const [requestBodyClassName, setRequestBodyClassName] = useState('');
  const [requestBodyProperties, setRequestBodyProperties] = useState<Property[]>([]);
  const [hasResponse, setHasResponse] = useState(false);
  const [responseClassName, setResponseClassName] = useState('');
  const [responseProperties, setResponseProperties] = useState<Property[]>([]);

  useEffect(() => {
    setClassName(action + "RouteDef");
    setRequestBodyClassName(action + "RequestBody");
    setResponseClassName(action + "Response");
  }, [action]);



  // State variable for generated code
  const [generatedCode, setGeneratedCode] = useState('');

  // Function to generate code based on inputs
  const generateCode = useCallback(() => {
    let code = '';

    // Generate Route Definition
    code += `export class ${className} extends IRouteDefinition {\n\n`;
    code += `  constructor() {\n`;
    code += `    super('${httpMethod}');\n`;

    if (requiresSiteOwnershipPermissions) {
      code += `    this.permissions = new RouteDefinitionPermissions(true);\n`;
    }
    // Missing permissions here
    code += `  }\n\n`;

    // Query parameters
    const tempQueryParams: string[] = [];
    queryParameters.forEach((param) => {

      if (param.name !== param.urlName) {
        tempQueryParams.push(`"${param.urlName}": ${param.name}`);
      }
      else {
        tempQueryParams.push(`${param.name}`);
      }
    });

    const queryParamsUtilPart = "QueryParamsUtil.build({ \n      " + tempQueryParams.join(',\n      ') + " \n    })";

    const queryParamsString = tempQueryParams.length ? queryParamsUtilPart : '';

    // Route function
    const allParams = [...routeParameters, ...queryParameters].map((param) => `${param.name}: ${param.type}`).join(', ');

    if (allParams.length) {
      code += `  public route(${allParams}): string {\n`;
    }
    else {
      code += `  public route(): string {\n`;
    }

    //-------------------------------

    var routeCodeLine = "";
    routeCodeLine += `    return \`${module}/${routePath}`;

    if (queryParameters.length > 0) {
      routeCodeLine += `?\` + ${queryParamsString}`;
    }
    else {
      routeCodeLine += `\``;
    }

    routeCodeLine += ';';
    code += routeCodeLine;
    code += '\n  }\n\n';

    code += '  public routeTemplate(): string {\n';
    code += `    return '${module}/${routeTemplate}';\n`;
    code += '  }\n';
    code += `}\n\n`;


    // Generate Route Params Enum
    if (routeParameters.length) {
      const enumName = `${action}RouteParams`;
      code += `export const enum ${enumName} {\n`;
      routeParameters.forEach((param) => {
        code += `  ${param.name} = '${param.name}',\n`;
      });
      code += `}\n\n`;
    }

    // Generate Query Params Enum
    if (queryParameters.length) {
      const enumName = `${action}QueryParams`;
      code += `export const enum ${enumName} {\n`;
      queryParameters.forEach((param) => {
        code += `  ${param.name} = '${param.urlName}',\n`;
      });
      code += `}\n\n`;
    }

    // Generate Request Body Class
    if (hasRequestBody) {
      code += `export class ${requestBodyClassName} extends RequestBody {\n`;
      code += `  constructor(\n`;

      const tempParams: string[] = [];
      requestBodyProperties.forEach((prop) => {
        tempParams.push(`    public ${prop.name}: ${prop.type}`);
      });

      code += tempParams.join(',\n') + '\n';
      code += `  ) {\n    super();\n  }\n}\n\n`;
    }

    // Generate Response Class
    if (hasResponse) {
      code += `export class ${responseClassName} extends RequestResponse {\n`;
      code += `  constructor(\n`;

      const tempParams: string[] = [];
      responseProperties.forEach((prop) => {
        tempParams.push(`    public ${prop.name}: ${prop.type}`);
      });
      code += tempParams.join(',\n') + '\n';
      code += `  ) {\n    super();\n  }\n}\n`;
    }

    // Imports

    const imports: string[] = [];
    if (hasRequestBody) {
      imports.push(`import { RequestBody } from "../../../common/api/requests/body/requestBody";`);
    }
    if (hasResponse) {
      imports.push(`import { RequestResponse } from "../../../common/api/requests/response/requestResponse";`);
    }
    imports.push(`import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";`);

    if (queryParameters.length) {
      imports.push(`import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";`);
    }

    code = imports.join('\n') + '\n\n' + code;

    setGeneratedCode(code);
  }, [action,
    className,
    hasRequestBody,
    hasResponse,
    httpMethod,
    module,
    queryParameters,
    requestBodyClassName,
    requestBodyProperties,
    responseClassName,
    responseProperties,
    routeParameters,
    routePath,
    routeTemplate,
    requiresSiteOwnershipPermissions
  ]);

  useEffect(() => {
    generateCode();
  }, [module,
    action,
    httpMethod,
    routePath,
    routeTemplate,
    className,
    routeParameters,
    queryParameters,
    hasRequestBody,
    requestBodyClassName,
    requestBodyProperties,
    hasResponse,
    responseClassName,
    responseProperties,
    requiresSiteOwnershipPermissions,
    generateCode]);

  // JSX for the component
  return (
    <div className="code-gen-actions">
      
      {/* Group Base Information */}
      <div className='group'>
        <h5>Base Information</h5>

        <VerticalListComponent>
          <div>

            {/* Module */}
            <label>
              Module
            </label>
            <input
              className='edit-value'
              type="text"
              value={module}
              onChange={(e) => setModule(e.target.value)}
              placeholder="e.g., contacts"
            />
          </div>

          <div>

            {/* Action */}
            <label>
              Action
            </label>
            <input
              className='edit-value'
              type="text"
              value={action}
              onChange={(e) => setAction(e.target.value)}
              placeholder="e.g., CreateContact"
            />
          </div>


          {/* HTTP Method */}
          <div>
            <label>
              HTTP Method:
            </label>
            <select
              value={httpMethod}
              onChange={(e) => setHttpMethod(e.target.value)}>
              <option>GET</option>
              <option>POST</option>
              <option>PUT</option>
              <option>DELETE</option>
            </select>
          </div>

          {/* Route Path */}
          <div>
            <label>
              Route Path:
            </label>
            <input
              className='edit-value'
              type="text"
              value={routePath}
              onChange={(e) => setRoutePath(e.target.value)}
              // eslint-disable-next-line no-template-curly-in-string
              placeholder='e.g., ${siteId}/popup-needs-showing'
            />
          </div>
          <div style={{ marginLeft: "200px" }}>
            {/* Missing Route Parameters */}
            {routeParameters.map((param, index) => (
              <div key={index}>
                {!routePath.includes(`\${${param.name}}`) && (
                  <span><i className="bi bi-x-square-fill"></i> {param.name} is missing in the route path</span>
                )}
              </div>
            ))}
          </div>

          <div>
            <label>
              Route Template:
            </label>
            <input
              className='edit-value'
              type="text"
              value={routeTemplate}
              onChange={(e) => setRouteTemplate(e.target.value)}
              placeholder="e.g., :siteId/popup-needs-showing"
            />
          </div>

          {/* Requires site ownership permissions */}
          <label className="question">
            <input
              type="checkbox"
              checked={requiresSiteOwnershipPermissions}
              onChange={(e) => setRequiresSiteOwnershipPermissions(e.target.checked)}
            />
            Requires site ownership permissions
          </label>

          <div style={{ marginLeft: "200px" }}>
            {/* Missing Route Parameters */}
            {routeParameters.map((param, index) => (
              <div key={index}>
                {!routeTemplate.includes(`:${param.name}`) && (
                  <span><i className="bi bi-x-square-fill"></i> {param.name} is missing in the route template</span>
                )}
              </div>
            ))}
          </div>

          <div>

            {/* Class Name */}
            <label>
              Class Name
            </label>
            <input
              disabled
              className='edit-value'
              type="text"
              value={className}
              placeholder="e.g., CreateContactRouteDef"
            />
          </div>
        </VerticalListComponent>

      </div>


      {/* Group Route Parameters */}
      <div className='group'>
        <h5>Route Parameters</h5>

        <VerticalListComponent>

          {routeParameters.map((param, index) => (
            <div key={index}>
              <HorizontalListComponent>
                <input
                  type="text"
                  placeholder="Route Parameter Name"
                  value={param.name}
                  onChange={(e) => {
                    const newParams = [...routeParameters];
                    newParams[index].name = e.target.value;
                    setRouteParameters(newParams);
                  }}
                />
                <input
                  type="text"
                  placeholder="Type"
                  value={param.type}
                  onChange={(e) => {
                    const newParams = [...routeParameters];
                    newParams[index].type = e.target.value;
                    setRouteParameters(newParams);
                  }}
                />
                <Button
                  onClick={() => {
                    const newParams = routeParameters.filter((_, i) => i !== index);
                    setRouteParameters(newParams);
                  }}
                >
                  Remove
                </Button>
              </HorizontalListComponent>
            </div>
          ))}

          <div>
            <Button
              onClick={() =>
                setRouteParameters([...routeParameters, { name: '', type: 'string' }])
              }
            >
              Add Route Parameter
            </Button>
          </div>

        </VerticalListComponent>
      </div>

      {/* Query Parameters */}
      <div className='group'>
        <h5>Query Parameters</h5>
        <VerticalListComponent>

          {queryParameters.map((param, index) => (
            <div key={index}>
              <HorizontalListComponent>

                <input
                  type="text"
                  placeholder="Query Parameter Name"
                  value={param.name}
                  onChange={(e) => {
                    const newParams = [...queryParameters];
                    newParams[index].name = e.target.value;
                    newParams[index].urlName = nameToURLName(e.target.value);
                    setQueryParameters(newParams);
                  }}
                />
                <input
                  type="text"
                  placeholder="Type"
                  value={param.type}
                  onChange={(e) => {
                    const newParams = [...queryParameters];
                    newParams[index].type = e.target.value;
                    setQueryParameters(newParams);
                  }}
                />

                <Button
                  onClick={() => {
                    const newParams = queryParameters.filter((_, i) => i !== index);
                    setQueryParameters(newParams);
                  }}
                >
                  Remove
                </Button>

                <div>
                  {param.urlName}
                </div>
              </HorizontalListComponent>
            </div>
          ))}
          <div>
            <Button onClick={() => setQueryParameters([...queryParameters, { name: '', urlName: '', type: 'string' }])}>
              Add Query Parameter
            </Button>
          </div>
        </VerticalListComponent>

      </div>

      {/* Group Request */}

      <div className='group'>
        <h5>Request</h5>

        {/* Has Request Body */}
        <label className="question">
          <input
            type="checkbox"
            checked={hasRequestBody}
            onChange={(e) => setHasRequestBody(e.target.checked)}
          />
          Does the Route Have a Request Body?
        </label>

        {hasRequestBody && (
          <div>
            <VerticalListComponent>

              <div>
                {/* Request Body Class Name */}
                <label className='label'>
                  Class Name
                </label>
                <input
                  className='edit-value'
                  disabled
                  type="text"
                  value={requestBodyClassName}
                  placeholder="e.g., CreateContactRequestBody"
                />


              </div>

              {/* Request Body Properties */}

              {requestBodyProperties.map((prop, index) => (
                <div key={index}>

                  <HorizontalListComponent>

                    <input
                      type="text"
                      placeholder="Name"
                      value={prop.name}
                      onChange={(e) => {
                        const newProps = [...requestBodyProperties];
                        newProps[index].name = e.target.value;
                        setRequestBodyProperties(newProps);
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Type"
                      value={prop.type}
                      onChange={(e) => {
                        const newProps = [...requestBodyProperties];
                        newProps[index].type = e.target.value;
                        setRequestBodyProperties(newProps);
                      }}
                    />
                    <Button
                      onClick={() => {
                        const newProps = requestBodyProperties.filter((_, i) => i !== index);
                        setRequestBodyProperties(newProps);
                      }}
                    >
                      Remove
                    </Button>

                  </HorizontalListComponent>

                </div>
              ))}
              <div>


                <Button
                  onClick={() =>
                    setRequestBodyProperties([...requestBodyProperties, { name: '', type: 'any' }])
                  }
                >
                  Add Request Body Property
                </Button>
              </div>

            </VerticalListComponent>

          </div>
        )}
      </div>



      {/* Group Response */}

      <div className="group">
        <h5>Response</h5>

        {/* Has Response */}
        <label className="question">
          <input
            type="checkbox"
            checked={hasResponse}
            onChange={(e) => setHasResponse(e.target.checked)}
          />
          Does the Route Have a Response?
        </label>

        {hasResponse && (
          <div>

            <VerticalListComponent>

              <div>
                {/* Response Class Name */}
                <label className="label">
                  Class Name
                </label>
                <input
                  className='edit-value'
                  disabled
                  type="text"
                  value={responseClassName}
                  placeholder="e.g., GetContactsResponse"
                />
              </div>

              {/* Response Properties */}

              {responseProperties.map((prop, index) => (
                <div key={index}>

                  <HorizontalListComponent>


                    <input
                      type="text"
                      placeholder="Name"
                      value={prop.name}
                      onChange={(e) => {
                        const newProps = [...responseProperties];
                        newProps[index].name = e.target.value;
                        setResponseProperties(newProps);
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Type"
                      value={prop.type}
                      onChange={(e) => {
                        const newProps = [...responseProperties];
                        newProps[index].type = e.target.value;
                        setResponseProperties(newProps);
                      }}
                    />
                    <Button
                      onClick={() => {
                        const newProps = responseProperties.filter((_, i) => i !== index);
                        setResponseProperties(newProps);
                      }}
                    >
                      Remove
                    </Button>
                  </HorizontalListComponent>

                </div>
              ))}
              <div>
                <Button
                  onClick={() =>
                    setResponseProperties([...responseProperties, { name: '', type: 'any' }])
                  }
                >
                  Add Response Property
                </Button>
              </div>
            </VerticalListComponent>
          </div>
        )}
      </div>

      {/* Generate Button */}

      <div className='group'>
        <Button onClick={generateCode}>Generate Code</Button>
      </div>

      {/* Generated Code */}
      <div>
        <h3>Generated Code</h3>
        <textarea
          className='generated-code'
          readOnly
          value={generatedCode}
          rows={20}
          cols={140}
        ></textarea>
      </div>
    </div>
  );
};
