import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SitePageType } from '../../../../../common-app/sites/enums/sitePageType';
import { SitePageEditorList } from '../../../../../common-app/sites/models/sitePageEditorList';
import { ServiceApiSites } from '../../../../../services/api/serviceApiSites';
import { SiteNameComponent } from '../../common/siteName/siteName';
import { SitePageSelectParentModalComponent } from '../../common/sitePageSelectParentModal/SitePageSelectParentModal';
import { AddSitePageButtonComponent } from '../../pages/addSitePage/addSitePageButton/addSitePageButton';
import { PageToDisplay, SiteEditorSidePanelItemComponent } from './siteEditorSidePanelItemList';

import './siteEditorSidePanel.css';

interface SiteEditorSidePanelProps {
  dataVersion: number;
  site: string;
  siteId: string;

  selectedItemId: string;
  setSelectedItem: (selectedItemId: string) => void;

  onAddNewPage: (pageType: SitePageType) => void;

  callbackPagesChanged: () => void;
}

export function SiteEditorSidePanelComponent({
  dataVersion,
  siteId,
  selectedItemId,
  setSelectedItem,
  onAddNewPage,
  callbackPagesChanged

}: SiteEditorSidePanelProps): JSX.Element {

  const { t } = useTranslation();

  const [pages, setPages] = useState<SitePageEditorList[]>([]);

  const [showChangePageParentModel, setShowChangePageParentModal] = useState<boolean>(false);

  const handleShowChangeParentModal = (pageId: string) => {
    setShowChangePageParentModal(true);
  }

  const refreshData = () => {
    ServiceApiSites.getSitePagesEditorList(siteId).then((pages) => {
      setPages(pages);
    });
  }

  const handlePagesReorganized = async (parentPageId: string, pageIds: string[]) => {

    const result = await ServiceApiSites.reorderSitePages(siteId, parentPageId, pageIds);

    if (result) {
      refreshData();
    }
  }

  const handleChangeParent = async (pageId: string, newParentPageId: string) => {

    setShowChangePageParentModal(false);

    // Update the data on the server
    const result = await ServiceApiSites.updateSitePageParent(siteId, pageId, newParentPageId);
    if (result) {
      refreshData();
    }
  }

  useEffect(() => {
    refreshData();
  }, [siteId, dataVersion]);

  const createPageDisplayFromPage = (page: SitePageEditorList): PageToDisplay => {
    return {
      pageInfo: page,
      children: []
    }
  }

  const getPagesToDisplay = (pages: SitePageEditorList[]): PageToDisplay[] => {

    // Build the tree
    const arrPagesToDisplay: PageToDisplay[] = [];

    // Build the root items
    for (let curPage = 0; curPage < pages.length; curPage++) {
      const page = pages[curPage];

      if (page.parentPageId === '') {
        arrPagesToDisplay.push(createPageDisplayFromPage(page));
        continue;
      }
    }

    // Build the children
    const buildChildren = (displayPage: PageToDisplay) => {
      for (let curPage = 0; curPage < pages.length; curPage++) {
        const page = pages[curPage];
        if (page.parentPageId === displayPage.pageInfo.id) {
          const childPage = createPageDisplayFromPage(page);
          displayPage.children.push(childPage);
          buildChildren(childPage);
        }
      }
    }

    // Build the children
    for (let curPage = 0; curPage < arrPagesToDisplay.length; curPage++) {
      buildChildren(arrPagesToDisplay[curPage]);
    }

    return arrPagesToDisplay;
  }

  const pagesToDisplay = getPagesToDisplay(pages);

  return (
    <div className="site-editor-side-panel-component">

      {/* Site Settings */}
      <div
        className={classNames("item item-site", { "item-selected": selectedItemId === siteId })}
        onClick={() => setSelectedItem(siteId)}
      >
        <SiteNameComponent name={t('site')}></SiteNameComponent>
      </div>

      {/* Site Pages */}
      <SiteEditorSidePanelItemComponent
        siteId={siteId}
        parentItem={undefined}
        pagesToDisplay={pagesToDisplay}
        selectedItemId={selectedItemId}
        setSelectedItem={setSelectedItem}
        onPagesReorganized={handlePagesReorganized}
        onShowChangeParentModal={handleShowChangeParentModal}
        callbackPagesChanged={callbackPagesChanged}
      />

      {/* Add Page Button */}
      <div
        className='add-page-holder'>
        <AddSitePageButtonComponent
          onAddNewPage={onAddNewPage}
        />
      </div>

      {/* Change page parent modal */}
      <SitePageSelectParentModalComponent
        onClose={() => { setShowChangePageParentModal(false) }}
        onChangeParent={handleChangeParent}
        siteId={siteId}
        pageId={selectedItemId}
        show={showChangePageParentModel} />
    </div>
  );
}
