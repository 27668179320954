import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class CanCurrentUserEditSiteRouteDef extends IRouteDefinition {

  constructor() {
    super('GET');
  }

  public route(siteId: string, deviceId: string, sessionId: string): string {
    return `system/canCurrentUserEditSite?` + QueryParamsUtil.build({ 
      "site-id": siteId,
      "device-id": deviceId,
      "session-id": sessionId 
    });
  }

  public routeTemplate(): string {
    return 'system/canCurrentUserEditSite';
  }
}

export const enum CanCurrentUserEditSiteQueryParams {
  siteId = 'site-id',
  deviceId = 'device-id',
  sessionId = 'session-id',
}

export class CanCurrentUserEditSiteResponse extends RequestResponse {
  constructor(
    public canEdit: boolean
  ) {
    super();
  }
}
