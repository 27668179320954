import { Button } from 'react-bootstrap';

interface GenerateUrlPartProps {
  onGenerateUrl: () => void;
}

export function GenerateUrlPartComponent({ onGenerateUrl }: GenerateUrlPartProps): JSX.Element {
  return (
    <Button
      variant='outline-primary'
      size='sm'
      onClick={onGenerateUrl}
    >
      <i className="bi bi-arrow-clockwise"></i>
    </Button>
  );
}
