import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SitePageEditorInfo } from '../../../../../../../common-app/sites/models/sitePageEditorInfo';
import { ContainerLabeledComponent } from '../../../../../../../common-frontend/components/containerLabeled/containerLabeled';
import { FormEditComponent } from '../../../../../../../common-frontend/components/form/formEdit/formEdit';
import { HorizontalListComponent } from '../../../../../../../common-frontend/components/horizontalList/horizontalList';
import { VerticalListComponent } from '../../../../../../../common-frontend/components/verticalList/verticalList';
import { useAutoSave } from '../../../../../../../common-frontend/hooks/autoSave/autoSave';
import { TypeUpdate } from '../../../../../../../common/util/types/typeUpdate';
import { ServiceApiSites } from '../../../../../../../services/api/serviceApiSites';

import './pagePaneSettings.css';

interface PagePaneSettingsComponentProps {
  siteId: string;
  pageId: string;
  callbackPagesChanged: () => void;
}

export function PagePaneSettingsComponent({
  siteId,
  pageId,
  callbackPagesChanged
}: PagePaneSettingsComponentProps): JSX.Element {

  const { t } = useTranslation();

  const savePageData = (pageData: SitePageEditorInfo): boolean => {

    ServiceApiSites.updateSitePage(
      siteId,
      pageId,
      pageData.name,
      pageData.isActive).then((result) => {
        if (result == true) {
          callbackPagesChanged();
        }
      });
    return true;
  };

  const {
    data: pageData,
    updateData: updatePageData
  } = useAutoSave<SitePageEditorInfo>(savePageData)

  useEffect(() => {
    const loadPageData = async () => {
      const pageEditData = await ServiceApiSites.getPageEditorInfo(siteId, pageId);
      if (pageEditData) {
        updatePageData(pageEditData, true);
      }
    }

    loadPageData();

  }, [siteId, pageId]);

  return (
    <div className="page-common-settings-component">
      <ContainerLabeledComponent
        label={t('page')}
        uniqueId={`page-settings-${pageId}`}
      >
        {pageData &&
          <VerticalListComponent>
            <HorizontalListComponent>
              <label
                className='label'
                htmlFor="siteName">
                {t('name')}
              </label>
              <FormEditComponent
                id="siteName"
                className='input'
                type="text"
                value={pageData.name}
                onChange={(value) => {
                  updatePageData(TypeUpdate.updateObjectField(pageData, 'name', value))
                }}
              />
            </HorizontalListComponent>

            <HorizontalListComponent>
              <label
                className='label'
                htmlFor="siteActive">{t('visible')}
              </label>
              <Form.Check
                type="switch"
                id="siteActive"
                checked={pageData.isActive}
                onChange={(e) => {
                  updatePageData(TypeUpdate.updateObjectField(pageData, 'isActive', e.target.checked))
                }}
              />
            </HorizontalListComponent>
          </VerticalListComponent >
        }
      </ContainerLabeledComponent>
    </div>
  );
}
