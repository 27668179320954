
export class StringUtil {
  public static enforceLength(value: string, length: number): string {
    if (value.length > length) {
      return value.substring(0, length);
    }
    if (value.length < length) {
      return value + ' '.repeat(length - value.length);
    }

    return value;
  }

  public static joinSentences(values: string[]): string {
    // Join the values with a space in between.
    return values.join(' ');
  }
}