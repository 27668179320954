import { GetStoreOrderStateColor } from "../../../../../../../common-app/sitePagesStores/enums/storeOrderState";
import { StoreOrderAdmin } from "../../../../../../../common-app/sitePagesStores/models/storeOrderAdmin";

import './orderListItem.css';

interface OrderListItemProps {
  item: StoreOrderAdmin;
}

export function OrderListItemComponent({ item }: OrderListItemProps): JSX.Element {

  const renderOrderStatusIndicator = (): JSX.Element => {

    const color = GetStoreOrderStateColor(item.state);

    return <div
      className="indicator"
      style={{
        backgroundColor: color,
      }} />;
  }

  return <div
    className="order-list-item-component"
  >
    <div className="name"
    >
      {item.buyerName}
    </div>
    <div>
      {renderOrderStatusIndicator()}
    </div>
  </div>
}