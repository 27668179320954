import { SearchStoreFrontComponent } from './components/frontPageSearch/frontPageSearch';
import { DynamicPageStoreProvider } from './provider/dynamicPageStoreProvider';
import { FrontPageCategoriesComponent } from './components/frontPageCategories/frontPageCategories';
import { VerticalWhiteSpaceComponent } from '../../verticalWhiteSpace/verticalWhiteSpace';
import { useNavigate } from 'react-router-dom';
import { FrontPageCartComponent } from './components/frontPageCart/frontPageCart';

interface DynamicPageStoreFrontProps {
  dataProvider: DynamicPageStoreProvider;
}

export function DynamicPageStoreFrontComponent({
  dataProvider
}: DynamicPageStoreFrontProps): JSX.Element {

  const navigate = useNavigate();

  return (
    <>
      <SearchStoreFrontComponent
        keyword=''
        onSubmit={(keyword) => {
          navigate(`${location.pathname}?search=${keyword}`);
        }} />
        
      <VerticalWhiteSpaceComponent amount={3} />

      <FrontPageCategoriesComponent dataProvider={dataProvider} />

      <VerticalWhiteSpaceComponent amount={3} />

      <FrontPageCartComponent dataProvider={dataProvider} />

      <VerticalWhiteSpaceComponent amount={3} />
    </>
  )
}