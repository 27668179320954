import './elementConfigurationSnippetTextInput.css';

interface ElementConfigurationSnippetTextInputProps {
  caption: string;
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
}

export function ElementConfigurationSnippetTextInputComponent({
  caption,
  value,
  placeholder,
  onChange
}: ElementConfigurationSnippetTextInputProps): JSX.Element {
  return (
    <div className="element-configuration-snippet-text-input-component">
      <label>{caption}</label>
      <input
        type="text"
        placeholder={placeholder ? placeholder : ''}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}
