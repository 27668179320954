import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslationCommonFrontEnd } from '../../../../../translations/translation';
import { VerticalListComponent } from '../../../../verticalList/verticalList';
import {
  CustomElement,
  CustomElementTypesEnum,
  CustomElementYoutubeVideo,
  CustomElementYoutubeVideoVersion
} from '../../../types';
import { ElementCreationRef } from '../../elementSettingsConfig';
import { ElementSettingsCreationProps } from '../../elementSettingsCreationProps';
import { ElementConfigurationSnippetTextInputComponent } from '../../snippets/elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput';
import { ElementConfigurationSnippetYoutubeContentComponent } from '../../snippets/elementConfigurationSnippetYoutubeContent/elementConfigurationSnippetYoutubeContent';
import { ElementConfigurationSnippetYoutubeVideoPreviewComponent } from '../../snippets/elementConfigurationSnippetYoutubeVideoPreview/elementConfigurationSnippetYoutubeVideoPreview';

export const ElementCreationYoutubeVideoComponent = forwardRef<ElementCreationRef, ElementSettingsCreationProps>(
  (props, ref): JSX.Element => {

    const t = useTranslationCommonFrontEnd();

    const [videoId, setVideoId] = useState<string>("");
    const [caption, setCaption] = useState<string>("");

    useEffect(() => {

      const canBeCreated = videoId.length > 0;
      props.elementCanBeCreated(canBeCreated);

    }, [videoId]);

    // Function that needs to be called from the parent component
    const internalCreateElement = (): CustomElement | undefined => {

      const youtubeVideoNode: CustomElementYoutubeVideo = {
        type: CustomElementTypesEnum.YoutubeVideo,
        version: CustomElementYoutubeVideoVersion,
        videoId: videoId,
        caption: caption,
        children: [{ text: '' }],
      };

      return youtubeVideoNode;
    }

    useImperativeHandle(ref, () => ({
      createElement: internalCreateElement,
    }));

    return (
      <div className="element-creation-url-component">

        <VerticalListComponent>

          <ElementConfigurationSnippetYoutubeContentComponent
            videoId={videoId}
            setVideoId={setVideoId}
          />

          <ElementConfigurationSnippetYoutubeVideoPreviewComponent
            videoId={videoId}
          />

          <ElementConfigurationSnippetTextInputComponent
            caption={t('subtitle')}
            placeholder=''
            value={caption}
            onChange={
              (value) => setCaption(value)
            }
          />

        </VerticalListComponent>
      </div>
    );
  });
