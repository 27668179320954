import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";

export class GetSiteCreationPopupNeedsShowingRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(siteId: string): string {
    return `sites/${siteId}/popup-needs-showing`;
  }

  public routeTemplate(): string {
    return 'sites/:siteId/popup-needs-showing';
  }
}

export const enum GetSiteCreationPopupNeedsShowingQueryParams {
  siteId = 'siteId'
}

export class GetSiteCreationPopupNeedsShowingResponse extends RequestResponse {
  constructor(
    public needsShowing: boolean) {
    super();
  }
}