import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SitePage } from "../../models/sitePage";

export class GetSitePagesRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(siteId: string): string {
    return `sites/get-site-pages?` + QueryParamsUtil.build({ siteId });
  }

  public routeTemplate(): string {
    return 'sites/get-site-pages';
  }
}

export const enum GetSitePagesQueryParams {
  siteId = 'siteId',
}

export class GetSitePagesResponse extends RequestResponse {
  constructor(
    public pages: SitePage[]) {
    super();
  }
}