import { Container } from "react-bootstrap";
import { CodeGenActions } from "../../components/debug/codeGenActions/codeGenActions";
import { PageTitle } from "../../common-frontend/components/pageTitle/pageTitle";

export function PageRouteBuilder(): JSX.Element {
  return (
    <div>
      <Container>
        <PageTitle title='Route Builder' />
        <CodeGenActions />
      </Container>
    </div>
  );
}