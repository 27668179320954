export class TreeNode<T> {

  constructor(item: T, id: string, name: string, enabled: boolean = true) {
    this.item = item;
    this.id = id;
    this.name = name;
    this.enabled = enabled;
    this.children = [];
  }
  item: T;
  children: TreeNode<T>[];

  // For rendering
  id: string;
  name: string;
  enabled: boolean;
}