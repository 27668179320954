import { useTranslationCommonFrontEnd } from "../../translations/translation";
import { MenuItemComponent } from "./menuItem";

export function MenuItemDuplicatePageComponent(): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  return (
    <MenuItemComponent
      caption={t('duplicatePage')}
      iconName='files'
    />
  );
}