
import { DynamicPageDataContext } from "./dynamicPageDataContext";

interface DynamicPageDataProviderProps {
  siteId: string;
  pageId: string;
  backendServerAddress: string;
  children: React.ReactNode;
}

export function DynamicPageDataProvider(
  {
    siteId,
    pageId,
    backendServerAddress,
    children
  }: DynamicPageDataProviderProps): JSX.Element {

  return (
    <DynamicPageDataContext.Provider
      value={{
        siteId,
        pageId,
        backendServerAddress
      }}
    >
      {children}
    </DynamicPageDataContext.Provider>
  )
}
