import { useRef } from "react";

export function useScroll<T extends HTMLElement>() {
  const refElement = useRef<T>(null);
  const scrollToElement = () => {
    refElement.current?.scrollIntoView({ behavior: 'smooth' });
  }

  return [
    scrollToElement,
    refElement
  ] as const;
}
