import { BlogPost } from "../../../../../common-app/sitePagesBlogs/models/blogPost";
import { DynamicPageBlogProvider } from "../../../../../common-frontend/components/dynamicPages/blog/provider/dynamicPageBlogProvider";
import { ServiceApiPageDataBlogs } from "../../../../../services/api/serviceApiPageDataBlogs";

export class DynamicPageBlogServiceProvider implements DynamicPageBlogProvider {
  public getBlogPost(pageId: string, postId: string): Promise<BlogPost | undefined> {
    return ServiceApiPageDataBlogs.getBlogPost(pageId, postId);
  }
  public getBlogPosts(pageId: string, pageSize: number, page: number): Promise<BlogPost[]> {
    return ServiceApiPageDataBlogs.getBlogPosts(pageId, pageSize, page);
  }
  public getBlogPostCount(pageId: string): Promise<number> {
    return ServiceApiPageDataBlogs.getBlogPostCount(pageId);
  }
  public getAdjacentBlogPosts(pageId: string, postId: string): Promise<{ previous: BlogPost | undefined; next: BlogPost | undefined; }> {
    return ServiceApiPageDataBlogs.getAdjacentBlogPosts(pageId, postId);
  }
}