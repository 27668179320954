import { BlogPostAdmin } from "../blogPostAdmin";

export class BlopPostAdminFactory {
  public static createNew(id: string, pageId: string): BlogPostAdmin {
    return {
      id,
      pageId,
      title: '',
      subTitle: '',
      content: '',
      category: '',
      visible: true,
      creatorId: '',
      creationDate: new Date()
    };
  }
}