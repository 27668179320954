export class NumbersUtil {
  static parseFloat(value: string): number {

    if (value === undefined || value === null || value === '') {
      return 0;
    }
    const result = parseFloat(value);
    if (isNaN(result)) {
      return 0;
    }

    return result;
  }

  static floatToString(value: number, covert0ToEmptyString: boolean = false): string {
    if (value === undefined || value === null) {
      return '';
    }

    if (isNaN(value)) {
      return '';
    }

    if (covert0ToEmptyString && value === 0) {
      return '';
    }

    return value.toString();
  }
}