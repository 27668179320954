import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class GetUserQueriesCountRouteDef extends IRouteDefinition {

  constructor() {
    super("GET");
  }

  public route(
    siteId: string,
    pageId: string): string {
    return `user-queries/count?` +
      QueryParamsUtil.build({
        "site-id": siteId,
        "page-id": pageId
      });
  }

  public routeTemplate(): string {
    return 'user-queries/count';
  }
}

export const enum GetUserQueriesCountQueryParams {
  siteId = 'site-id',
  pageId = 'page-id',
}

export class GetUserQueriesCountResponse extends RequestResponse {
  constructor(public count: number) {
    super();
  }
}