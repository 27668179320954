import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SitePageParentTreeNode } from '../../../../../common-app/sites/models/sitePageParentTreeNode';
import { TreeComponent } from '../../../../../common-frontend/components/tree/Tree';
import { TreeNode } from '../../../../../common/util/tree/treeNode';
import { TreeNodeUtil } from '../../../../../common/util/tree/treeNodeUtil';
import { ServiceApiSites } from '../../../../../services/api/serviceApiSites';
import { PageNameComponent } from '../pageName/pageName';

import './siteHomePageSelectModal.css';

interface SiteHomePageSelectModalProps {
  show: boolean;
  siteId: string,
  pageId: string;
  onClose: () => void;
  onSelectHomePage: (newHomePageId: string) => void;
}

export function SiteHomePageSelectModalComponent({
  show,
  siteId,
  pageId,
  onClose,
  onSelectHomePage
}: SiteHomePageSelectModalProps): JSX.Element {

  const { t } = useTranslation();

  const [treeNodes, setTreeNodes] = useState<TreeNode<SitePageParentTreeNode>[]>([]);
  const [temporaryHomePageId, setTemporaryParentPageId] = useState<string>();

  // Load all the data when the page id changes
  useEffect(() => {

    const loadPages = async () => {

      const pages = await ServiceApiSites.getSitePagesParentTree(siteId, pageId);
      const treeNodes = TreeNodeUtil.buidlTreeFromList(
        pages,
        (node, parentNode) => node.parentPageId === parentNode.id,
        (node) => node.parentPageId === "" || node.parentPageId === null,
        (node) => node.id,
        (node) => node.name,
        () => true
      );

      setTreeNodes(treeNodes);

      if (pages.find(p => p.id === pageId) === undefined) {
        setTemporaryParentPageId(undefined);
      }
      else {
        setTemporaryParentPageId(pageId);
      }
    }
    loadPages();

  }, [pageId, show])

  // Control the confirm button 
  const getConfirmChoiceEnabled = () => {
    return temporaryHomePageId !== undefined;
  }

  // Send the results back
  const handleConfirmChoice = () => {
    if (temporaryHomePageId === undefined) {
      return;
    }

    onSelectHomePage(temporaryHomePageId);
  }

  return (
    <div className="site-start-page-modal-component">
      <Modal
        show={show}
        centered
      >
        <Modal.Header>
          <Modal.Title>{t('chooseHomePage')}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="site-start-page-modal-component-tree">
          <TreeComponent
            items={treeNodes}
            initialSelectedItemId={pageId}
            selectedItem={temporaryHomePageId}
            onSelect={(itemId) => {
              if (temporaryHomePageId === itemId) {
                setTemporaryParentPageId(undefined);
              }
              else {
                setTemporaryParentPageId(itemId);
              }
            }}
            renderNodeText={(node) => {
              return <PageNameComponent name={node.name} />;
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={onClose}>
            {t('close')}
          </Button>
          <Button
            variant="primary"
            disabled={getConfirmChoiceEnabled() === false}
            onClick={handleConfirmChoice}>
            {t('confirm')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  );
}
