import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ArrayUtil } from '../../../../../../common/util/array/arrayUtil';
import { TypeUpdate } from '../../../../../../common/util/types/typeUpdate';
import { useTranslationCommonFrontEnd } from '../../../../../translations/translation';
import { GetImagesInfoFunction } from '../../../../imageUploader/imageBrowser';
import { VerticalListComponent } from '../../../../verticalList/verticalList';
import { CustomElementImageUserGallery } from '../../../types';
import { ElementConfigurationSnippetTextInputComponent } from '../elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput';

import './elementConfigurationSnippetGalleryImagesCaptions.css';

type ImageDisplay = {
  id: string;
  url: string;
  altText: string;
}

interface ElementConfigurationSnippetGalleryImagesCaptionsProps {
  elementImageUserGallery: CustomElementImageUserGallery;
  updateElementImageUserGallery: (element: CustomElementImageUserGallery) => void;
  getImagesInfoFunction: GetImagesInfoFunction;
}

export function ElementConfigurationSnippetGalleryImagesCaptionsComponent({
  elementImageUserGallery,
  updateElementImageUserGallery,
  getImagesInfoFunction
}: ElementConfigurationSnippetGalleryImagesCaptionsProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const [lastRequestedImageIds, setLastRequestedImageIds] = useState<string[]>([]);

  const [galleryImages, setGalleryImages] = useState<ImageDisplay[]>();
  const [selectedImageId, setSelectedImageId] = useState<string>('');

  const imageIds = elementImageUserGallery.images.map((image) => image.imageId);

  useEffect(() => {

    const fetchImageData = async () => {
      const userFileImages = await getImagesInfoFunction(imageIds);
      if (userFileImages.length === 0) {
        return;
      }

      const galleryImages: ImageDisplay[] = userFileImages.map((userFileImage) => ({
        id: userFileImage.id,
        url: userFileImage.publicUrlThumbnail,
        altText: userFileImage.originalFileName,
      }));

      setGalleryImages(galleryImages);
    }

    // Check if we need to request the images again
    if (ArrayUtil.containSameData(lastRequestedImageIds, imageIds, (a, b) => a === b) === false) {

      // Request the data about the images again
      setLastRequestedImageIds(imageIds);

      // Select the first image
      if (imageIds.length > 0) {
        setSelectedImageId(imageIds[0]);
      }

      // Reqeust the data about the images
      fetchImageData();
    }
  }, [elementImageUserGallery]);

  const renderImageThumbnail = (imageId: string) => {

    if (!galleryImages) {
      return null;
    }

    const image = galleryImages.find((image) => image.id === imageId);

    const className = classNames(
      'image-item',
      { 'image-item-not-found': !image },
      { 'image-item-selected': imageId === selectedImageId }
    );

    let elem: JSX.Element | undefined;

    if (!image) {
      elem = <div className={className}>{t('imageNotFound')}</div>;
    }
    else {
      elem = <div className={className}>
        <img
          draggable={false}
          src={image.url}
          alt={image.altText}
        />
      </div>;
    }

    return elem;
  }

  const getImageCaption = (imageId: string) => {
    const image = elementImageUserGallery.images.find((image) => image.imageId === imageId);
    return image ? image.caption : '';
  }

  const handleImageCaptionChange = (imageId: string, caption: string) => {

    const updatedImages = elementImageUserGallery.images.map((image) => {
      if (image.imageId === imageId) {
        return TypeUpdate.updateObjectField(image, 'caption', caption);
      }
      return image;
    });

    const elementImageUserGalleryUpdated = TypeUpdate.updateObjectField(
      elementImageUserGallery,
      'images',
      updatedImages);

    updateElementImageUserGallery(elementImageUserGalleryUpdated);
  }

  return (
    <div className="element-configuration-snippet-gallery-config-component">
      <VerticalListComponent>
        <div className="images-list">
          {imageIds.map((imageId) => (
            <div
              key={imageId}
              onClick={() => setSelectedImageId(imageId)}
            >
              {renderImageThumbnail(imageId)}
            </div>
          ))}
        </div>

        <ElementConfigurationSnippetTextInputComponent
          caption={t('subtitle')}
          key={selectedImageId}
          value={
            getImageCaption(selectedImageId)
          }
          onChange={
            (value) => handleImageCaptionChange(selectedImageId, value)
          }
        />
      </VerticalListComponent>
    </div>
  );
}
