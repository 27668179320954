import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContainerLabeledComponent } from '../../../../../../common-frontend/components/containerLabeled/containerLabeled';
import { useRichTextEditor } from '../../../../../../common-frontend/components/richTextEditor/hooks/useRichTextEditor';
import { RichTextEditor } from '../../../../../../common-frontend/components/richTextEditor/richTextEditor';
import { VerticalListComponent } from '../../../../../../common-frontend/components/verticalList/verticalList';
import { useAutoSave } from '../../../../../../common-frontend/hooks/autoSave/autoSave';
import { ServiceApiPageDataStatic } from '../../../../../../services/api/serviceApiPageDataStatic';
import { ServiceApiSites } from '../../../../../../services/api/serviceApiSites';
import { useRichTextEditorContentManagerSite } from '../../../common/richTextEditorContentManagerSite/useRichTextEditorContentManagerSite';
import { PagePaneDynamicCollect } from '../../pagePanesDynamic/pagePaneDynamicCollect';
import { PagePaneProps } from '../pagePanesMapping';

export function PagePaneStaticEditorComponent({
  siteId,
  pageId,
  callbackPageContentsChanged
}: PagePaneProps): JSX.Element {

  const { t } = useTranslation();

  const editorConfig = useRichTextEditor(false, true);
  const contentManagerSite = useRichTextEditorContentManagerSite(siteId, pageId);

  const handleSaveEditorContents = (data: string): boolean => {
    // Save the data
    ServiceApiPageDataStatic.updatePageDataStatic(siteId, pageId, data);

    // Update the features used in this page
    const features = PagePaneDynamicCollect.getPageFeaturesFromRichTextEditorContent(
      data
    );

    ServiceApiSites.updatePageFeatures(siteId, pageId, features).then(() => {
      callbackPageContentsChanged();
    });

    return true;
  }

  const {
    updateData: updateEditorData
  } = useAutoSave<string>(handleSaveEditorContents);

  useEffect(() => {
    const getStaticPageData = async () => {
      const content = await ServiceApiPageDataStatic.getPageDataStatic(pageId);
      editorConfig.updateInitialValue(content);
    }
    getStaticPageData();
  }, [siteId, pageId]);

  return (
    <div className="page-contents-editor-static-component">
      <ContainerLabeledComponent
        label={t('content')}
        uniqueId={`page-content-static-${pageId}`}
      >
        <VerticalListComponent>
          {/* EDITOR */}
          <RichTextEditor
            key={editorConfig.getEditorKey()}
            features={{
              editable: editorConfig.getEditorEditable(),
              allowUserQueries: editorConfig.getEditorAllowUserQueries()
            }}
            initialValue={editorConfig.getEditorStartupValue()}
            contentManager={contentManagerSite}
            onChange={(newValue) => {
              editorConfig.updateContents(newValue);
              updateEditorData(newValue);
            }}
          />
        </VerticalListComponent>
      </ContainerLabeledComponent>
    </div>
  );
}
