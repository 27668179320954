import { ApiService } from "../../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../../common-frontend/services/api/backendServerEndpointProvider";
import {
  GetSiteCreationPopupNeedsShowingResponse,
  GetSiteCreationPopupNeedsShowingRouteDef
} from "../../../common-app/sites/actions/creationPopup/getSiteCreationPopupNeedsShowing";
import {
  UpdateSiteCreationPopupNeedsShowingRequestBody,
  UpdateSiteCreationPopupNeedsShowingRouteDef
} from "../../../common-app/sites/actions/creationPopup/updateSiteCreationPopupNeedsShowing";
import { RequestHeaderHelper } from "../../requestHeaderData/requestHeaderHelper";

export class ServiceApiSitesCreationPopup extends ApiService {

  static async getSiteCreationPopupNeedsShowing(siteId: string): Promise<boolean> {

    const routeDef = new GetSiteCreationPopupNeedsShowingRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSiteCreationPopupNeedsShowingResponse>(
      routeDef.route(siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return false;
    }

    return response.needsShowing;
  }

  static async updateSiteCreationPopupNeedsShowing(site: string, needsShowing: boolean = false): Promise<boolean> {

    const routeDef = new UpdateSiteCreationPopupNeedsShowingRouteDef();
    const body = new UpdateSiteCreationPopupNeedsShowingRequestBody(needsShowing);

    return this.DoRequest(
      routeDef.route(site),
      this.getRequestOptions(routeDef, body),
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(site)
    );
  }
}
