import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class GetBlogPostCountRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(pageId: string): string {
    return `blogs/blog-posts-count?` + QueryParamsUtil.build({ 'page-id': pageId });
  }

  public routeTemplate(): string {
    return 'blogs/blog-posts-count';
  }
}

export const enum GetBlogPostCountParams {
  pageId = 'page-id',
}

export class GetBlogPostCountResponse extends RequestResponse {
  constructor(public count: number) {
    super();
  }
}
