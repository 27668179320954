import { LocalStorage } from "../../../common-frontend/library/localStorage/localStorage";

export class LocalStorageSession {

    private static readonly sessionGroup = 'session';
    private static readonly sessionIdField = 'sessionId';
    private static readonly userIdField = 'userId';

    public static saveSessionId(sessionId: string) {
        LocalStorage.saveItem(this.sessionGroup, this.sessionIdField, sessionId);
    }

    public static getSessionId(): string | undefined {
        return LocalStorage.loadItem(this.sessionGroup, this.sessionIdField);
    }

    public static deleteSessionId() {
        LocalStorage.deleteItem(this.sessionGroup, this.sessionIdField);
    }

    public static saveUserId(UserId: string) {
        LocalStorage.saveItem(this.sessionGroup, this.userIdField, UserId);
    }

    public static getUserId(): string | undefined {
        return LocalStorage.loadItem(this.sessionGroup, this.userIdField);
    }

    public static deleteUserId() {
        LocalStorage.deleteItem(this.sessionGroup, this.userIdField);
    }
}