import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class GetBlogPostAdminCountRouteDef extends IRouteDefinition {

  constructor() {
    super("GET");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(pageId: string): string {
    return `blogs/blog-posts-admin-count?` +
      QueryParamsUtil.build(
        {
          'page-id': pageId
        }
      );
  }

  public routeTemplate(): string {
    return 'blogs/blog-posts-admin-count';
  }
}

export const enum GetBlogPostAdminCountListQueryParams {
  pageId = 'page-id',
}

export class GetBlogPostAdminCountResponse extends RequestResponse {
  constructor(public count: number) {
    super()
  }
}