
import './pageTitle.css'

interface PageTitleProps {
  title: string;
}

export function PageTitle({ title }: PageTitleProps): JSX.Element {
  return (
    <div className="page-title">
      <h1>
        {title}
      </h1>
    </div>
  );
}