import { SitePageFeature } from "../../../../../common-app/sites/enums/sitePageFeature";
import { VerticalListComponent } from "../../../../../common-frontend/components/verticalList/verticalList";
import { PagePaneDynamicComponent } from "./pagePaneDynamic";

interface PagePaneDynamicRendererProps {
  siteId: string;
  pageId: string;
  pageFeatures: SitePageFeature[];
}

export function PagePaneDynamicRendererComponent({
  siteId,
  pageId,
  pageFeatures
}: PagePaneDynamicRendererProps): JSX.Element {

  return <div className="page-features-component">
    <VerticalListComponent>
      {pageFeatures.map((feature, index) => {
        return <PagePaneDynamicComponent
          key={index}
          siteId={siteId}
          pageId={pageId}
          feature={feature}
        />
      })}
    </VerticalListComponent>
  </div>
}