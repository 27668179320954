import { useEffect, useState } from "react";
import { CustomElementImageUser } from "../../types";
import { ServiceApiUserFiles } from "../../../../../services/api/serviceApiUserFiles";
import { ImageGalleryComponent } from "../../../imageGallery/imageGallery";
import { RenderComponentProps } from "../renderComponentProps";
import { ImageGalleryItem } from "../../../imageGallery/imageGalleryItem";
import { useDynamicPageDataContext } from "../../../dynamicPages/provider/hooks/useDynamicPageDataContext";

import './renderImageUser.css';

export function RenderImageUserComponent({
  element,
  attributes,
  children }: RenderComponentProps): JSX.Element {

  const [galleryImage, setGalleryImage] = useState<ImageGalleryItem>();
  const dynamicPageContext = useDynamicPageDataContext();

  useEffect(() => {

    const elementImageUser = element as CustomElementImageUser;

    const fetchImageData = async () => {

      const userFileImages = await ServiceApiUserFiles.getUserImagesInfo(dynamicPageContext.siteId, [elementImageUser.image.imageId]);

      if (userFileImages.length === 0) {
        return;
      }

      elementImageUser.displayBlockOptions;

      setGalleryImage({
        id: userFileImages[0].id,
        url: dynamicPageContext.backendServerAddress + userFileImages[0].publicUrl,
        urlthumbnail: dynamicPageContext.backendServerAddress + userFileImages[0].publicUrlThumbnail,
        altText: userFileImages[0].originalFileName,
        caption: elementImageUser.image.caption,
        backgroundColor: elementImageUser.displayBlockOptions?.backgroundColor,
        borderColor: elementImageUser.displayBlockOptions?.borderColor,
        borderWidth: elementImageUser.displayBlockOptions?.borderWidth,
        margin: elementImageUser.displayBlockOptions?.margin
      });
    }

    fetchImageData();

  }, [element]);

  return (
    <div className="editor-user-image-node-parent">
      <div
        {...attributes}
        contentEditable={false}
        className="editor-user-image-node">

        {galleryImage &&
          <ImageGalleryComponent
            images={[galleryImage]}
          />
        }

        {children}
      </div>
    </div>
  )
}


