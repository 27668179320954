import { RequestHeaderData } from "../../common-frontend/services/api/requestHeaderData/requestHeaderData";
import { RequestHeaderSiteOwnerPermissions } from "./requestHeaderSiteOwnerPermission";

export class RequestHeaderHelper {
  static getRequestHeaderDataNoPermissions(): RequestHeaderData[] {
    return [];
  }

  static getRequestHeaderDataSiteOwnership(siteId: string): RequestHeaderData[] {
    return [
      new RequestHeaderSiteOwnerPermissions(siteId)
    ];
  }
}