import { LocalStorageAnalytics } from "../../../library/localStorage/analytics/analytics";
import { LocalStorageSession } from "../../../library/localStorage/session/session";
import { IUserIdentificationProvider } from "./userIdentificationProvider";

export class DefaultUserIdentificationProvider implements IUserIdentificationProvider {

  public getDeviceId(): string {
    const deviceId = LocalStorageAnalytics.getDeviceId();
    if (deviceId === undefined) {
      return "";
    }

    return deviceId;
  }

  public getSessionId(): string {
    const sessionId = LocalStorageSession.getSessionId();
    if (sessionId === undefined) {
      return "";
    }

    return sessionId;
  }
}
