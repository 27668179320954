import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SiteOwnership } from '../../../../common-app/sites/models/siteOwnership/siteOwnership';
import { HorizontalListComponent } from '../../../../common-frontend/components/horizontalList/horizontalList';
import { LinkNewWindow } from '../../../../common-frontend/components/linkNewWindow/linkNewWindow';
import { Spanner } from '../../../../common-frontend/components/micro/spanner/spanner';
import { VerticalListComponent } from '../../../../common-frontend/components/verticalList/verticalList';
import { Environment } from '../../../../common-frontend/environment/environment';
import { SitesUtil2 } from '../../../../common/sites/sitesUtil2';
import { DateUtil } from '../../../../common/util/date/dateUtil';

import './siteInformation.css';

interface SiteInformationProps {
  site: SiteOwnership;
  isClaimed: boolean;
  handleClaimSite: (siteId: string) => void;
}

export function SiteInformationComponent({
  site,
  isClaimed,
  handleClaimSite }: SiteInformationProps): JSX.Element {

  const { t } = useTranslation();

  return (
    <div className="site-information-component shadow-sm">
      <Card >
        <Card.Header><div className="header">{site.name}</div></Card.Header>
        <Card.Body>

          <VerticalListComponent>
            <HorizontalListComponent className='align-center'>
              <div className='label'>{t('path')}</div>
              <div className='value'>{site.domain}</div>
            </HorizontalListComponent>

            <HorizontalListComponent className='align-center'>
              <div className='label'>{t('url')}</div>
              <div className='value'>
                <LinkNewWindow url={SitesUtil2.getFullSiteUrl(Environment.GetAppClientUrl(), site.domain)} />
              </div>
            </HorizontalListComponent>

            <HorizontalListComponent className='align-center'>
              <div className='label'>{t('creationDate')}</div>
              <div className='value'>{DateUtil.formatDateAsHumanReadable(site.creationDate)}</div>
            </HorizontalListComponent>

          </VerticalListComponent>
        </Card.Body>

        <Card.Footer className="text-muted">
          <HorizontalListComponent className='align-center'>
            {isClaimed === false &&
              <Button
                variant="primary"
                onClick={() => handleClaimSite(site.id)}>
                {t('connectToMyAccount')}
              </Button>

            }
            <Spanner />
            <Link to={`/sites/${site.domain}`} >
              <Button variant="outline-primary">{t('visit')}</Button>
            </Link>

            <Link to={`${SitesUtil2.getSiteAdminUrl(site.domain)}`} >
              <Button variant="outline-primary">{t('edit')}</Button>
            </Link>
          </HorizontalListComponent>
        </Card.Footer>
      </Card>
    </div >
  )
}