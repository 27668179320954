import { RequestBody } from "../../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";

export class UpdateSiteCreationPopupNeedsShowingRouteDef extends IRouteDefinition {

  constructor() {
    super("PUT");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(siteId: string): string {
    return `sites/${siteId}/popup-needs-showing`;
  }

  public routeTemplate(): string {
    return 'sites/:siteId/popup-needs-showing';
  }
}

export const enum UpdateSiteCreationPopupNeedsShowingQueryParams {
  siteId = 'siteId'
}

export class UpdateSiteCreationPopupNeedsShowingRequestBody extends RequestBody {
  constructor(
    public needsShowing: boolean
  ) {
    super();
  }
}