import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";

export class DeleteSitePageRouteDef extends IRouteDefinition {
  constructor() {
    super("DELETE");
    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(siteId: string, pageId: string): string {
    return `sites/delete-site-page?` +
      QueryParamsUtil.build({
        "site-id": siteId,
        "page-id": pageId
      });
  }

  public routeTemplate(): string {
    return 'sites/delete-site-page';
  }
}

export const enum DeleteSitePageQueryParams {
  siteId = 'site-id',
  pageId = 'page-id',
}
