import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";

export class GetPossibleSiteDomainRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(siteId: string, siteName: string): string {
    return `sites/get-possible-site-domain?` + QueryParamsUtil.build({
      "site-id": siteId,
      "site-name": siteName
    });
  }

  public routeTemplate(): string {
    return 'sites/get-possible-site-domain';
  }
}

export const enum GetPossibleSiteDomainQueryParams {
  siteId = 'site-id',
  siteName = 'site-name',
}

export class GetPossibleSiteDomainResponse extends RequestResponse {
  constructor(
    public domain: string
  ) {
    super();
  }
}
