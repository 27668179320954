import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";
import { StoreOrderState } from "../enums/storeOrderState";

export class UpdateStoreOrderStateRouteDef extends IRouteDefinition {
  constructor() {
    super('PUT');
    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(storeOrderId: string): string {
    return `site-pages-store/store-order-state/${storeOrderId}`;
  }

  public routeTemplate(): string {
    return 'site-pages-store/store-order-state/:storeOrderId';
  }
}

export const enum UpdateStoreOrderStateRouteParams {
  storeOrderId = 'storeOrderId'
}

export class UpdateStoreOrderStateRequestBody extends RequestBody {
  constructor(
    public siteId: string,
    public pageId: string,
    public storeOrderState: StoreOrderState
  ) {
    super();
  }
}