import { FormEvent, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { LoginResult } from "../../../common-app/system/enums/loginResult";
import { ErrorListComponent } from "../../../common-frontend/components/errorList/errorList";
import { FormEditComponent } from "../../../common-frontend/components/form/formEdit/formEdit";
import { PageTitle } from "../../../common-frontend/components/pageTitle/pageTitle";
import { VerticalListComponent } from "../../../common-frontend/components/verticalList/verticalList";
import { ValidationEmail } from "../../../common/util/validation/email";
import { useUserSessionContext } from "../../../components/contexts/session/hooks/useUserSessionContext";
import { LocalStorageSession } from "../../../library/localStorage/session/session";
import { ServiceApiSystem } from "../../../services/api/serviceApiSystem";

import "./pageSignIn.css";

enum SignInButtonLabelOption {
  SignIn = "login",
  SigningIn = "signingIn"
}

export function PageSignIn(): JSX.Element {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const userSessionContext = useUserSessionContext();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [buttonSignInLabel, setButtonSignInLabel] = useState<string>(t(SignInButtonLabelOption.SignIn));
  const [isSigningInAccount, setIsSigningInAccount] = useState<boolean>(false);


  const handleSignIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let errors: string[] = [];

    // Use a regular expression to check if the email is valid
    if (ValidationEmail.isValid(email) === false) {
      errors.push(t('invalidEmail'));
    }

    if (errors.length > 0) {
      setErrorMessages(errors);
      return;
    }

    setButtonSignInLabel(t(SignInButtonLabelOption.SigningIn));
    setIsSigningInAccount(true);
    setErrorMessages([]);

    const result = await ServiceApiSystem.login(email, password);

    setIsSigningInAccount(false);
    setButtonSignInLabel(t(SignInButtonLabelOption.SignIn));

    switch (result.result) {
      case LoginResult.Success:

        // Save this to the local storage
        LocalStorageSession.saveSessionId(result.sessionId);
        LocalStorageSession.saveUserId(result.userId);

        // Save this to the session context
        userSessionContext.setSessionInformation(result.sessionId, result.userId);

        // Navigate to the user sites page
        navigate("/user-sites");
        break;

      case LoginResult.InvalidMailOrPassword:
        errors.push(t('invalidUserOrPassword'));
        break;
    }

    setErrorMessages(errors);
  }

  return (
    <div className="page-sign-in">
      <Container>
        <div className="content-parent">
          <div className="content">

            <PageTitle
              title={t('login')}
            />
            <Form onSubmit={(e) => { handleSignIn(e) }}>

              {/* Add an input for the user's mail */}
              <VerticalListComponent>
                <Form.Group controlId="formBasicEmail" >
                  <Form.Label>{t('email')}</Form.Label>
                  <FormEditComponent
                    type="text"
                    value={email}
                    onChange={setEmail}
                  />
                </Form.Group>

                {/* Add an input for the user's password */}
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>{t('password')}</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <div className="buttons-holder">
                  <Button
                    type="submit"
                    variant="primary"
                    className=""
                    disabled={isSigningInAccount}
                  >
                    {buttonSignInLabel}
                  </Button>

                  <Link to={'/signup'}>
                    <Button
                      type="submit"
                      variant="outline-primary">
                      {t('noAccount')}
                    </Button>
                  </Link>
                </div>

                {/* Add a component to display errors */}
                <ErrorListComponent errorMessages={errorMessages} />

              </VerticalListComponent>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
}