import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ProductStoreNavigation } from "../../../../common-app/sitePagesStores/models/productStoreNavigation";
import { useTranslationCommonFrontEnd } from "../../../translations/translation";
import { VerticalWhiteSpaceComponent } from "../../verticalWhiteSpace/verticalWhiteSpace";
import { SearchStoreFrontComponent } from "./components/frontPageSearch/frontPageSearch";
import { ProductListComponent } from "./components/productList/productList";
import { DynamicPageStoreProvider } from "./provider/dynamicPageStoreProvider";

import "./dynamicPageStoreSearch.css";

interface DynamicPageStoreSearchProps {
  dataProvider: DynamicPageStoreProvider;
  initialKeyword: string;
}

export function DynamicPageStoreSearchComponent({
  dataProvider,
  initialKeyword
}: DynamicPageStoreSearchProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const navigate = useNavigate();

  // Grab the search term from the URL
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const search = searchParams.get('search');

  // Products to display
  const [products, setProducts] = useState<ProductStoreNavigation[]>([]);

  const filterProductsByKeyword = (keyword: string) => {
    dataProvider.getProductsStoreNavigationByKeyword(keyword).then((products) => {
      setProducts(products);
    });
  }

  useEffect(() => {
    if (search) {
      filterProductsByKeyword(search);
    }
  }, [search]);

  return (
    <div className="dynamic-page-store-search-component">
      <SearchStoreFrontComponent
        keyword={initialKeyword}
        onSubmit={(searchTerm) => {
          navigate(`?search=${searchTerm}`);
        }}
      />

      <VerticalWhiteSpaceComponent amount={3} />

      {products.length === 0 &&
        <div className="no-results">{t('noProductFound')}</div>
      }

      {products.length > 0 &&
        <>
          <div className="group-label">{t('results')}</div>
          <ProductListComponent
            dataProvider={dataProvider}
            products={products}
          />
        </>
      }
    </div>
  )
}