import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslationCommonFrontEnd } from "../../translations/translation";
import { useRichTextEditor } from "../richTextEditor/hooks/useRichTextEditor";
import { RichTextEditor } from "../richTextEditor/richTextEditor";
import { RichTextEditorContentManager } from "../richTextEditor/richTextEditorContentManager";

interface RichTextEditorDialogProps {
  show: boolean;
  title: string;
  contents: string;
  contentManager: RichTextEditorContentManager;
  allowUserQueries: boolean;
  onClose: () => void;
  onSave: (contents: string) => void;
}

export function RichTextEditorDialogComponent({
  show,
  title,
  contents,
  contentManager,
  allowUserQueries,
  onClose,
  onSave
}: RichTextEditorDialogProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const editorConfig = useRichTextEditor(false, allowUserQueries);

  useEffect(() => {
    editorConfig.updateInitialValue(contents);
  }, [contents]);

  const handleSave = () => {
    onSave(editorConfig.getEditorContents());
    onClose();
  }

  return (<Modal
    show={show}
    onHide={onClose}
    size="xl"
    backdrop="static"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        {title}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <RichTextEditor
        key={editorConfig.getEditorKey()}
        features={{
          editable: true,
          allowUserQueries: editorConfig.getEditorAllowUserQueries()
        }}
        initialValue={editorConfig.getEditorStartupValue()}
        onChange={(newValue) => {
          editorConfig.updateContents(newValue);
        }}
        contentManager={contentManager}
      />

    </Modal.Body>

    <Modal.Footer>
      <Button variant="outline-primary"
        onClick={onClose}>{t('close')}
      </Button>

      <Button variant={"primary"}
        onClick={handleSave}>{t('save')}
      </Button>
    </Modal.Footer>
  </Modal >
  )
}