import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './adminUserQueriesListItemActions.css';

interface AdminUserQueriesListItemActionsProps {
  onItemMarkAsUnread: () => void;
}

export function AdminUserQueriesListItemActionsComponent({
  onItemMarkAsUnread
}: AdminUserQueriesListItemActionsProps): JSX.Element {

  const { t } = useTranslation();

  return (
    <div className="admin-user-queries-list-item-actions-component">
      <DropdownButton
        id="dropdown-actions"
        title="..."
        size='sm'
        variant="light"
      >
        <Dropdown.Item onClick={onItemMarkAsUnread}>
          {t('markMessageAsUnread')}
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
}
