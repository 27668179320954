import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import './sitesLayout.css';
import { useEffect } from 'react';

export function SitesLayout(): JSX.Element {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/sites') {
      navigate('/user-sites');
    }
  }, [location]);

  return (
    <div className="sites-layout">
      <div className="content-holder">
        <Outlet />
      </div>
    </div>
  );
}