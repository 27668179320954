import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";
import { Product } from "../models/product";

export class UpdateProductRouteDef extends IRouteDefinition {
  constructor() {
    super('PUT');
    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(productId: string): string {
    return `site-pages-store/products/${productId}`;
  }

  public routeTemplate(): string {
    return 'site-pages-store/products/:productId';
  }
}

export const enum UpdateProductRouteParams {
  productId = 'productId'
}

export class UpdateProductRequestBody extends RequestBody {
  constructor(
    public product: Product
  ) {
    super();
  }
}