import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";
import { BlogPostAdminUpdate } from "../models/blogPostAdminUpdate";

export class CreateBlogPostRouteDef extends IRouteDefinition {
  constructor() {
    super("POST");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(): string {
    return "blogs/blog-posts";
  }

  public routeTemplate(): string {
    return "blogs/blog-posts";
  }
}

export class CreateBlogPostRequestBody extends RequestBody {
  constructor(
    public blogPost: BlogPostAdminUpdate
  ) {
    super();
  }
}