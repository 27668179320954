import { CanCurrentUserEditSiteResponse, CanCurrentUserEditSiteRouteDef } from "../../common-app/system/actions/canCurrentUserEditSite";
import { CreateUserRequestBody, CreateUserResponse, CreateUserRouteDef } from "../../common-app/system/actions/createUser";
import { DeleteUserRouteDef } from "../../common-app/system/actions/deleteUser";
import { IsSessionActiveResponse, IsSessionActiveRouteDef } from "../../common-app/system/actions/isSessionActive";
import { IsSiteOwnerResponse, IsSiteOwnerRouteDef } from "../../common-app/system/actions/isSiteOwner";
import { LoginRequestBody, LoginResponse, LoginRouteDef } from "../../common-app/system/actions/login";
import { LogoutRouteDef } from "../../common-app/system/actions/logout";
import { CreateUserResult } from "../../common-app/system/enums/createUserResult";
import { LoginResult } from "../../common-app/system/enums/loginResult";
import { IsSessionActiveModel } from "../../common-app/system/models/isSessionActiveModel";
import { LoginResponseModel } from "../../common-app/system/models/loginResponseModel";
import { ApiService } from "../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../common-frontend/services/api/backendServerEndpointProvider";
import { ServiceFeatureAnalytics } from "../features/analytics/serviceFeatureAnalytics";
import { RequestHeaderHelper } from "../requestHeaderData/requestHeaderHelper";

export class ServiceApiSystem extends ApiService {
  static async createUser(
    userId: string,
    email: string,
    password: string): Promise<CreateUserResult> {

    const routeDef = new CreateUserRouteDef();

    const requestBody = new CreateUserRequestBody(
      userId,
      email,
      password);

    const requestOptions = this.getRequestOptions(
      routeDef,
      requestBody);

    const response = await this.DoRequestWithResponse<CreateUserResponse>(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return CreateUserResult.UnspecifiedError;
    }

    return response.result;
  }

  static async deleteUser(userId: string): Promise<boolean> {

    const routeDef = new DeleteUserRouteDef();
    const requestOptions = this.getRequestOptions(
      routeDef,
      undefined
    );

    return this.DoRequest(
      routeDef.route(userId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );
  }

  static async isSessionActive(sessionId: string): Promise<IsSessionActiveModel> {

    const routeDef = new IsSessionActiveRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<IsSessionActiveResponse>(
      routeDef.route(sessionId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return {
        isActive: false,
        userId: ''
      };
    }

    return {
      isActive: response.isActive,
      userId: response.userId
    };
  }

  static async isSiteOwner(sessionId: string, siteId: string): Promise<boolean> {

    const routeDef = new IsSiteOwnerRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<IsSiteOwnerResponse>(
      routeDef.route(sessionId, siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return false;
    }

    return response.isOwner;
  }

  static async canSiteBeEdited(siteId: string, sessionId: string, deviceId: string): Promise<boolean> {

    const routeDef = new CanCurrentUserEditSiteRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<CanCurrentUserEditSiteResponse>(
      routeDef.route(siteId, deviceId, sessionId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return false;
    }

    return response.canEdit;
  }

  static async login(
    email: string,
    password: string): Promise<LoginResponseModel> {

    const routeDef = new LoginRouteDef();
    const deviceId = ServiceFeatureAnalytics.getDeviceIdCreateIfDoesNotExist();

    const requestBody = new LoginRequestBody(email, password, deviceId);
    const requestOptions = this.getRequestOptions(
      routeDef,
      requestBody
    );

    const response = await this.DoRequestWithResponse<LoginResponse>(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return {
        result: LoginResult.InvalidMailOrPassword,
        sessionId: '',
        userId: ''
      }
    }

    return {
      result: response.result,
      sessionId: response.sessionId,
      userId: response.userId
    }
  }

  static async logout(sessionId: string): Promise<boolean> {

    const routeDef = new LogoutRouteDef();
    const requestOptions = this.getRequestOptions(
      routeDef,
      undefined
    );

    return this.DoRequest(
      routeDef.route(sessionId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );
  }
}