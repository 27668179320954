import { Contact } from "../contact";

export class ContactFactory {

  public static createNew(id: string, pageId: string): Contact {
    return this.create(
      id,
      pageId,
      true,
      0,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      0,
      0,
      false);
  }

  public static create(
    id: string,
    pageId: string,
    enabled: boolean,
    displayOrder: number,
    name: string,
    mail01: string,
    mail02: string,
    mail03: string,
    phone01: string,
    phone02: string,
    phone03: string,
    addressLine01: string,
    addressLine02: string,
    addressLine03: string,
    addressLine04: string,
    latitude: number,
    longitude: number,
    showMap: boolean
  ): Contact {
    return {
      id,
      pageId,
      enabled,
      displayOrder,
      name,
      mail01,
      mail02,
      mail03,
      phone01,
      phone02,
      phone03,
      addressLine01,
      addressLine02,
      addressLine03,
      addressLine04,
      latitude,
      longitude,
      showMap
    };
  }
}