import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SiteInfo } from "../../models/siteInfo";

export class GetSiteInfoRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(siteId: string): string {
    return `sites/get-site-info?` + QueryParamsUtil.build({ "site-id": siteId });
  }

  public routeTemplate(): string {
    return 'sites/get-site-info';
  }
}

export const enum GetSiteInfoQueryParams {
  siteId = 'site-id',
}

export class GetSiteInfoResponse extends RequestResponse {
  constructor(
    public siteInfo: SiteInfo
  ) {
    super();
  }
}
