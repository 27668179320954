import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class LogoutRouteDef extends IRouteDefinition {
  constructor() {
    super("POST");
  }

  public route(sessionId: string): string {
    return `system/logout?` + QueryParamsUtil.build({ "session-id": sessionId });
  }

  public routeTemplate(): string {
    return 'system/logout';
  }
}

export const enum LogoutQueryParams {
  sessionId = 'session-id',
}

