import { Container } from "react-bootstrap";
import { PageTitle } from "../common-frontend/components/pageTitle/pageTitle";
import { Link } from "react-router-dom";
import { VerticalListComponent } from "../common-frontend/components/verticalList/verticalList";

export function PageNotFound(): JSX.Element {
  return (
    <div>
      <Container>

        <PageTitle title='Página não encontrada' />
        <VerticalListComponent>

          <h4>Esta página não existe ou foi removida.</h4>

          <Link to={`/`}><h5>Voltar para página inicial</h5></Link>
        </VerticalListComponent>
      </Container>
    </div>
  );
}