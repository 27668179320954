
interface VerticalWhiteSpaceProps {
  amount: number;
}

export function VerticalWhiteSpaceComponent({ amount }: VerticalWhiteSpaceProps): JSX.Element {
  return (
    <>
      {Array(amount).fill(0).map((_, index) => (
        <div style={{
          minHeight: '2rem'
        }}
          key={index}>
        </div>))}
    </>
  );
}