import { YoutubeShort } from "../../../../youtubeVideo/youtubeShort";

import "./elementConfigurationSnippetYoutubeShortPreview.css";

interface ElementConfigurationSnippetYoutubeShortPreviewProps {
  videoId: string;
}

export function ElementConfigurationSnippetYoutubeShortPreviewComponent(
  { videoId }: ElementConfigurationSnippetYoutubeShortPreviewProps
): JSX.Element {

  return <div className="element-configuration-snippet-youtube-short-preview-component">
    {videoId === "" &&
      <div className="no-video-id">
        <i className="bi bi-camera-video-off" />
      </div>
    }
    {videoId !== "" &&
      <YoutubeShort videoId={videoId} />
    }
  </div>
}
