import { useState } from 'react';
import { Container, Offcanvas } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { HorizontalListComponent } from '../../common-frontend/components/horizontalList/horizontalList';
import { LogoComponent } from '../../common-frontend/components/logo/logo';
import { Spanner } from '../../common-frontend/components/micro/spanner/spanner';
import { VerticalListComponent } from '../../common-frontend/components/verticalList/verticalList';
import { VerticalWhiteSpaceComponent } from '../../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace';
import { LocalStorageSession } from '../../library/localStorage/session/session';
import { ServiceApiSystem } from '../../services/api/serviceApiSystem';
import { useUserSessionContext } from '../contexts/session/hooks/useUserSessionContext';
import { SelectLanguageButtonComponent } from './selectLanguageButton/selectLanguageButton';

import '../../common-frontend/components/common-styles.css';
import './header.css';
export function HeaderComponent(): JSX.Element {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const userSessionContext = useUserSessionContext();

  const [showNavigationPanel, setShowNavigationPanel] = useState<boolean>(false);

  const onShowNavigationPanel = () => {
    setShowNavigationPanel(!showNavigationPanel);
  }

  const handleLogout = () => {

    const sessionId = userSessionContext.userSessionId;

    ServiceApiSystem.logout(sessionId).then(() => {
      LocalStorageSession.deleteSessionId();
      LocalStorageSession.deleteUserId();

      userSessionContext.clearSessionInformation();

      navigate('/');
    });
  }

  const onNavigationFromSidePanel = () => {
    setShowNavigationPanel(false);
  }

  return (
    <>

      <Container>
        <div className='component-header flex-h'>

          <div
            className="side-menu-toggle"
            onClick={onShowNavigationPanel}
          >
            <i className="bi bi-list" />
          </div>
          {/* <Spanner /> */}

          <Link to={'/'} >
            <div className='logo-holder'>
              <LogoComponent />
            </div>
          </Link>

          <div className="header-items flex-h">

            <Link to={'/services'}>
              <div className='item'>
                {t('services')}
              </div>
            </Link>

            <Link to={'/1-click'}>
              <div className='item'>
                {t('sites1Click')}
              </div>
            </Link>

            <Link to={'/contacts'}>
              <div className='item'>
                {t('contacts')}
              </div>
            </Link>

            <Spanner />
            <Link to={'/user-sites'}>
              <div className='item'>
                {t('sites')}
              </div>
            </Link>
            {
              userSessionContext.userId &&
              <>
                <div className='item'
                  onClick={() => handleLogout()}>
                  {t('logout')}
                </div>
              </>
            }

            {
              !userSessionContext.userId &&
              <Link to={'/signin'} >
                <div className='item'>
                  {t('login')}
                </div>
              </Link>
            }

            <SelectLanguageButtonComponent />

          </div>

        </div>
      </Container>

      <Offcanvas show={showNavigationPanel} onHide={() => { setShowNavigationPanel(false) }}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <Link className="no-decor" to={'/'} >
              <div
                style={{ width: '50px' }}
                onClick={onNavigationFromSidePanel}
              >
                <LogoComponent />
              </div>
            </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>

          <div className="side-panel-navigation" >
            <VerticalListComponent>

              <HorizontalListComponent>
                <Link to={'/'} >
                  <div
                    className='side-panel-item'
                    onClick={onNavigationFromSidePanel}
                  >
                    {t('home')}
                  </div>
                </Link>
                <Spanner />
                <SelectLanguageButtonComponent />

              </HorizontalListComponent>

              <VerticalWhiteSpaceComponent amount={1} />

              <Link to={'/services'}>
                <div
                  className='side-panel-item'
                  onClick={onNavigationFromSidePanel}
                >
                  {t('services')}
                </div>
              </Link>

              <Link to={'/1-click'}>
                <div
                  className='side-panel-item'
                  onClick={onNavigationFromSidePanel}
                >
                  {t('sites1Click')}
                </div>
              </Link>

              <Link to={'/contacts'}>
                <div
                  className='side-panel-item'
                  onClick={onNavigationFromSidePanel}
                >
                  {t('contacts')}
                </div>
              </Link>

              <VerticalWhiteSpaceComponent amount={1} />
              {
                userSessionContext.userId &&
                <>
                  <Link to={'/user-sites'}>
                    <div
                      className='side-panel-item'
                      onClick={onNavigationFromSidePanel}
                    >
                      {t('sites')}
                    </div>
                  </Link>

                  <Link to={'/'} >
                    <div
                      className='side-panel-item'
                      onClick={() => {
                        onNavigationFromSidePanel();
                        handleLogout()
                      }}>
                      {t('logout')}
                    </div>
                  </Link>
                </>
              }

              {
                !userSessionContext.userId &&
                <>
                  <Link to={'/signin'} >
                    <div
                      className='side-panel-item'
                      onClick={onNavigationFromSidePanel}
                    >
                      {t('login')}
                    </div>
                  </Link>

                  <Link to={'/signup'} >
                    <div
                      className='side-panel-item'
                      onClick={onNavigationFromSidePanel}
                    >
                      {t('createAccount')}
                    </div>
                  </Link>
                </>
              }
            </VerticalListComponent>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}