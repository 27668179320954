import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslationCommonFrontEnd } from "../../../../../translations/translation";

export function ButtonViewCartComponent(): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const navigate = useNavigate();

  const handleGoToCart = () => {
    navigate('?cart=1');
  }

  return (
    <Button
      variant="outline-primary"
      onClick={handleGoToCart}
    >
      {t('viewCart')}
    </Button>
  );
}