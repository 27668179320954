import { TypeRebuilding } from "../../../common/util/types/typeRebuilding";
import { GetSiteOwnershipResponse, GetSiteOwnershipRouteDef } from "../../../common-app/sites/actions/ownership/getSiteOwnership";
import { GetSitesOwnedByUserResponse, GetSitesOwnedByUserRouteDef } from "../../../common-app/sites/actions/ownership/getSitesOwnedByUser";
import { GetUnclaimedSitesResponse, GetUnclaimedSitesRouteDef } from "../../../common-app/sites/actions/ownership/getUnclaimedSites";
import { TakeSiteOwnershipResponse, TakeSiteOwnershipRouteDef } from "../../../common-app/sites/actions/ownership/takeSiteOwnership";
import { SiteOwnership } from "../../../common-app/sites/models/siteOwnership/siteOwnership";
import { ApiService } from "../../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../../common-frontend/services/api/backendServerEndpointProvider";
import { RequestHeaderHelper } from "../../requestHeaderData/requestHeaderHelper";

export class ServiceApiSitesOwnership extends ApiService {
  static async getSiteOwnership(siteId: string): Promise<SiteOwnership | undefined> {

    const routeDef = new GetSiteOwnershipRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSiteOwnershipResponse>(
      routeDef.route(siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return undefined;
    }

    if (response.siteOwnership === undefined) {
      return undefined;
    }

    return TypeRebuilding.rebuildObject(response.siteOwnership, () => new SiteOwnership());
  }

  static async takeSiteOwnership(sessionId: string, siteId: string, deviceId: string): Promise<boolean> {

    const routeDef = new TakeSiteOwnershipRouteDef();
    const requestOptions = this.getRequestOptions(
      routeDef,
      undefined
    );

    const response = await this.DoRequestWithResponse<TakeSiteOwnershipResponse>(
      routeDef.route(sessionId, siteId, deviceId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );

    if (response === undefined) {
      return false;
    }

    return response.success;
  }

  static async getSiteOwnedByUser(userId: string): Promise<SiteOwnership[]> {

    const routeDef = new GetSitesOwnedByUserRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetSitesOwnedByUserResponse>(
      routeDef.route(userId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return [];
    }

    return response.sites.map(site => TypeRebuilding.rebuildObject(site, () => new SiteOwnership()));
  }

  static async getUnclaimedSites(deviceId: string): Promise<SiteOwnership[]> {

    const routeDef = new GetUnclaimedSitesRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetUnclaimedSitesResponse>(
      routeDef.route(deviceId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return [];
    }

    return response.sites.map(site => TypeRebuilding.rebuildObject(site, () => new SiteOwnership()));
  }
}