import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProductStoreNavigation } from "../../../../../../common-app/sitePagesStores/models/productStoreNavigation";
import { useTranslationCommonFrontEnd } from "../../../../../translations/translation";
import { HorizontalListComponent } from "../../../../horizontalList/horizontalList";
import { PriceComponent } from "../../../../price/price";
import { StoreCartProduct } from "../../data/storeCartProduct";

import './productCartItem.css';

interface ProductCartItemProps {
  cartItem: StoreCartProduct;
  product: ProductStoreNavigation;
  handleRemoveItem: () => void;
  handleChangeQuantity: (quantity: number) => void;
}

export function ProductCartItemComponent({
  cartItem,
  product,
  handleRemoveItem,
  handleChangeQuantity
}: ProductCartItemProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const handleLeaveQuantity = () => {
    if (cartItem.quantity <= 0) {
      handleRemoveItem();
    }
  }

  return (
    <div className="product-cart-item-component">

      <HorizontalListComponent className="product-name-price">
        <Link
          className="product-name no-decor"
          to={`?item=${product.id}`}
        >
          <div>{product.name}</div>
        </Link>
        <div className="product-price">
          <PriceComponent value={product.price} />
        </div>
      </HorizontalListComponent>

      <HorizontalListComponent className="product-quantity-management">
        <input
          className="product-quantity"
          type="number"
          value={cartItem.quantity}
          min="0"
          max="50000"
          onChange={(e) => handleChangeQuantity(parseInt(e.target.value))}
          onBlur={(e) => handleLeaveQuantity()}
        />
        <Button
          variant="outline-primary"
          onClick={handleRemoveItem}>
          {t('remove')}
        </Button>
      </HorizontalListComponent>
    </div>
  )
}