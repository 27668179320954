import { useEffect, useState } from 'react';
import { ImageGalleryItem } from '../../../../imageGallery/imageGalleryItem';
import { GetImagesInfoFunction } from '../../../../imageUploader/imageBrowser';

import './elementConfigurationSnippetImagePreview.css';

interface ElementConfigurationSnippetImagePreviewProps {
  imageId: string,
  getImagesInfoFunction: GetImagesInfoFunction,
}

export function ElementConfigurationSnippetImagePreviewComponent({
  imageId,
  getImagesInfoFunction
}: ElementConfigurationSnippetImagePreviewProps): JSX.Element {

  const [galleryImage, setGalleryImage] = useState<ImageGalleryItem>();
  useEffect(() => {

    const fetchImageData = async () => {

      const userFileImages = await getImagesInfoFunction([imageId]);

      if (userFileImages.length === 0) {
        return;
      }

      setGalleryImage({
        id: userFileImages[0].id,
        url: userFileImages[0].publicUrl,
        urlthumbnail: userFileImages[0].publicUrlThumbnail,
        altText: userFileImages[0].originalFileName,
        caption: ""
      });
    }

    fetchImageData();

  }, [imageId]);

  return (
    <div className="element-configuration-snippet-image-preview-component">
      <div className="image-parent">
        {galleryImage &&
          <img
            src={galleryImage.urlthumbnail}
            alt={galleryImage.altText}
            draggable={false}
          />
        }
      </div>
    </div>
  );
}
