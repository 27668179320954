import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AlertInlineComponent } from "../../common-frontend/components/alertInline/alertInline";
import { PageTitle } from "../../common-frontend/components/pageTitle/pageTitle";
import { VerticalWhiteSpaceComponent } from "../../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace";

import './pageServices.css';

export function PageServices(): JSX.Element {

  const { t } = useTranslation();

  return (

    <Container>

      <div className="page-services">
        <PageTitle title={t('services')}></PageTitle>

        <div className="group">
          <h3>{t('whatWeDo')}</h3>
          <p>
            {t('whatWeDoDescription')}
          </p>
          <ul>
            <li>{t('developWebsite')}</li>
            <li>{t('developWebApp')}</li>
            <li>{t('developDesktopApp')}</li>
            <li>{t('unsureNeeds')}<br />{t('discussIdeas')}</li>
          </ul>
        </div>

        <VerticalWhiteSpaceComponent amount={1} />

        <div className="shadow">
          <AlertInlineComponent variant="info">
            <div>
              {t('simpleWebsiteInfo1')} <Link to="/1-click">{t('oneClick')}</Link> {t('simpleWebsiteInfo2')}
            </div>
          </AlertInlineComponent>
        </div>

        <VerticalWhiteSpaceComponent amount={1} />

        <div className="group">
          <h3>{t('howWeWork')}</h3>
          <p>
            {t('ourProcess')}
          </p>
          <ul>
            <li>{t('initialMeeting')}</li>
            <li>{t('budgetPreparation')}</li>
            <li>{t('prototypeDevelopment')}</li>
            <li>{t('prototypeValidation')}</li>
            <li>{t('appOrSiteDevelopment')}</li>
            <li>{t('testing')}</li>
            <li>{t('productDelivery')}</li>
          </ul>
        </div>

        <VerticalWhiteSpaceComponent amount={1} />

        <h5><Link to={"/contacts"}>{t('contactUsShort')}</Link> {t('howWeCanHelp')}</h5>

        <VerticalWhiteSpaceComponent amount={2} />

      </div>
    </Container>
  );
}