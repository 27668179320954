import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";

export class UpdateUserQueryReadStateRouteDef extends IRouteDefinition {
  constructor() {
    super("PUT");
  }

  public route(userQueryId: string): string {
    return `user-queries/${userQueryId}/read-state`;
  }

  public routeTemplate(): string {
    return 'user-queries/:userQueryId/read-state';
  }
}

export const enum UpdateUserQueryReadStateQueryParams {
  userQueryId = 'userQueryId'
}

export class UpdateUserQueryReadStateRequestBody extends RequestBody {
  constructor(
    public isRead: boolean
  ) {
    super();
  }
}