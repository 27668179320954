import { ProductStoreNavigation } from "../../../../../../common-app/sitePagesStores/models/productStoreNavigation";
import { DynamicPageStoreProvider } from "../../provider/dynamicPageStoreProvider";
import { ProductListItemComponent } from "../productListItem/productListItem";

import './productList.css';

interface ProductListComponentProps {
  dataProvider: DynamicPageStoreProvider;
  products: ProductStoreNavigation[];
}

export function ProductListComponent({
  dataProvider,
  products
}: ProductListComponentProps): JSX.Element {
  return (
    <div className="product-list-component">
      {
        products.map(product =>
          <ProductListItemComponent
            key={product.id}
            dataProvider={dataProvider}
            product={product}
          />
        )
      }
    </div>
  );
}