import { ItemsCollection } from "../../../common/util/itemsCollection/itemsCollection";

export function useSelectAnItemFromList<T>(
  getItemKey: (item: T) => string,
  selectedItemKey: string | undefined,
  setSelectedItem: (itemKey: string) => void) {

  const selectAnItem = (items: T[]) => {
    const itemToSelect = ItemsCollection.getAnItemFromList(items, getItemKey, selectedItemKey);
    if (itemToSelect) {
      setSelectedItem(itemToSelect);
    }
  }

  return [
    selectAnItem,
  ] as const;
}
