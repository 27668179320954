import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { UserQueryCreation } from "../models/userQueryCreation";

export class CreateUserQueryRouteDef extends IRouteDefinition {
  constructor() {
    super("POST");
  }

  public route(): string {
    return `user-queries/user-queries`;
  }

  public routeTemplate(): string {
    return 'user-queries/user-queries';
  }
}
export class CreateUserQueryRequestBody extends RequestBody {
  constructor(
    public userQuery: UserQueryCreation
  ) {
    super();
  }
}