export class ProductAdminList {

  id: string = '';
  name: string = "";
  category: string = "";

  public static isEqual(a: ProductAdminList, b: ProductAdminList): boolean {
    return (
      a.id === b.id &&
      a.name === b.name &&
      a.category === b.category
    );
  }
}
