import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";

export class DeleteUserRouteDef extends IRouteDefinition {

  constructor() {
    super("DELETE");
  }

  public route(id: string): string {
    return `system/user/${id}`;
  }

  public routeTemplate(): string {
    return 'system/user/:id';
  }
}

export const enum DeleteUserRouteParams {
  id = 'id',
}

