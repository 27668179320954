import { RequestBody } from "../../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SitePageFeature } from "../../enums/sitePageFeature";

export class UpdateSitePageFeaturesRouteDef extends IRouteDefinition {
  constructor() {
    super("PUT");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(siteId: string, pageId: string): string {
    return `sites/update-site-page-features?` +
      QueryParamsUtil.build({
        "site-id": siteId,
        "page-id": pageId
      });
  }

  public routeTemplate(): string {
    return 'sites/update-site-page-features';
  }
}

export const enum UpdateSitePageFeaturesQueryParams {
  siteId = 'site-id',
  pageId = 'page-id',
}

export class UpdateSitePageFeaturesRequestBody extends RequestBody {
  constructor(
    public features: SitePageFeature[],
  ) {
    super();
  }
}

