
import { Button } from 'react-bootstrap';
import { useTranslationCommonFrontEnd } from '../../../translations/translation';
import { HorizontalListComponent } from '../../horizontalList/horizontalList';

interface ActionsSaveDiscardProps {
  canSave: boolean;
  onDiscard: () => void;
}

export function ActionsSaveDiscardComponent({
  canSave,
  onDiscard
}: ActionsSaveDiscardProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  return (
    <HorizontalListComponent>
      <Button
        variant="outline-primary"
        onClick={onDiscard}
        disabled={!canSave}>
        {t('discard')}
      </Button>

      <Button
        variant="primary"
        type="submit"
        disabled={!canSave}>
        {t('save')}
      </Button>
    </HorizontalListComponent>
  );
}
