import { useTranslation } from "react-i18next";
import { ProductAdminList } from "../../../../../../../common-app/sitePagesStores/models/productAdminList";
import { StoreOrderLine } from "../../../../../../../common-app/sitePagesStores/models/storeOrderLine";
import { CurrencyUtil } from "../../../../../../../common/util/currency/currencyUtil";

import './orderLineDetails.css';

interface OrderLineDetailsProps {
  orderLine: StoreOrderLine;
  product: ProductAdminList;
}

export function OrderLineDetailsComponent({
  orderLine,
  product
}: OrderLineDetailsProps): JSX.Element {

  const { t } = useTranslation();

  const getCategoryLabel = (category: string): string => {
    if (category.length === 0) {
      return t('noCategory');
    }
    return category;
  }

  return (
    <div
      className="order-line-details-component"
    >
      <div className="order-line">
        <div className="product-name">
          {getCategoryLabel(product.category)} - {product.name}
        </div>
        <div className="product-price">
          {CurrencyUtil.formatCurrencyAsEur(orderLine.price)}
        </div>
        <div className="quantity">
          {orderLine.quantity}x
        </div>
      </div>
    </div>
  );
}