import { RequestBody } from "../../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";

export class DuplicateSitePageRouteDef extends IRouteDefinition {
  constructor() {
    super("POST");
    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(): string {
    return `sites/duplicate-site-page`;
  }

  public routeTemplate(): string {
    return 'sites/duplicate-site-page';
  }
}

export class DuplicateSitePageRequestBody extends RequestBody {
  constructor(
    public siteId: string,
    public existingPageId: string,
    public newPageId: string
  ) {
    super();
  }
}
