import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ArrayUtil } from '../../../common/util/array/arrayUtil';
import { ImageSorterComponent } from '../imageSorter/imageSorter';
import {
  GetImagesInfoFunction,
  GetImagesListFunction,
  ImageBrowserComponent,
  UploadImageFunction
} from './imageBrowser';

import { useTranslationCommonFrontEnd } from '../../translations/translation';
import './imageUploaderModal.css';

interface ImageUploaderModalProps {

  imagesSelected: string[],

  uploadImageFunction: UploadImageFunction;
  getImagesListFunction: GetImagesListFunction;
  getImagesInfoFunction: GetImagesInfoFunction;

  showDialog: boolean;
  allowEmptySelection: boolean;
  allowMultipleSelection: boolean;

  onClose: () => void;
  onConfirm: (images: string[]) => void;
}

export function ImageUploaderModalComponent({
  imagesSelected,
  uploadImageFunction,
  getImagesListFunction,
  getImagesInfoFunction,
  showDialog,
  allowEmptySelection,
  allowMultipleSelection,
  onClose,
  onConfirm
}: ImageUploaderModalProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  useEffect(() => {
    setSelectedFiles(imagesSelected);
  }, [imagesSelected, showDialog]);

  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);

  const canConfirmSelection = () => {
    return allowEmptySelection ? true : selectedFiles.length > 0;
  }

  return (
    <Modal
      className='image-uploader-modal-component'
      onHide={onClose}
      show={showDialog}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('chooseImage')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            height: '100%',
            width: '100%',
            maxHeight: "calc(100vh - 400px)",
            overflow: "auto"
          }}>
          <div >
            <h6>
              {t('choose')}
            </h6>
            <ImageBrowserComponent
              uploadImageFunction={uploadImageFunction}
              getImagesListFunction={getImagesListFunction}
              selectedFiles={selectedFiles}
              setSelectedFiles={(files) => {
                if (allowMultipleSelection) {
                  setSelectedFiles(files);
                }
                else {
                  setSelectedFiles(ArrayUtil.last(files, 1));
                }
              }}
            />
          </div>

        </div>
        {allowMultipleSelection &&
          <>
            <h6 style={{ marginTop: "var(--size-6)" }}>
              {t('sortDrag')}
            </h6>
            <ImageSorterComponent
              getImagesInfoFunction={getImagesInfoFunction}
              images={selectedFiles}
              setImages={setSelectedFiles}
            />
          </>
        }

      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            setSelectedFiles([]);
            onClose()
          }}
        >
          {t('close')}
        </Button>

        <Button
          variant="primary"
          disabled={canConfirmSelection() === false}
          onClick={() => {
            onConfirm(selectedFiles)
            setSelectedFiles([]);
          }}
        >
          {t('choose')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
