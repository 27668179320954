import { Link } from 'react-router-dom';
import { HorizontalListComponent } from '../../../../common-frontend/components/horizontalList/horizontalList';
import { LogoComponent } from '../../../../common-frontend/components/logo/logo';
import { Spanner } from '../../../../common-frontend/components/micro/spanner/spanner';
import { useTranslation } from 'react-i18next';

import './dynamicSiteFooter.css';

export function DynamicSiteFooterComponent(): JSX.Element {

  const { t } = useTranslation();

  return (
    <div className="dynamic-site-footer-component">
      <HorizontalListComponent>
        <Spanner />
        <Link to="/">
          <HorizontalListComponent>
            <div className="logo">
              <LogoComponent />
            </div>
            <div
              className="text"
            >
              {t('madeWithPixelVaidoso')}
            </div>
          </HorizontalListComponent>
        </Link>
        <Spanner />
      </HorizontalListComponent>
    </div >
  );
}
