import {
  GetStoreOrderStateColor,
  GetStoreOrderStateLabel,
  StoreOrderState
} from "../../../../../../../common-app/sitePagesStores/enums/storeOrderState";

import './orderState.css';

interface OrderStateProps {
  orderState: StoreOrderState;
  onOrderStateChange: (newState: StoreOrderState) => void;
}

export function OrderStateComponent({ orderState, onOrderStateChange }: OrderStateProps): JSX.Element {

  const getBackgroundColor = (state: StoreOrderState): string => {

    if (state === orderState) {
      return GetStoreOrderStateColor(state);
    }
    return "transparent";
  }

  const renderButton = (state: StoreOrderState) => {
    return (
      <div
        className="order-state-component"
        style={{
          backgroundColor: getBackgroundColor(state)
        }}
        onClick={() => {
          onOrderStateChange(state);
        }}>
        {GetStoreOrderStateLabel(state)}
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '6px',
        }}>

        {renderButton(StoreOrderState.Received)}
        {renderButton(StoreOrderState.MoreInfoNeeded)}
        {renderButton(StoreOrderState.WaitingPayment)}
        {renderButton(StoreOrderState.Handling)}
        {renderButton(StoreOrderState.Completed)}
        {renderButton(StoreOrderState.Rejected)}
      </div>
    </div>
  )
}