import { TypeRebuilding } from "../../common/util/types/typeRebuilding";
import {
  CreateUserQueryRequestBody,
  CreateUserQueryRouteDef
} from "../../common-app/userQueries/actions/createUserQuery";
import {
  UpdateUserQueryReadStateRequestBody,
  UpdateUserQueryReadStateRouteDef
} from "../../common-app/userQueries/actions/updateUserQueryReadState";
import {
  GetUserQueriesAdminListResponse,
  GetUserQueriesAdminListRouteDef
} from "../../common-app/userQueries/actions/getUserQueriesAdminList";
import {
  GetUserQueriesCountResponse,
  GetUserQueriesCountRouteDef
} from "../../common-app/userQueries/actions/getUserQueriesCount";
import { UserQueryAdminList } from "../../common-app/userQueries/models/userQueryAdminList";
import { UserQueryCreation } from "../../common-app/userQueries/models/userQueryCreation";
import { ApiService } from "../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../common-frontend/services/api/backendServerEndpointProvider";
import { RequestHeaderHelper } from "../requestHeaderData/requestHeaderHelper";
import { UserQueryCreationFactory } from "../../common-app/userQueries/models/factories/userQueryCreationFactory";
import { GuidUtil } from "../../common/util/guid/guid";

export class ServiceApiUserQueries extends ApiService {

  static async insertUserQuery(
    siteId: string,
    pageId: string,
    userQueryId: string,
    contact: string,
    subject: string,
    message: string): Promise<boolean> {

    const userQuery = UserQueryCreationFactory.create(
      siteId,
      pageId,
      userQueryId,
      GuidUtil.GenerateNewGuid(),
      contact,
      subject,
      message);

    const routeDef = new CreateUserQueryRouteDef();
    const body = new CreateUserQueryRequestBody(userQuery);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );
  }

  static async createUserQuery(userQuery: UserQueryCreation): Promise<boolean> {

    const routeDef = new CreateUserQueryRouteDef();
    const body = new CreateUserQueryRequestBody(userQuery);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );
  }

  static async getUserQueriesAdminList(
    siteId: string,
    pageId: string,
    pageSize: number,
    page: number): Promise<UserQueryAdminList[]> {

    const routeDef = new GetUserQueriesAdminListRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetUserQueriesAdminListResponse>(
      routeDef.route(siteId, pageId, pageSize, page),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return [];
    }

    return TypeRebuilding.rebuildObjectArray(
      response.userQueries,
      () => new UserQueryAdminList());
  }

  static async getUserQueriesCount(siteId: string, pageId: string): Promise<number> {

    const routeDef = new GetUserQueriesCountRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetUserQueriesCountResponse>(
      routeDef.route(siteId, pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return 0;
    }

    return response.count;
  }

  static async updateUserQueryReadState(userQueryId: string, isRead: boolean): Promise<boolean> {

    const routeDef = new UpdateUserQueryReadStateRouteDef();
    const body = new UpdateUserQueryReadStateRequestBody(isRead);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(userQueryId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );
  }
}