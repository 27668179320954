import { useEffect, useState } from 'react';
import { ProductStoreNavigation } from '../../../../../../common-app/sitePagesStores/models/productStoreNavigation';
import { useTranslationCommonFrontEnd } from '../../../../../translations/translation';
import { HorizontalListComponent } from '../../../../horizontalList/horizontalList';
import { Spanner } from '../../../../micro/spanner/spanner';
import { PriceComponent } from '../../../../price/price';
import { VerticalListComponent } from '../../../../verticalList/verticalList';
import { DynamicPageStoreProvider } from '../../provider/dynamicPageStoreProvider';

import './checkoutCartSummary.css';

interface CheckoutCartSummaryProps {
  dataProvider: DynamicPageStoreProvider;
}

export function CheckoutCartSummaryComponent({
  dataProvider
}: CheckoutCartSummaryProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const [products, setProducts] = useState<ProductStoreNavigation[]>([]);
  const [cartTotal, setCartTotal] = useState<number>(0);

  const itemsInCart = dataProvider.getProductsInCart();

  useEffect(() => {
    dataProvider.getProductsStoreNavigation().then(setProducts);
    dataProvider.getCartTotal().then(setCartTotal);
  }, [dataProvider]);

  return (
    <div className="checkout-cart-summary-component">

      <VerticalListComponent>
        <div className="summary-label">{t('summary')}</div>

        <div>
          {itemsInCart.map((itemInCart, index) => {
            const product = products.find(product => product.id === itemInCart.productId);
            if (!product) {
              return <div key={index}>
                {t('productNotFound')}
              </div>
            }

            return (
              <div key={product.id} className="cart-item">
                <HorizontalListComponent>
                  <div>
                    <div className="cart-item-quantity">{itemInCart.quantity}</div> x
                  </div>
                  <div className="cart-item-name">{product.name}</div>
                </HorizontalListComponent>
              </div>
            );
          })}
        </div>

        <HorizontalListComponent>
          <Spanner />
          <div className="total">
            <PriceComponent value={cartTotal} />
          </div>
        </HorizontalListComponent>
      </VerticalListComponent>
    </div>
  );
}