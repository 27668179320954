import {
  CreateProductRequestBody,
  CreateProductRouteDef
} from "../../common-app/sitePagesStores/actions/createProduct";
import {
  CreateStoreOrderRequestBody,
  CreateStoreOrderRouteDef
} from "../../common-app/sitePagesStores/actions/createStoreOrder";
import {
  GetProductResponse,
  GetProductRouteDef
} from "../../common-app/sitePagesStores/actions/getProduct";
import {
  GetProductsAdminListResponse,
  GetProductsAdminListRouteDef
} from "../../common-app/sitePagesStores/actions/getProductsAdminList";
import {
  GetProductsStoreNavigationResponse,
  GetProductsStoreNavigationRouteDef
} from "../../common-app/sitePagesStores/actions/getProductsStoreNavigation";
import {
  GetStoreOrdersAdminCountResponse,
  GetStoreOrdersAdminCountRouteDef
} from "../../common-app/sitePagesStores/actions/getStoreOrdersAdminCount";
import {
  GetStoreOrdersAdminListResponse,
  GetStoreOrdersAdminListRouteDef
} from "../../common-app/sitePagesStores/actions/getStoreOrdersAdminList";
import {
  UpdateProductRequestBody,
  UpdateProductRouteDef
} from "../../common-app/sitePagesStores/actions/updateProduct";
import {
  UpdateStoreOrderStateRequestBody,
  UpdateStoreOrderStateRouteDef
} from "../../common-app/sitePagesStores/actions/updateStoreOrderState";
import { StoreOrderState } from "../../common-app/sitePagesStores/enums/storeOrderState";
import { Product } from "../../common-app/sitePagesStores/models/product";
import { ProductAdminList } from "../../common-app/sitePagesStores/models/productAdminList";
import { ProductStoreNavigation } from "../../common-app/sitePagesStores/models/productStoreNavigation";
import { StoreOrder } from "../../common-app/sitePagesStores/models/storeOrder";
import { StoreOrderAdmin } from "../../common-app/sitePagesStores/models/storeOrderAdmin";
import { ApiService } from "../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../common-frontend/services/api/backendServerEndpointProvider";
import { TypeRebuilding } from "../../common/util/types/typeRebuilding";
import { RequestHeaderHelper } from "../requestHeaderData/requestHeaderHelper";

export class ServiceApiPageDataStore extends ApiService {

  static async getProduct(pageId: string, productId: string): Promise<Product | undefined> {
    const routeDef = new GetProductRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetProductResponse>(
      routeDef.route(pageId, productId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (response === undefined) {
      return undefined;
    }

    if (response.product === undefined) {
      return undefined;
    }

    return TypeRebuilding.rebuildObject(response.product, () => new Product());
  }

  static async getProductsAdminList(pageId: string): Promise<ProductAdminList[]> {
    const routeDef = new GetProductsAdminListRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetProductsAdminListResponse>(
      routeDef.route(pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    // Check the response
    if (response === undefined) {
      return [];
    }

    return TypeRebuilding.rebuildObjectArray(
      response.products,
      () => new ProductAdminList()
    );
  }

  static async getProductsStoreNavigation(pageId: string): Promise<ProductStoreNavigation[]> {
    const routeDef = new GetProductsStoreNavigationRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetProductsStoreNavigationResponse>(
      routeDef.route(pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    // Check the response
    if (response === undefined) {
      return [];
    }

    return TypeRebuilding.rebuildObjectArray(
      response.products,
      () => new ProductStoreNavigation()
    );
  }

  static async createProduct(siteId: string, product: Product): Promise<boolean> {
    const routeDef = new CreateProductRouteDef();
    const body = new CreateProductRequestBody(product);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async updateProduct(siteId: string, product: Product): Promise<boolean> {
    const routeDef = new UpdateProductRouteDef();
    const body = new UpdateProductRequestBody(product);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(product.id),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async createStoreOrder(storeOrder: StoreOrder): Promise<boolean> {
    const routeDef = new CreateStoreOrderRouteDef();
    const body = new CreateStoreOrderRequestBody(storeOrder);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );
  }

  static async getStoreOrdersAdminList(siteId: string, pageId: string, itemsPerPage: number, currentPage: number): Promise<StoreOrderAdmin[]> {
    const routeDef = new GetStoreOrdersAdminListRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetStoreOrdersAdminListResponse>(
      routeDef.route(pageId, itemsPerPage, currentPage),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );

    // Check the response
    if (response === undefined) {
      return [];
    }

    return TypeRebuilding.rebuildObjectArray(
      response.orders,
      () => new StoreOrderAdmin()
    );
  }

  static async getStoreOrdersAdminCount(siteId: string, pageId: string): Promise<number> {
    const routeDef = new GetStoreOrdersAdminCountRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetStoreOrdersAdminCountResponse>(
      routeDef.route(pageId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );

    // Check the response
    if (response === undefined) {
      return 0;
    }

    return response.count;
  }

  static async updateStoreOrderState(siteId: string, pageId: string, storeOrderId: string, newState: StoreOrderState): Promise<boolean> {
    const routeDef = new UpdateStoreOrderStateRouteDef();
    const body = new UpdateStoreOrderStateRequestBody(siteId, pageId, newState);
    const requestOptions = this.getRequestOptions(routeDef, body);

    return this.DoRequest(
      routeDef.route(storeOrderId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }
}