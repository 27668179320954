import { useMemo } from "react";
import { SitePageRenderInfo } from "../../../../../common-app/sites/models/sitePageRenderInfo";
import { DynamicPageContactsComponent } from "../../../../../common-frontend/components/dynamicPages/contacts/dynamicPageContacts";
import { DynamicPageContactsServiceProvider } from "./dynamicPageContactsServiceProvider";
import { useDynamicPageDataContext } from "../../../../../common-frontend/components/dynamicPages/provider/hooks/useDynamicPageDataContext";

interface DynamicPageContactsWrapperProps {
  pageRenderInfo: SitePageRenderInfo;
}

export function DynamicPageContactsWrapperComponent({
  pageRenderInfo: pagePresentationInfo
}: DynamicPageContactsWrapperProps): JSX.Element {

  const dynamicPageContext = useDynamicPageDataContext();

  const dataProvider = useMemo(() => new DynamicPageContactsServiceProvider(dynamicPageContext.pageId), [dynamicPageContext.pageId]);

  return (
    <div>
      <DynamicPageContactsComponent
        dataProvider={dataProvider}
      />
    </div>
  );
}