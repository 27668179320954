import { RequestHeaderData } from "../../common-frontend/services/api/requestHeaderData/requestHeaderData";
import { DefaultUserIdentificationProvider } from "./senderIdentification/defaultUserIdentificationProvider";

export class RequestHeaderSiteOwnerPermissions extends RequestHeaderData {
  
  constructor(private siteId: string) {
    super();
  }

  public WriteHeaders(requestOptions: RequestInit) {

    const provider = new DefaultUserIdentificationProvider();

    requestOptions.headers = {
      ...requestOptions.headers,
      'pv-site-id': this.siteId,
      'pv-device-id': provider.getDeviceId(),
      'pv-session-id': provider.getSessionId()
    }
  }
}
