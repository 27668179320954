import { Link } from 'react-router-dom';
import { BlogPost } from '../../../../../common-app/sitePagesBlogs/models/blogPost';
import { HorizontalListComponent } from '../../../horizontalList/horizontalList';
import { Spanner } from '../../../micro/spanner/spanner';
import { UrlBuilderBlogPost } from '../util/UrlBuilderBlogPost';

import './blogPostAdjacentNavigation.css';

interface BlogPostAdjacentNavigationProps {
  previousPost: BlogPost | undefined;
  nextPost: BlogPost | undefined;
}

export function BlogPostAdjacentNavigationComponent({
  previousPost,
  nextPost
}: BlogPostAdjacentNavigationProps): JSX.Element {

  const currentPagePath = window.location.pathname;

  return (
    <div className="blog-post-adjacent-navigation-component">
      <HorizontalListComponent>
        <div>
          {
            nextPost &&
            (
              <div className="post-link">
                <Link
                  to={UrlBuilderBlogPost.buildPostLink(currentPagePath, nextPost)}
                >
                  <i className="bi bi-chevron-left" /> {nextPost.title}
                </Link>
              </div>
            )
          }
        </div>
        <Spanner />

        <div>
          {
            previousPost &&
            (
              <div className="post-link">
                <Link
                  to={UrlBuilderBlogPost.buildPostLink(currentPagePath, previousPost)}
                >
                  {previousPost.title} <i className="bi bi-chevron-right" />
                </Link>
              </div>
            )
          }
        </div>
      </HorizontalListComponent >
    </div>
  );
}
