import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { StoreOrderAdmin } from "../models/storeOrderAdmin";

export class GetStoreOrdersAdminListRouteDef extends IRouteDefinition {
  constructor() {
    super('GET');
  }

  public route(pageId: string, pageSize: number, page: number): string {
    return `site-pages-store/store-orders-admin?` +
      QueryParamsUtil.build(
        {
          'page-id': pageId,
          'page': page,
          'page-size': pageSize
        }
      );
  }

  public routeTemplate(): string {
    return 'site-pages-store/store-orders-admin';
  }
}

export const enum GetStoreOrdersAdminListQueryParams {
  pageId = 'page-id',
  pageSize = 'page-size',
  page = 'page'
}

export class GetStoreOrdersAdminListResponse extends RequestResponse {
  constructor(public orders: StoreOrderAdmin[]) {
    super();
  }
}