import { createContext } from "react";
import { UserFileImage } from "../../../../../common-app/userFiles/models/userFileImage";
import { InsertUserQueryFunction } from "../../richTextEditorContentManager";

export type GetUserImagesInfo = (imageIds: string[]) => Promise<UserFileImage[]>;

export interface RichTextEditorDataContextType {

  // Images
  getUserImagesInfo: GetUserImagesInfo;

  // User Query
  insertUserQuery: InsertUserQueryFunction;
}

const RichTextEditorDataContext = createContext<RichTextEditorDataContextType | undefined>(undefined);

export { RichTextEditorDataContext };