import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDynamicPageDataContext } from '../../../../common-frontend/components/dynamicPages/provider/hooks/useDynamicPageDataContext';
import { HorizontalListComponent } from '../../../../common-frontend/components/horizontalList/horizontalList';
import { Spanner } from '../../../../common-frontend/components/micro/spanner/spanner';
import { Environment } from '../../../../common-frontend/environment/environment';
import { SitesUtil2 } from '../../../../common/sites/sitesUtil2';
import { ServiceApiSites } from '../../../../services/api/serviceApiSites';

import './dynamicSiteHeader.css';

interface DynamicSiteHeaderProps {
  onShowMenu: () => void;
}

export function DynamicSiteHeaderComponent({
  onShowMenu
}: DynamicSiteHeaderProps): JSX.Element {

  const dynamicPageContext = useDynamicPageDataContext();

  const [siteName, setSiteName] = useState<string>('');
  const [siteHomeUrl, setSiteHomeUrl] = useState<string>('');

  useEffect(() => {
    const getSiteInformation = async () => {
      const siteInfo = await ServiceApiSites.getSiteInfo(dynamicPageContext.siteId);

      if (siteInfo !== undefined) {
        setSiteName(siteInfo.name);
        setSiteHomeUrl(SitesUtil2.getFullSiteUrl(Environment.GetAppClientUrl(), siteInfo.domainName));
      }
    };
    getSiteInformation();

  }, [dynamicPageContext.siteId]);

  return (
    <div className="dynamic-site-header-component">
      <Container>
        <HorizontalListComponent
          className='header'
        >
          <div
            className="side-menu-toogle"
            onClick={onShowMenu}>
            <i className="bi bi-list" />
          </div>
          <Link to={siteHomeUrl}
            className="no-decor">
            <div className="site-name">
              {siteName}
            </div>
          </Link>
          <Spanner />
        </HorizontalListComponent>
      </Container>
    </div >
  );
}
