import { useState } from "react";
import { Button } from "react-bootstrap";
import { Product } from "../../../../../../common-app/sitePagesStores/models/product";
import { useTranslationCommonFrontEnd } from "../../../../../translations/translation";

interface ButtonAddToCartProps {
  product: Product;
  handleAddToCart: () => void;
}

export function ButtonAddToCart({
  product,
  handleAddToCart
}: ButtonAddToCartProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const [itemAdded, setItemAdded] = useState(false);

  const handleClick = () => {

    handleAddToCart();

    setItemAdded(true);
    setTimeout(() => {
      setItemAdded(false);
    }, 2000);
  }

  return (
    <Button
      onClick={handleClick}
      disabled={product.available === false || itemAdded}
      style={{ width: "12rem" }}
    >
      {itemAdded ? t('addedToCart') : t('addToCart')}
    </Button>
  );
}