import { HorizontalListComponent } from "../../../../horizontalList/horizontalList";

import './elementConfigurationSnippetLineWidth.css';

interface ElementConfigurationSnippetLineWidthProps {
  caption: string;
  value: number;
  onChange: (value: number) => void;
}

export function ElementConfigurationSnippetLineWidthComponent({
  caption,
  value,
  onChange
}: ElementConfigurationSnippetLineWidthProps): JSX.Element {
  return (
    <div className="element-configuration-snippet-line-width-component">
      <HorizontalListComponent>
        <label>{caption}</label>
        <input
          type="number"
          value={value}
          max={100}
          min={1}
          onChange={(e) => onChange(parseInt(e.target.value))}
        />
      </HorizontalListComponent>
    </div>
  );
}