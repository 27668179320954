import { StoreCartProduct } from "../../../../../../common-frontend/components/dynamicPages/store/data/storeCartProduct";
import { LocalStorage } from "../../../../../../common-frontend/library/localStorage/localStorage";

export class StoreCart {
  items: StoreCartProduct[] = [];
}

export class CartLocalStorage {

  public static addProductToCart(
    siteId: string,
    pageId: string,
    productId: string,
    quantity: number,
    pricePresented: number
  ): void {

    let storeCart = LocalStorage.loadItem<StoreCart>(
      this.getLocalStorageGroup(),
      this.getLocalStorageKey(siteId, pageId));
    if (!storeCart) {
      storeCart = new StoreCart();
    }

    // Check if the product is already in the cart
    const item = storeCart.items.find(x => x.productId == productId);
    if (item) {
      item.quantity += quantity;
    } else {
      storeCart.items.push({
        productId: productId,
        quantity: quantity,
        pricePresented: pricePresented
      });
    }

    // Save the cart
    LocalStorage.saveItem(
      this.getLocalStorageGroup(),
      this.getLocalStorageKey(siteId, pageId),
      storeCart);
  }

  public static updateProductInCart(
    siteId: string,
    pageId: string,
    productId: string,
    quantity: number): boolean {

    const storeCart = LocalStorage.loadItem<StoreCart>(
      this.getLocalStorageGroup(),
      this.getLocalStorageKey(siteId, pageId));

    if (!storeCart) {
      return false;
    }

    // Check if the product is already in the cart
    const item = storeCart.items.find(x => x.productId == productId);
    if (!item) {
      return false;
    }

    item.quantity = quantity;

    // Save the cart
    LocalStorage.saveItem(
      this.getLocalStorageGroup(),
      this.getLocalStorageKey(siteId, pageId),
      storeCart);

    return true;
  }

  public static getProductsInCart(
    siteId: string,
    pageId: string): StoreCartProduct[] {

    const storeCart = LocalStorage.loadItem<StoreCart>(
      this.getLocalStorageGroup(),
      this.getLocalStorageKey(siteId, pageId));

    if (!storeCart) {
      return [];
    }

    return storeCart.items;
  }

  public static removeProductFromCart(siteId: string, pageId: string, productId: string): void {

    const storeCart = LocalStorage.loadItem<StoreCart>(
      this.getLocalStorageGroup(),
      this.getLocalStorageKey(siteId, pageId));

    if (!storeCart) {
      return;
    }

    // Check if the product is already in the cart
    const itemIndex = storeCart.items.findIndex(x => x.productId == productId);
    if (itemIndex < 0) {
      return;
    }

    storeCart.items.splice(itemIndex, 1);

    // Save the cart
    LocalStorage.saveItem(
      this.getLocalStorageGroup(),
      this.getLocalStorageKey(siteId, pageId),
      storeCart);
  }

  public static clearCart(siteId: string, pageId: string): void {
    LocalStorage.deleteItem(
      this.getLocalStorageGroup(),
      this.getLocalStorageKey(siteId, pageId)
    );
  }

  private static getLocalStorageGroup(): string {
    return `store-cart`;
  }

  private static getLocalStorageKey(siteId: string, pageId: string): string {
    return `cart-${siteId}-${pageId}`;
  }
}