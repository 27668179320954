
import './container.css';

interface ContainerProps {
    children: React.ReactNode;
}

export function ContainerComponent({ children }: ContainerProps): JSX.Element {
    return (
        <div className="container-component rounded shadow-sm">
            {children}
        </div>
    );
}
