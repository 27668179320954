import { UserQueryCreation } from "../userQueryCreation";

export class UserQueryCreationFactory {
  public static create(
    siteId: string,
    pageId: string,
    userQueryId: string,
    id: string,
    contact: string,
    subject: string,
    message: string
  ): UserQueryCreation {

    return {
      siteId,
      pageId,
      userQueryId,
      id,
      contact,
      subject,
      message
    }
  }
}