import { FormEvent, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { CreateUserResult } from "../../../common-app/system/enums/createUserResult";
import { ErrorListComponent } from "../../../common-frontend/components/errorList/errorList";
import { FormEditComponent } from "../../../common-frontend/components/form/formEdit/formEdit";
import { PageTitle } from "../../../common-frontend/components/pageTitle/pageTitle";
import { VerticalListComponent } from "../../../common-frontend/components/verticalList/verticalList";
import { GuidUtil } from "../../../common/util/guid/guid";
import { ValidationEmail } from "../../../common/util/validation/email";
import { ServiceApiSystem } from "../../../services/api/serviceApiSystem";

import "./pageSignUp.css";

enum SignUpButtonLabelOption {
  SignUp = "createAccount",
  SigningUp = "creatingAccount"
}

export function PageSignUp(): JSX.Element {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [buttonSignUpLabel, setButtonSignUpLabel] = useState<string>(t(SignUpButtonLabelOption.SignUp));
  const [isSigningUpAccount, setIsSigningUpAccount] = useState<boolean>(false);

  const handleSignUp = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let errors: string[] = [];

    // Use a regular expression to check if the email is valid
    if (ValidationEmail.isValid(email) === false) {
      errors.push(t('invalidEmail'));
    }

    // Check if the passwords match
    if (password !== passwordConfirmation) {
      errors.push(t('passwordsDoNotMatch'));
    }

    // CHeck if the password contains at least 5 characters
    if (password.length < 5) {
      errors.push(t('passwordMinLength'));
    }

    if (errors.length > 0) {
      setErrorMessages(errors);
      return;
    }

    const newUserId = GuidUtil.GenerateNewGuid();
    setButtonSignUpLabel(t(SignUpButtonLabelOption.SigningUp));
    setIsSigningUpAccount(true);
    setErrorMessages([]);

    const result = await ServiceApiSystem.createUser(newUserId, email, password);

    setIsSigningUpAccount(false);
    setButtonSignUpLabel(t(SignUpButtonLabelOption.SignUp));

    switch (result) {
      case CreateUserResult.Success:
        // Redirect to the login page
        navigate("/signin");
        break;
      case CreateUserResult.UserAlreadyExists:
        errors.push(t('usernameAlreadyInUse'));
        break;
      case CreateUserResult.UnspecifiedError:
        errors.push(t('errorCreatingAccount'));
        break;
    }

    setErrorMessages(errors);
  }

  return (
    <div className="page-sign-up">
      <Container>
        <div className="content-parent">

          <div className="content">

            <PageTitle
              title={t("createAccount")}
            />
            <Form onSubmit={(e) => { handleSignUp(e) }}>

              {/* Add an input for the user's mail */}
              <VerticalListComponent>
                <Form.Group controlId="formBasicEmail" >
                  <Form.Label>
                    {t('email')}
                  </Form.Label>
                  <FormEditComponent
                    type="text"
                    value={email}
                    onChange={setEmail}
                  />
                </Form.Group>

                {/* Add an input for the user's password */}
                <Form.Group controlId="formBasicPassword">
                  <Form.Label>
                    {t('password')}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                {/* Add an input for the user's password confirmation */}
                <Form.Group controlId="formBasicPasswordConfirmation">
                  <Form.Label>
                    {t('passwordConfirmation')}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                </Form.Group>

                <div className="buttons-holder">
                  <Button
                    type="submit"
                    variant="primary"
                    className=""
                    disabled={isSigningUpAccount}
                  >{buttonSignUpLabel}</Button>

                  <Link to={'/signin'}>
                    <Button
                      type="submit"
                      variant="outline-primary">
                      {t('alreadyHaveAccount')}
                    </Button>
                  </Link>
                </div>

                {/* Add a component to display errors */}
                <ErrorListComponent errorMessages={errorMessages} />

              </VerticalListComponent>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
}