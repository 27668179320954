import { ProductAdminList } from "../productAdminList";

export class ProductAdminListFactory {

  public static createNew(id: string, pageId: string): ProductAdminList {
    return this.create(
      id,
      pageId,
      "");
  }

  public static create(
    id: string,
    name: string,
    category: string
  ): ProductAdminList {
    return {
      id,
      name,
      category
    };
  }
}