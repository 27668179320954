
import { createContext } from 'react';
import { CustomElement } from '../../types';

export interface RichTextEditorOperationsContextType {
  editing: boolean;
  configureElementFunction: (element: CustomElement) => void;
  insertEmptyElementFunction: (element: CustomElement, location: "before" | "after") => void;
  deleteElementFunction: (element: CustomElement) => void;
}

const RichTextEditorOperationsContext = createContext<RichTextEditorOperationsContextType | undefined>(undefined);

export { RichTextEditorOperationsContext };
