
export class SiteOwnership {

  id: string = "";
  name: string = "";
  domain: string = "";

  creationDate: Date = new Date();

  isClaimed: boolean = false;
  plannedRemovalDate: Date | undefined = new Date();

  ownerId: string = "";
  ownerMail: string = "";
}