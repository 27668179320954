import './listAndItem.css';

interface ListAndItemProps {
  children: React.ReactNode;
}

export function ListAndItemComponent({ children }: ListAndItemProps): JSX.Element {
  return (
    <div className="list-and-item-component">
      {children}
    </div>
  );
}