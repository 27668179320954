import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { LoginResult } from "../enums/loginResult";

export class LoginRouteDef extends IRouteDefinition {
  constructor() {
    super("POST");
  }

  public route(): string {
    return "system/login";
  }

  public routeTemplate(): string {
    return "system/login";
  }
}

export class LoginRequestBody extends RequestBody {
  constructor(
    public mail: string,
    public password: string,
    public deviceId: string
  ) {
    super();
  }
}

export class LoginResponse extends RequestResponse {
  constructor(
    public result: LoginResult,
    public sessionId: string,
    public userId: string
  ) {
    super();
  }
}
