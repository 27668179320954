import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class GetPageDataStaticRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(pageId: string): string {
    return `site-pages-static/get-page-data-static?` + QueryParamsUtil.build({ "page-id": pageId });
  }

  public routeTemplate(): string {
    return 'site-pages-static/get-page-data-static';
  }
}

export const enum GetPageDataStaticQueryParams {
  page_id = 'page-id',
}

export class GetPageDataStaticResponse extends RequestResponse {
  constructor(public contents: string) {
    super();
  }
}