import { InsertUserQueryFunction } from "../../richTextEditorContentManager";
import { GetUserImagesInfo, RichTextEditorDataContext } from "./richTextEditorDataContext";

interface RichTextEditorDataProviderProps {
  getUserImagesInfo: GetUserImagesInfo;
  insertUserQuery: InsertUserQueryFunction;
  children: React.ReactNode;
}

export function RichTextEditorDataProvider(
  {
    getUserImagesInfo,
    insertUserQuery,
    children
  }: RichTextEditorDataProviderProps): JSX.Element {

  return (
    <RichTextEditorDataContext.Provider
      value={{
        getUserImagesInfo,
        insertUserQuery
      }}
    >
      {children}
    </RichTextEditorDataContext.Provider>
  )
}
