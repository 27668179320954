export class ArrayUtil {

  public static findIndexAndItem<T>(
    array: T[],
    predicate: (item: T) => boolean
  ): {
    index: number,
    item: T | undefined
  } {
    for (let i = 0; i < array.length; i++) {
      if (predicate(array[i])) {
        return { index: i, item: array[i] };
      }
    }
    return { index: -1, item: undefined };
  }

  public static allofTheSame<T, Q>(
    array: T[],
    predicate: (item: T) => Q
  ): boolean {
    if (array.length === 0) {
      return true;
    }

    const firstValue = predicate(array[0]);

    for (let i = 1; i < array.length; i++) {
      if (predicate(array[i]) !== firstValue) {
        return false;
      }
    }

    return true;
  }

  public static getUpdatedItems<T>(
    array: T[],
    selector: (item: T) => boolean,
    update: (item: T) => void): T[] {

    return array.map(item => {
      if (selector(item)) {
        const updatedItem = { ...item };
        update(updatedItem);
        return updatedItem;
      }
      else {
        return item;
      }
    });
  }

  public static containSameData<T>(arrayA: T[], arrayB: T[], predicateCompare: (a: T, b: T) => boolean) {

    if (arrayA.length !== arrayB.length) {
      return false;
    }

    for (let i = 0; i < arrayA.length; i++) {
      if (!predicateCompare(arrayA[i], arrayB[i])) {
        return false;
      }
    }

    return true;
  }

  public static nextIndex<T>(index: number, data: T[]): number {
    return (index + 1) % data.length;
  }

  public static last<T>(array: T[], count: number): T[] {
    return array.slice(array.length - count);
  }

  public static first<T>(array: T[], count: number): T[] {
    return array.slice(0, count);
  }

  public static insertAfterOrAtTheEnd<T>(array: T[], itemToInsert: T, predicate: (item: T) => boolean): T[] {
    const index = array.findIndex(predicate);
    if (index === -1) {
      return [...array, itemToInsert];
    }

    const newArray = [...array];
    newArray.splice(index + 1, 0, itemToInsert);
    return newArray;
  }

  public static getUnique<T>(array: T[]): T[] {
    return Array.from(new Set(array));
  }

  public static getUniqueWithPredicate<T, U extends string | number | symbol>(array: T[], getIdentifier: (item: T) => U): T[] {

    // Use a Set to keep track of the unique items
    const uniqueElements = new Set<U>();

    // Go through the array and check if we have already seen the item
    return array.filter(item => {
      const identifier = getIdentifier(item);
      if (uniqueElements.has(identifier)) {
        return false;
      }

      uniqueElements.add(identifier);
      return true;
    });
  }

  public static diff<T, K>(
    oldArray: T[],
    newArray: T[],
    getId: (item: T) => K,
    compareItems: (item1: T, item2: T) => boolean
  ): {
    newElements: T[];
    updatedElements: T[];
    deletedElements: T[];
    unchangedElements: T[];
  } {
    const result = {
      newElements: [] as T[],
      updatedElements: [] as T[],
      deletedElements: [] as T[],
      unchangedElements: [] as T[],
    };

    const oldMap = new Map<K, T>();
    const newMap = new Map<K, T>();

    // Populate maps for easy lookup
    oldArray.forEach((item) => oldMap.set(getId(item), item));
    newArray.forEach((item) => newMap.set(getId(item), item));

    // Determine new, updated, and unchanged elements
    newArray.forEach((item) => {
      const id = getId(item);
      if (!oldMap.has(id)) {
        result.newElements.push(item);
      } else if (!compareItems(oldMap.get(id)!, item)) {
        result.updatedElements.push(item);
      } else {
        result.unchangedElements.push(item);
      }
    });

    // Determine deleted elements
    oldArray.forEach((item) => {
      const id = getId(item);
      if (!newMap.has(id)) {
        result.deletedElements.push(item);
      }
    });

    return result;
  }
}