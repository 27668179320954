import { useEffect, useState } from 'react';
import { SitePageFeature } from '../../../../common-app/sites/enums/sitePageFeature';
import { SitePageEditorInfo } from '../../../../common-app/sites/models/sitePageEditorInfo';
import { VerticalListComponent } from '../../../../common-frontend/components/verticalList/verticalList';
import { ServiceApiSites } from '../../../../services/api/serviceApiSites';
import { PagePaneSettingsComponent } from './pagePanes/common/settings/pagePaneSettings';
import { PagePaneDynamicRendererComponent } from './pagePanesDynamic/pagePaneDynamicRenderer';
import { PagePanesComponent } from './pagePanes/pagePanes';

import './pageSettings.css';

interface PageSettingsProps {
  siteId: string;
  pageId: string;
  callbackPagesChanged: () => void;
}

export function PageSettingsComponent({ siteId, pageId, callbackPagesChanged }: PageSettingsProps): JSX.Element {

  const [pageData, setPageData] = useState<SitePageEditorInfo>(new SitePageEditorInfo());

  const [pageFeatures, setPageFeatures] = useState<SitePageFeature[]>([]);

  const loadPageData = async () => {
    const pageEditData = await ServiceApiSites.getPageEditorInfo(siteId, pageId);
    if (pageEditData) {
      setPageData(pageEditData);
    }
  }

  const loadPageFeatures = async () => {

    // Get the page features
    const featuresList = await ServiceApiSites.getPageFeatures(siteId, pageId);

    // Update the page features
    setPageFeatures(featuresList);
  }

  useEffect(() => {
    loadPageData();
    loadPageFeatures();
  }, [siteId, pageId]);

  const handlePageSettingsChanged = () => {
    callbackPagesChanged();
    loadPageData();
  }

  const handlePageContentsChanged = () => {
    loadPageFeatures();
  }

  return (
    <div className="page-pane-settings-component">
      <div>
        <VerticalListComponent>
          <h2>
            {pageData.name}
          </h2>

          {/* Common Page Settings */}
          <PagePaneSettingsComponent
            siteId={siteId}
            pageId={pageId}
            callbackPagesChanged={handlePageSettingsChanged}
          />

          {/* Page Features */}
          {pageFeatures.length > 0 &&
            <PagePaneDynamicRendererComponent
              siteId={siteId}
              pageId={pageId}
              pageFeatures={pageFeatures}
            />
          }

          {/* Page Content Settings */}
          <PagePanesComponent
            siteId={siteId}
            pageId={pageId}
            pageType={pageData.type}
            callbackPageContentsChanged={handlePageContentsChanged}
          />

        </VerticalListComponent>
      </div>
    </div>
  );
}
