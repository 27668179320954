import { Product } from "../../../../../../common-app/sitePagesStores/models/product";

// Note: This data structure might feel a bit redundant as of now, because we could simply cache the 
// product object itself, but in the future we might want to have more information about when the 
// product was cached, for example.

export class CacheProduct {
  constructor(
    public productId: string,
    public product: Product
  ) {
  }
}