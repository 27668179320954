import { SitePageType } from "../../../../../common-app/sites/enums/sitePageType";
import { PagePaneStoreOrdersComponent } from "./store/orders/pagePaneStoreOrders";
import { PagePaneBlogEntriesComponent } from "./blog/pagePaneBlogEntries";
import { PagePaneStaticEditorComponent } from "./static/pagePaneStaticEditor";
import { PagePaneStoreProductsComponent } from "./store/products/pagePaneStoreProducts";
import { PagePaneContactEntriesComponent } from "./contacts/pagePaneContactEntries";

export interface PagePaneProps {
  siteId: string;
  pageId: string;
  callbackPageContentsChanged: () => void;
}

export type PagePane = React.ComponentType<PagePaneProps>;

export class PagePanesMapping {

  public static getPagePanes(pageType: SitePageType): PagePane[] {

    switch (pageType) {
      case SitePageType.Static:
        return [
          PagePaneStaticEditorComponent
        ];
      case SitePageType.Blog:
        return [
          PagePaneBlogEntriesComponent
        ];
      case SitePageType.Contacts:
        return [
          PagePaneContactEntriesComponent
        ];
      case SitePageType.Store:
        return [
          PagePaneStoreProductsComponent,
          PagePaneStoreOrdersComponent
        ];
      default:
        return [];
    }
  }
}