import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HorizontalListComponent } from "../../../../../common-frontend/components/horizontalList/horizontalList";
import { VerticalListComponent } from "../../../../../common-frontend/components/verticalList/verticalList";
import { ServiceApiSitesOwnership } from "../../../../../services/api/sites/serviceApiSitesOwnership";
import { ServiceFeatureAnalytics } from "../../../../../services/features/analytics/serviceFeatureAnalytics";
import { useUserSessionContext } from "../../../../contexts/session/hooks/useUserSessionContext";

interface SiteSettingsOwnershipActionsProps {
  siteId: string;
}

export function SiteSettingsOwnershipActionsComponent({ siteId }: SiteSettingsOwnershipActionsProps): JSX.Element {

  const { t } = useTranslation();

  const userSessionContext = useUserSessionContext();
  const userIsLoggedIn = userSessionContext.userId !== "";

  const handleTakeOwnership = async (): Promise<void> => {

    const deviceId = ServiceFeatureAnalytics.getDeviceIdCreateIfDoesNotExist();

    const result = await ServiceApiSitesOwnership.takeSiteOwnership(
      userSessionContext.userSessionId,
      siteId,
      deviceId
    );

    if (result) {
      // Refresh the page
      window.location.reload();
    }
  }

  const getExplanationMessage = (): string => {

    if (userIsLoggedIn) {
      return t('connectSiteToAccount');
    }
    else {
      return t('siteCreatedWithoutSession');
    }
  }

  const renderActionButtons = (): JSX.Element => {

    if (userIsLoggedIn) {
      return (
        <HorizontalListComponent>
          <Button onClick={handleTakeOwnership}>
            {t('connectToMyAccount')}
          </Button>
        </HorizontalListComponent>
      )
    }
    else {
      return (
        <HorizontalListComponent>
          <Link to="/signup">
            <Button>
              {t('createAccount')}
            </Button>
          </Link>

          <Link to="/signin">
            <Button variant="outline-primary">
              {t('login')}
            </Button>
          </Link>
        </HorizontalListComponent>
      )
    }
  }

  return (
    <div className="site-settings-ownership-actions">
      <VerticalListComponent>
        <div>{getExplanationMessage()}</div>
        <div>{renderActionButtons()}</div>
      </VerticalListComponent>
    </div>
  );
}