import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslationCommonFrontEnd } from '../../../../translations/translation';
import { RichTextEditorContentManager } from '../../richTextEditorContentManager';
import { CustomElement } from '../../types';
import { ElementSettingsConfig } from '../elementSettingsConfig';
import { ElementName } from '../information/elementName';

import './elementEdit.css';

interface ElementEditProps {
  contentManager: RichTextEditorContentManager;
  element: CustomElement;
  show: boolean;
  onDismiss: () => void;
  onConfirm: (element: CustomElement) => void;
}

export function ElementEditComponent({
  contentManager,
  element,
  show,
  onDismiss,
  onConfirm }: ElementEditProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const [elementCanBeUpdated, setElementCanBeUpdated] = useState<boolean>(false);
  const [localElement, setLocalElement] = useState<CustomElement | undefined>(undefined);
  const [elementName, setElementName] = useState<string | undefined>(undefined);

  let RenderComponent = ElementSettingsConfig.getElementEditComponent(element.type);
  const editElementRef = useRef<any>(null);

  useEffect(() => {
    setLocalElement(element);
    setElementName(t(ElementName.getElementName(element.type)));
  }, [element]);

  const handleClose = () => {
    onDismiss();
  }

  const handleSave = () => {

    if (editElementRef.current) {

      const elementToUpdate = structuredClone(element);
      const updatedElement = editElementRef.current.updateElement(elementToUpdate);

      if (!updatedElement) {
        return;
      }

      onConfirm(updatedElement);
      onDismiss();
    }
  }

  return (
    <div className="element-edit-component">
      <Modal
        show={show}
        size="lg"
        centered
        backdrop="static"
        onHide={handleClose}>

        <Modal.Header closeButton>
          <Modal.Title>{t('configureElement', { elementName: elementName })}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='element-edit-component-render-area'>
            {RenderComponent && localElement &&
              <RenderComponent
                ref={editElementRef}
                contentManager={contentManager}
                element={localElement}
                elementCanBeUpdated={setElementCanBeUpdated}
              />
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={handleClose}>
            {t('close')}
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!elementCanBeUpdated}
          >
            {t('save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
