import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProductStoreNavigation } from "../../../../common-app/sitePagesStores/models/productStoreNavigation";
import { useTranslationCommonFrontEnd } from "../../../translations/translation";
import { VerticalWhiteSpaceComponent } from "../../verticalWhiteSpace/verticalWhiteSpace";
import { ProductListComponent } from "./components/productList/productList";
import {
  UncategorizedCategoryName,
  UncategorizedLink
} from "./data/uncategorized";
import { DynamicPageStoreProvider } from "./provider/dynamicPageStoreProvider";

import './dynamicPageStoreCategory.css';

interface DynamicPageStoreCategoryProps {
  dataProvider: DynamicPageStoreProvider;
  category: string;
}

export function DynamicPageStoreCategoryComponent({
  dataProvider,
  category
}: DynamicPageStoreCategoryProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const categoryAdjusted = category === UncategorizedLink ? "" : category;
  const categoryName = category === UncategorizedLink ? t(UncategorizedCategoryName) : category;

  const [products, setProducts] = useState<ProductStoreNavigation[]>([]);

  useEffect(() => {

    dataProvider.getProductsStoreNavigationByCategory(categoryAdjusted).then((products) => {
      setProducts(products);
    });

  }, [dataProvider]);

  dataProvider.getProductsStoreNavigation();

  return (
    <div className="dynamic-page-store-category-component">
      <div className="group-label">{categoryName}</div>

      {products.length === 0 &&
        <div className="no-products">
          {t('noProductFound')}
        </div>
      }

      <ProductListComponent
        dataProvider={dataProvider}
        products={products}
      />

      <Link
        className="no-decor"
        to="?"
      >
        <Button>
          {t('goBack')}
        </Button>
      </Link>
      <VerticalWhiteSpaceComponent amount={3} />
    </div>
  );
}