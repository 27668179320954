import { SitePageType } from '../../../../../common-app/sites/enums/sitePageType';
import { VerticalListComponent } from '../../../../../common-frontend/components/verticalList/verticalList';
import { PagePanesMapping } from './pagePanesMapping';

interface PagePanesProps {
  siteId: string;
  pageId: string;
  pageType: SitePageType;
  callbackPageContentsChanged: () => void;
}

export function PagePanesComponent({
  siteId,
  pageId,
  pageType,
  callbackPageContentsChanged,
}: PagePanesProps): JSX.Element {

  const componentsToRender = PagePanesMapping.getPagePanes(pageType);

  return (
    <div className="page-data-editor-component">
      <VerticalListComponent>
        {componentsToRender.map((ComponentToRender, index) => (
          <ComponentToRender
            key={index}
            siteId={siteId}
            pageId={pageId}
            callbackPageContentsChanged={callbackPageContentsChanged}
          />
        ))}
      </VerticalListComponent>
    </div>
  );
}
