import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreOrderState } from '../../../../../../../common-app/sitePagesStores/enums/storeOrderState';
import { ProductAdminList } from '../../../../../../../common-app/sitePagesStores/models/productAdminList';
import { StoreOrderAdmin } from '../../../../../../../common-app/sitePagesStores/models/storeOrderAdmin';
import { StoreOrderLine } from '../../../../../../../common-app/sitePagesStores/models/storeOrderLine';
import { HorizontalListComponent } from '../../../../../../../common-frontend/components/horizontalList/horizontalList';
import { LabelComponent } from '../../../../../../../common-frontend/components/label/label';
import { LabelAndValueComponent } from '../../../../../../../common-frontend/components/labelAndValue/labelAndValue';
import { Spanner } from '../../../../../../../common-frontend/components/micro/spanner/spanner';
import { VerticalListComponent } from '../../../../../../../common-frontend/components/verticalList/verticalList';
import { VerticalWhiteSpaceComponent } from '../../../../../../../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace';
import { CurrencyUtil } from '../../../../../../../common/util/currency/currencyUtil';
import { DateUtil } from '../../../../../../../common/util/date/dateUtil';
import { OrderStateComponent } from '../orderState/orderState';
import { OrderLineDetailsComponent } from './orderLineDetails';

import './orderDetails.css';

interface OrderDetailsProps {
  order: StoreOrderAdmin;
  products: ProductAdminList[];
  handleUpdatedOrderState: (newState: StoreOrderState) => void;
}

export function OrderDetailsComponent({
  order,
  products,
  handleUpdatedOrderState
}: OrderDetailsProps): JSX.Element {

  const { t } = useTranslation();

  const [orderLines, setOrderLines] = useState<StoreOrderLine[]>([]);

  useEffect(() => {

    setOrderLines(order.lines.sort((orderLineA, orderLineB) => {

      // Get the product for each order line
      const productA = products.find((product) => product.id === orderLineA.productId);
      const productB = products.find((product) => product.id === orderLineB.productId);

      if (!productA || !productB) {
        return 0;
      }

      if (productA.category === "") {
        return -1;
      }

      if (productB.category === "") {
        return 1;
      }

      if (productA.category < productB.category) {
        return -1;
      }

      if (productA.category > productB.category) {
        return 1;
      }

      if (productA.name < productB.name) {
        return -1;
      }

      if (productA.name > productB.name) {
        return 1;
      }

      return 0;
    }));

  }, [order, products]);

  return (
    <div className="order-details-component rounded">

      {/* Order details */}
      <div className="group-name">
        {t('details')}
      </div>

      <VerticalListComponent>
        {/* Code and Total */}
        <HorizontalListComponent>
          <LabelAndValueComponent
            label={t('code')}
            value={order.code}
          />

          <Spanner />
          <LabelAndValueComponent
            label={t('total')}
            value={CurrencyUtil.formatCurrencyAsEur(order.orderTotal)}
          />
        </HorizontalListComponent>

        {/* Date */}
        <LabelAndValueComponent
          label={t('date')}
          value={DateUtil.formatDateAsHumanReadable(order.creationDate)}
        />

        {/* State */}
        <div className="order-state">
          <LabelComponent bold={true}>{t('state')}</LabelComponent>
          <OrderStateComponent
            orderState={order.state}
            onOrderStateChange={handleUpdatedOrderState}
          />
        </div>

      </VerticalListComponent>

      <VerticalWhiteSpaceComponent amount={2} />

      {/* Order Lines */}
      <div className="group-name">
        {t('products')}
      </div>

      <div>
        {orderLines.map((orderLine, index) => {
          const product = products.find((product) => product.id === orderLine.productId);
          if (!product) {
            return null;
          }

          return (
            <div key={index} className='order-line'>
              <OrderLineDetailsComponent
                orderLine={orderLine}
                product={product}
              />
            </div>
          );
        })}
      </div>

      <VerticalWhiteSpaceComponent amount={2} />

      {/* Buyer Information */}
      <div className="group-name">
        {t('buyer')}
      </div>

      <VerticalListComponent>
        <LabelAndValueComponent
          label={t('name')}
          value={order.buyerName}
        />
        <LabelAndValueComponent
          label={t('email')}
          value={order.buyerEmail}
        />
        <LabelAndValueComponent
          label={t('phone')}
          value={order.buyerPhone}
        />
        <LabelAndValueComponent
          label={t('address')}
          value={order.buyerAddress}
        />
      </VerticalListComponent>
    </div>
  );
}