
export class TypeUpdate {

  public static updateObjectField<T extends object, K extends keyof T>(
    obj: T,
    field: K,
    value: T[K]
  ): T {
    return { ...obj, [field]: value };
  }

  public static updateObjectFieldDeep<
    T extends object,
    K1 extends keyof T,
    K2 extends keyof T[K1]
  >(
    obj: T,
    field1: K1,
    field2: K2,
    value: T[K1][K2]
  ): T {
    return {
      ...obj,
      [field1]: {
        ...obj[field1],
        [field2]: value,
      },
    };
  }
}