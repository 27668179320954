import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

export type ListDirection = "horizontal" | "vertical";

interface SortableListItemProps<T> {
  item: T;
  index: number;
  listId: string;
  listDirection: ListDirection;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  renderElement: (item: T) => JSX.Element;
  onDropComplete?: () => void;
}

export function SortableListItem<T>({
  item,
  index,
  listId,
  listDirection,
  moveItem,
  renderElement,
  onDropComplete }: SortableListItemProps<T>) {

  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: listId,
    hover(draggedItem: { index: number; listId: string }, monitor) {
      if (!ref.current) {
        return;
      }

      if (draggedItem.listId !== listId) {
        return;
      }

      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Determine middle points
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }

      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      const hoverClientX = clientOffset.x - hoverBoundingRect.left;

      if (listDirection === "horizontal") {
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return;
        }
      }

      if (listDirection === "vertical") {
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
      }

      moveItem(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
    drop() {
      if (onDropComplete) {
        onDropComplete();
      }
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: listId,
    item: { index, listId },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {renderElement(item)}
    </div>
  );
}