import { SitePageType } from "../../../common-app/sites/enums/sitePageType";
import { SitePageRenderInfo } from "../../../common-app/sites/models/sitePageRenderInfo";
import { DynamicPageBlogWrapperComponent } from "./dynamicPages/blog/dynamicPageBlogWrapper";
import { DynamicPageStaticWrapperComponent } from "./dynamicPages/static/dynamicPageStaticWrapper";
import { DynamicPageContactsWrapperComponent } from "./dynamicPages/contacts/dynamicPageContactsWrapper";
import { DynamicPageStoreWrapperComponent } from "./dynamicPages/store/dynamicPageStoreWrapper";

export interface DynamicPageComponentProps {
  pageRenderInfo: SitePageRenderInfo;
}

export class DynamicPageContentsRenderingMapping {

  public static getPageContentsRenderComponent(pageType: SitePageType): React.ComponentType<DynamicPageComponentProps> | null {
    return this.pageContentsRenderComponentMap[pageType];
  }

  private static pageContentsRenderComponentMap: Record<SitePageType, React.ComponentType<DynamicPageComponentProps> | null> = {
    [SitePageType.Static]: DynamicPageStaticWrapperComponent,
    [SitePageType.Blog]: DynamicPageBlogWrapperComponent,
    [SitePageType.Contacts]: DynamicPageContactsWrapperComponent,
    [SitePageType.Store]: DynamicPageStoreWrapperComponent,
    [SitePageType.Unknown]: null
  };
}