import './sectionTitle.css';

interface SectionTitleProps {
    children: React.ReactNode;
}

export function SectionTitleComponent({ children }: SectionTitleProps): JSX.Element {
    return (
        <div className="section-title-component">
            {children}
        </div>
    );
}
