import { SitePageFeature } from "../../../../../common-app/sites/enums/sitePageFeature";
import { DynamicPageFeatureMapping } from "./dynamicPageFeatureMapping";

interface PagePaneDynamicComponentProps {
  siteId: string;
  pageId: string;
  feature: SitePageFeature;
}

export function PagePaneDynamicComponent({ siteId, pageId, feature }: PagePaneDynamicComponentProps): JSX.Element {

  const ComponentToRender = DynamicPageFeatureMapping.getPageFeatureComponent(feature);

  if (!ComponentToRender) {
    return <div>Unknown feature: {feature}</div>
  }

  return (
    <ComponentToRender
      siteId={siteId}
      pageId={pageId}
    />
  )
}