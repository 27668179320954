import { useTranslationCommonFrontEnd } from "../../../../../translations/translation";
import { HorizontalListComponent } from "../../../../horizontalList/horizontalList";
import { Spanner } from "../../../../micro/spanner/spanner";
import { DynamicPageStoreProvider } from "../../provider/dynamicPageStoreProvider";
import { ButtonGoToCheckoutComponent } from "../buttonGoToCheckout/buttonGoToCheckout";
import { ButtonViewCartComponent } from "../buttonViewCart/buttonViewCart";

import "./frontPageCart.css";

interface FrontPageCartProps {
  dataProvider: DynamicPageStoreProvider;
}

export function FrontPageCartComponent({ dataProvider }: FrontPageCartProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const itemsInCart = dataProvider.getProductsInCart();
  const itemsCount = itemsInCart.reduce((acc, item) => {
    return acc + item.quantity;
  }, 0);

  return (
    <div className="front-page-cart-component">
      <div className="group-label">{t('cart')}</div>
      <HorizontalListComponent>

        {itemsCount === 0 &&
          <div className="message">
            {t('cartEmpty')}
          </div>
        }

        {itemsCount > 0 &&
          <div className="message">
            {t('cartItemCount', { count: itemsCount })}
          </div>
        }

        <Spanner />
        <ButtonViewCartComponent />
        <ButtonGoToCheckoutComponent />
      </HorizontalListComponent>
    </div>
  );
}