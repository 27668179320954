import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class IsSessionActiveRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(sessionId: string): string {
    return `system/is-session-active?` + QueryParamsUtil.build({ "session-id": sessionId });
  }

  public routeTemplate(): string {
    return 'system/is-session-active';
  }
}

export const enum IsSessionActiveQueryParams {
  sessionId = 'session-id',
}

export class IsSessionActiveResponse extends RequestResponse {
  constructor(
    public isActive: boolean,
    public userId: string
  ) {
    super();
  }
}
