import { CustomElementHorizontalLine } from "../../types";
import { RenderComponentProps } from "../renderComponentProps";

export function RenderHorizontalLineComponent({
  element,
  attributes,
  children
}: RenderComponentProps): JSX.Element {

  const horizontalLineElement = element as CustomElementHorizontalLine;

  const styleHeightProp = horizontalLineElement.width ? `${horizontalLineElement.width}px` : '1px';
  const styleColorProp = horizontalLineElement.color ? horizontalLineElement.color : 'black';

  return (
    <div
      {...attributes}
      contentEditable={false}
      style={{
        marginBlock: 'var(--size-3)'
      }}
      className="editor-horizontal-line-node"
    >
      <div style={{
        width: '100%',
        minHeight: 'var(--size-1)',
      }} />

      <div style={{
        width: '100%',
        borderTopWidth: styleHeightProp,
        borderTopColor: styleColorProp,
        borderTopStyle: 'solid',
      }} />

      <div style={{
        width: '100%',
        minHeight: 'var(--size-1)',
        paddingBottom: 'var(--size-3)',
      }} />

      <div style={{ display: "none" }}>
        {children}
      </div>
    </div>
  );
}