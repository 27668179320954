import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SiteOwnership } from "../../models/siteOwnership/siteOwnership";

export class GetUnclaimedSitesRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(deviceId: string): string {
    return `sites/get-unclaimed-sites?` + QueryParamsUtil.build({ "device-id": deviceId });
  }

  public routeTemplate(): string {
    return 'sites/get-unclaimed-sites';
  }
}

export const enum GetUnclaimedSitesQueryParams {
  deviceId = 'device-id',
}

export class GetUnclaimedSitesResponse extends RequestResponse {
  constructor(
    public sites: SiteOwnership[]
  ) {
    super();
  }
}
