import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SiteOwnership } from "../../models/siteOwnership/siteOwnership";

export class GetSitesOwnedByUserRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(userId: string): string {
    return `sites/get-sites-owned-by-user?` + QueryParamsUtil.build({ "user-id": userId });
  }

  public routeTemplate(): string {
    return 'sites/get-sites-owned-by-user';
  }
}

export const enum GetSitesOwnedByUserQueryParams {
  userId = 'user-id',
}

export class GetSitesOwnedByUserResponse extends RequestResponse {
  constructor(
    public sites: SiteOwnership[]
  ) {
    super();
  }
}
