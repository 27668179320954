import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SitePageEditorList } from "../../models/sitePageEditorList";

export class GetSitePagesEditorListRouteDef extends IRouteDefinition {

  constructor() {
    super("GET");
  }

  public route(siteId: string): string {
    return `sites/get-site-pages-editor-list?` + QueryParamsUtil.build({ siteId });
  }

  public routeTemplate(): string {
    return 'sites/get-site-pages-editor-list';
  }
}

export const enum GetSitePagesEditorListQueryParams {
  siteId = 'siteId',
}

export class GetSitePagesEditorListResponse extends RequestResponse {
  constructor(
    public pages: SitePageEditorList[]) {
    super();
  }
}