
export class UserQueryAdminList {
  id: string = '';
  siteId: string = '';
  pageId: string = '';
  userQueryId: string = '';
  contact: string = '';
  subject: string = '';
  message: string = '';
  creationDate: Date = new Date();
  isUnread: boolean = false;
}
