import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SitePageFeature } from "../../enums/sitePageFeature";

export class GetSitePageFeaturesRouteDef extends IRouteDefinition {

  constructor() {
    super('GET');
  }

  public route(siteId: string, pageId: string): string {
    return `sites/get-site-page-features?` + QueryParamsUtil.build({
      "site-id": siteId,
      "page-id": pageId
    });
  }

  public routeTemplate(): string {
    return 'sites/get-site-page-features';
  }
}

export const enum GetSitePageFeaturesQueryParams {
  siteId = 'site-id',
  pageId = 'page-id',
}

export class GetSitePageFeaturesResponse extends RequestResponse {
  constructor(
    public features: SitePageFeature[]
  ) {
    super();
  }
}
