import { useEffect, useState } from "react";
import classNames from "classnames";
import { ArrayUtil } from "../../../../../common/util/array/arrayUtil";
import { GuidUtil } from "../../../../../common/util/guid/guid";
import { SitePageEditorList } from "../../../../../common-app/sites/models/sitePageEditorList";
import { HorizontalListComponent } from "../../../../../common-frontend/components/horizontalList/horizontalList";
import { SortableList } from "../../../../../common-frontend/components/sortableList/sortableList";
import { Spanner } from "../../../../../common-frontend/components/micro/spanner/spanner";
import { QuestionDialogComponent } from "../../../../../common-frontend/components/questionDialog/questionDialog";
import { SiteEditorPageListMenuButtonComponent } from "../siteEditorPageListMenuButton/siteEditorPageListMenuButton";
import { ServiceApiSites } from "../../../../../services/api/serviceApiSites";
import { PageNameComponent } from "../../common/pageName/pageName";

export interface PageToDisplay {
  pageInfo: SitePageEditorList;
  children: PageToDisplay[];
}

interface SiteEditorSidePanelItemProps {
  siteId: string;
  parentItem: PageToDisplay | undefined;
  pagesToDisplay: PageToDisplay[];

  onShowChangeParentModal: (pageId: string) => void;
  onPagesReorganized: (parentPageId: string, pageIds: string[]) => void;

  selectedItemId: string,
  setSelectedItem: (selectedItemId: string) => void;
  callbackPagesChanged: () => void;
}

export function SiteEditorSidePanelItemComponent({
  siteId,
  parentItem,
  pagesToDisplay,
  onShowChangeParentModal,
  onPagesReorganized,
  selectedItemId,
  setSelectedItem,
  callbackPagesChanged
}: SiteEditorSidePanelItemProps): JSX.Element {

  const [localPagesToDisplay, setLocalPagesToDisplay] = useState<PageToDisplay[]>([]);
  const [showConfirmationDeletePage, setShowConfirmationDeletePage] = useState<boolean>(false);

  useEffect(() => {
    setLocalPagesToDisplay(pagesToDisplay);
  }, [pagesToDisplay]);

  const handlePagesOrderChanged = (pages: PageToDisplay[]) => {
    setLocalPagesToDisplay(pages);
  }

  const handleDropComplete = () => {

    // Check if the order has changed
    if (ArrayUtil.containSameData(localPagesToDisplay, pagesToDisplay, (a, b) => a.pageInfo.id === b.pageInfo.id)) {
      return;
    }

    // The order has changed
    let parentPageId = '';
    if (parentItem !== undefined) {
      parentPageId = parentItem.pageInfo.id;
    }

    onPagesReorganized(parentPageId, localPagesToDisplay.map((page) => page.pageInfo.id));
  }

  const renderListContainer = (children: JSX.Element): JSX.Element => {

    return (<div className={parentItem ? "item-children" : ""}>
      {children}
    </div>);
  }

  const askConfirmationDeletePage = () => {
    setShowConfirmationDeletePage(true);
  }

  const handleDeletePage = async (pageId: string) => {
    const result = await ServiceApiSites.deleteSitePage(siteId, pageId);
    if (result) {
      callbackPagesChanged();
      setSelectedItem(siteId);
    }
  }

  const handleDuplicatePage = async (pageId: string) => {
    const newPageId = GuidUtil.GenerateNewGuid();
    await ServiceApiSites.duplicateSitePage(siteId, pageId, newPageId);

    callbackPagesChanged();
    setSelectedItem(newPageId);
  }

  const renderPage = (parentPage: PageToDisplay | undefined, displayPage: PageToDisplay) => {

    const isPageSelected = displayPage.pageInfo.id === selectedItemId;
    return (
      <div
        key={displayPage.pageInfo.id}>
        <div
          className={classNames("item", { "item-selected": isPageSelected })}
          onClick={() => setSelectedItem(displayPage.pageInfo.id)}
        >
          <HorizontalListComponent>
            <div className="page-name">
              <PageNameComponent name={displayPage.pageInfo.name} />
            </div>

            {isPageSelected &&
              <>
                <Spanner />
                <SiteEditorPageListMenuButtonComponent
                  onChangeParent={() => {
                    onShowChangeParentModal(displayPage.pageInfo.id)
                  }}
                  onDuplicatePage={() => handleDuplicatePage(displayPage.pageInfo.id)}
                  onDeletePage={() => askConfirmationDeletePage()}
                />
              </>
            }
          </HorizontalListComponent>
        </div>
      </div>
    );
  }

  const renderPageItem = (page: PageToDisplay): JSX.Element => {
    return (
      <div>

        {/* Render the page name and styles */}
        {renderPage(undefined, page)}

        {/* Render the children */}
        <SiteEditorSidePanelItemComponent
          siteId={siteId}
          parentItem={page}
          pagesToDisplay={page.children}
          selectedItemId={selectedItemId}
          setSelectedItem={setSelectedItem}
          onPagesReorganized={onPagesReorganized}
          onShowChangeParentModal={onShowChangeParentModal}
          callbackPagesChanged={callbackPagesChanged}
        />
      </div>
    );
  }

  return <div>

    <SortableList
      items={localPagesToDisplay}
      listId={`list-${parentItem?.pageInfo.id}`}
      listDirection="vertical"

      onItemsReordered={handlePagesOrderChanged}

      getItemKey={(item) => item.pageInfo.id}
      renderContainer={renderListContainer}
      renderElement={renderPageItem}

      onDropComplete={handleDropComplete}
    />

    {/* Question dialog */}
    <QuestionDialogComponent
      showDialog={showConfirmationDeletePage}
      title="Apagar página?"
      question={`Tem a certeza que deseja apagar esta página?`}
      labelPositive="Apagar"
      positiveIsDanger={true}
      onPositive={() => {
        setShowConfirmationDeletePage(false);
        handleDeletePage(selectedItemId);
      }}
      onNegative={() => {
        setShowConfirmationDeletePage(false);
      }}
    />
  </div>
}