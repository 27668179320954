import { v4 as uuidv4 } from 'uuid';

export class GuidUtil {
    public static GenerateNewGuid(): string {
        return uuidv4();
    }

    public static GetGuidLength(): number {
        return 36;
    }

    public static CheckValidGuid(guid: string): boolean {
        const guidRegex = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
        return guidRegex.test(guid);
    }
}