import './validationError.css';

interface ValidationErrorProps {
    show: boolean;
    message: string;
}

export function ValidationErrorComponent({ show, message }: ValidationErrorProps): JSX.Element | null {

    if (!show) {
        return null;
    }

    return (
        <div className="validation-error-component">
            <i className="bi bi-exclamation-triangle" /> {message}
        </div>
    );
}
