export class ListItem<T> {
  constructor(item: T, savedInTheBackend: boolean) {
    this.item = item;
    this.isSavedInTheBackend = savedInTheBackend;
  }

  item: T;
  isSavedInTheBackend: boolean;
}

export class ListItemHelper {

  public static WrapItems<T>(items: T[], savedInTheBackend: boolean): ListItem<T>[] {
    return items.map((item) => new ListItem(item, savedInTheBackend));
  }

  public static IsAnyItemUnsaved<T>(items: ListItem<T>[]): boolean {
    return items.some((item) => item.isSavedInTheBackend === false);
  }

  public static GetUnsavedItem<T>(items: ListItem<T>[]): ListItem<T> | undefined {
    return items.find((item) => item.isSavedInTheBackend === false);
  }
}