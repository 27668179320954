import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FormEditComponent } from "../../../../../common-frontend/components/form/formEdit/formEdit";
import { HorizontalListComponent } from "../../../../../common-frontend/components/horizontalList/horizontalList";
import { ValidationMessagesGroupComponent } from "../../../../../common-frontend/components/validations/validationMessagesGroup/validationMessagesGroup";
import { VerticalListComponent } from "../../../../../common-frontend/components/verticalList/verticalList";
import { SitesUtil } from "../../../../../common/sites/sitesUtil";
import { ServiceApiSites } from "../../../../../services/api/serviceApiSites";
import { GenerateUrlPartComponent } from "../../common/generateUrlPart/generateUrlPart";

interface SiteSettingsDomainChangeProps {
  siteId: string;
  siteName: string;
  currentDomain: string;
  show: boolean;
  onDismiss: () => void;
  onConfirm: (newDomain: string) => void;
}

export function SiteSettingsDomainChangeComponent({
  siteId,
  siteName,
  currentDomain,
  show,
  onDismiss,
  onConfirm
}: SiteSettingsDomainChangeProps): JSX.Element {

  const { t } = useTranslation();

  const [tentativeDomain, setTentativeDomain] = useState<string>("");
  const [domainEditable, setDomainEditable] = useState<boolean>(true);

  const [checkingDomainCount, setCheckingDomainCount] = useState<number>(0);
  const [isDomainUnique, setIsDomainUnique] = useState<boolean>(true);

  const isDomainValid = SitesUtil.isSiteDomainValid(tentativeDomain);

  useEffect(() => {
    if (show) {
      setTentativeDomain(currentDomain);
    }
  }, [currentDomain, show]);

  const handleGenerateDomain = async () => {

    const result = await ServiceApiSites.getPossibleSiteDomain(siteId, siteName);
    if (!result) {
      return;
    }

    setTentativeDomain(result);
  }

  const checkSiteDomain = async (): Promise<boolean> => {

    setCheckingDomainCount((n) => n + 1);
    const isDomainAvailable = await ServiceApiSites.getIsSiteDomainAvailable(siteId, tentativeDomain);
    setCheckingDomainCount((n) => n - 1);

    if (isDomainAvailable) {
      setIsDomainUnique(true);
      return true;
    }
    else {
      setIsDomainUnique(false);
      return false;
    }
  }

  useEffect(() => {
    if (tentativeDomain != "") {
      checkSiteDomain();
    }

  }, [tentativeDomain]);

  const handleConfirm = async () => {

    setDomainEditable(false);

    // Check the domain one more time
    const isValid = await checkSiteDomain();

    if (isValid) {
      onConfirm(tentativeDomain);
      onDismiss();
    }
    setDomainEditable(true);
  }

  return (
    <Modal
      show={show}
      onHide={onDismiss}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{t('changePath')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VerticalListComponent>
          <HorizontalListComponent>
            <FormEditComponent
              id="siteUrl"
              className='input'
              type="text"
              value={tentativeDomain}
              onChange={(value) => setTentativeDomain(value)}
              readonly={!domainEditable}
            />
            <GenerateUrlPartComponent
              onGenerateUrl={handleGenerateDomain}
            ></GenerateUrlPartComponent>
          </HorizontalListComponent>
          <ValidationMessagesGroupComponent
            messages={[
              { text: t('pathAlreadyInUse'), show: !isDomainUnique },
              { text: t('invalidPath'), show: !isDomainValid },
            ]}
          />
        </VerticalListComponent>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onDismiss}>
          {t('cancel')}
        </Button>
        <Button variant="primary"
          onClick={handleConfirm}
          disabled={!isDomainValid || !isDomainUnique || checkingDomainCount > 0}
        >
          {t('confirm')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
