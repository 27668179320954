import { Col } from "react-bootstrap";
import { Row } from "react-bootstrap";

interface FormCellProps {
  children: React.ReactNode;
  size: "Extra Small" | "Small" | "Medium" | "Large";
}

export function FormCellComponent({ size, children }: FormCellProps): JSX.Element {

  const getColSize = (size: string) => {
    switch (size) {
      case "Extra Small":
        return 3;
      case "Small":
        return 6;
      case "Medium":
        return 8;
      case "Large":
        return 12;
    }
  }

  return (
    <Row>
      <Col md={getColSize(size)}>
        {children}
      </Col>
    </Row>
  )
}