import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { BlogPost } from "../models/blogPost";

export class GetBlogPostRouteDef extends IRouteDefinition {

  constructor() {
    super("GET");
  }

  public route(pageId: string, postId: string): string {
    return `blogs/get-blog-post?` +
      QueryParamsUtil.build(
        {
          "page-id": pageId,
          "post-id": postId
        });
  }

  public routeTemplate(): string {
    return 'blogs/get-blog-post';
  }
}

export const enum GetBlogPostQueryParams {
  pageId = 'page-id',
  postId = 'post-id',
}

export class GetBlogPostResponse extends RequestResponse {
  constructor(
    public post: BlogPost
  ) {
    super();
  }
}
