
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";

export class UploadUserFileRouteDef extends IRouteDefinition {

  constructor() {
    super("POST");
    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(): string {
    return 'user-files/upload-user-file';
  }

  public routeTemplate(): string {
    return 'user-files/upload-user-file';
  }
}

export enum UploadUserFileFields {
  file = 'file',
  fileName = 'fileName',
  fileId = 'fileId',
  siteId = 'siteId'
}

export interface IUploadUserFileRequestBody {
  fileName: string;
  fileId: string;
  siteId: string;
}

