interface ErrorMessageProps {
  message: string;
}

export function ErrorMessageComponent({ message }: ErrorMessageProps): JSX.Element {
  return (
    <div>
      ❗{message}
    </div>
  );
}
