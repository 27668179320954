import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";

export class UpdatePageDataStaticRouteDef extends IRouteDefinition {
  constructor() {
    super("PUT");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(pageId: string): string {
    return `site-pages-static/update-page-data-static/${pageId}`;
  }

  public routeTemplate(): string {
    return 'site-pages-static/update-page-data-static/:pageId';
  }
}

export const enum UpdatePageDataStaticRouteParams {
  pageId = 'pageId',
}

export class UpdatePageDataStaticRequestBody extends RequestBody {
  constructor(
    public content: string
  ) {
    super();
  }
}
