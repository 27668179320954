import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { IBackendServer } from "./backendServerEndpointProvider";
import { RequestHeaderData } from "./requestHeaderData/requestHeaderData";

export class ApiService {

  protected static getGetRequestOptions(): RequestInit {
    return {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
  }

  protected static getDeleteRequestOptions(): RequestInit {
    return {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    };
  }

  protected static getRequestOptionsForFormData(
    routeDefinition: IRouteDefinition,
    formData: FormData): RequestInit {

    let method = this.getMethodFromRouteDefinition(routeDefinition);
    return {
      method,
      body: formData
    };
  }

  protected static getRequestOptions(routeDefinition: IRouteDefinition, body: RequestBody | undefined): RequestInit {

    let method = this.getMethodFromRouteDefinition(routeDefinition);

    return {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    };
  }


  protected static getMethodFromRouteDefinition(routeDefinition: IRouteDefinition): string {

    let method: string = ''
    if (routeDefinition.type === 'POST') {
      method = 'POST';
    }
    else if (routeDefinition.type === 'PUT') {
      method = 'PUT';
    }
    else if (routeDefinition.type === 'DELETE') {
      method = 'DELETE';
    }
    else {
      throw new Error("Invalid body data for request: " + routeDefinition.type);
    }
    return method;
  }

  protected static async DoRequest(
    url: string,
    requestOptions: RequestInit,
    backendServer: IBackendServer,
    headerData: RequestHeaderData[]
  ): Promise<boolean> {

    // Append the server URL
    const fullUrl = backendServer.getEndpoint() + "/" + url;

    // Add the headers
    headerData.forEach(header => {
      header.WriteHeaders(requestOptions);
    });

    // Make the request
    const response = await fetch(fullUrl, requestOptions);

    // Check the response
    if (response.status === 200) {
      return true;
    }

    console.warn(`Request failed with status ${response.status}. URL: ${fullUrl}`);
    return false;
  }

  protected static async DoRequestWithResponse<T>(
    url: string,
    requestOptions: RequestInit,
    backendServer: IBackendServer,
    headerData: RequestHeaderData[]): Promise<T | undefined> {

    // Append the server URL
    const fullUrl = backendServer.getEndpoint() + "/" + url;

    // Add the sender identification
    headerData.forEach(header => {
      header.WriteHeaders(requestOptions);
    });

    // Make the request
    const response = await fetch(fullUrl, requestOptions);

    // Check the response
    if (response.status === 200) {

      const responseData = await response.json();
      return responseData as T;
    }

    console.warn(`Request failed with status ${response.status}. URL: ${url}`);
    return undefined;
  }
  /*
  private static addSenderIdentification(
    requestOptions: RequestInit,
    senderIdProvider: IUserIdentificationProvider): void {

    requestOptions.headers = {
      ...requestOptions.headers,
      'pv-device-id': senderIdProvider.getDeviceId(),
      'pv-session-id': senderIdProvider.getSessionId()
    };
  }*/
}