import { useEffect, useState } from "react";
import { UserQueryAdminList } from "../../../../common-app/userQueries/models/userQueryAdminList";
import { HorizontalListComponent } from "../../../../common-frontend/components/horizontalList/horizontalList";
import { Spanner } from "../../../../common-frontend/components/micro/spanner/spanner";
import { PaginationControlsComponent } from "../../../../common-frontend/components/pagination/pagination";
import { VerticalListComponent } from "../../../../common-frontend/components/verticalList/verticalList";
import { ArrayUtil } from "../../../../common/util/array/arrayUtil";
import { PaginationUtil } from "../../../../common/util/pagination/paginationUtil";
import { ServiceApiUserQueries } from "../../../../services/api/serviceApiUserQueries";
import { AdminUserQueriesListComponent } from "../adminUserQueriesList/adminUserQueriesList";

import './adminUserQueriesSelector.css';

interface AdminUserQueriesSelectorProps {
  siteId: string;
  pageId: string;
  onUserQuerySelected: (selectedItem: UserQueryAdminList | undefined) => void;
}

export function AdminUserQueriesSelector({
  siteId,
  pageId,
  onUserQuerySelected
}: AdminUserQueriesSelectorProps): JSX.Element {

  // Selected Item ID
  const [selectedUserQueryId, setSelectedUserQueryId] = useState<string | undefined>(undefined);

  // Currently visible user queries
  const [userQueries, setUserQueries] = useState<UserQueryAdminList[]>([]);

  // Pagination
  const pageSize = 5;
  const [numPages, setNumPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handleItemMarkAsUnread = (itemId: string): void => {

    // Update the backend
    ServiceApiUserQueries.updateUserQueryReadState(itemId, false);

    // Update the user query list
    setUserQueries(ArrayUtil.getUpdatedItems(
      userQueries,
      uq => uq.id === itemId,
      uq => uq.isUnread = true
    ));
  }

  const handleUserQuerySelection = (selectedUserQueryId: string | undefined): void => {

    // Update the selected user query ID
    setSelectedUserQueryId(selectedUserQueryId);

    if (selectedUserQueryId === undefined) {
      return;
    }

    // Find the selected user query
    const selectedUserQuery = userQueries.find(uq => uq.id === selectedUserQueryId);
    if (selectedUserQuery === undefined) {
      return;
    }

    // Since the item is selected, mark it as read
    if (selectedUserQuery.isUnread) {
      ServiceApiUserQueries.updateUserQueryReadState(selectedUserQuery.id, true);

      // Update the user query list
      setUserQueries(ArrayUtil.getUpdatedItems(
        userQueries,
        uq => uq.id === selectedUserQuery.id,
        uq => uq.isUnread = false
      ));
    }
  }

  useEffect(() => {

    const fetchUserQueriesCount = async () => {
      const userQueriesCount = await ServiceApiUserQueries.getUserQueriesCount(siteId, pageId);
      setNumPages(PaginationUtil.getRequiredPageCount(userQueriesCount, pageSize));
    }
    fetchUserQueriesCount();
  }, []);

  useEffect(() => {

    const fetchUserQueries = async () => {
      const results = await ServiceApiUserQueries.getUserQueriesAdminList(
        siteId,
        pageId,
        pageSize,
        currentPage);
      setUserQueries(results);
    }

    // Reset the selected item
    onUserQuerySelected(undefined);
    setSelectedUserQueryId(undefined);

    // Fetch the user queries
    fetchUserQueries();

  }, [currentPage, numPages, onUserQuerySelected]);

  useEffect(() => {

    // Find the item
    const selectedItem = userQueries.find(uq => uq.id === selectedUserQueryId);

    // Notify the parent component
    onUserQuerySelected(selectedItem);

  }, [selectedUserQueryId, userQueries, onUserQuerySelected]);

  return (
    <div
      className="admin-user-queries-selector"
    >
      <VerticalListComponent>
        <AdminUserQueriesListComponent
          items={userQueries}
          selectedItemId={selectedUserQueryId}
          onItemSelected={handleUserQuerySelection}
          onItemMarkAsUnread={handleItemMarkAsUnread}
          itemsToShowCount={pageSize}
        />

        {
          numPages === 1 ? null : (
            <HorizontalListComponent>
              <Spanner />
              <PaginationControlsComponent
                pageCount={numPages}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
                buttonsToShowCount={8}
                showFirstAndLast={false}
                showPreviousAndNext={true}
                hideOnSinglePage={true}
              />
              <Spanner />
            </HorizontalListComponent>
          )
        }
      </VerticalListComponent>
    </div>
  );
}