import { useEffect, useState } from "react";
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SiteInfo } from "../../../../../common-app/sites/models/siteInfo";
import { AlertInlineComponent } from "../../../../../common-frontend/components/alertInline/alertInline";
import { ContainerLabeledComponent } from "../../../../../common-frontend/components/containerLabeled/containerLabeled";
import { FormEditComponent } from "../../../../../common-frontend/components/form/formEdit/formEdit";
import { HorizontalListComponent } from "../../../../../common-frontend/components/horizontalList/horizontalList";
import { SpacerHorizontal } from "../../../../../common-frontend/components/micro/spacer/spacerHorizontal";
import { ValidationMessagesGroupComponent } from "../../../../../common-frontend/components/validations/validationMessagesGroup/validationMessagesGroup";
import { VerticalListComponent } from "../../../../../common-frontend/components/verticalList/verticalList";
import { Environment } from "../../../../../common-frontend/environment/environment";
import { AutoSaveDataParams, useAutoSave } from "../../../../../common-frontend/hooks/autoSave/autoSave";
import { SitesUtil2 } from "../../../../../common/sites/sitesUtil2";
import { TypeUpdate } from "../../../../../common/util/types/typeUpdate";
import { ServiceApiSites } from "../../../../../services/api/serviceApiSites";
import { LocationDisplayComponent } from "../../common/locationDisplay/locationDisplay";
import { HomePageSelectionButtonComponent } from "./homePage/homePageSelectionButton";
import { PageNameDisplayComponent } from "./homePage/pageNameDisplay";
import { SiteSettingsDomainChangeComponent } from "./siteSettingsDomainChange";

import './siteSettingsInfo.css';

interface SiteSettingsInfoProps {
  siteId: string;
  onNameUpdated: () => void;
}

export function SiteSettingsInfoComponent({
  siteId,
  onNameUpdated
}: SiteSettingsInfoProps): JSX.Element {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const [previousSiteData, setPreviousSiteData] = useState<SiteInfo>();

  const handleSaveSiteData = (data: SiteInfo, saveParams: AutoSaveDataParams): boolean => {

    ServiceApiSites.updateSite(
      siteId,
      data.name,
      data.domainName,
      data.homePageId,
      data.isActive
    ).then((result) => {

      if (data) {

        if (saveParams.isUnmounting === false) {
          if (data.domainName !== previousSiteData?.domainName && result) {
            navigate(SitesUtil2.getSiteAdminUrl(data.domainName));
          }
        }

        if (data.name !== previousSiteData?.name && result) {
          onNameUpdated();
        }

        if (result) {
          setPreviousSiteData(data);
        }
      }
    });

    return true;
  }

  const [showChangeDomainModal, setShowChangeDomainModal] = useState<boolean>(false);

  const {
    data: siteData,
    updateData: updateSiteData
  } = useAutoSave<SiteInfo>(handleSaveSiteData);

  const getSiteFullUrl = (): string => {
    if (siteData === undefined) {
      return '';
    }

    return SitesUtil2.getFullSiteUrl(
      Environment.GetAppClientUrl(),
      siteData.domainName
    );
  }

  useEffect(() => {

    const loadSiteData = async () => {
      const siteInfo = await ServiceApiSites.getSiteInfo(siteId);
      if (siteInfo) {
        updateSiteData(siteInfo, true);
        setPreviousSiteData(siteInfo);
      }
    }

    loadSiteData();
  }, [siteId]);

  if (siteData === undefined) {
    return <></>;
  }

  return <div className="site-settings-info-component">
    <ContainerLabeledComponent
      label={t('site')}
      uniqueId={`site-settings-${siteId}`}
    >
      <VerticalListComponent>
        {/* Site Name */}
        <HorizontalListComponent>
          <label
            className='label'
            htmlFor="siteName">
            {t('name')}
          </label>
          <FormEditComponent
            id="siteName"
            className='input'
            type="text"
            value={siteData.name}
            onChange={
              (value) => {
                if (siteData) {
                  updateSiteData(TypeUpdate.updateObjectField(siteData, 'name', value));
                }
              }
            }
          />
        </HorizontalListComponent>

        {/* Domain */}
        <VerticalListComponent>
          <HorizontalListComponent>
            <label
              className='label'
              htmlFor="siteUrl">{t('path')}
            </label>
            <FormEditComponent
              id="siteUrl"
              className='input'
              type="text"
              value={siteData.domainName}
              onChange={() => { }}
              readonly
            />

            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setShowChangeDomainModal(true)}
            >
              {t('change')}
            </Button>

          </HorizontalListComponent>
        </VerticalListComponent>

        {/* URL Display */}
        <VerticalListComponent>
          <HorizontalListComponent>
            <label
              className='label'
              htmlFor="siteUrl">
              {t('url')}
            </label>
            <LocationDisplayComponent
              url={getSiteFullUrl()}
            />
          </HorizontalListComponent>
        </VerticalListComponent>

        {/* HomePage */}
        <VerticalListComponent>
          <HorizontalListComponent>
            <label
              className='label'
              htmlFor="site-home-page">
              {t('home')}
            </label>

            <div className='input'>
              <div className='start-page-parent'>
                <PageNameDisplayComponent
                  siteId={siteId}
                  pageId={siteData.homePageId} />
              </div>
            </div>

            <HomePageSelectionButtonComponent
              siteId={siteId}
              homePageId={siteData.homePageId}
              onHomePageSelected={
                (homePageId) => {
                  if (siteData) {
                    updateSiteData(TypeUpdate.updateObjectField(siteData, 'homePageId', homePageId));
                  }
                }
              }
            />
          </HorizontalListComponent>

          <ValidationMessagesGroupComponent
            messages={[
              { text: t('homePageRequired'), show: siteData.homePageId === "" },
            ]}
          >
            <SpacerHorizontal width='var(--size-11)' />
          </ValidationMessagesGroupComponent>

        </VerticalListComponent>

        {/* Visible / Active */}
        <HorizontalListComponent>
          <label
            className='label'
            htmlFor="siteActive">{t('active')}
          </label>
          <Form.Check
            type="switch"
            id="siteActive"
            checked={siteData.isActive}
            onChange={
              (e) => {
                if (siteData) {
                  updateSiteData(TypeUpdate.updateObjectField(siteData, 'isActive', e.target.checked))
                }
              }
            }
          />

        </HorizontalListComponent>
        {siteData.isActive == false &&
          <AlertInlineComponent variant="warning">
            <div>
              {t('siteNotVisible')}
            </div>
          </AlertInlineComponent>
        }

      </VerticalListComponent>
    </ContainerLabeledComponent>
    <SiteSettingsDomainChangeComponent
      siteId={siteId}
      siteName={siteData.name}
      currentDomain={siteData.domainName}
      show={showChangeDomainModal}
      onDismiss={() => { setShowChangeDomainModal(false) }}
      onConfirm={(newDomain) => {
        setShowChangeDomainModal(false);
        updateSiteData(TypeUpdate.updateObjectField(siteData, 'domainName', newDomain));
      }}
    />
  </div>
}