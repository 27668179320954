import { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SiteInfo } from '../../../../../common-app/sites/models/siteInfo';
import { HorizontalListComponent } from '../../../../../common-frontend/components/horizontalList/horizontalList';
import { Spanner } from '../../../../../common-frontend/components/micro/spanner/spanner';
import { QuestionDialogComponent } from '../../../../../common-frontend/components/questionDialog/questionDialog';
import { VerticalListComponent } from '../../../../../common-frontend/components/verticalList/verticalList';
import { ServiceApiSites } from '../../../../../services/api/serviceApiSites';
import { SiteSettingsInfoComponent } from './siteSettingsInfo';
import { SiteSettingsOwnershipComponent } from './siteSettingsOwnership';

import './siteSettings.css';

interface SiteSettingsProps {
  siteId: string;
}

export function SiteSettingsComponent({
  siteId
}: SiteSettingsProps): JSX.Element {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const [siteData, setSiteData] = useState<SiteInfo>();
  const [showConfirmationDeleteSite, setShowConfirmationDeleteSite] = useState<boolean>(false);

  const askConfirmationDeleteSite = () => {
    setShowConfirmationDeleteSite(true);
  }

  const handleDeleteSite = async () => {

    await ServiceApiSites.deleteSite(siteId);

    navigate('/');
  }

  const refreshData = async () => {
    const siteInfo = await ServiceApiSites.getSiteInfo(siteId);
    if (siteInfo) {
      setSiteData(siteInfo);
    }
  }

  useEffect(() => {
    const loadSiteData = async () => {
      const siteInfo = await ServiceApiSites.getSiteInfo(siteId);
      if (siteInfo) {
        setSiteData(siteInfo);
      }
    }

    loadSiteData();
  }, [siteId]);

  return (
    <div className="site-settings-component">

      {siteData &&
        <VerticalListComponent>
          <HorizontalListComponent>
            <h2>
              {t('site')} {siteData.name}
            </h2>
            <Spanner />

            <DropdownButton
              title={t('actions')}
              variant={'outline-primary'}>
              <Dropdown.Item onClick={askConfirmationDeleteSite}>
                {t('deleteSite')}
              </Dropdown.Item>
            </DropdownButton>
          </HorizontalListComponent>

          {/* Site settings Group */}
          <SiteSettingsInfoComponent
            siteId={siteId}
            onNameUpdated={() => { refreshData() }}
          />

          {/* Ownership Group */}
          <SiteSettingsOwnershipComponent
            siteId={siteId}
          />

        </VerticalListComponent>
      }

      {/* Question dialog */}
      <QuestionDialogComponent
        showDialog={showConfirmationDeleteSite}
        title={t('deleteSiteQuestion')}
        question={t('confirmDeleteSite')}
        labelPositive={t('delete')}
        positiveIsDanger={true}
        onPositive={() => {
          setShowConfirmationDeleteSite(false);
          handleDeleteSite();
        }}
        onNegative={() => {
          setShowConfirmationDeleteSite(false);
        }}
      />
    </div>
  );
}
