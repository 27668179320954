import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ProductAdminListFactory } from "../../../../../../../common-app/sitePagesStores/models/factories/productAdminList";
import { ProductFactory } from "../../../../../../../common-app/sitePagesStores/models/factories/productFactory";
import { Product } from "../../../../../../../common-app/sitePagesStores/models/product";
import { ProductAdminList } from "../../../../../../../common-app/sitePagesStores/models/productAdminList";
import { ContainerLabeledComponent } from "../../../../../../../common-frontend/components/containerLabeled/containerLabeled";
import { ListAndItemComponent } from "../../../../../../../common-frontend/components/listAndItem/listAndItem";
import { VerticalListComponent } from "../../../../../../../common-frontend/components/verticalList/verticalList";
import { ListItem, ListItemHelper } from "../../../../../../../common-frontend/library/list/listItem";
import { ArrayUtil } from "../../../../../../../common/util/array/arrayUtil";
import { GuidUtil } from "../../../../../../../common/util/guid/guid";
import { ServiceApiPageDataStore } from "../../../../../../../services/api/ServiceApiPageDataStore";
import { PagePaneProps } from "../../pagePanesMapping";
import { ProductFormComponent } from "./productForm";
import { TreeStoreProductsComponents } from "./tree/TreeStoreProducts";

import './pagePaneStoreProducts.css';

type ListItemProduct = ListItem<ProductAdminList>;

export function PagePaneStoreProductsComponent({
  siteId,
  pageId
}: PagePaneProps): JSX.Element {

  const { t } = useTranslation();

  const [items, setItems] = useState<ListItemProduct[]>([]);
  const [selectedItemKey, setSelectedItemKey] = useState<string | undefined>(undefined);
  const [selectedItem, setSelectedItem] = useState<Product | undefined>(undefined);

  const selectedProductAdminList = items.find((item) => item.item.id === selectedItemKey);

  useEffect(() => {

    setSelectedItem(undefined);
    if (selectedItemKey === undefined) {
      return;
    }

    // Locate the selected item in the items list
    const itemInList = items.find((item) => item.item.id === selectedItemKey);
    if (itemInList === undefined) {
      return;
    }

    if (itemInList.isSavedInTheBackend === false) {
      // We need to create a new product
      const newProduct = ProductFactory.createNew(itemInList.item.id, pageId);
      newProduct.name = itemInList.item.name;
      newProduct.category = itemInList.item.category;
      setSelectedItem(newProduct);
      return;
    }
    else {
      // We need to get the product from the backend
      ServiceApiPageDataStore.getProduct(pageId, selectedItemKey).then((product) => {
        setSelectedItem(product);
      });
    }

  }, [selectedItemKey, items]);

  // Get the existing categories
  const existingCategories = ArrayUtil.getUnique(
    items.map((item) => item.item.category)
      .filter((c) => c !== "")
      .sort((a, b) => a.localeCompare(b))
  );

  useEffect(() => {

    ServiceApiPageDataStore.getProductsAdminList(pageId).then((products) => {

      // Wrap the products in the ListItem class
      const productListItems = ListItemHelper.WrapItems(products, true);
      setItems(productListItems);
    });

  }, [pageId]);

  const handleCreateNewProduct = (): void => {
    // Check if we already have one product that's not saved in the backend
    if (ListItemHelper.IsAnyItemUnsaved(items)) {
      // Select that item
      const unsavedItem = items.find((item) => item.isSavedInTheBackend === false);
      if (unsavedItem) {
        setSelectedItemKey(unsavedItem.item.id);
      }
      return;
    }

    // Create new contact
    const newProduct = ProductAdminListFactory.createNew(GuidUtil.GenerateNewGuid(), pageId);
    newProduct.name = t('newProduct');

    // Create a new ListItem with the new contact
    const newProductListItem = new ListItem(newProduct, false);

    // Add the new contact to the list
    setItems([...items, newProductListItem]);

    // Set this item as the selected item
    setSelectedItemKey(newProductListItem.item.id);
  }

  const handleSaveProduct = async (product: Product): Promise<void> => {

    // Get the item and the index
    const {
      index: itemIndex,
      item: contactListItem
    } = ArrayUtil.findIndexAndItem(items, (item) => item.item.id === product.id);

    if (contactListItem === undefined) {
      return;
    }

    // Update the backend
    if (contactListItem.isSavedInTheBackend === false) {
      await ServiceApiPageDataStore.createProduct(siteId, product);
    }
    else {
      await ServiceApiPageDataStore.updateProduct(siteId, product);
    }

    // Update this item with the new content
    contactListItem.item.name = product.name;
    contactListItem.item.category = product.category;
    contactListItem.isSavedInTheBackend = true;

    // Update the items array
    const newItems = [...items];
    newItems[itemIndex] = contactListItem;
    setItems(newItems);
  }

  return (
    <div className="page-pane-store-products-component">
      <ContainerLabeledComponent
        label={t('products')}
        uniqueId={`page-content-store-${pageId}`}
      >
        <ListAndItemComponent>
          <div
            className='products-side-panel'
          >
            <div>
              <VerticalListComponent>
                <Button
                  variant='outline-primary'
                  style={{ width: "100%" }}
                  onClick={handleCreateNewProduct}
                >
                  {t('addProduct')}
                </Button>

                <div className="items-list-component-parent">
                  <TreeStoreProductsComponents
                    items={items}
                    selectedItemKey={selectedItemKey}
                    onSelectedItemChanged={setSelectedItemKey}
                  />
                </div>

              </VerticalListComponent>
            </div>
          </div>

          <div>
            {
              selectedItem && selectedProductAdminList && (

                <ProductFormComponent
                  siteId={siteId}
                  pageId={pageId}
                  productToEdit={selectedItem}
                  onSaveProduct={handleSaveProduct}
                  isNew={selectedProductAdminList.isSavedInTheBackend == false}
                  existingCategories={existingCategories}
                />
              )
            }
          </div>
        </ListAndItemComponent>
      </ContainerLabeledComponent>
    </div>
  )
}