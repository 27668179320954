import { useMemo } from "react";
import { SitePageRenderInfo } from "../../../../../common-app/sites/models/sitePageRenderInfo";
import { DynamicPageBlogComponent } from "../../../../../common-frontend/components/dynamicPages/blog/dynamicPageBlog";
import { DynamicPageBlogServiceProvider } from "./dynamicPageBlogServiceProvider";

interface DynamicPageBlogWrapperProps {
  pageRenderInfo: SitePageRenderInfo;
}

export function DynamicPageBlogWrapperComponent({
  pageRenderInfo
}: DynamicPageBlogWrapperProps): JSX.Element {

  const dataProvider = useMemo(() => new DynamicPageBlogServiceProvider(), []);

  return (
    <div>
      <DynamicPageBlogComponent
        pageRenderInfo={pageRenderInfo}
        dataProvider={dataProvider}
      />
    </div>
  );
}