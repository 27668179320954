import { useState } from 'react';
import { ColorPickerComponent } from '../../../../../colorPicker/colorPicker';

import './buttonColor.css';

interface ButtonColorProps {
  onColorChange: (color: string) => void;
}

export function ButtonColor({ onColorChange }: ButtonColorProps): JSX.Element {

  const [lastColorUsed, setLastColorUsed] = useState<string>("#000000");

  return <div className="toolbar-button-color rounded">

    <ColorPickerComponent
      id="rich-text-editor"
      value={lastColorUsed}
      onColorChange={(color) => {
        onColorChange(color);
        setLastColorUsed(color);
      }}
      fixPosition={false}
    />
  </div>;
}