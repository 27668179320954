import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import './selectLanguageButton.css';

interface CustomToggleProps {
  children: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const CustomToggle = React.forwardRef<HTMLAnchorElement, CustomToggleProps>(({ children, onClick }, ref) => (
  <a
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

export function SelectLanguageButtonComponent(): JSX.Element {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  }

  return (
    <div className='select-language-button-component'>
      <Dropdown align="end">
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components"
        >
          <div style={{ fontSize: "1.5rem", cursor: "pointer" }}>
            🌍
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu >
          <Dropdown.Item eventKey="en" onClick={() => changeLanguage('en')} className="language-option">🇬🇧 English</Dropdown.Item>
          <Dropdown.Item eventKey="pt" onClick={() => changeLanguage('pt')} className="language-option">🇵🇹 Português</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}