import { useContext } from "react";
import { DynamicPageDataContext } from "../dynamicPageDataContext";

export const useDynamicPageDataContext = () => {

  const context = useContext(DynamicPageDataContext);
  if (!context) {
    throw new Error("useDynamicPageDataContext must be used within a DynamicPageDataProvider");
  }
  return context;
}