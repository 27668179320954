import { Contact } from "../../../../../common-app/sitePagesContacts/models/contact";
import { DynamicPageContactsProvider } from "../../../../../common-frontend/components/dynamicPages/contacts/provider/dynamicPageContactsProvider";
import { ServiceApiPageDataContacts } from "../../../../../services/api/serviceApiPageDataContacts";

export class DynamicPageContactsServiceProvider implements DynamicPageContactsProvider {

  constructor(private pageId: string) { };

  getContacts(): Promise<Contact[]> {
    return ServiceApiPageDataContacts.getContacts(this.pageId);
  }
}