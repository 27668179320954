import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslationCommonFrontEnd } from '../../../../../translations/translation';
import { VerticalListComponent } from '../../../../verticalList/verticalList';
import { CustomElement, CustomElementUrl } from '../../../types';
import { ElementEditRef } from '../../elementSettingsConfig';
import { ElementSettingsEditProps } from '../../elementSettingsEditProps';
import { ElementConfigurationSnippetTextInputComponent } from '../../snippets/elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput';

export const ElementEditUrlComponent = forwardRef<ElementEditRef, ElementSettingsEditProps>(
  ({
    element,
    elementCanBeUpdated
  }, ref): JSX.Element => {

    const t = useTranslationCommonFrontEnd();

    const elementUrl = element as CustomElementUrl;

    const [url, setUrl] = useState<string>(elementUrl.url);

    useImperativeHandle(ref, () => ({
      updateElement: (element: CustomElement) => {
        const updatedElement = element as CustomElementUrl;
        updatedElement.url = url;
        return updatedElement;
      },
    }));

    useEffect(() => {
      elementCanBeUpdated(url.length > 0);
    }, [url]);

    return (
      <div className="element-edit-url-component">
        <VerticalListComponent>
          <ElementConfigurationSnippetTextInputComponent
            caption={t('target')}
            value={url}
            onChange={setUrl}
          />
        </VerticalListComponent>
      </div>
    );
  }
);