import { useEffect, useState } from "react";
import { Product } from "../../../../../../common-app/sitePagesStores/models/product";
import { ImageGalleryItem } from "../../../../imageGallery/imageGalleryItem";
import { DynamicPageStoreProvider } from "../../provider/dynamicPageStoreProvider";
import { useDynamicPageDataContext } from "../../../provider/hooks/useDynamicPageDataContext";
import { ImageGalleryComponent } from "../../../../imageGallery/imageGallery";

interface ProductImageGalleryProps {
  dataProvider: DynamicPageStoreProvider;
  product: Product;
}

export function ProductImageGalleryComponent({
  product,
  dataProvider
}: ProductImageGalleryProps): JSX.Element {

  const dynamicPageContext = useDynamicPageDataContext();

  const [galleryImages, setGalleryImages] = useState<ImageGalleryItem[]>([]);

  useEffect(() => {

    dataProvider.getProductGalleryImagesInfo(product.id).then((userFileImage) => {

      const galleryImages = userFileImage.map((galleryImage) => {
        return {
          id: galleryImage.id,
          url: dynamicPageContext.backendServerAddress + galleryImage.publicUrl,
          urlthumbnail: dynamicPageContext.backendServerAddress + galleryImage.publicUrlThumbnail,
          altText: galleryImage.originalFileName,
          caption: ""
        }
      });
      setGalleryImages(galleryImages);
    });

  }, [product]);

  return (
    <div>
      <ImageGalleryComponent
        images={galleryImages}
      />
    </div>
  );
}