import { RouteBuilder } from "../util/routes/routeBuilder";

export class SitesUtil2 {
  // Givan a full path name, return the last part of the path
  // Example: .../sites/my-site/pageA/pageB -> my-site/pageA/pageB
  public static getSitePagePathFromFullUrl(fullUrl: string): string {

    // We need to find the first instance of '/sites/' and then return everything after that
    const sitesRouteSegment = this.getSitesRouteSegment();

    const sitesIndex = fullUrl.indexOf(sitesRouteSegment);
    if (sitesIndex === -1) {
      return '';
    }

    // domain/pageA/pageB
    return fullUrl.substring(sitesIndex + sitesRouteSegment.length);
  }

  // Given a partial URL, return the domain and the pages
  // Example: my-site/pageA/pageB -> { domain: my-site, pages: [pageA, pageB] }
  public static getDomainAndPagesFromUrlPart(pageUrlPart: string): { domain: string; pages: string[]; } {

    // Split the URL by '/'
    const urlParts = pageUrlPart.split('/').filter(part => part !== '');

    // The first part is the domain
    const domain = urlParts[0];

    // The rest are the pages
    const pages = urlParts.slice(1);

    return { domain, pages };
  }

  // Returns true if the route is the route used to go to settings page
  public static isSiteEditorPage(pages: string[]) {
    return (pages.length === 1 && pages[0] === this.getSiteAdminPageName())
  }

  public static getSiteAdminUrl(siteDomain: string): string {
    return RouteBuilder.build(this.getSitesRouteSegment(), siteDomain, this.getSiteAdminPageName());
  }

  public static getFullSiteUrl(clientUrl: string, siteDomain: string): string {
    return clientUrl + RouteBuilder.build(
      this.getSitesRouteSegment(),
      siteDomain);
  }

  // ** FIXED STUFF **

  // Returns the route segment that is used for sites
  public static getSitesRouteSegment(): string {
    return "/sites/";
  }

  // Returns the name of the site admin page
  public static getSiteAdminPageName(): string {
    return "site-admin";
  }
}