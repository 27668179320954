import { Product } from "../../../../../common-app/sitePagesStores/models/product";
import { ProductStoreNavigation } from "../../../../../common-app/sitePagesStores/models/productStoreNavigation";
import { StoreOrder } from "../../../../../common-app/sitePagesStores/models/storeOrder";
import { StoreOrderLine } from "../../../../../common-app/sitePagesStores/models/storeOrderLine";
import { UserFileImage } from "../../../../../common-app/userFiles/models/userFileImage";
import { StoreCartProduct } from "../../../../../common-frontend/components/dynamicPages/store/data/storeCartProduct";
import { DynamicPageStoreProvider } from "../../../../../common-frontend/components/dynamicPages/store/provider/dynamicPageStoreProvider";
import { ArrayUtil } from "../../../../../common/util/array/arrayUtil";
import { GuidUtil } from "../../../../../common/util/guid/guid";
import { ServiceApiPageDataStore } from "../../../../../services/api/ServiceApiPageDataStore";
import { ServiceApiUserFiles } from "../../../../../services/api/serviceApiUserFiles";
import { CartLocalStorage } from "./cart/cartLocalStorage";
import { CacheProduct } from "./providerCache/cacheProduct";
import { CacheProductUserFileImages } from "./providerCache/cacheProductUserFileImages";

export class DynamicPageStoreServiceProvider implements DynamicPageStoreProvider {

  constructor(
    private siteId: string,
    private pageId: string) {

    this.updateProductsStoreNavigation();
  }

  public async getProductsStoreNavigation(): Promise<ProductStoreNavigation[]> {

    if (this.productsStoreNavigationUpdated == false) {
      await this.updateProductsStoreNavigation();
    }
    return this.productsStoreNavigation;
  }

  public async getProductsStoreNavigationByCategory(category: string): Promise<ProductStoreNavigation[]> {
    return (await this.getProductsStoreNavigation()).filter(x => x.category == category);
  }

  public async getProductsStoreNavigationByKeyword(keyword: string): Promise<ProductStoreNavigation[]> {

    if (this.productsStoreNavigationUpdated == false) {
      await this.updateProductsStoreNavigation();
    }

    const keywordLower = keyword.toLowerCase();
    const matchedByName = this.productsStoreNavigation.filter(x => x.name.toLowerCase().includes(keywordLower));
    const matchedByDescription = this.productsStoreNavigation.filter(x => x.description.toLowerCase().includes(keywordLower));
    const matchedByCategory = this.productsStoreNavigation.filter(x => x.category.toLowerCase().includes(keywordLower));

    return ArrayUtil.getUniqueWithPredicate(
      [
        ...matchedByName,
        ...matchedByDescription,
        ...matchedByCategory
      ],
      (item) => item.id);
  }

  public async getCategories(): Promise<string[]> {

    if (this.productsStoreNavigationUpdated == false) {
      await this.updateProductsStoreNavigation();
    }
    return this.productsCategories;
  }

  public async getProductGalleryImagesInfo(productId: string): Promise<UserFileImage[]> {

    // Check if the images are already cached
    let cache = this.cacheProductsImages.find(x => x.productId == productId);
    if (cache) {
      return cache.userFileImages;
    }

    // Get the product info
    const product = this.productsStoreNavigation.find(x => x.id == productId);
    if (!product) {
      return [];
    }

    // Get the images from the product
    const imageIds = product.galleryItems.map(x => x.data);

    // Get the images from the service
    let userFileImages = await ServiceApiUserFiles.getUserImagesInfo(this.siteId, imageIds);

    // Cache the images
    cache = new CacheProductUserFileImages();
    cache.productId = productId;
    cache.userFileImages = userFileImages;
    this.cacheProductsImages.push(cache);

    return userFileImages;
  }

  public async getProduct(productId: string): Promise<Product | undefined> {

    // Check if the product is already cached
    let cache = this.cacheProducts.find(x => x.productId == productId);
    if (cache) {
      return cache.product;
    }

    // Get the product info
    const product = await ServiceApiPageDataStore.getProduct(this.pageId, productId);

    if (!product) {
      return undefined;
    }

    // Cache the product
    cache = new CacheProduct(productId, product);
    this.cacheProducts.push(cache);

    return product;
  }

  public addProductToCart(productId: string, quantity: number, pricePresented: number): void {
    CartLocalStorage.addProductToCart(
      this.siteId,
      this.pageId,
      productId,
      quantity,
      pricePresented);
  }

  public updateProductInCart(productId: string, quantity: number): boolean {
    return CartLocalStorage.updateProductInCart(
      this.siteId,
      this.pageId,
      productId,
      quantity);
  }

  public getProductsInCart(): StoreCartProduct[] {
    return CartLocalStorage.getProductsInCart(
      this.siteId,
      this.pageId);
  }

  public removeProductFromCart(productId: string): void {
    CartLocalStorage.removeProductFromCart(
      this.siteId,
      this.pageId,
      productId);
  }

  public clearCart(): void {
    CartLocalStorage.clearCart(
      this.siteId,
      this.pageId);
  }

  public async getCartTotal(): Promise<number> {

    if (this.productsStoreNavigationUpdated == false) {
      await this.updateProductsStoreNavigation();
    }

    const productsInCart = this.getProductsInCart();

    return productsInCart.reduce((total, cartItem) => {

      const product = this.productsStoreNavigation.find(x => x.id == cartItem.productId);
      if (!product) {
        return total;
      }

      return total + product.price * cartItem.quantity;
    }, 0);
  }

  public async finalizeOrder(
    name: string,
    mail: string,
    phone: string,
    address: string
  ): Promise<boolean> {

    // Create a new order Id
    const newOrderId = GuidUtil.GenerateNewGuid();

    const cartItems = this.getProductsInCart();
    const orderLines: StoreOrderLine[] = cartItems.map((item) => {

      const newOrderLineId = GuidUtil.GenerateNewGuid();

      const newStoreOrderLine = new StoreOrderLine();
      newStoreOrderLine.id = newOrderLineId;
      newStoreOrderLine.orderId = newOrderId;
      newStoreOrderLine.productId = item.productId;
      newStoreOrderLine.quantity = item.quantity;
      newStoreOrderLine.price = item.pricePresented;
      return newStoreOrderLine;
    });

    const storeOrder = new StoreOrder(
      newOrderId,
      this.siteId,
      this.pageId,
      name,
      mail,
      phone,
      address,
      orderLines
    );

    return ServiceApiPageDataStore.createStoreOrder(storeOrder);
  }

  private async updateProductsStoreNavigation() {

    // Get the products store navigation
    this.productsStoreNavigation = await ServiceApiPageDataStore.getProductsStoreNavigation(this.pageId);

    // Update the categories
    this.productsCategories = ArrayUtil.getUnique(this.productsStoreNavigation.map(x => x.category)).
      sort((a, b) => {
        if (a == "") { return 1; }
        if (b == "") { return -1; }
        return a.localeCompare(b);
      });

    this.productsStoreNavigationUpdated = true;
  }

  private productsStoreNavigation: ProductStoreNavigation[] = [];
  private productsCategories: string[] = [];

  private productsStoreNavigationUpdated: boolean = false;

  // Cache
  private cacheProductsImages: CacheProductUserFileImages[] = [];
  private cacheProducts: CacheProduct[] = [];
}