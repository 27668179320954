import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { StoreOrder } from "../models/storeOrder";

export class CreateStoreOrderRouteDef extends IRouteDefinition {

  constructor() {
    super('POST');
  }

  public route(): string {
    return `store/create-store-order`;
  }

  public routeTemplate(): string {
    return 'store/create-store-order';
  }
}

export class CreateStoreOrderRequestBody extends RequestBody {
  constructor(
    public order: StoreOrder
  ) {
    super();
  }
}
