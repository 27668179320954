import { CustomElementUrl } from "../../types";
import { RenderComponentProps } from "../renderComponentProps";

import './renderUrl.css';

export function RenderUrlComponent({
  element,
  attributes,
  children
}: RenderComponentProps): JSX.Element {

  const urlElement = element as CustomElementUrl;

  return (
    <p className="render-url-component">
      <a {...attributes} href={urlElement.url}>{children}</a>
    </p>
  );
}