import { HorizontalListComponent } from '../../horizontalList/horizontalList';
import { VerticalListComponent } from '../../verticalList/verticalList';
import { ValidationErrorComponent } from '../validationError/validationError';

interface ValidationMessagesGroupProps {
    messages: { text: string, show: boolean }[];
    children?: React.ReactNode;
}

export function ValidationMessagesGroupComponent({ children, messages }: ValidationMessagesGroupProps): JSX.Element | null {

    if (messages.filter(m => m.show).length == 0) {
        return null;
    }

    return (
        <HorizontalListComponent>
            {children}
            <VerticalListComponent>
                {messages.map((message, index) => {
                    return (
                        <ValidationErrorComponent
                            key={index}
                            show={message.show}
                            message={message.text} />
                    );
                })}
            </VerticalListComponent>
        </HorizontalListComponent>
    );
}
