import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SitePageRenderInfo } from "../../models/sitePageRenderInfo";

export class GetSitePageRenderInfoRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(pageUrlPart: string, sessionId: string, deviceId: string): string {
    return `sites/get-site-page-render-info?` + QueryParamsUtil.build(
      {
        "page-url-part": pageUrlPart,
        "session-id": sessionId,
        "device-id": deviceId,
      });
  }

  public routeTemplate(): string {
    return 'sites/get-site-page-render-info';
  }
}

export const enum GetSitePageRenderInfoQueryParams {
  pageUrlPart = 'page-url-part',
  sessionId = 'session-id',
  deviceId = 'device-id',
}

export class GetSitePageRenderInfoResponse extends RequestResponse {
  constructor(
    public pageRenderInfo: SitePageRenderInfo | undefined
  ) {
    super();
  }
}