import { Dropdown } from 'react-bootstrap';
import { MenuItemChangeParentComponent } from '../../../../../common-frontend/components/menuItems/menuItemChangeParent';
import { MenuItemDuplicatePageComponent } from '../../../../../common-frontend/components/menuItems/menuItemDuplicatePage';
import { MenuItemDeletePageComponent } from '../../../../../common-frontend/components/menuItems/menuItemDeletePage';

import './siteEditorPageListMenuButton.css';

interface SiteEditorPageListMenuButtonProps {
  onChangeParent: () => void;
  onDuplicatePage: () => void;
  onDeletePage: () => void;
}

export function SiteEditorPageListMenuButtonComponent({
  onChangeParent,
  onDuplicatePage,
  onDeletePage
}: SiteEditorPageListMenuButtonProps): JSX.Element {

  return (
    <div className='site-editor-page-list-menu-button-component'>
      <Dropdown>
        <Dropdown.Toggle
          className='menu-button'
          variant="outline-primary"
          id="dropdown-basic"
          size='sm'>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={onChangeParent}
          >
            <MenuItemChangeParentComponent />
          </Dropdown.Item>

          <Dropdown.Item
            onClick={onDuplicatePage}
          >
            <MenuItemDuplicatePageComponent />
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item
            onClick={onDeletePage}
          >
            <MenuItemDeletePageComponent />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
