import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreOrderState } from "../../../../../../../common-app/sitePagesStores/enums/storeOrderState";
import { ProductAdminList } from "../../../../../../../common-app/sitePagesStores/models/productAdminList";
import { StoreOrderAdmin } from "../../../../../../../common-app/sitePagesStores/models/storeOrderAdmin";
import { ContainerLabeledComponent } from "../../../../../../../common-frontend/components/containerLabeled/containerLabeled";
import { HorizontalListComponent } from "../../../../../../../common-frontend/components/horizontalList/horizontalList";
import { ItemsListComponent } from "../../../../../../../common-frontend/components/itemsList/itemsList";
import { ListAndItemComponent } from "../../../../../../../common-frontend/components/listAndItem/listAndItem";
import { Spanner } from "../../../../../../../common-frontend/components/micro/spanner/spanner";
import { PaginationControlsComponent } from "../../../../../../../common-frontend/components/pagination/pagination";
import { VerticalListComponent } from "../../../../../../../common-frontend/components/verticalList/verticalList";
import { ListItem, ListItemHelper } from "../../../../../../../common-frontend/library/list/listItem";
import { PaginationUtil } from "../../../../../../../common/util/pagination/paginationUtil";
import { ServiceApiPageDataStore } from "../../../../../../../services/api/ServiceApiPageDataStore";
import { PagePaneProps } from "../../pagePanesMapping";
import { OrderDetailsComponent } from "./orderDetails";
import { OrderListItemComponent } from "./orderListItem";

import './pagePaneStoreOrders.css';

type ListItemStoreOrder = ListItem<StoreOrderAdmin>;

export function PagePaneStoreOrdersComponent({
  siteId,
  pageId
}: PagePaneProps): JSX.Element {

  const itemsPerPage = 10;

  const { t } = useTranslation();

  const [items, setItems] = useState<ListItemStoreOrder[]>([]);
  const [selectedItemKey, setSelectedItemKey] = useState<string>('');

  const [pageCount, setPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [storeProducts, setStoreProducts] = useState<ProductAdminList[]>([]);

  const selectedItem = items.find((item) => item.item.id === selectedItemKey);

  const handleUpdatedOrderState = (newState: StoreOrderState) => {

    if (!selectedItem) {
      return;
    }

    ServiceApiPageDataStore.updateStoreOrderState(siteId, pageId, selectedItem.item.id, newState).then((result) => {
      if (result === true) {

        // Load the orders
        ServiceApiPageDataStore.getStoreOrdersAdminList(siteId, pageId, itemsPerPage, currentPage).then((storeOrders) => {
          const storeOrderListItems = ListItemHelper.WrapItems(storeOrders, true);
          setItems(storeOrderListItems);
        });
      }
    });
  }

  useEffect(() => {

    // Load the orders count
    ServiceApiPageDataStore.getStoreOrdersAdminCount(siteId, pageId).then((storeOrdersCount) => {
      setPageCount(PaginationUtil.getRequiredPageCount(storeOrdersCount, itemsPerPage));
    });

    // Load the orders
    ServiceApiPageDataStore.getStoreOrdersAdminList(siteId, pageId, itemsPerPage, currentPage).then((storeOrders) => {
      const storeOrderListItems = ListItemHelper.WrapItems(storeOrders, true);
      setItems(storeOrderListItems);
    });

    // Load the products
    ServiceApiPageDataStore.getProductsAdminList(pageId).then((products) => {
      setStoreProducts(products);
    });

  }, [siteId, pageId]);

  return (
    <div className="page-feature-store-orders-component">

      <ContainerLabeledComponent
        label={t('orders')}
        uniqueId={`feature-store-orders-${pageId}`}
        defaultViewState="closed"
      >
        <ListAndItemComponent>

          {/* Orders list */}
          <div
            className='orders-side-panel'
          >
            <VerticalListComponent>
              <div className='list-holder'>
                <ItemsListComponent
                  items={items}
                  listName='orders'
                  getItemKey={(item) => item.item.id}
                  getItemLabel={(item) => item.item.buyerName}
                  selectedItemKey={selectedItemKey}
                  onSelectedItemChanged={setSelectedItemKey}
                  allowSorting={false}
                  renderItem={(listItem) => <OrderListItemComponent item={listItem.item} />}
                />
              </div>

              <HorizontalListComponent>
                <Spanner />
                <PaginationControlsComponent
                  currentPage={currentPage}
                  pageCount={pageCount}
                  onPageChange={setCurrentPage}
                  buttonsToShowCount={5}
                  showFirstAndLast={true}
                  showPreviousAndNext={false}
                  hideOnSinglePage={true}
                />
                <Spanner />
              </HorizontalListComponent>
            </VerticalListComponent>
          </div>

          {/* Order details */}
          <div>
            {selectedItem &&
              <OrderDetailsComponent
                order={selectedItem.item}
                products={storeProducts}
                handleUpdatedOrderState={handleUpdatedOrderState}
              />
            }
          </div>

        </ListAndItemComponent>
      </ContainerLabeledComponent>
    </div >
  );
}