import Form from 'react-bootstrap/Form';

interface FormNumberProps {
  size: 'sm' | 'lg' | undefined;
  value: string | undefined;
  onChange: (value: string) => void;
}

export function FormNumberComponent({
  size,
  value,
  onChange
}: FormNumberProps): JSX.Element {
  return (
    <Form.Control
      size={size}
      type={"text"}
      value={value}
      onChange={(e) => { onChange(e.target.value); }}
    />
  );
}
