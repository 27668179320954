import { RequestBody } from "../../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";

export class ReorderSitePagesRouteDef extends IRouteDefinition {
  constructor() {
    super("PUT");
    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(siteId: string, parentPageId: string): string {
    return `sites/reorder-site-pages?` +
      QueryParamsUtil.build(
        {
          "site-id": siteId,
          "parent-page-id": parentPageId
        }
      );
  }

  public routeTemplate(): string {
    return 'sites/reorder-site-pages';
  }
}

export const enum ReorderSitePagesQueryParams {
  siteId = 'site-id',
  parentPageId = 'parent-page-id'
}

export class ReorderSitePagesRequestBody extends RequestBody {
  constructor(
    public pageIds: string[]
  ) {
    super();
  }
}