
import { LabelComponent } from '../label/label';

interface LabelAndValueProps {
    label: string;
    value: string;
}

export function LabelAndValueComponent({ label, value }: LabelAndValueProps): JSX.Element {
    return (
        <div className="label-and-value-component">
            <LabelComponent bold>
                {label}
            </LabelComponent>
            <LabelComponent>
                {value}
            </LabelComponent>
        </div>
    );
}
