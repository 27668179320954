import Form from 'react-bootstrap/Form';

interface FormTextAreaProps {
  size: 'sm' | 'lg' | undefined;
  lines: number;
  readonly?: boolean;
  value: string | undefined;
  onChange: (value: string) => void;
}

export function FormTextAreaComponent({
  size,
  lines,
  value,
  readonly = false,
  onChange
}: FormTextAreaProps): JSX.Element {

  return (
    <Form.Control
      as="textarea"
      size={size}
      rows={lines}
      readOnly={readonly}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}
