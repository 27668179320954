import { RequestBody } from "../../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";

export class UpdateSitePageParentRouteDef extends IRouteDefinition {
  constructor() {
    super("PUT");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(siteId: string, pageId: string): string {
    return `sites/update-site-page-parent?` +
      QueryParamsUtil.build({
        "site-id": siteId,
        "page-id": pageId
      });
  }

  public routeTemplate(): string {
    return 'sites/update-site-page-parent';
  }
}

export const enum UpdateSitePageParentQueryParams {
  siteId = 'site-id',
  pageId = 'page-id',
}

export class UpdateSitePageParentRequestBody extends RequestBody {
  constructor(
    public parentPageId: string,
  ) {
    super();
  }
}

