import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslationCommonFrontEnd } from '../../translations/translation';

interface ButtonCopyToClipboardProps {
    size?: 'sm' | 'lg' | undefined;
    valueToCopy: string;
}

export function ButtonCopyToClipboardComponent({
    size = undefined,
    valueToCopy,
}: ButtonCopyToClipboardProps): JSX.Element {

    const t = useTranslationCommonFrontEnd();

    const [buttonClicked, setButtonClicked] = useState(false);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(valueToCopy);
        setButtonClicked(true);
    }

    useEffect(() => {
        setButtonClicked(false);
    }, [valueToCopy]);

    return (

        <Button
            variant='outline-primary'
            size={size}
            disabled={buttonClicked}
            onClick={handleCopyToClipboard}
        >
            {buttonClicked ? t('copied') : t('copy')}
        </Button>

    );
}
