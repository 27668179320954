import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SimpleTextAccordeonComponent } from "../../../common-frontend/components/accordeon/simpleText/simpleTextAccordeon";
import { SimpleTextAccordeonItem } from "../../../common-frontend/components/accordeon/simpleText/simpleTextAccordeonItem";
import { SectionTitleComponent } from "../../../common-frontend/components/sectionTitle/sectionTitle";
import { StringUtil } from "../../../common/util/string/stringUtil";

import "./reasons.css";

export function ReasonsComponent(): JSX.Element {

  const { t } = useTranslation();

  const reasons = useMemo<SimpleTextAccordeonItem[]>(() => [
    {
      eventKey: "0",
      title: t('professionalism'),
      body: StringUtil.joinSentences([
        t('onlinePresence'),
        t('havingWebsite')
      ]),
    },
    {
      eventKey: "1",
      title: t('totalContentControl'),
      body: StringUtil.joinSentences([
        t('ownSiteControl'),
        t('youDecideContent')
      ]),
    },
    {
      eventKey: "2",
      title: t('dataOwnership'),
      body: StringUtil.joinSentences([
        t('avoidThirdPartyPlatforms'),
        t('useSocialMedia'),
      ]),
    },
    {
      eventKey: "3",
      title: t('customBrand'),
      body: StringUtil.joinSentences([
        t('havingWebsiteAllowsBrand'),
        t('noNeedToAdjustImage'),
        t('showWhatYouWant')
      ]),
    },
    {
      eventKey: "4",
      title: t('noCommissionsOrFees'),
      body: StringUtil.joinSentences([
        t('keepAllRevenue'),
        t('moreProfitAndFreedom')
      ]),
    },
    {
      eventKey: "5",
      title: t('flexibilityOfFeatures'),
      body: StringUtil.joinSentences([
        t('addSpecificFeatures'),
        t('examplesOfFeatures')
      ]),
    },
    {
      eventKey: "6",
      title: t('resilienceAndSustainability'),
      body: StringUtil.joinSentences([
        t('socialPlatformsChange'),
        t('ownWebsitePresence'),
        t('useSocialMediaToPromote'),
        t('yourWebsiteIsYours')
      ]),
    },
  ], []);

  return <div>
    <SectionTitleComponent>
      {t('whyHaveWebsite')}
    </SectionTitleComponent>

    <SimpleTextAccordeonComponent items={reasons} />
  </div>
}