import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { ProductStoreNavigation } from "../models/productStoreNavigation";

export class GetProductsStoreNavigationRouteDef extends IRouteDefinition {
  constructor() {
    super('GET');
  }

  public route(pageId: string): string {
    return `site-pages-store/products-store-navigation?` + QueryParamsUtil.build({ "page-id": pageId });
  }

  public routeTemplate(): string {
    return 'site-pages-store/products-store-navigation';
  }
}

export const enum GetProductsStoreNavigationQueryParams {
  pageId = 'page-id'
}

export class GetProductsStoreNavigationResponse extends RequestResponse {
  constructor(public products: ProductStoreNavigation[]) {
    super();
  }
}