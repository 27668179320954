import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { HorizontalListComponent } from '../../../../../common-frontend/components/horizontalList/horizontalList';
import { LogoComponent } from '../../../../../common-frontend/components/logo/logo';
import { Spanner } from '../../../../../common-frontend/components/micro/spanner/spanner';
import { SitesUtil } from '../../../../../common/sites/sitesUtil';
import { useUserSessionContext } from '../../../../contexts/session/hooks/useUserSessionContext';

import './siteEditorHeader.css';

export function SiteEditorHeaderComponent(): JSX.Element {

  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();
  const userSessionContext = useUserSessionContext();

  const handleCloseEditor = () => {
    const siteHomeLocation = SitesUtil.getHomePageAddress(location.pathname);
    navigate(siteHomeLocation);
  }

  return (
    <div className="site-editor-header-component">
      <HorizontalListComponent>
        <Link to={'/'} >
          <div className='logo-holder'>
            <LogoComponent />
          </div>
        </Link>
        <Spanner />

        <div className='buttons-holder'>
          {
            userSessionContext.userSessionId &&
            <Link to={"/user-sites"}>
              <Button
                variant="outline-primary"
              >
                {t('siteList')}
              </Button>
            </Link>
          }

          {
            userSessionContext.userSessionId === '' &&
            <Link to={"/signin"}>
              <Button
                variant="outline-primary"
              >
                {t('login')}
              </Button>
            </Link>
          }

          <Button
            variant="primary"
            onClick={handleCloseEditor}
          >
            {t('viewSite')}
          </Button>
        </div>
      </HorizontalListComponent>
    </div>
  );
}
