import { Accordion } from "react-bootstrap";
import { SimpleTextAccordeonItem } from "./simpleTextAccordeonItem";

import "./simpleTextAccordeon.css";

interface SimpleTextAccordeonProps {
  items: SimpleTextAccordeonItem[];
}

export function SimpleTextAccordeonComponent({ items }: SimpleTextAccordeonProps): JSX.Element {

  const renderOption = ({ eventKey, title, body }: SimpleTextAccordeonItem): JSX.Element => {
    return <Accordion.Item
      key={eventKey}
      eventKey={eventKey}>
      <Accordion.Header>
        <b>{title}</b>
      </Accordion.Header>
      <Accordion.Body >
        <div className="accordeon-item-body">
          {body}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  }

  return <div className="accordeon-simple-text-component">
    <Accordion
      flush>
      {items.map(renderOption)}
    </Accordion>
  </div>
}