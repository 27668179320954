import { createContext } from "react";

export interface DynamicPageDataContextType {
  siteId: string;
  pageId: string;
  backendServerAddress: string;
}

const DynamicPageDataContext = createContext<DynamicPageDataContextType | undefined>(undefined);

export { DynamicPageDataContext };