import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";
import { Product } from "../models/product";

export class CreateProductRouteDef extends IRouteDefinition {
  constructor() {
    super('POST');
    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(): string {
    return 'site-pages-store/products';
  }

  public routeTemplate(): string {
    return 'site-pages-store/products';
  }
}

export class CreateProductRequestBody extends RequestBody {
  constructor(
    public product: Product
  ) {
    super();
  }
}