import { BlogPostAdminUpdate } from "../blogPostAdminUpdate";

export class BlogPostAdminUpdateFactory {
  public static createNew(id: string, pageId: string): BlogPostAdminUpdate {
    return {
      id,
      pageId,
      title: '',
      subTitle: '',
      content: '',
      category: '',
      visible: true,
      creatorId: ''
    };
  }
}