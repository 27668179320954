import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ListDirection, SortableListItem } from "./sortableListItem";

interface SortableListProps<T> {
  items: T[];
  listId: string;
  listDirection: ListDirection;

  onItemsReordered: (items: T[]) => void;
  onDropComplete?: () => void;

  getItemKey: (item: T) => string;
  renderContainer: (children: JSX.Element) => JSX.Element;
  renderElement: (item: T) => JSX.Element;
}

export function SortableList<T>({
  items,
  listId,
  listDirection,
  getItemKey,
  onItemsReordered: setItems,
  renderContainer,
  renderElement,
  onDropComplete
}: SortableListProps<T>): JSX.Element {

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const dragItem = items[dragIndex];
    const newItems = [...items];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, dragItem);
    setItems(newItems);
  };

  const childrenNodes = <>
    {items.map((item, index) => (
      <SortableListItem
        key={getItemKey(item)}
        index={index}
        item={item}
        moveItem={moveItem}
        renderElement={renderElement}
        listDirection={listDirection}
        listId={listId}
        onDropComplete={onDropComplete}
      />
    ))}
  </>;

  return (
    <DndProvider backend={HTML5Backend}>
      {renderContainer(childrenNodes)}
    </DndProvider>
  );
}