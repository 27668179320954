import { WhiteSpaceComponent } from "../whiteSpace/whiteSpace";

interface MenuItemProps {
  caption: string;
  iconName: string;
}

export function MenuItemComponent({ caption, iconName }: MenuItemProps): JSX.Element {

  const fullClassName = `bi bi-${iconName}`;

  return (
    <>
      <i className={fullClassName} />
      <WhiteSpaceComponent ammount={3} />
      {caption}
    </>
  )
}