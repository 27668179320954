import { CurrencyUtil } from "../../../common/util/currency/currencyUtil";

interface PriceComponentProps {
  value: number;
}

export function PriceComponent({ value }: PriceComponentProps): JSX.Element {

  const formattedPrice = CurrencyUtil.formatCurrencyAsEur(value);

  return (
    <div className="price">{formattedPrice}</div>
  );
}