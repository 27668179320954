import { UserFileImage } from "../../../../../common-app/userFiles/models/userFileImage";
import {
  GetImagesInfoFunction,
  GetImagesListFunction,
  UploadImageFunction
} from "../../../../../common-frontend/components/imageUploader/imageBrowser";

import {
  InsertUserQueryFunction,
  RichTextEditorContentManager,
} from "../../../../../common-frontend/components/richTextEditor/richTextEditorContentManager";
import { BackendServerEndpointProvider } from "../../../../../common-frontend/services/api/backendServerEndpointProvider";
import { ServiceApiUserFiles } from "../../../../../services/api/serviceApiUserFiles";
import { ServiceApiUserQueries } from "../../../../../services/api/serviceApiUserQueries";

export class RichTextEditorContentManagerSite implements RichTextEditorContentManager {

  constructor(private siteId: string, private pageId: string) {

    this.getSiteIdFunction = (): string => {
      return this.siteId;
    }

    this.getPageIdFunction = (): string => {
      return this.pageId;
    }

    this.getBackendServerEndpointFunction = (): string => {
      const backendServer = BackendServerEndpointProvider.getDefaultBackendServer();
      return backendServer.getEndpoint();
    }

    this.uploadImageFunction = (file: File, name: string, id: string): Promise<boolean> => {
      return ServiceApiUserFiles.uploadUserFile(file, name, id, this.siteId);
    }

    this.getImagesListFunction = async (): Promise<UserFileImage[]> => {
      const images = await ServiceApiUserFiles.getUserImagesList(this.siteId);
      return this.adjustImageUrls(images);
    }

    this.getImagesInfoFunction = async (imageIds: string[]): Promise<UserFileImage[]> => {
      const images = await ServiceApiUserFiles.getUserImagesInfo(this.siteId, imageIds);
      return this.adjustImageUrls(images);
    }

    this.insertUserQueryFunction = (siteId: string, pageId: string, userQueryId: string, id: string, contact: string, subject: string, message: string): Promise<boolean> => {
      return ServiceApiUserQueries.insertUserQuery(
        siteId,
        pageId,
        userQueryId,
        contact,
        subject,
        message);
    }
  }


  private adjustImageUrls(images: UserFileImage[]): UserFileImage[] {

    const backendServer = BackendServerEndpointProvider.getDefaultBackendServer();
    const serverEndpoint = backendServer.getEndpoint();

    // We need to append the server address to the URL of each image
    images.forEach((image) => {
      image.publicUrl = serverEndpoint + image.publicUrl;
      image.publicUrlThumbnail = serverEndpoint + image.publicUrlThumbnail;
    });

    return images;
  }

  public getSiteIdFunction: () => string;
  public getPageIdFunction: () => string;
  public getBackendServerEndpointFunction: () => string;

  public uploadImageFunction: UploadImageFunction;
  public getImagesListFunction: GetImagesListFunction;
  public getImagesInfoFunction: GetImagesInfoFunction;

  public insertUserQueryFunction: InsertUserQueryFunction;

}