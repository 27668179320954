interface WhiteSpaceProps {
  ammount: number;
}

export function WhiteSpaceComponent({ ammount }: WhiteSpaceProps): JSX.Element {
  return (
    <>
      {Array(ammount).fill(0).map((_, index) => <span key={index}>&nbsp;</span>)}
    </>
  );
}
