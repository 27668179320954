import { Pagination } from 'react-bootstrap';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import './pagination.css';

interface PaginationControlsProps {
    pageCount: number;
    currentPage: number;
    onPageChange: (page: number) => void;
    buttonsToShowCount: number;
    showFirstAndLast: boolean;
    showPreviousAndNext: boolean;
    hideOnSinglePage: boolean;
}

export function PaginationControlsComponent({
    pageCount,
    currentPage,
    onPageChange,
    buttonsToShowCount,
    showFirstAndLast,
    showPreviousAndNext,
    hideOnSinglePage }: PaginationControlsProps): JSX.Element {

    const [paginationButtons, setPaginationButtons] = useState<ReactNode[]>([]);

    const handlePageChange = useCallback((page: number) => {
        onPageChange(page);
    }, [onPageChange]);

    useEffect(() => {

        // Here, we need to determine what buttons to add to the pagination component
        // based on the number of pages, the current page, and the number of buttons to show

        const buttonFirst: JSX.Element[] = [];
        const buttonPrevious: JSX.Element[] = [];
        const buttonNext: JSX.Element[] = [];
        const buttonLast: JSX.Element[] = [];
        const buttonsCenter: JSX.Element[] = [];

        let numButtonsAvailable = buttonsToShowCount;

        if (showFirstAndLast) {

            buttonFirst.push(
                <Pagination.First
                    key={"first"}
                    onClick={() => handlePageChange(1)} />
            );
            buttonLast.push(
                <Pagination.Last
                    key={"last"}
                    onClick={() => handlePageChange(pageCount)}
                />
            );
            numButtonsAvailable -= 2;
        }

        if (showPreviousAndNext) {
            buttonPrevious.push(
                <Pagination.Prev
                    key={"previous"}
                    onClick={() => {
                        if (currentPage !== 1) {
                            handlePageChange(currentPage - 1)
                        }
                    }}
                />
            );
            buttonNext.push(
                <Pagination.Next
                    key={"next"}
                    onClick={() => {
                        if (currentPage !== pageCount) {
                            handlePageChange(currentPage + 1)
                        }
                    }}
                />
            );
            numButtonsAvailable -= 2;
        }

        if (numButtonsAvailable < 3) {
            numButtonsAvailable = 3;
        }

        // We have certain slots for page numbers to fill
        // Depending on the current page and the number of buttons available
        // we need to determine which pages to show

        const numSlotsBeforeCurrent = Math.floor((numButtonsAvailable - 1) / 2);
        const numSlotsAfterCurrent = numSlotsBeforeCurrent;

        let firstPageIndexToShow = Math.max(1, currentPage - numSlotsBeforeCurrent);
        let lastPageIndexToShow = Math.min(pageCount, currentPage + numSlotsAfterCurrent);

        if ((lastPageIndexToShow - firstPageIndexToShow + 1) < numButtonsAvailable) {
            firstPageIndexToShow = Math.max(1, lastPageIndexToShow - numButtonsAvailable + 1);
            lastPageIndexToShow = Math.min(pageCount, firstPageIndexToShow + numButtonsAvailable - 1);
        }

        for (let pageIndex = firstPageIndexToShow; pageIndex <= lastPageIndexToShow; pageIndex++) {
            if (pageIndex > pageCount) {
                break;
            }

            buttonsCenter.push(
                <Pagination.Item
                    key={pageIndex}
                    active={pageIndex === currentPage}
                    onClick={() => handlePageChange(pageIndex)}
                >
                    {pageIndex}
                </Pagination.Item>
            );
        }

        setPaginationButtons([
            ...buttonFirst,
            ...buttonPrevious,
            ...buttonsCenter,
            ...buttonNext,
            ...buttonLast
        ]);

    }, [
        pageCount,
        currentPage,
        buttonsToShowCount,
        showFirstAndLast,
        showPreviousAndNext,
        handlePageChange
    ]);

    return (
        <div className='pagination-component'>
            {
                (hideOnSinglePage && pageCount <= 1) ? null : (
                    <Pagination>
                        {paginationButtons}
                    </Pagination>
                )
            }
        </div>
    )
}
