import { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MediaGalleryItem } from "../../../../../../../../common-app/common/models/mediaGalleryItem";
import { HorizontalListComponent } from "../../../../../../../../common-frontend/components/horizontalList/horizontalList";
import { ImageUploaderModalComponent } from "../../../../../../../../common-frontend/components/imageUploader/imageUploaderModal";
import { RichTextEditorContentManager } from "../../../../../../../../common-frontend/components/richTextEditor/richTextEditorContentManager";
import { GuidUtil } from "../../../../../../../../common/util/guid/guid";

interface ButtonMediaGallerySelectProps {
  contentManager: RichTextEditorContentManager,
  itemId: string,
  mediaItems: MediaGalleryItem[],
  updateItems: (items: MediaGalleryItem[]) => void
}

export function ButtonMediaGallerySelectComponent({
  contentManager,
  itemId,
  mediaItems,
  updateItems
}: ButtonMediaGallerySelectProps): JSX.Element {

  const { t } = useTranslation();

  const [showSelectImagesDialog, setShowSelectImagesDialog] = useState<boolean>(false);

  const imageIds = mediaItems.map((item) => item.data);

  const handleImageSelection = (images: string[]) => {

    // Create new items
    const newItems: MediaGalleryItem[] = images.map((imageId, index) => {
      return {
        id: GuidUtil.GenerateNewGuid(),
        itemId,
        displayOrder: index,
        type: "image",
        data: imageId
      }
    });

    // Check if some GUIDs can be reused
    newItems.forEach((newItem) => {
      const existingItem = mediaItems.find((item) => item.data === newItem.data);
      if (existingItem) {
        newItem.id = existingItem.id;
      }
    });

    // Update items
    updateItems(newItems);
    setShowSelectImagesDialog(false)
  }

  return (
    <div>
      {/* Browser button */}
      <HorizontalListComponent>
        <Button
          onClick={() => { setShowSelectImagesDialog(true); }}
        >
          {t('chooseImages')}
        </Button>
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}>
          <span>
            {t('imagesSelected', { count: mediaItems.length })}
          </span>
        </div>
      </HorizontalListComponent>

      {/* Picker for images */}
      <ImageUploaderModalComponent
        imagesSelected={imageIds}
        uploadImageFunction={contentManager.uploadImageFunction}
        getImagesListFunction={contentManager.getImagesListFunction}
        getImagesInfoFunction={contentManager.getImagesInfoFunction}
        allowMultipleSelection={true}
        allowEmptySelection={true}
        showDialog={showSelectImagesDialog}
        onClose={() => { setShowSelectImagesDialog(false) }}
        onConfirm={handleImageSelection}
      />
    </div>
  )
}