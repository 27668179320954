import { CustomElementTypesEnum } from "../../types";

export class ElementName {
  static getElementName(elementType: CustomElementTypesEnum): string {
    switch (elementType) {
      case CustomElementTypesEnum.Paragraph:
        return "paragraph";
      case CustomElementTypesEnum.HeadingOne:
        return "headingOne";
      case CustomElementTypesEnum.HeadingTwo:
        return "headingTwo";
      case CustomElementTypesEnum.HeadingThree:
        return "headingThree";
      case CustomElementTypesEnum.Image:
        return "image";
      case CustomElementTypesEnum.ImageUser:
        return "imageUser";
      case CustomElementTypesEnum.ImageUserGallery:
        return "imageUserGallery";
      case CustomElementTypesEnum.Url:
        return "url";
      case CustomElementTypesEnum.YoutubeVideo:
        return "youtubeVideo";
      case CustomElementTypesEnum.YoutubeShort:
        return "youtubeShort";
      case CustomElementTypesEnum.UserQuery:
        return "userQuery";
      case CustomElementTypesEnum.HorizontalLine:
        return "horizontalLine";
      default:
        return "unknownElement";
    }
  }
}