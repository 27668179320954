import { Link } from "react-router-dom";

import './linkNewWindow.css';

interface LocationDisplayComponentProps {
  url: string;
}

export function LinkNewWindow({
  url
}: LocationDisplayComponentProps): JSX.Element {

  return <Link to={url} target="blank" >
    <div className="link-new-window-component align-center">
      <div>
        {url}
      </div>
      <div>
        <i className="bi bi-box-arrow-up-right"></i>
      </div>
    </div>
  </Link>
}
