import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ArrayUtil } from '../../../common/util/array/arrayUtil';
import { ImageCaptionComponent } from '../imageCaption/imageCaption';
import { ImageGalleryItem } from './imageGalleryItem';

import './imageGallery.css';

interface ImageGalleryComponentProps {
  images: ImageGalleryItem[];
}

export function ImageGalleryComponent({ images }: ImageGalleryComponentProps): JSX.Element {

  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const thumbnailContainerRef = useRef<HTMLDivElement>(null);

  const thumbnailImageRefs = useRef<(HTMLDivElement | null)[]>([]);

  const selectedImage = images[selectedImageIndex];

  useEffect(() => {

    const thumbnailContainer = thumbnailContainerRef.current;
    if (thumbnailContainer === null) {
      return;
    }

    const containerRect = thumbnailContainer.getBoundingClientRect();

    if (selectedImageIndex === 0) {
      const selectedImage = thumbnailImageRefs.current[selectedImageIndex];
      if (selectedImage) {
        const selectedImageRect = selectedImage.getBoundingClientRect();
        if (selectedImageRect.left < containerRect.left || selectedImageRect.right > containerRect.right) {
          selectedImage.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
          return;
        }
      }
    }

    if (selectedImageIndex > 0) {
      // Check if we need to bring into view the previous image
      const previousImageIndex = selectedImageIndex - 1;
      const previousImage = thumbnailImageRefs.current[previousImageIndex];
      if (previousImage) {
        const previousImageRect = previousImage.getBoundingClientRect();
        if (previousImageRect.left < containerRect.left || previousImageRect.right > containerRect.right) {
          previousImage.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
      }
    }

    if (selectedImageIndex < images.length - 1) {
      // Check if we need to bring into view the next image
      const nextImageIndex = selectedImageIndex + 1;
      const nextImage = thumbnailImageRefs.current[nextImageIndex];
      if (nextImage) {
        const nextImageRect = nextImage.getBoundingClientRect();
        if (nextImageRect.left < containerRect.left || nextImageRect.right > containerRect.right) {
          nextImage.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
        }
      }
    }
  }, [selectedImageIndex, images]);

  if (images.length === 0) {
    return <></>;
  }

  let imageBorderWidth = 0;
  let imageBorderStyle = 'none';
  if (selectedImage.borderWidth !== undefined) {
    imageBorderWidth = selectedImage.borderWidth;
    if (imageBorderWidth > 0) {
      imageBorderStyle = 'solid';
    }
  }

  return (
    <div className="image-gallery-component">
      <div
        className={classNames(
          'active-item',
          { 'gallery': images.length > 1 }
        )}
        style={{
          backgroundColor: selectedImage.backgroundColor,
          borderColor: selectedImage.borderColor,
          borderWidth: `${imageBorderWidth}px`,
          padding: selectedImage.margin,
          borderStyle: imageBorderStyle
        }}
        onClick={() => setSelectedImageIndex(ArrayUtil.nextIndex(selectedImageIndex, images))}
      >
        <img
          src={selectedImage.url}
          alt={selectedImage.altText}
          draggable={false}
        />

        <ImageCaptionComponent
          caption={selectedImage.caption}
        />

      </div>

      {images.length > 1 &&

        <div
          className='thumbnails'
          ref={thumbnailContainerRef}
        >
          {images.map((image, index) => (
            <div
              key={index}
              onClick={() => setSelectedImageIndex(index)}
              ref={(el) => (thumbnailImageRefs.current[index] = el)}
              className={classNames(
                'thumbnail-item',
                { 'thumbnail-item-selected': selectedImage.url === image.url })}
            >
              <img
                src={image.urlthumbnail}
                alt={image.altText}
                draggable={false}
              />

              {/* Hidden image used for preloading the full-resolution image for 
                improved user experience on slow connections */}
              <img
                src={image.url}
                alt={image.altText}
                style={{ display: 'none' }}
              />
            </div>
          ))}
        </div>
      }
    </div>
  );
};

