import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import { useTranslation } from "react-i18next";
import { BlogPostAdmin } from "../../../common-app/sitePagesBlogs/models/blogPostAdmin";
import { BlogPostAdminUpdate } from "../../../common-app/sitePagesBlogs/models/blogPostAdminUpdate";
import { BlogPostAdminUpdateFactory } from "../../../common-app/sitePagesBlogs/models/factories/blogPostAdminUpdateFactory";
import { ButtonDelete } from "../../../common-frontend/components/buttonDelete/buttonDelete";
import { ActionsSaveDiscardComponent } from "../../../common-frontend/components/form/actionsSaveDiscard/actionsSaveDiscard";
import { FormCellComponent } from "../../../common-frontend/components/form/formCell";
import { FormEditComponent } from "../../../common-frontend/components/form/formEdit/formEdit";
import { FormSwitchComponent } from "../../../common-frontend/components/form/formSwitch/formSwitch";
import { HorizontalListComponent } from "../../../common-frontend/components/horizontalList/horizontalList";
import { Spanner } from "../../../common-frontend/components/micro/spanner/spanner";
import { useRichTextEditor } from "../../../common-frontend/components/richTextEditor/hooks/useRichTextEditor";
import { RichTextEditor } from "../../../common-frontend/components/richTextEditor/richTextEditor";
import { useRichTextEditorContentManagerSite } from "../../../components/sites/editor/common/richTextEditorContentManagerSite/useRichTextEditorContentManagerSite";

interface BlogPostFormProps {
  siteId: string;
  pageId: string;
  blogPostToEdit: BlogPostAdmin;
  isNewItem: boolean;
  onSaveBlogPost: (blogPost: BlogPostAdminUpdate) => void;
  onDeleteBlogPost: (blogPost: BlogPostAdmin) => void;
}

export function BlogPostFormComponent({
  siteId,
  pageId,
  blogPostToEdit,
  isNewItem,
  onSaveBlogPost,
  onDeleteBlogPost
}: BlogPostFormProps): JSX.Element {

  const { t } = useTranslation();

  const contentManagerSite = useRichTextEditorContentManagerSite(siteId, pageId);
  const editorConfig = useRichTextEditor(false, false);

  const [canSave, setCanSave] = useState<boolean>(true);
  const [title, setTitle] = useState<string>("");
  const [subTitle, setSubtitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    copyBlogPostInfoToControls(blogPostToEdit);
  }, [blogPostToEdit]);

  useEffect(() => {
    const currentBlogPost = BlogPostAdminUpdateFactory.createNew(
      blogPostToEdit.id,
      blogPostToEdit.pageId);

    copyControlsInfoToBlogPost(currentBlogPost);

    setCanSave(isNewItem || !BlogPostAdminUpdate.isEqual(blogPostToEdit, currentBlogPost));

  }, [
    blogPostToEdit,
    title,
    subTitle,
    visible,
    content]);

  const handleDiscardChanges = () => {
    copyBlogPostInfoToControls(blogPostToEdit);
  }

  const copyBlogPostInfoToControls = (blogPost: BlogPostAdmin) => {
    setTitle(blogPost.title);
    setSubtitle(blogPost.subTitle || "");
    setContent(blogPost.content || "");
    editorConfig.updateInitialValue(blogPost.content || "");
    setVisible(blogPost.visible);
  }

  const copyControlsInfoToBlogPost = (blogPost: BlogPostAdminUpdate) => {
    blogPost.title = title;
    blogPost.subTitle = subTitle;
    blogPost.content = content;
    blogPost.visible = visible;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const blogPost = BlogPostAdminUpdateFactory.createNew(
      blogPostToEdit.id,
      blogPostToEdit.pageId);

    copyControlsInfoToBlogPost(blogPost);

    onSaveBlogPost(blogPost);
  };

  return <div>
    <Form onSubmit={handleSubmit}>

      <FormCellComponent size="Large">
        <Form.Group
          className="mb-3"
          controlId='blog-post-title'>
          <Form.Label>{t('title')}</Form.Label>
          <FormEditComponent
            type="text"
            value={title}
            onChange={setTitle}
          />
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId='blog-post-subtitle'>
          <Form.Label>{t('subtitle')}</Form.Label>
          <FormEditComponent
            type="text"
            value={subTitle}
            onChange={setSubtitle}
          />
        </Form.Group>

        <Form.Group
          className="mb-5"
          controlId='blog-post-visible'>
          <FormSwitchComponent
            label={t('visible')}
            value={visible}
            onChange={setVisible}
          />
        </Form.Group>

        {/* EDITOR */}
        <Form.Group
          className="mb-3"
          controlId='blog-post-content'>

          <RichTextEditor
            key={editorConfig.getEditorKey()}
            features={{
              editable: editorConfig.getEditorEditable(),
              allowUserQueries: editorConfig.getEditorAllowUserQueries()
            }}
            initialValue={editorConfig.getEditorStartupValue()}
            contentManager={contentManagerSite}
            onChange={(newValue) => {
              editorConfig.updateContents(newValue);
              setContent(newValue);
            }}
          />
        </Form.Group>
      </FormCellComponent>

      <HorizontalListComponent>
        <ButtonDelete
          itemName={blogPostToEdit.title || t('postPlaceHolder')}
          onDelete={() => onDeleteBlogPost(blogPostToEdit)}
        />
        <Spanner />
        <div>
          <ActionsSaveDiscardComponent
            canSave={canSave}
            onDiscard={handleDiscardChanges}
          />
        </div>
      </HorizontalListComponent>
    </Form>
  </div>

}