import { useEffect, useState } from 'react';
import { UserSessionContext } from './userSessionContext';
import { LocalStorageSession } from '../../../library/localStorage/session/session';

interface UserSessionProviderProps {
  children: React.ReactNode;
}

export function UserSessionProvider({
  children,
}: UserSessionProviderProps): JSX.Element {

  useEffect(() => {

    const sessionId = LocalStorageSession.getSessionId();
    const userId = LocalStorageSession.getUserId();

    if (sessionId && userId) {
      setUserSessionId(sessionId);
      setUserId(userId);
    }

  }, []);

  const [userSessionId, setUserSessionId] = useState('');
  const [userId, setUserId] = useState('');

  const setSessionInformation = (sessionId: string, id: string) => {
    setUserSessionId(sessionId);
    setUserId(id);
  }

  const clearSessionInformation = () => {
    setUserSessionId('');
    setUserId('');
  }

  return (
    <UserSessionContext.Provider value={{
      userSessionId,
      userId,
      setSessionInformation,
      clearSessionInformation
    }}>
      {children}
    </UserSessionContext.Provider>
  );
}