import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslationCommonFrontEnd } from "../../../../../translations/translation";

export function ButtonGoToCheckoutComponent(): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const navigate = useNavigate();

  const handleGoToCheckout = () => {
    navigate('?checkout=1');
  }

  return (
    <Button
      onClick={handleGoToCheckout}
    >
      {t('finalizeOrder')}
    </Button>
  );
}