import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";


export class TakeSiteOwnershipRouteDef extends IRouteDefinition {
  constructor() {
    super("POST");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(sessionId: string, siteId: string, deviceId: string): string {
    return `sites/take-site-ownership?` +
      QueryParamsUtil.build(
        {
          "session-id": sessionId,
          "site-id": siteId,
          "device-id": deviceId
        });
  }

  public routeTemplate(): string {
    return 'sites/take-site-ownership';
  }
}

export const enum TakeSiteOwnershipQueryParams {
  sessionId = 'session-id',
  siteId = 'site-id',
  deviceId = 'device-id'
}

export class TakeSiteOwnershipResponse extends RequestResponse {
  constructor(
    public success: boolean
  ) {
    super();
  }
}
