import classNames from "classnames";
import { HorizontalListComponent } from "../../../../../../../../common-frontend/components/horizontalList/horizontalList";
import { ListItem } from "../../../../../../../../common-frontend/library/list/listItem";
import { CollapseIndicatorComponent } from "../../../../../../../../common-frontend/components/collapseIndicator/collapseIndicator";
import { ProductAdminList } from "../../../../../../../../common-app/sitePagesStores/models/productAdminList";

import './TreeStoreProductGroup.css';

export type ListItemProduct = ListItem<ProductAdminList>;

export type ProductGroupRender = {
  categoryName: string;
  expanded: boolean;
  items: ListItemProduct[];
};

interface TreeStoreProductGroupProps {
  group: ProductGroupRender;
  onToggleExpanded: () => void;
  selectedItemKey: string | undefined;
  onSelectedItemChanged: (itemKey: string) => void;
}

export function TreeStoreProductGroupComponent({
  group,
  onToggleExpanded,
  selectedItemKey,
  onSelectedItemChanged
}: TreeStoreProductGroupProps): JSX.Element {

  // Sort items by name
  const itemsToRender = group.items.sort((a, b) => a.item.name.localeCompare(b.item.name));

  return (
    <div className="tree-store-product-group-component">

      {/* Category Name */}
      <div className="category-name"
        onClick={onToggleExpanded}>
        <HorizontalListComponent>
          <CollapseIndicatorComponent isExpanded={group.expanded} />
          {group.categoryName}
        </HorizontalListComponent>
      </div>

      {/* Child Items */}
      <div
        className="products-list">
        {group.expanded && itemsToRender.map((item) => {
          return (
            <div
              key={item.item.id}
              className={classNames(
                'product-name',
                { 'product-name-selected': item.item.id === selectedItemKey })}

              onClick={() => {
                onSelectedItemChanged(item.item.id);
              }}
            >
              {item.item.name}
            </div>
          )
        })}
      </div>
    </div>
  )
}