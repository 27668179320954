import { useEffect } from 'react';
import { RichTextEditor } from '../../richTextEditor/richTextEditor';
import { useRichTextEditor } from '../../richTextEditor/hooks/useRichTextEditor';
import { useRichTextEditorContentManagerSite } from '../../../../components/sites/editor/common/richTextEditorContentManagerSite/useRichTextEditorContentManagerSite';
import { DynamicPageStaticProvider } from './provider/dynamicPageStaticProvider';
import { SitePageRenderInfo } from '../../../../common-app/sites/models/sitePageRenderInfo';
import { useDynamicPageDataContext } from '../provider/hooks/useDynamicPageDataContext';

interface DynamicPageStaticProps {
  pageRenderInfo: SitePageRenderInfo;
  dataProvider: DynamicPageStaticProvider;
}

export function DynamicPageStaticComponent({
  dataProvider,
}: DynamicPageStaticProps): JSX.Element {

  const dynamicPageContext = useDynamicPageDataContext();

  const editorConfig = useRichTextEditor(true, true);
  const contentManagerSite = useRichTextEditorContentManagerSite(dynamicPageContext.siteId, dynamicPageContext.pageId);

  useEffect(() => {
    const loadStaticData = async () => {
      // Get the contents from the backend
      const content = await dataProvider.getPageDataStatic(dynamicPageContext.pageId);
      editorConfig.updateInitialValue(content);
    }
    loadStaticData();
  }, [dynamicPageContext.pageId]);

  return <div>
    <RichTextEditor
      key={editorConfig.getEditorKey()}
      features={{
        editable: editorConfig.getEditorEditable(),
        allowUserQueries: editorConfig.getEditorAllowUserQueries()
      }}
      initialValue={editorConfig.getEditorStartupValue()}
      onChange={(newValue) => {
        editorConfig.updateContents(newValue);
      }}
      contentManager={contentManagerSite}
    />
  </div>
}