import { Youtube } from "../../../../../../common/util/youtube/youtube";
import { useTranslationCommonFrontEnd } from "../../../../../translations/translation";
import { ElementConfigurationSnippetTextInputComponent } from "../elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput";

interface ElementConfigurationSnippetYoutubeContentProps {
  videoId: string;
  setVideoId: (videoId: string) => void;
}

export function ElementConfigurationSnippetYoutubeContentComponent({
  videoId,
  setVideoId
}: ElementConfigurationSnippetYoutubeContentProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const parseVideoId = (url: string): void => {

    const parsedVideoId = Youtube.extractYouTubeVideoId(url);
    if (parsedVideoId) {
      setVideoId(parsedVideoId);
    }
    else {
      setVideoId(url);
    }
  }

  return (
    <ElementConfigurationSnippetTextInputComponent
      caption={t('videoId')}
      placeholder='Ex: dQw4w9WgXcQ'
      value={videoId}
      onChange={
        (value) => parseVideoId(value)
      }
    />
  )
}

