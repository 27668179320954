import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ProductStoreNavigation } from "../../../../../../common-app/sitePagesStores/models/productStoreNavigation";
import { UserFileImage } from "../../../../../../common-app/userFiles/models/userFileImage";
import { DynamicPageStoreProvider } from "../../provider/dynamicPageStoreProvider";
import { ProductListItemImageComponent } from "./productListItemImage";

import "./productListItem.css";

interface ProductListItemProps {
  dataProvider: DynamicPageStoreProvider;
  product: ProductStoreNavigation;
}

export function ProductListItemComponent({
  dataProvider,
  product,
}: ProductListItemProps): JSX.Element {

  const [userFileImages, setUserFileImages] = useState<UserFileImage[]>([]);

  useEffect(() => {

    dataProvider.getProductGalleryImagesInfo(product.id).then((userFileImages) => {
      setUserFileImages(userFileImages);
    });
  }, [product, dataProvider]);

  return (
    <div className="product-list-item-component">
      <Link
        className="no-decor"
        to={`?item=${product.id}`}
      >
        <div className="image-parent shadow-sm">
          <ProductListItemImageComponent
            images={userFileImages}
          />
        </div>

        <div>
          <div
            className="product-name"
          >
            {product.name}
          </div>
          <div
            className="product-price"
          >
            {product.price}€
          </div>
        </div>
      </Link>
    </div>
  );
}