import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useTranslationCommonFrontEnd } from '../../../../../translations/translation';
import { ContainerLabeledComponent } from '../../../../containerLabeled/containerLabeled';
import { CustomElement, CustomElementImageUser } from '../../../types';
import { ElementEditRef } from '../../elementSettingsConfig';
import { ElementSettingsEditProps } from '../../elementSettingsEditProps';
import { ElementConfigurationDisplayBlockOptionsComponent } from '../../snippets/elementConfigurationDisplayBlockOptions/elementConfigurationDisplayBlockOptions';
import { ElementConfigurationSnippetImagePreviewComponent } from '../../snippets/elementConfigurationSnippetImagePreview/elementConfigurationSnippetImagePreview';
import { ElementConfigurationSnippetImageSelectionComponent } from '../../snippets/elementConfigurationSnippetImageSelection/elementConfigurationSnippetImageSelection';
import { ElementConfigurationSnippetTextInputComponent } from '../../snippets/elementConfigurationSnippetTextInput/elementConfigurationSnippetTextInput';

import './elementEditImageUser.css';

export const ElementEditImageUserComponent = forwardRef<ElementEditRef, ElementSettingsEditProps>(
  ({
    contentManager,
    element,
    elementCanBeUpdated
  }, ref): JSX.Element => {

    const t = useTranslationCommonFrontEnd();

    const elementImageUser = element as CustomElementImageUser;

    const [imageId, setImageId] = useState(elementImageUser.image.imageId);
    const [caption, setCaption] = useState(elementImageUser.image.caption);
    const [displayBlockOptions, setDisplayBlockOptions] = useState(elementImageUser.displayBlockOptions);

    useImperativeHandle(ref, () => ({
      updateElement: (element: CustomElement) => {
        const updatedElement = element as CustomElementImageUser;
        updatedElement.image.imageId = imageId;
        updatedElement.image.caption = caption;
        updatedElement.displayBlockOptions = displayBlockOptions;
        return updatedElement;
      },
    }));

    useEffect(() => {
      elementCanBeUpdated(imageId !== '');
    }, [imageId]);

    return (
      <div className="element-edit-image-user-component">

        <ContainerLabeledComponent
          label={t('image')}
        >

          <ElementConfigurationSnippetImagePreviewComponent
            getImagesInfoFunction={contentManager.getImagesInfoFunction}
            imageId={imageId}
          />

          <ElementConfigurationSnippetImageSelectionComponent
            uploadImageFunction={contentManager.uploadImageFunction}
            getImagesListFunction={contentManager.getImagesListFunction}
            onImageSelected={setImageId}
          />
        </ContainerLabeledComponent>

        <ContainerLabeledComponent
          label={t('subtitle')}>

          <ElementConfigurationSnippetTextInputComponent
            caption={t('subtitle')}
            value={caption}
            onChange={setCaption}
          />
        </ContainerLabeledComponent>

        <ContainerLabeledComponent
          label={t('displayOptions')}>
          <ElementConfigurationDisplayBlockOptionsComponent
            displayOptions={elementImageUser.displayBlockOptions}
            setDisplayOptions={setDisplayBlockOptions}
          />
        </ContainerLabeledComponent>
      </div>
    );
  }
);