import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SitePageEditorInfo } from "../../models/sitePageEditorInfo";

export class GetSitePageEditorInfoRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(siteId: string, pageId: string): string {
    return `sites/get-site-page-editor-info?` + QueryParamsUtil.build({
      "site-id": siteId,
      "page-id": pageId
    });
  }

  public routeTemplate(): string {
    return 'sites/get-site-page-editor-info';
  }
}

export const enum GetSitePageEditorInfoQueryParams {
  siteId = 'site-id',
  pageId = 'page-id',
}

export class GetSitePageEditorInfoResponse extends RequestResponse {
  constructor(
    public pageInfo: SitePageEditorInfo | undefined
  ) {
    super();
  }
}
