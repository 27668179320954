import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";

export class DeleteContactRouteDef extends IRouteDefinition {
  constructor() {
    super('DELETE');

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(contactId: string): string {
    return `site-pages-contacts/contacts/${contactId}`;
  }

  public routeTemplate(): string {
    return 'site-pages-contacts/contacts/:contactId';
  }
}

export const enum DeleteContactRouteParams {
  contactId = 'contactId'
}
