import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class IsSiteOwnerRouteDef extends IRouteDefinition {

  constructor() {
    super("GET");
  }

  public route(sessionId: string, siteId: string): string {
    return `system/is-site-owner?` + QueryParamsUtil.build({
      "session-id": sessionId,
      "site-id": siteId
    });
  }

  public routeTemplate(): string {
    return 'system/is-site-owner';
  }
}

export const enum IsSiteOwnerQueryParams {
  sessionId = 'session-id',
  siteId = 'site-id',
}

export class IsSiteOwnerResponse extends RequestResponse {
  constructor(
    public isOwner: boolean
  ) {
    super();
  }
}
