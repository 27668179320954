import { useCallback, useEffect, useState } from 'react';
import { debounce } from '../../../common/util/debounce/debounce';
import { LocalStorage } from '../../library/localStorage/localStorage';
import { VerticalLineComponent } from '../verticalLine/verticalLine';
import { useTranslationCommonFrontEnd } from '../../translations/translation';

import './colorPicker.css';

interface ColorPickerProps {
  id: string;
  value: string;
  onColorChange: (color: string) => void;
  fixPosition: boolean;
}

export function ColorPickerComponent({
  id,
  value,
  onColorChange,
  fixPosition }: ColorPickerProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const [latestColors, setLatestColors] = useState<string[]>([]);

  const loadLatestColors = () => {
    const colorsFromLocalStorage = LocalStorage.loadItem<string[]>("color-picker-last-colors", id);
    if (colorsFromLocalStorage === undefined) {
      return;
    }
    setLatestColors(colorsFromLocalStorage);
  }

  const saveLatestColors = () => {

    if (latestColors.length === 0) {
      return;
    }

    LocalStorage.saveItem<string[]>("color-picker-last-colors", id, latestColors);
  }

  useEffect(() => {
    loadLatestColors();
  }, []);

  useEffect(() => {
    saveLatestColors();
  }, [latestColors]);

  const updateLatestColors = useCallback(
    debounce((color: string) => {
      setLatestColors((prevColors) => {
        if (!prevColors.includes(color)) {
          const newColors = [color, ...prevColors];
          if (newColors.length > 5) {
            newColors.pop();
          }
          return newColors;
        }
        return prevColors;
      });
    }, 1500),
    []
  );

  const fixPositionStyle = fixPosition ? {
    transform: "translateX(calc(var(--size-2) * -1))"
  } : {};

  return <>
    <div className="color-picker-component rounded"
      style={fixPositionStyle}>
      <input
        type="color"
        onChange={(event) => {
          const color = event.target.value;
          onColorChange(color);
          updateLatestColors(color);
        }}
        value={value}
        style={{ marginLeft: '8px' }}
        aria-label={t('pickTextColor')}
      />

      {latestColors.length > 0 &&
        <>
          <VerticalLineComponent />

          <div className="color-picker-component-latest">
            {latestColors.map((color, index) => {
              return <div
                key={index}
                className="color-picker-component-latest-color"
                style={{ backgroundColor: color }}
                onClick={() => onColorChange(color)}
                aria-label={`${t('recentColor')}: ${color}`}
              ></div>;
            })}
          </div>
        </>
      }
    </div>
  </>
}