import { DeleteUserFileRouteDef } from "../../common-app/userFiles/actions/deleteUserFile";
import { GetUserImagesInfoRequestBody, GetUserImagesInfoResponse, GetUserImagesInfoRouteDef } from "../../common-app/userFiles/actions/getUserImagesInfo";
import { GetUserImagesListResponse, GetUserImagesListRouteDef } from "../../common-app/userFiles/actions/getUserImagesList";
import { UploadUserFileFields, UploadUserFileRouteDef } from "../../common-app/userFiles/actions/uploadUserFile";
import { UserFileImage } from "../../common-app/userFiles/models/userFileImage";
import { ApiService } from "../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../common-frontend/services/api/backendServerEndpointProvider";
import { RequestHeaderHelper } from "../requestHeaderData/requestHeaderHelper";

export class ServiceApiUserFiles extends ApiService {

  static async uploadUserFile(file: File, fileName: string, fileId: string, siteId: string): Promise<boolean> {

    const routeDef = new UploadUserFileRouteDef();
    const formData = new FormData();
    formData.append(UploadUserFileFields.file, file);
    formData.append(UploadUserFileFields.fileName, fileName);
    formData.append(UploadUserFileFields.fileId, fileId);
    formData.append(UploadUserFileFields.siteId, siteId);

    const requestInit = this.getRequestOptionsForFormData(routeDef, formData);

    return this.DoRequest(
      routeDef.route(),
      requestInit,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );
  }

  static async getUserImagesList(siteId: string): Promise<UserFileImage[]> {

    const routeDef = new GetUserImagesListRouteDef();
    const requestOptions = this.getGetRequestOptions();

    const response = await this.DoRequestWithResponse<GetUserImagesListResponse>(
      routeDef.route(siteId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (!response) {
      return [];
    }

    return response.images;
  }

  static async getUserImagesInfo(siteId: string, imageIds: string[]): Promise<UserFileImage[]> {

    const routeDef = new GetUserImagesInfoRouteDef();
    const requestBody = new GetUserImagesInfoRequestBody(siteId, imageIds);

    const requestOptions = this.getRequestOptions(routeDef, requestBody);

    const response = await this.DoRequestWithResponse<GetUserImagesInfoResponse>(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );

    if (!response) {
      return [];
    }

    return response.images;
  }

  static async deleteUserFile(siteId: string, fileId: string): Promise<boolean> {

    const routeDef = new DeleteUserFileRouteDef();
    const requestOptions = this.getDeleteRequestOptions();
    const response = await this.DoRequest(
      routeDef.route(fileId),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataSiteOwnership(siteId)
    );

    return response;
  }
}