import { Link } from "react-router-dom";
import { useTranslationCommonFrontEnd } from "../../../../../translations/translation";
import {
  UncategorizedCategoryName,
  UncategorizedLink
} from "../../data/uncategorized";

import './frontPageCategory.css';

interface FrontPageCategoryProps {
  categoryName: string;
}

export function FrontPageCategoryComponent({
  categoryName
}: FrontPageCategoryProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const categoryNameDisplay = categoryName === "" ? t(UncategorizedCategoryName) : categoryName;
  const categoryNameLink = categoryName === "" ? UncategorizedLink : categoryName;

  return (
    <Link
      to={`?categ=${categoryNameLink}`}
      className={"no-decor"}
    >
      <div className="front-page-category">
        <div className="name">
          {categoryNameDisplay}
        </div>
      </div>
    </Link>
  );
}