import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { UserFileImage } from "../models/userFileImage";

export class GetUserImagesInfoRouteDef extends IRouteDefinition {
  constructor() {
    super("POST");
  }

  public route(): string {
    return 'user-files/get-user-images-info';
  }

  public routeTemplate(): string {
    return 'user-files/get-user-images-info';
  }
}

export class GetUserImagesInfoRequestBody extends RequestBody {
  constructor(
    public siteId: string,
    public imageIds: string[]
  ) {
    super();
  }
}

export class GetUserImagesInfoResponse extends RequestResponse {
  constructor(
    public images: UserFileImage[]
  ) {
    super();
  }
}
