import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AppComponent } from './app/App';
import './i18n';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/output/theme-colors-bootstrap.css';

polyfillCountryFlagEmojis();

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <AppComponent />
    </React.StrictMode>
);

