import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { Product } from "../models/product";

export class GetProductRouteDef extends IRouteDefinition {
  constructor() {
    super('GET');
  }

  public route(pageId: string, productId: string): string {
    return `site-pages-store/product?` + QueryParamsUtil.build({ "page-id": pageId, "product-id": productId });
  }

  public routeTemplate(): string {
    return 'site-pages-store/product';
  }
}

export const enum GetProductQueryParams {
  pageId = 'page-id',
  productId = 'product-id'
}

export class GetProductResponse extends RequestResponse {
  constructor(public product: Product | undefined) {
    super();
  }
}