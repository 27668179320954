import { LocalStorage } from "../../../common-frontend/library/localStorage/localStorage";

export class LocalStorageAnalytics {

    private static readonly analyticsGroup = 'analytics';
    private static readonly deviceIDField = 'deviceId';

    public static saveDeviceId(deviceId: string) {
        LocalStorage.saveItem(this.analyticsGroup, this.deviceIDField, deviceId);
    }

    public static getDeviceId(): string | undefined {
        return LocalStorage.loadItem(this.analyticsGroup, this.deviceIDField);
    }
}