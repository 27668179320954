import { useEffect, useRef, useState } from 'react';
import { ServiceApiSites } from '../../../../services/api/serviceApiSites';
import { SitePostCreationPopupComponent } from './sitePostCreationPopup';

interface SitePostCreationPopupControllerProps {
  siteId: string;
}

export function SitePostCreationPopupControllerComponent({ siteId }: SitePostCreationPopupControllerProps): JSX.Element {

  const [showPopup, setShowPopup] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {

    const determineIfPopupNeedsToBeShown = async () => {

      const popupNeedsShowing = await ServiceApiSites.getSiteCreationPopupNeedsShowing(siteId);

      if (popupNeedsShowing) {

        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
          setShowPopup(true);
          ServiceApiSites.updateSiteCreationPopupNeedsShowing(siteId, false);
        }, 1500);
      }
    }

    determineIfPopupNeedsToBeShown();

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };

  }, [siteId]);

  return (
    <div>
      {showPopup &&
        <SitePostCreationPopupComponent
          show={showPopup}
          onClose={() => setShowPopup(false)} />
      }
    </div>
  );
}
