import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";

export class DeleteSiteRouteDef extends IRouteDefinition {

  constructor() {
    super("DELETE");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(siteId: string): string {
    return `sites/delete-site?` + QueryParamsUtil.build({ "site-id": siteId })
  }

  public routeTemplate(): string {
    return 'sites/delete-site';
  }
}

export const enum DeleteSiteQueryParams {
  siteId = 'site-id',
}

