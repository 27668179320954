import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SitePageSelect } from "../../models/sitePageSelect";

export class GetSitePagesAvailableForHomePageRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(siteId: string): string {
    return `sites/get-site-pages-available-for-home-page?` + QueryParamsUtil.build({
      "site-id": siteId
    });
  }

  public routeTemplate(): string {
    return 'sites/get-site-pages-available-for-home-page';
  }
}

export const enum GetSitePagesAvailableForHomePageQueryParams {
  siteId = 'site-id',
}

export class GetSitePagesAvailableForHomePageResponse extends RequestResponse {
  constructor(
    public pages: SitePageSelect[]
  ) {
    super();
  }
}
