import { useContext } from "react";
import { RichTextEditorDataContext } from "../richTextEditorDataContext";

export const useRichTextEditorDataContext = () => {

  const context = useContext(RichTextEditorDataContext);
  if (!context) {
    throw new Error("useRichTextEditorDataContext must be used within a RichTextEditorDataProvider");
  }
  return context;
}