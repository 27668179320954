import { BrowserRouter as Router } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import { MainLayout } from './layout/mainLayout';
import { PageContact } from '../pages/pageContact';
import { PageNotFound } from '../pages/pageNotFound';
import { PageHome } from '../pages/home/pageHome';
import { PageSignIn } from '../pages/user/signIn/pageSignIn';
import { PageSignUp } from '../pages/user/signUp/pageSignUp';
import { PageUserSites } from '../pages/user/userSites/userSites';
import { SitesLayout } from './layout/sitesLayout';
import { DynamicSiteRenderer } from '../pages/sites/dynamicSiteRenderer';
import { PageRouteBuilder } from '../pages/debug/pageRouteBuilder';
import { PageServices } from '../pages/services/pageServices';
import { Page1Click } from '../pages/1click/page1Click';

export function AppRouter(): JSX.Element {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<MainLayout />} >
          <Route index element={<PageHome />} />
          <Route path="contacts" Component={PageContact} />
          <Route path="signin" Component={PageSignIn} />
          <Route path="signup" Component={PageSignUp} />
          <Route path="user-sites" Component={PageUserSites} />
          <Route path="services" Component={PageServices} />
          <Route path="1-click" Component={Page1Click} />

          <Route path="sites" element={<SitesLayout />}>
            <Route path="*" Component={DynamicSiteRenderer} />
          </Route>

          {/* Debug Pages */}
          <Route path="8d890a39-003e-48ed-b395-9fc43c075351" Component={PageRouteBuilder} />

          <Route path="*" Component={PageNotFound} />
        </Route>
      </Routes>
    </Router>
  );
}
