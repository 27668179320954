import { useEffect, useState } from "react";
import { Dropdown, Form } from "react-bootstrap";

import "./editableComboBox.css";

interface EditableComboBoxProps {
  size: "sm" | "lg" | undefined;
  options: string[];
  value: string;
  onChange: (value: string) => void;
}

export function EditableComboBoxComponent({
  size,
  options,
  value,
  onChange,
}: EditableComboBoxProps): JSX.Element {

  const [showDropdown, setShowDropdown] = useState(false);

  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(value.toLowerCase())
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
    setShowDropdown(true);
  };

  const handleSelect = (option: string) => {
    onChange(option);
    setShowDropdown(false);
  };

  return (
    <div
      className={"editable-combo-box-component"}
    >
      <Form.Control
        type="text"
        value={value}
        size={size}
        onChange={handleInputChange}
        onFocus={() => setShowDropdown(true)}
        onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
      />
      {showDropdown && filteredOptions.length > 0 && (
        <Dropdown.Menu
          className={"dropdown"}
          show>
          {filteredOptions.map((option, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleSelect(option)}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </div>
  );
}