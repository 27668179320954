import { ArrayUtil } from "../../../common/util/array/arrayUtil";
import { MediaGalleryItem } from "../../common/models/mediaGalleryItem";

export class Product {

  id: string = '';
  pageId: string = '';
  name: string = "";
  description: string = "";
  price: number = 0;
  category: string = "";
  available: boolean = false;
  visible: boolean = false;
  extraInfo: string = "";
  galleryItems: MediaGalleryItem[] = [];

  public static isEqual(a: Product, b: Product): boolean {
    return (
      a.id === b.id &&
      a.pageId === b.pageId &&
      a.name === b.name &&
      a.description === b.description &&
      a.price === b.price &&
      a.category === b.category &&
      a.available === b.available &&
      a.visible === b.visible &&
      a.extraInfo === b.extraInfo &&
      ArrayUtil.containSameData(a.galleryItems, b.galleryItems, (a, b) =>
        MediaGalleryItem.isEqual(a, b)
      )
    );
  }
}
