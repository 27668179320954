import { useTranslation } from 'react-i18next';
import { SectionTitleComponent } from '../../../common-frontend/components/sectionTitle/sectionTitle';

import './whyUs.css';

export function WhyUsComponent(): JSX.Element {

  const { t } = useTranslation();

  return (
    <div className="why-us-component">

      <SectionTitleComponent>
        {t('whyChooseUs')}
      </SectionTitleComponent>

      <div className='content'>

        <div className='content-item'>
          <div className='content-item-title'>
            {t('availability')}
          </div>
          <div className='content-item-body'>
            {t('alwaysAvailable')}
            {t('neverWithoutSupport')}
          </div>
        </div>

        <div className='content-item'>
          <div className='content-item-title'>
            {t('expectations')}
          </div>
          <div className='content-item-body'>
            {t('dontFeelLost')}
          </div>
        </div>

        <div className='content-item'>
          <div className='content-item-title'>
            {t('empathy')}
          </div>
          <div className='content-item-body'>
            {t('focusOnBusiness')}
          </div>
        </div>

        <div className='content-item'>
          <div className='content-item-title'>
            {t('price')}
          </div>
          <div className='content-item-body'>
            {t('clearAndTransparentCosts')}
          </div>
        </div>

        <div className='content-item'>
          <div className='content-item-title'>
            {t('support')}
          </div>
          <div className='content-item-body'>
            {t('teamHelpingPeople')}
          </div>
        </div>

        <div className='content-item'>
          <div className='content-item-title'>
            {t('principles')}
          </div>
          <div className='content-item-body'>
            {t('honestyAndTransparency')}
          </div>
        </div>
      </div>
    </div>
  );
}
