import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";

export class SetDisplayOrderRouteDef extends IRouteDefinition {
  constructor() {
    super('POST');

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(): string {
    return 'site-pages-contacts/set-display-order';
  }

  public routeTemplate(): string {
    return 'site-pages-contacts/set-display-order';
  }
}

export class SetDisplayOrderRequestBody extends RequestBody {
  constructor(
    public pageId: string,
    public contactIds: string[]
  ) {
    super();
  }
}
