
export class LocalStorage {

    public static saveItem<T>(group: string, key: string, value: T) {
        localStorage.setItem(this.buildStorageKey(group, key), JSON.stringify(value));
    }

    public static loadItem<T>(group: string, key: string): T | undefined {
        const dataValue = localStorage.getItem(this.buildStorageKey(group, key));
        if (dataValue === null) {
            return undefined;
        }
        return JSON.parse(dataValue);
    }

    public static deleteItem(group: string, key: string) {
        localStorage.removeItem(this.buildStorageKey(group, key));
    }

    private static buildStorageKey(group: string, key: string) {
        if (group === "" || key === "") {
            throw new Error("Both 'group' and 'key' must be non-empty strings.");
        }
        return `${group}/${key}`;
    }
}
