import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";

export class CreateRouteLogRouteDef extends IRouteDefinition {

    constructor() {
        super("POST");
    }

    public route(): string {
        return "analytics/create-route-log";
    }

    public routeTemplate(): string {
        return "analytics/create-route-log";
    }
}

export class CreateRouteLogRequestBody extends RequestBody {
    constructor(
        public id: string,
        public deviceID: string,
        public route: string,
        public when: Date
    ) {
        super();
    }
}
