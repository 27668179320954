import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { UserFileImage } from "../models/userFileImage";

export class GetUserImagesListRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(siteId: string): string {
    return `user-files/get-user-images-list?` + QueryParamsUtil.build({ "site-id": siteId });
  }

  public routeTemplate(): string {
    return 'user-files/get-user-images-list';
  }
}

export const enum GetUserImagesListQueryParams {
  siteId = 'site-id',
}

export class GetUserImagesListResponse extends RequestResponse {
  constructor(
    public images: UserFileImage[]
  ) {
    super();
  }
}
