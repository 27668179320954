import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";

export class GetSitePageNameRouteDef extends IRouteDefinition {

  constructor() {
    super('GET');
  }

  public route(siteId: string, pageId: string): string {
    return `sites/get-site-page-name?` + QueryParamsUtil.build({ 
      "site-id": siteId,
      "page-id": pageId 
    });
  }

  public routeTemplate(): string {
    return 'sites/get-site-page-name';
  }
}

export const enum GetSitePageNameQueryParams {
  siteId = 'site-id',
  pageId = 'page-id',
}

export class GetSitePageNameResponse extends RequestResponse {
  constructor(
    public pageName: string
  ) {
    super();
  }
}
