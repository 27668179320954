import { useLocation } from "react-router-dom";

export function useQueryParam(param: string, defaultValue?: string): string | undefined {

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const value = params.get(param);
  if (value === null) {
    return defaultValue;
  }

  return value;
}