import { useState } from "react";
import { Button } from "react-bootstrap";
import { GuidUtil } from "../../../../../common/util/guid/guid";
import { useTranslationCommonFrontEnd } from "../../../../translations/translation";
import { useDynamicPageDataContext } from "../../../dynamicPages/provider/hooks/useDynamicPageDataContext";
import { FormEditComponent } from "../../../form/formEdit/formEdit";
import { FormTextAreaComponent } from "../../../form/formTextArea/formTextArea";
import { VerticalListComponent } from "../../../verticalList/verticalList";
import { useRichTextEditorDataContext } from "../../contexts/dataContext/hooks/useRichTextEditorDataContext";
import { useRichTextEditorOperationsContext } from "../../contexts/operationsContext/hooks/useRichTextEditorOperationsContext";
import { CustomElementUserQuery } from "../../types";
import { RenderComponentProps } from "../renderComponentProps";

import './renderUserQuery.css';

export function RenderUserQueryComponent({
  element,
  attributes,
  children
}: RenderComponentProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const userQueryElement = element as CustomElementUserQuery;

  const operationsContext = useRichTextEditorOperationsContext();
  const dynamicPageContext = useDynamicPageDataContext();
  const dataContext = useRichTextEditorDataContext();

  const [contact, setContact] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);

  const validDataToSubmit = (contact !== "") || (subject !== "") || (message !== "");
  const submitEnabled = !submitted && validDataToSubmit;

  const handleSubmit = () => {
    if (submitEnabled === false) {
      return;
    }

    dataContext.insertUserQuery(
      dynamicPageContext.siteId,
      dynamicPageContext.pageId,
      userQueryElement.userQueryId,
      GuidUtil.GenerateNewGuid(),
      contact,
      subject,
      message);

    setSubmitted(true);
  }

  return (
    <div {...attributes} className="render-user-query-component">
      <div contentEditable={false} className="user-query-parent">
        <div className="user-query-title">
          {userQueryElement.name}
        </div>
        <VerticalListComponent>
          <div>
            {t('phoneOrMail')}
            <FormEditComponent
              type="text"
              readonly={operationsContext.editing || submitted}
              value={contact}
              onChange={setContact} />
          </div>

          <div>
            {t('subject')}
            <FormEditComponent
              type="text"
              readonly={operationsContext.editing || submitted}
              value={subject}
              onChange={setSubject}
            />
          </div>

          <div>
            {t('message')}
            <FormTextAreaComponent
              size="sm"
              lines={5}
              readonly={operationsContext.editing || submitted}
              value={message}
              onChange={setMessage} />
          </div>

          <Button
            onClick={handleSubmit}
            disabled={!submitEnabled}
          >
            {submitted ? t('thanksForMessage') : t('send')}
          </Button>
        </VerticalListComponent>
      </div>
      {children}
    </div>
  );
}