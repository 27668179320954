import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { Contact } from "../models/contact";

export class GetContactsRouteDef extends IRouteDefinition {
  constructor() {
    super('GET');
  }

  public route(pageId: string): string {
    return `site-pages-contacts/contacts?` + QueryParamsUtil.build({ "page-id": pageId });
  }

  public routeTemplate(): string {
    return 'site-pages-contacts/contacts';
  }
}

export const enum GetContactsQueryParams {
  pageId = 'page-id',
}

export class GetContactsResponse extends RequestResponse {
  constructor(public contacts: Contact[]) {
    super();
  }
}