import { DynamicPageStoreProvider } from './provider/dynamicPageStoreProvider';
import { useLocation } from 'react-router-dom';
import { DynamicPageStoreCartComponent } from './dynamicPageStoreCart';
import { DynamicPageStoreCategoryComponent } from './dynamicPageStoreCategory';
import { DynamicPageStoreItemComponent } from './dynamicPageStoreItem';
import { DynamicPageStoreCheckoutComponent } from './dynamicPageStoreCheckout';
import { DynamicPageStoreFrontComponent } from './dynamicPageStoreFront';
import { DynamicPageStoreSearchComponent } from './dynamicPageStoreSearch';

import './dynamicPageStore.css'

interface DynamicPageStoreProps {
  dataProvider: DynamicPageStoreProvider;
}

export function DynamicPageStoreComponent({
  dataProvider
}: DynamicPageStoreProps): JSX.Element {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const getPageToRender = (): JSX.Element => {
    if (searchParams.has('categ')) {
      const category = searchParams.get('categ');
      if (category && category.length > 0) {
        return <DynamicPageStoreCategoryComponent
          category={category}
          dataProvider={dataProvider}
        />;
      }
    }

    if (searchParams.has('item')) {
      const item = searchParams.get('item');
      if (item && item.length > 0) {
        return <DynamicPageStoreItemComponent
          item={item}
          dataProvider={dataProvider}
        />;
      }
    }

    if (searchParams.has('search')) {
      const searchKeyword = searchParams.get('search');

      return <DynamicPageStoreSearchComponent
        dataProvider={dataProvider}
        initialKeyword={searchKeyword || ""}
      />;
    }

    if (searchParams.has('cart')) {
      return <DynamicPageStoreCartComponent
        dataProvider={dataProvider}
      />;
    }

    if (searchParams.has('checkout')) {
      return <DynamicPageStoreCheckoutComponent
        dataProvider={dataProvider}
      />;
    }

    return <DynamicPageStoreFrontComponent
      dataProvider={dataProvider}
    />;
  }

  return <div className="store">
    {getPageToRender()}
  </div>
}