import { createContext } from 'react';

export interface UserSessionContextType {
  userSessionId: string;
  userId: string;

  setSessionInformation: (sessionId: string, id: string) => void;
  clearSessionInformation: () => void;
}

const UserSessionContext = createContext<UserSessionContextType | undefined>(undefined);

export { UserSessionContext };