import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { BlogPost } from "../models/blogPost";

export class GetAdjacentBlogPostsRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(pageId: string, postId: string): string {
    return `blogs/get-adjacent-blog-posts?` +
      QueryParamsUtil.build(
        {
          "page-id": pageId,
          "post-id": postId
        });
  }

  public routeTemplate(): string {
    return 'blogs/get-adjacent-blog-posts';
  }
}

export const enum GetAdjacentBlogPostsQueryParams {
  pageId = 'page-id',
  postId = 'post-id',
}

export class GetAdjacentBlogPostsResponse extends RequestResponse {
  constructor(
    public newer: BlogPost | undefined,
    public older: BlogPost | undefined
  ) {
    super();
  }
}
