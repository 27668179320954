import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SiteOwnership } from "../../../../../common-app/sites/models/siteOwnership/siteOwnership";
import { AlertInlineComponent } from "../../../../../common-frontend/components/alertInline/alertInline";
import { ContainerLabeledComponent } from "../../../../../common-frontend/components/containerLabeled/containerLabeled";
import { HorizontalListComponent } from "../../../../../common-frontend/components/horizontalList/horizontalList";
import { VerticalListComponent } from "../../../../../common-frontend/components/verticalList/verticalList";
import { DateUtil } from "../../../../../common/util/date/dateUtil";
import { ServiceApiSites } from "../../../../../services/api/serviceApiSites";
import { SiteSettingsOwnershipActionsComponent } from "./siteSettingsOwnershipActions";

import './siteSettingsOwnership.css';

interface SiteSettingsOwnershipProps {
  siteId: string;
}

export function SiteSettingsOwnershipComponent({ siteId }: SiteSettingsOwnershipProps): JSX.Element {

  const { t } = useTranslation();
  const [siteOwnershipData, setSiteOwnershipData] = useState<SiteOwnership | undefined>(undefined);

  useEffect(() => {

    const loadSiteOwnership = async () => {

      const siteOwnership = await ServiceApiSites.getSiteOwnership(siteId);
      setSiteOwnershipData(siteOwnership);
    }

    loadSiteOwnership();

  }, [siteId]);

  return (
    <ContainerLabeledComponent
      label={t('ownership')}
      uniqueId={`site-ownership-${siteId}`}
    >
      <div className="site-settings-ownership-component">
        {siteOwnershipData &&
          <VerticalListComponent>
            {/* Creation Date */}
            <HorizontalListComponent>
              <div className="label">
                {t('creationDate')}
              </div>
              <div>
                {DateUtil.formatDateAsHumanReadable(siteOwnershipData.creationDate)}
              </div>
            </HorizontalListComponent>

            {/* State */}
            <HorizontalListComponent>
              <div className="label">
                {t('state')}
              </div>

              {siteOwnershipData.isClaimed &&
                <AlertInlineComponent variant="success">
                  <div>
                    {t('associatedWithAccount')}
                  </div>
                </AlertInlineComponent>
              }

              {siteOwnershipData.isClaimed === false &&
                <AlertInlineComponent variant="warning">
                  <div>
                    {t('userAccountRequired')}
                  </div>
                </AlertInlineComponent>
              }

            </HorizontalListComponent>

            {/* Planned date for removal */}
            {siteOwnershipData.plannedRemovalDate !== undefined &&
              <>
                {/* Claim actions */}
                <HorizontalListComponent>
                  <div className="label" />
                  <SiteSettingsOwnershipActionsComponent
                    siteId={siteId}
                  />
                </HorizontalListComponent>
              </>
            }
          </VerticalListComponent>
        }
      </div>
    </ContainerLabeledComponent>
  )
}