import Form from 'react-bootstrap/Form';

interface FormEditProps {
  type: "text" | "email";
  value: string | undefined;
  placeHolder?: string;
  size?: "sm" | "lg";
  readonly?: boolean;
  onChange: (value: string) => void;
  id?: string;
  className?: string;
}

export function FormEditComponent({
  type,
  value,
  placeHolder = "",
  size = "sm",
  readonly = false,
  onChange,
  id,
  className
}: FormEditProps): JSX.Element {

  return (
    <Form.Control
      className={className}
      id={id}
      size={size}
      type={type}
      readOnly={readonly}
      value={value}
      placeholder={placeHolder}
      onChange={(e) => { onChange(e.target.value); }}
    />
  );
}
