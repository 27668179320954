import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslationCommonFrontEnd } from '../../translations/translation';

interface QuestionDialogProps {
  title: string;
  question: string;
  labelPositive: string;
  positiveIsDanger?: boolean;
  onPositive: () => void;
  onNegative: () => void;
  showDialog: boolean;
}

export function QuestionDialogComponent({
  title,
  question,
  labelPositive,
  positiveIsDanger,
  onPositive,
  onNegative,
  showDialog
}: QuestionDialogProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  return (
    <Modal
      onHide={onNegative}
      show={showDialog}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {question}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary"
          onClick={onNegative}>{t('close')}</Button>

        <Button variant={positiveIsDanger ? "danger" : "primary"}
          onClick={onPositive}>{labelPositive}</Button>
      </Modal.Footer>
    </Modal>
  );
}
