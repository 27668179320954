import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { CreateUserResult } from "../enums/createUserResult";

export class CreateUserRouteDef extends IRouteDefinition {
  constructor() {
    super("POST");
  }

  public route(): string {
    return "system/create-user";
  }

  public routeTemplate(): string {
    return "system/create-user";
  }
}

export class CreateUserRequestBody extends RequestBody {
  constructor(
    public id: string,
    public mail: string,
    public password: string
  ) {
    super();
  }
}

export class CreateUserResponse extends RequestResponse {
  constructor(
    public result: CreateUserResult
  ) {
    super();
  }
}
