import { RichTextEditorOperationsContext } from "./richTextEditorOperationsContext";
import { CustomElement } from "../../types";

interface RichTextEditorOperationsProviderProps {
  editing: boolean;
  children: React.ReactNode;

  deleteElementFunction: (element: CustomElement) => void;
  insertEmptyElementFunction: (element: CustomElement, location: "before" | "after") => void;
  configureElementFunction: (element: CustomElement) => void;
}

export function RichTextEditorOperationsProvider(
  {
    editing,
    children,
    deleteElementFunction,
    insertEmptyElementFunction,
    configureElementFunction
  }: RichTextEditorOperationsProviderProps): JSX.Element {

  return (
    <RichTextEditorOperationsContext.Provider value={{
      editing,
      deleteElementFunction,
      insertEmptyElementFunction,
      configureElementFunction
    }}>
      {children}
    </RichTextEditorOperationsContext.Provider>
  );
}

