import { useMemo } from "react";
import { SitePageRenderInfo } from "../../../../../common-app/sites/models/sitePageRenderInfo";
import { DynamicPageStoreServiceProvider } from "./dynamicPageStoreServiceProvider";
import { DynamicPageStoreComponent } from "../../../../../common-frontend/components/dynamicPages/store/dynamicPageStore";
import { useDynamicPageDataContext } from "../../../../../common-frontend/components/dynamicPages/provider/hooks/useDynamicPageDataContext";

interface DynamicPageStoreWrapperProps {
  pageRenderInfo: SitePageRenderInfo;
}

export function DynamicPageStoreWrapperComponent({
  pageRenderInfo: pagePresentationInfo
}: DynamicPageStoreWrapperProps): JSX.Element {

  const dynamicPageContext = useDynamicPageDataContext();

  const dataProvider = useMemo(() => {
    return new DynamicPageStoreServiceProvider(
      dynamicPageContext.siteId,
      dynamicPageContext.pageId)
  }, [
    dynamicPageContext.siteId,
    dynamicPageContext.pageId
  ]);

  return (
    <div>
      <DynamicPageStoreComponent
        dataProvider={dataProvider}
      />
    </div>
  );
}