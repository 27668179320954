import { RouteDefinitionPermissions } from "./routeDefinitionPermissions";

export type RouteDefinitionType = "GET" | "POST" | "PUT" | "DELETE";

export class IRouteDefinition {

  constructor(
    type: RouteDefinitionType,
  ) {
    this.type = type;
  }

  public routeTemplate(): string {
    return "";
  }

  public type: RouteDefinitionType;
  public permissions: RouteDefinitionPermissions | undefined = undefined;
}