import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslationCommonFrontEnd } from "../../../../../translations/translation";
import { FormEditComponent } from "../../../../form/formEdit/formEdit";
import { VerticalListComponent } from "../../../../verticalList/verticalList";

import './frontPageSearch.css';

interface SearchStoreFrontProps {
  keyword: string;
  onSubmit: (keyword: string) => void;
}
export function SearchStoreFrontComponent({
  keyword,
  onSubmit
}: SearchStoreFrontProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const [searchTerm, setSearchTerm] = useState<string>(keyword);

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(searchTerm);
  }

  return (
    <div className="search-store-front-component">
      <Form
        onSubmit={handleSearchSubmit}
      >
        <div className="group-label">
          {t('searchProducts')}
        </div>

        <VerticalListComponent>
          <FormEditComponent
            type="text"
            value={searchTerm}
            onChange={setSearchTerm}
          />
          <Button
            type="submit"
            disabled={searchTerm.length === 0}>
            {t('search')}
          </Button>
        </VerticalListComponent>
      </Form>
    </div>
  )
}