import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useTranslationCommonFrontEnd } from "../../../translations/translation";
import { ErrorListComponent } from "../../errorList/errorList";
import { FormCellComponent } from "../../form/formCell";
import { FormEditComponent } from "../../form/formEdit/formEdit";
import { FormTextAreaComponent } from "../../form/formTextArea/formTextArea";
import { VerticalListComponent } from "../../verticalList/verticalList";
import { VerticalWhiteSpaceComponent } from "../../verticalWhiteSpace/verticalWhiteSpace";
import { useDynamicPageDataContext } from "../provider/hooks/useDynamicPageDataContext";
import { CheckoutCartSummaryComponent } from "./components/checkoutCartSummary/checkoutCartSummary";
import { DynamicPageStoreProvider } from "./provider/dynamicPageStoreProvider";

interface DynamicPageStoreCheckoutProps {
  dataProvider: DynamicPageStoreProvider;
}

export function DynamicPageStoreCheckoutComponent({
  dataProvider
}: DynamicPageStoreCheckoutProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const dynamicPageContext = useDynamicPageDataContext();

  const [name, setName] = useState<string>("");
  const [mail, setMail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [address, setAddress] = useState<string>("");

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const sendOrder = async (): Promise<boolean> => {

    return dataProvider.finalizeOrder(
      name,
      mail,
      phone,
      address
    );
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const errors: string[] = [];

    if (name.length === 0) {
      errors.push(t('nameRequired'));
    }
    if (mail.length === 0) {
      errors.push(t('emailRequired'));
    }
    if (phone.length === 0) {
      errors.push(t('phoneRequired'));
    }

    setErrorMessages(errors);

    if (errors.length > 0) {
      return;
    }

    setSubmitting(true);
    if (await sendOrder() === true) {
      setSubmitting(false);
      setSubmitted(true);

      dataProvider.clearCart();
    }
  }

  return (
    <div>
      <div className="group-label">
        {t('orderCompletion')}
      </div>

      <div>
        <Form onSubmit={handleSubmit}>

          <FormCellComponent size="Large">
            <Form.Group
              className="mb-3"
              controlId='contact-name'>
              <Form.Label>{t('name')}</Form.Label>
              <FormEditComponent
                type="text"
                value={name}
                onChange={setName}
                readonly={submitted || submitting}
              />
            </Form.Group>
          </FormCellComponent>

          <FormCellComponent size="Large">
            <Form.Group
              className="mb-3"
              controlId='contact-mail-01'>
              <Form.Label>{t('email')}</Form.Label>
              <FormEditComponent
                type="email"
                value={mail}
                onChange={setMail}
                readonly={submitted || submitting}
              />
            </Form.Group>
          </FormCellComponent>

          <FormCellComponent size="Small">
            <Form.Group
              className="mb-3"
              controlId='contact-phone-01'>
              <Form.Label>{t('phone')}</Form.Label>
              <FormEditComponent
                type="text"
                value={phone}
                onChange={setPhone}
                readonly={submitted || submitting}
              />
            </Form.Group>
          </FormCellComponent>

          <FormCellComponent size="Large">
            <Form.Group
              className="mb-3"
              controlId='contact-address-line-01'>
              <Form.Label>{t('address')}</Form.Label>

              <FormTextAreaComponent
                size="sm"
                lines={4}
                value={address}
                onChange={setAddress}
                readonly={submitted || submitting}
              />
            </Form.Group>
          </FormCellComponent>

          <VerticalWhiteSpaceComponent amount={1} />

          {/* Order Summary */}
          {submitted === false &&
            <>
              <CheckoutCartSummaryComponent
                dataProvider={dataProvider}
              ></CheckoutCartSummaryComponent>

              <VerticalWhiteSpaceComponent amount={1} />
            </>
          }

          {/* Submission */}
          <VerticalListComponent>
            <div>
              {t('dataSentNotice')}
            </div>

            <Button
              type="submit"
              disabled={submitting || submitted}
            >
              {(!submitting && !submitted) && t('finalizeOrder')}
              {(submitting) && t('sending')}
              {(submitted) && t('orderSubmitted')}

            </Button>

            {/* Error List */}
            <ErrorListComponent errorMessages={errorMessages} />

          </VerticalListComponent>

          <VerticalWhiteSpaceComponent amount={3} />
        </Form>
      </div>
    </div>
  )
}