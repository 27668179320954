import { StoreOrderState } from "../enums/storeOrderState";
import { StoreOrderLine } from "./storeOrderLine";

export class StoreOrderAdmin {
  public id: string = "";
  public siteId: string = "";
  public pageId: string = "";
  public code: string = "";
  public buyerName: string = "";
  public buyerEmail: string = "";
  public buyerPhone: string = "";
  public buyerAddress: string = "";
  public orderTotal: number = 0;
  public state: StoreOrderState = StoreOrderState.Unknown;
  public creationDate: Date = new Date();
  public lines: StoreOrderLine[] = [];
}
