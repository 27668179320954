import { YoutubeVideo } from "../../../youtubeVideo/youtubeVideo";
import { CustomElementYoutubeVideo } from "../../types";
import { RenderComponentProps } from "../renderComponentProps";

import './renderYoutubeVideo.css';

export function RenderYoutubeVideoComponent({
  element,
  attributes,
  children
}: RenderComponentProps): JSX.Element {

  const youtubeVideoElement = element as CustomElementYoutubeVideo;

  return (
    <div
      className="render-youtube-video-component"
      contentEditable={false}
    >
      <YoutubeVideo videoId={youtubeVideoElement.videoId} />
      {
        youtubeVideoElement.caption.length > 0 &&
        <div
          className="render-youtube-video-component-caption">
          {youtubeVideoElement.caption}
        </div>
      }
      {children}
    </div>
  );
}