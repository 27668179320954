import { RouteBuilder } from "../util/routes/routeBuilder";
import { SitesUtil2 } from "./sitesUtil2";

export class SitesUtil {

  public static getUrlPartFromName(name: string, defaultName: string): string {

    let result = this.adjustUrlPartText(name);

    if (result.length == 0) {
      result = defaultName;
    }

    return result;
  }

  public static isSitePageValid(text: string): boolean {
    return this.isTextValidForUrlPart(text);
  }

  public static isSiteDomainValid(text: string): boolean {
    return this.isTextValidForUrlPart(text);
  }

  private static isTextValidForUrlPart(text: string): boolean {
    let adjustedText = this.adjustUrlPartText(text);

    if (adjustedText.length === 0) {
      return false;
    }
    return adjustedText === text;
  }

  private static adjustUrlPartText(text: string): string {

    // Make the string lowercase
    let result = text.toLowerCase();

    // Replace accents with non-accented letters
    result = result.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // Replace whitespace characters with a dash
    result = result.replace(/\s+/g, "-");

    // Delete any character that is not a letter, digit, or dash
    result = result.replace(/[^a-z0-9-]/g, "");

    return result;
  }

  public static getWebsiteUrlName(websiteName: string): string {
    // Trim name
    // Replace all spaces with dashes
    // Lowercase the name
    return websiteName.trim().replace(/ /g, "-").toLowerCase();
  }

  public static getWebsiteHomeUrl(siteDomain: string): string {
    return SitesUtil2.getSitesRouteSegment() + `${siteDomain}`;
  }

  public static getWebsiteAndPageFromRoute(route: string): {
    site: string;
    page: string;
  } {
    const relevantRoute = this.getRelevantRoute(route);
    const formattedRoute = this.formatRoute(relevantRoute);

    const [site, ...pageArr] = formattedRoute.split("/", 2);
    const page = pageArr.join("/");

    return {
      site: site,
      page: page,
    };
  }

  public static isSitesLocation(route: string) {
    return route.startsWith(SitesUtil2.getSitesRouteSegment());
  }

  public static getSiteAdminUrl(route: string): string {
    const { site } = this.getWebsiteAndPageFromRoute(route);
    return RouteBuilder.build(
      SitesUtil2.getSitesRouteSegment(),
      site,
      SitesUtil2.getSiteAdminPageName()
    );
  }



  public static isSiteAdminPage(pathname: string): boolean {
    const siteAdminUrl = this.getSiteAdminUrl(pathname);
    return pathname.startsWith(siteAdminUrl);
  }

  public static getHomePageAddress(location: string): string {
    const { site } = this.getWebsiteAndPageFromRoute(location);
    return SitesUtil2.getSitesRouteSegment() + site + "/";
  }

  private static getRelevantRoute(route: string): string {
    const sitesRoutes = SitesUtil2.getSitesRouteSegment();

    if (route.startsWith(sitesRoutes)) {
      return route.substring(sitesRoutes.length);
    }
    return route;
  }



  private static formatRoute(route: string): string {
    return route.endsWith("/") ? route : route + "/";
  }
}
