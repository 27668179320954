import { useEffect, useState } from "react";
import { useTranslationCommonFrontEnd } from "../../../../../translations/translation";
import { VerticalListComponent } from "../../../../verticalList/verticalList";
import { DynamicPageStoreProvider } from "../../provider/dynamicPageStoreProvider";
import { FrontPageCategoryComponent } from "./frontPageCategory";

import './frontPageCategories.css';

interface FrontPageCategoriesComponentProps {
  dataProvider: DynamicPageStoreProvider;
}

export function FrontPageCategoriesComponent({
  dataProvider
}: FrontPageCategoriesComponentProps): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    dataProvider.getCategories().then(categories => {
      setCategories(categories);
    });
  }, [dataProvider]);

  return <div className="front-page-categories-component">
    <div
      className="group-label"
    >
      {t('exploreCategories')}
    </div>

    <VerticalListComponent>
      <div
        className="categories-list"
      >
        {
          categories.map(category => {
            return (
              <div key={category}>
                <FrontPageCategoryComponent
                  categoryName={category}
                />
              </div>
            );
          })
        }
      </div>
    </VerticalListComponent>
  </div>
}