import { useContext } from "react";
import { RichTextEditorOperationsContext } from "../richTextEditorOperationsContext";

export const useRichTextEditorOperationsContext = () => {

  const context = useContext(RichTextEditorOperationsContext);
  if (!context) {
    throw new Error("useRichTextEditorOperationsContext must be used within a RichTextEditorRenderProvider");
  }
  return context;
};
