import './jumbotron.css';

interface JumbotronProps {
  text: string;
}

export function JumbotronComponent({ text }: JumbotronProps): JSX.Element {

  return <div className='jumbotron-component'>
    {text}
  </div>
}