import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { ProductAdminList } from "../models/productAdminList";

export class GetProductsAdminListRouteDef extends IRouteDefinition {
  constructor() {
    super('GET');
  }

  public route(pageId: string): string {
    return `site-pages-store/products-admin-list?` + QueryParamsUtil.build({ "page-id": pageId });
  }

  public routeTemplate(): string {
    return 'site-pages-store/products-admin-list';
  }
}

export const enum GetProductsAdminListQueryParams {
  pageId = 'page-id'
}

export class GetProductsAdminListResponse extends RequestResponse {
  constructor(public products: ProductAdminList[]) {
    super();
  }
}