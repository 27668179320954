import { GetImagesInfoFunction } from '../imageUploader/imageBrowser';
import { SortableList } from '../sortableList/sortableList';
import { useEffect, useState } from 'react';
import { UserFileImage } from '../../../common-app/userFiles/models/userFileImage';

import './imageSorter.css';

interface ImageSorterProps {
  getImagesInfoFunction: GetImagesInfoFunction;
  images: string[];
  setImages: (files: string[]) => void;
}

export function ImageSorterComponent({
  getImagesInfoFunction,
  images,
  setImages: setSelectedFiles
}: ImageSorterProps): JSX.Element {

  const [imagesInfo, setImagesInfo] = useState<UserFileImage[]>([]);

  useEffect(() => {

    const fetchImagesInfo = async () => {
      const imagesInfo = await getImagesInfoFunction(images);
      setImagesInfo(imagesInfo);
    }

    fetchImagesInfo();

  }, [images]);

  const renderList = (children: JSX.Element): JSX.Element => {
    return <div className='list'>
      {children}
    </div>
  }

  const renderElement = (item: string): JSX.Element => {

    // Get the image info
    const imageInfo = imagesInfo.find((image) => image.id === item);

    if (!imageInfo) {
      return <div className='image-sorter-image image-not-ready'>
        ...
      </div>
    }

    return <div className='image-sorter-image'>
      <img
        draggable={false}
        src={imageInfo.publicUrlThumbnail}
        alt={imageInfo.originalFileName}
      />
    </div>
  }

  return (
    <div className="image-sorter-component">
      <SortableList
        items={images}
        listId={'images'}
        listDirection={'horizontal'}
        onItemsReordered={setSelectedFiles}
        getItemKey={(item: string) => item}
        renderContainer={(children: JSX.Element) => {
          return renderList(children);
        }}
        renderElement={(item: string) => {
          return renderElement(item);
        }}
      />
    </div>
  );
}
