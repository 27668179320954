import { useMemo } from "react";
import { SitePageRenderInfo } from "../../../../../common-app/sites/models/sitePageRenderInfo";
import { DynamicPageStaticComponent } from "../../../../../common-frontend/components/dynamicPages/static/dynamicPageStatic";
import { DynamicPageStaticServiceProvider } from "./dynamicPageStaticServiceProvider";

interface DynamicPageStaticWrapperProps {
  pageRenderInfo: SitePageRenderInfo;
}

export function DynamicPageStaticWrapperComponent({
  pageRenderInfo
}: DynamicPageStaticWrapperProps): JSX.Element {

  const dataProvider = useMemo(() => new DynamicPageStaticServiceProvider(), []);

  return (
    <div>
      <DynamicPageStaticComponent
        pageRenderInfo={pageRenderInfo}
        dataProvider={dataProvider}
      />
    </div>
  );
}