import Form from 'react-bootstrap/Form';

interface FormSwitchProps {
  label: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export function FormSwitchComponent({
  label,
  value,
  onChange
}: FormSwitchProps): JSX.Element {
  return (
    <Form.Check
      type="switch"
      label={label}
      checked={value}
      onChange={(e) => {
        onChange(e.target.checked);
      }}
    />
  );
}
