
import { useEffect, useRef, useState } from "react";

const timeoutAutoSave = 1000;

export type SetErrorMessage = (message: string) => void;

export type AutoSaveDataParams = {
  isUnmounting: boolean;
  setMessage: SetErrorMessage;
}

type AutoSaveData<DataType> = (data: DataType, saveParams: AutoSaveDataParams) => boolean;

export function useAutoSave<DataType>(commitData: AutoSaveData<DataType>) {

  // Data to manage
  const dataRef = useRef<DataType>();

  // State to indicate if data has changed
  const hasChanges = useRef<boolean>(false);

  // Timer for auto-save
  const timerAutoSave = useRef<ReturnType<typeof setTimeout>>();

  // Error message that the client can send when saving data
  const [errorMessage, setErrorMessage] = useState<string>("");

  // State to trigger re-render
  const [i, setI] = useState<number>(0);

  const forceRerender = () => {
    setI((i) => i + 1);
  }

  const saveData = (isUnmounting: boolean): boolean => {

    // Call the method to save the data
    if (dataRef.current === undefined) {
      return false;
    }

    let setErrorMessageContext = setErrorMessage;
    if (isUnmounting === true) {
      setErrorMessageContext = () => { };
    }

    setErrorMessageContext("");

    if (commitData(dataRef.current, { setMessage: setErrorMessageContext, isUnmounting }) === true) {

      // Indicate that data has been saved
      hasChanges.current = false;

      return true;
    }

    if (isUnmounting === false) {
      // Update the state to trigger re-render
      forceRerender();
    }

    return false;
  }

  const haveChangesToSave = (): boolean => {
    return hasChanges.current;
  }

  useEffect(() => {

    return () => {

      stopTimer();

      if (dataRef.current === undefined) {
        return;
      }

      setErrorMessage("");
      saveData(true);
    }
  }, []);

  const startTimer = () => {

    timerAutoSave.current = setTimeout(() => {

      if (hasChanges.current === false) {
        return;
      }

      const saveOK = saveData(false);

      if (saveOK === false) {
        startTimer();
        forceRerender();
      }
    }, timeoutAutoSave);
  }

  const stopTimer = () => {

    if (timerAutoSave.current) {
      clearTimeout(timerAutoSave.current);
      timerAutoSave.current = undefined;
    }
  }

  const restartTimer = () => {
    stopTimer();
    startTimer();
  }

  const updateData = (data: DataType, initialUpdate: boolean = false) => {

    // Update data in memory
    dataRef.current = data;

    if (initialUpdate === true) {

      // Indicate that data has not changed
      hasChanges.current = false;
    }

    else {

      // Restart the timer
      restartTimer();

      // Indicate that data has changed
      hasChanges.current = true;
    }

    // Update the state to trigger re-render
    forceRerender();
  }

  return {
    saveData,
    haveChangesToSave,
    data: dataRef.current,
    updateData,
    errorMessage
  }
}

