import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ListItemProduct,
  ProductGroupRender,
  TreeStoreProductGroupComponent
} from './TreeStoreProductGroup';

import './TreeStoreProducts.css';

type ProductGroup = {
  category: string;
  expanded: boolean;
};

interface TreeStoreProductsProps {
  items: ListItemProduct[];
  selectedItemKey: string | undefined;
  onSelectedItemChanged: (itemKey: string) => void;
}

export function TreeStoreProductsComponents({
  items,
  selectedItemKey,
  onSelectedItemChanged
}: TreeStoreProductsProps): JSX.Element {

  const { t } = useTranslation();

  const NoCategoryLabel = t('noCategory');

  const [productCategoriesToRender, setProductCategoriesToRender] = useState<ProductGroup[]>([]);

  useEffect(() => {

    const uniqueCategories = [...new Set(items.map((item) => item.item.category))].
      sort((a, b) => a.localeCompare(b));

    const productCategories: ProductGroup[] = [];

    uniqueCategories.forEach((category) => {
      const categoryToDisplay = category === "" ? NoCategoryLabel : category;
      productCategories.push({
        category: categoryToDisplay,
        expanded: getCurrentExpandedState(category)
      });
    });

    // Force the group of the selected item to be expanded
    const selectedItem = items.find((item) => item.item.id === selectedItemKey);
    if (selectedItem) {
      const category = selectedItem.item.category === "" ? NoCategoryLabel : selectedItem.item.category;
      const productGroup = productCategories.find((productGroup) => productGroup.category === category);
      if (productGroup) {
        productGroup.expanded = true;
      }
    }

    setProductCategoriesToRender(productCategories);

  }, [items, selectedItemKey]);

  const getCurrentExpandedState = (category: string): boolean => {
    const productGroup = productCategoriesToRender.find((productGroup) => productGroup.category === category);
    return productGroup ? productGroup.expanded : false;
  }

  const handleToggleExpanded = (category: string) => {
    const newProductCategories = productCategoriesToRender.map((productGroupItem) => {
      if (productGroupItem.category === category) {
        return {
          ...productGroupItem,
          expanded: !productGroupItem.expanded
        };
      }
      return productGroupItem;
    });
    setProductCategoriesToRender(newProductCategories);
  }

  return (
    <div className="tree-store-products-component">
      <div>
        {
          productCategoriesToRender.map((productGroup) => {

            const categoryNameToSearch =
              productGroup.category === NoCategoryLabel ? "" : productGroup.category;

            const categoryGroup: ProductGroupRender =
            {
              categoryName: productGroup.category,
              expanded: productGroup.expanded,
              items: items.filter(
                (item) => item.item.category === categoryNameToSearch)
            }

            return (
              <div
                key={productGroup.category}
              >
                <TreeStoreProductGroupComponent
                  group={categoryGroup}
                  onToggleExpanded={() => { handleToggleExpanded(productGroup.category) }}
                  selectedItemKey={selectedItemKey}
                  onSelectedItemChanged={onSelectedItemChanged}
                />
              </div>
            );
          })
        }
      </div>
    </div>
  );
}