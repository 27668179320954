import { BaseEditor, Descendant } from "slate";
import { ReactEditor } from "slate-react";

export const CustomElementParagraphVersion = 1;
export const CustomElementHeadingOneVersion = 1;
export const CustomElementHeadingTwoVersion = 1;
export const CustomElementHeadingThreeVersion = 1;
export const CustomElementImageVersion = 1;
export const CustomElementImageUserVersion = 1;
export const CustomElementImageUserGalleryVersion = 1;
export const CustomElementUrlVersion = 1;
export const CustomElementUserQueryVersion = 1;
export const CustomElementYoutubeVideoVersion = 1;
export const CustomElementYoutubeShortVersion = 1;

export const enum CustomElementTypesEnum {
  Paragraph = 'paragraph',
  HeadingOne = 'heading-one',
  HeadingTwo = 'heading-two',
  HeadingThree = 'heading-three',
  Image = 'image',
  ImageUser = 'image-user',
  ImageUserGallery = 'image-user-gallery',
  Url = 'url',
  YoutubeVideo = 'youtube-video',
  YoutubeShort = 'youtube-short',
  UserQuery = 'user-query',
  HorizontalLine = 'horizontal-line',
}

export type CustomElementTypes =
  CustomElementTypesEnum.Paragraph |
  CustomElementTypesEnum.HeadingOne |
  CustomElementTypesEnum.HeadingTwo |
  CustomElementTypesEnum.HeadingThree |
  CustomElementTypesEnum.Image |
  CustomElementTypesEnum.ImageUser |
  CustomElementTypesEnum.ImageUserGallery |
  CustomElementTypesEnum.Url |
  CustomElementTypesEnum.YoutubeVideo |
  CustomElementTypesEnum.YoutubeShort |
  CustomElementTypesEnum.UserQuery |
  CustomElementTypesEnum.HorizontalLine;

export type ElementImage = {
  imageId: string;
  caption: string;
};

export type DisplayBlockOptions = {
  backgroundColor?: string;
  borderColor?: string;
  borderWidth?: number;
  margin?: number;
}

export type CustomElementParagraph = {
  type: CustomElementTypesEnum.Paragraph;
  version: number;
  children: Descendant[];
};

export type CustomElementHeadingOne = {
  type: CustomElementTypesEnum.HeadingOne;
  version: number;
  children: Descendant[];
};

export type CustomElementHeadingTwo = {
  type: CustomElementTypesEnum.HeadingTwo;
  version: number;
  children: Descendant[];
};

export type CustomElementHeadingThree = {
  type: CustomElementTypesEnum.HeadingThree;
  version: number;
  children: Descendant[];
};

// Nuno - Remove this later
export type CustomElementImage = {
  type: CustomElementTypesEnum.Image;
  version: number;
  url: string;
  caption: string;
  children: Descendant[];
};

export type CustomElementImageUser = {
  type: CustomElementTypesEnum.ImageUser;
  version: number;
  image: ElementImage;
  displayBlockOptions?: DisplayBlockOptions;
  children: Descendant[];
};

export type CustomElementImageUserGallery = {
  type: CustomElementTypesEnum.ImageUserGallery;
  version: number;
  images: ElementImage[];
  caption: string;
  children: Descendant[];
};

export type CustomElementUrl = {
  type: CustomElementTypesEnum.Url;
  version: number;
  url: string;
  children: Descendant[];
};

export type CustomElementYoutubeVideo = {
  type: CustomElementTypesEnum.YoutubeVideo;
  version: number;
  videoId: string;
  caption: string;
  children: Descendant[];
};

export type CustomElementYoutubeShort = {
  type: CustomElementTypesEnum.YoutubeShort;
  version: number;
  videoId: string;
  caption: string;
  children: Descendant[];
};

export type CustomElementUserQuery = {
  type: CustomElementTypesEnum.UserQuery;
  version: number;
  userQueryId: string;
  name: string;
  children: Descendant[];
}

export type CustomElementHorizontalLine = {
  type: CustomElementTypesEnum.HorizontalLine;
  version: number;
  width: number;
  color: string;
  children: Descendant[];
}

export type CustomElement =
  | CustomElementParagraph
  | CustomElementHeadingOne
  | CustomElementHeadingTwo
  | CustomElementHeadingThree
  | CustomElementImage
  | CustomElementImageUser
  | CustomElementImageUserGallery
  | CustomElementUrl
  | CustomElementYoutubeVideo
  | CustomElementYoutubeShort
  | CustomElementUserQuery
  | CustomElementHorizontalLine;

export type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  color?: string;
};

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}