import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class UpdateBlogPostRouteDef extends IRouteDefinition {
  constructor() {
    super("PUT");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(blogPostId: string): string {
    return `blogs/blog-posts?` +
      QueryParamsUtil.build({
        "blog-post-id": blogPostId
      })
  }

  public routeTemplate(): string {
    return 'blogs/blog-posts';
  }
}

export const enum UpdateBlogPostQueryParams {
  blogPostId = 'blog-post-id'
}

export class UpdateBlogPostRequestBody extends RequestBody {
  constructor(
    public id: string,
    public title: string,
    public subTitle: string | undefined,
    public content: string | undefined,
    public visible: boolean) {
    super();
  }
}