import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';
import { Contact } from '../../../../../../common-app/sitePagesContacts/models/contact';
import { ContactFactory } from '../../../../../../common-app/sitePagesContacts/models/factories/contactFactory';
import { ButtonDelete } from '../../../../../../common-frontend/components/buttonDelete/buttonDelete';
import { ActionsSaveDiscardComponent } from '../../../../../../common-frontend/components/form/actionsSaveDiscard/actionsSaveDiscard';
import { FormCellComponent } from '../../../../../../common-frontend/components/form/formCell';
import { FormEditComponent } from '../../../../../../common-frontend/components/form/formEdit/formEdit';
import { FormNumberComponent } from '../../../../../../common-frontend/components/form/formNumber/formNumber';
import { FormSwitchComponent } from '../../../../../../common-frontend/components/form/formSwitch/formSwitch';
import { HorizontalListComponent } from '../../../../../../common-frontend/components/horizontalList/horizontalList';
import { Spanner } from '../../../../../../common-frontend/components/micro/spanner/spanner';
import { NumbersUtil } from '../../../../../../common/util/numbers/numbersUtil';

interface ContactFormProps {
  contactToEdit: Contact;
  onSaveContact: (contact: Contact) => void;
  onDeleteContact: (contact: Contact) => void;
}

export function ContactFormComponent({
  contactToEdit,
  onSaveContact,
  onDeleteContact
}: ContactFormProps): JSX.Element {

  const { t } = useTranslation();

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [enabled, setEnabled] = useState<boolean>(true);
  const [name, setName] = useState<string>("");
  const [mail01, setMail01] = useState<string>("");
  const [mail02, setMail02] = useState<string>("");
  const [mail03, setMail03] = useState<string>("");
  const [phone01, setPhone01] = useState<string>("");
  const [phone02, setPhone02] = useState<string>("");
  const [phone03, setPhone03] = useState<string>("");
  const [addressLine01, setAddressLine01] = useState<string>("");
  const [addressLine02, setAddressLine02] = useState<string>("");
  const [addressLine03, setAddressLine03] = useState<string>("");
  const [addressLine04, setAddressLine04] = useState<string>("");
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [showMap, setShowMap] = useState<boolean>(false);

  useEffect(() => {
    copyContactInfoToControls(contactToEdit);
  }, [contactToEdit]);

  useEffect(() => {
    const currentContact = ContactFactory.createNew(contactToEdit.id, contactToEdit.pageId);
    copyControlsInfoToContact(currentContact);
    setHasChanges(!Contact.isEqual(currentContact, contactToEdit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contactToEdit,
    enabled,
    name,
    mail01,
    mail02,
    mail03,
    phone01,
    phone02,
    phone03,
    addressLine01,
    addressLine02,
    addressLine03,
    addressLine04,
    latitude,
    longitude,
    showMap
  ]);

  const handleDiscardChanges = () => {
    copyContactInfoToControls(contactToEdit);
  }

  const copyContactInfoToControls = (contact: Contact) => {
    setEnabled(contact.enabled);
    setName(contact.name);
    setMail01(contact.mail01);
    setMail02(contact.mail02);
    setMail03(contact.mail03);
    setPhone01(contact.phone01);
    setPhone02(contact.phone02);
    setPhone03(contact.phone03);
    setAddressLine01(contact.addressLine01);
    setAddressLine02(contact.addressLine02);
    setAddressLine03(contact.addressLine03);
    setAddressLine04(contact.addressLine04);
    setLatitude(NumbersUtil.floatToString(contact.latitude, true));
    setLongitude(NumbersUtil.floatToString(contact.longitude, true));
    setShowMap(contact.showMap);
  }

  const copyControlsInfoToContact = (contact: Contact) => {
    contact.enabled = enabled;
    contact.name = name;
    contact.mail01 = mail01;
    contact.mail02 = mail02;
    contact.mail03 = mail03;
    contact.phone01 = phone01;
    contact.phone02 = phone02;
    contact.phone03 = phone03;
    contact.addressLine01 = addressLine01;
    contact.addressLine02 = addressLine02;
    contact.addressLine03 = addressLine03;
    contact.addressLine04 = addressLine04;
    contact.latitude = NumbersUtil.parseFloat(latitude);
    contact.longitude = NumbersUtil.parseFloat(longitude);
    contact.showMap = showMap;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const contact = ContactFactory.createNew(contactToEdit.id, contactToEdit.pageId);
    copyControlsInfoToContact(contact);
    onSaveContact(contact);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>

        <FormCellComponent size="Small">
          <Form.Group
            className="mb-5"
            controlId='contact-name'>
            <Form.Label>{t('name')}</Form.Label>
            <FormEditComponent
              type="text"
              value={name}
              onChange={setName}
            />
          </Form.Group>
        </FormCellComponent>

        <FormCellComponent size="Medium">
          <Form.Group
            className="mb-3"
            controlId='contact-mail-01'>
            <Form.Label>{t('emailAddresses')}</Form.Label>
            <FormEditComponent
              type="email"
              value={mail01}
              onChange={setMail01} />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId='contact-mail-02'>
            <FormEditComponent
              type="email"
              value={mail02}
              onChange={setMail02}
            />
          </Form.Group>

          <Form.Group
            className="mb-5"
            controlId='contact-mail-03'>
            <FormEditComponent
              type="email"
              value={mail03}
              onChange={setMail03}
            />
          </Form.Group>
        </FormCellComponent>

        <FormCellComponent size="Small">
          <Form.Group
            className="mb-3"
            controlId='contact-phone-01'>
            <Form.Label>{t('phoneNumbers')}</Form.Label>
            <FormEditComponent
              type="text"
              value={phone01}
              onChange={setPhone01}
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId='contact-phone-02'>
            <FormEditComponent
              type="text"
              value={phone02}
              onChange={setPhone02}
            />
          </Form.Group>

          <Form.Group
            className="mb-5"
            controlId='contact-phone-03'>
            <FormEditComponent
              type="text"
              value={phone03}
              onChange={setPhone03}
            />
          </Form.Group>

        </FormCellComponent>

        <FormCellComponent size="Medium">
          <Form.Group
            className="mb-3"
            controlId='contact-address-line-01'>
            <Form.Label>{t('address')}</Form.Label>
            <FormEditComponent
              type='text'
              value={addressLine01}
              onChange={setAddressLine01}
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId='contact-address-line-02'>
            <FormEditComponent
              type='text'
              value={addressLine02}
              onChange={setAddressLine02}
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId='contact-address-line-03'>
            <FormEditComponent
              type='text'
              value={addressLine03}
              onChange={setAddressLine03}
            />
          </Form.Group>

          <Form.Group
            className="mb-5"
            controlId='contact-address-line-04'>
            <FormEditComponent
              type='text'
              value={addressLine04}
              onChange={setAddressLine04}
            />
          </Form.Group>
        </FormCellComponent>

        <FormCellComponent size="Small">

          <Form.Group
            className="mb-3"
            controlId="contact-latitude">
            <Form.Label>{t('latitude')}</Form.Label>
            <FormNumberComponent
              size='sm'
              value={latitude}
              onChange={setLatitude}
            />
          </Form.Group>

          <Form.Group
            className="mb-3"
            controlId="contact-longitude">
            <Form.Label>{t('longitude')}</Form.Label>
            <FormNumberComponent
              size='sm'
              value={longitude}
              onChange={setLongitude}
            />
          </Form.Group>

          <Form.Group
            className="mb-5"
            controlId='contact-show-map'>
            <FormSwitchComponent
              label={t('showMap')}
              value={showMap}
              onChange={setShowMap}
            />
          </Form.Group>

        </FormCellComponent>

        <FormCellComponent size='Small'>
          <Form.Group
            className="mb-5"
            controlId='contact-enabled'>
            <FormSwitchComponent
              label={t('active')}
              value={enabled}
              onChange={setEnabled}
            />
          </Form.Group>
        </FormCellComponent>

        <HorizontalListComponent>
          <ButtonDelete
            itemName={contactToEdit.name || t('contactPlaceHolder')}
            onDelete={() => onDeleteContact(contactToEdit)}
          />
          <Spanner />
          <div>
            <ActionsSaveDiscardComponent
              canSave={hasChanges}
              onDiscard={handleDiscardChanges}
            />
          </div>
        </HorizontalListComponent>
      </Form>
    </div>
  );
}