import { ArrayUtil } from "../array/arrayUtil";

export class ItemsCollection {

  public static getAnItemFromList<T>(
    items: T[],
    getItemKey: (item: T) => string,
    selectedItemKey: string | undefined): string | undefined {

    // Check if the selected item is still in the list
    if (selectedItemKey !== undefined && selectedItemKey !== '') {
      const selectedItem = items.find((item) => getItemKey(item) === selectedItemKey);
      if (selectedItem) {
        return selectedItemKey;
      }
    }

    // If the selected item is not in the list, select the first item
    if (items.length > 0) {
      return getItemKey(items[0]);
    }
    else {
      return undefined;
    }
  }

  public static getItemToSelectAfterDeletion<T>(
    items: T[],
    getItemKey: (item: T) => string,
    selectedItemKey: string | undefined): string | undefined {

    // If the selected items is not provided, select the first item in the list
    if (selectedItemKey === undefined || selectedItemKey === '') {
      return this.getAnItemFromList(items, getItemKey, selectedItemKey);
    }

    // Check if the selected item is still in the list

    const result = ArrayUtil.findIndexAndItem(items, (item) => getItemKey(item) === selectedItemKey);

    if (result.index < 0) {
      // The item provided, cannot be found in the list.
      return this.getAnItemFromList(items, getItemKey, selectedItemKey);
    }

    // We want to select the next item in the list. 
    // If the selected item is the last one, we want to select the previous one.
    let indexToSelect = result.index + 1;
    if (indexToSelect >= items.length) {
      indexToSelect = result.index - 1;
    }

    // Ensure that the index is within the bounds
    if (indexToSelect < 0) {
      indexToSelect = 0;
    }

    // Check if the position to select is valid
    if (indexToSelect < items.length) {
      return getItemKey(items[indexToSelect]);
    }

    return undefined;
  }
}