import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { UserQueryAdminList } from "../models/userQueryAdminList";

export class GetUserQueriesAdminListRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(
    siteId: string,
    pageId: string,
    pageSize: number,
    page: number): string {

    return `user-queries/user-queries-admin-list?` + QueryParamsUtil.build({
      "site-id": siteId,
      "page-id": pageId,
      "page": page,
      "page_size": pageSize
    });
  }

  public routeTemplate(): string {
    return 'user-queries/user-queries-admin-list';
  }
}

export const enum GetUserQueriesAdminListParams {
  siteId = 'site-id',
  pageId = 'page-id',
  pageSize = 'page_size',
  page = 'page'
}

export class GetUserQueriesAdminListResponse extends RequestResponse {
  constructor(public userQueries: UserQueryAdminList[]) {
    super();
  }
}