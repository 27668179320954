import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class DeleteBlogPostRouteDef extends IRouteDefinition {
  constructor() {
    super("DELETE");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(blogPostId: string): string {
    return `blogs/delete-blog-post?` + QueryParamsUtil.build({
      "blog-post-id": blogPostId
    });
  }

  public routeTemplate(): string {
    return "blogs/delete-blog-post";
  }
}

export const enum DeleteBlogPostQueryParams {
  blogPostId = 'blog-post-id'
}