import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";

export class GetIsSiteDomainAvailableRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(siteId: string, domain: string): string {
    return `sites/get-is-site-domain-available?` + QueryParamsUtil.build({
      "site-id": siteId,
      domain
    });
  }

  public routeTemplate(): string {
    return 'sites/get-is-site-domain-available';
  }
}

export const enum GetIsSiteDomainAvailableQueryParams {
  siteId = 'site-id',
  domain = 'domain',
}

export class GetIsSiteDomainAvailableResponse extends RequestResponse {
  constructor(
    public isAvailable: boolean
  ) {
    super();
  }
}
