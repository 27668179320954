import { ErrorMessageComponent } from '../errorMessage/errorMessage';

import './errorList.css';

interface ErrorListProps {
    errorMessages: string[];
}

export function ErrorListComponent({ errorMessages }: ErrorListProps): JSX.Element {

    if (errorMessages.length === 0) {
        return <></>;
    }

    return (
        <div className="error-list-component">
            {errorMessages.map((message, index) => {
                return (
                    <div key={index} className="message">
                        <ErrorMessageComponent message={message} />
                    </div>
                );
            })}
        </div>
    );
}
