import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../common-frontend/components/pageTitle/pageTitle";
import { VerticalWhiteSpaceComponent } from "../common-frontend/components/verticalWhiteSpace/verticalWhiteSpace";
import { ContactUsComponent } from "../components/home/contactUs/contactUs";

export function PageContact(): JSX.Element {

  const { t } = useTranslation();

  return (
    <div className="contacts-page">
      <Container>
        <PageTitle title={t('contacts')} />
        <ContactUsComponent />
        <VerticalWhiteSpaceComponent amount={3} />
      </Container>
    </div>
  );
}