import { Outlet, useLocation } from 'react-router-dom';
import { HeaderComponent } from '../../components/header/header';
import { SitesUtil } from '../../common/sites/sitesUtil';
import { usePageTracking } from '../../library/pageTracking/pageTracking';

export function MainLayout() {

  usePageTracking();

  const location = useLocation();

  const isSitesLocation = SitesUtil.isSitesLocation(location.pathname);

  return (
    <div
      className="main-layout"
    >
      {isSitesLocation === false &&
        <HeaderComponent />
      }

      <Outlet />
    </div>
  );
}