import classNames from 'classnames';

import './label.css';

interface LabelProps {
    size?: "ExtraSmall" | "Small" | "Medium" | "Large" | "ExtraLarge" | "ExtraExtraLarge";
    bold?: boolean;
    children: React.ReactNode;
}

export function LabelComponent({
    size = "ExtraSmall",
    bold = false,
    children }: LabelProps): JSX.Element {

    return (
        <div className={
            classNames(
                { "fs-1": size === "ExtraExtraLarge" },
                { "fs-2": size === "ExtraLarge" },
                { "fs-3": size === "Large" },
                { "fs-4": size === "Medium" },
                { "fs-5": size === "Small" },
                { "fs-6": size === "ExtraSmall" },
                { "label-component-bold": bold }
            )}
        >
            {children}
        </div >
    );
}
