import { CustomElementImage } from "../../types";
import { RenderComponentProps } from "../renderComponentProps";

export function RenderImageComponent({
  element,
  attributes,
  children }: RenderComponentProps): JSX.Element {

  const imageElement = element as CustomElementImage;

  return (
    <div
      {...attributes}
      contentEditable={false}
      className="editor-image-node">
      <img
        className="editor-img"
        src={imageElement.url}
        alt={imageElement.caption}
      />
      <div
        className="editor-image-caption">
        {imageElement.caption}
      </div>
      {children}
    </div>
  );
}