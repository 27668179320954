import { ColorPickerComponent } from "../../../../colorPicker/colorPicker";
import { HorizontalListComponent } from "../../../../horizontalList/horizontalList";

import './elementConfigurationSnippetColor.css';

interface ElementConfigurationSnippetColorProps {
  caption: string;
  value: string;
  onChange: (value: string) => void;
}

export function ElementConfigurationSnippetColorComponent({
  caption,
  value,
  onChange
}: ElementConfigurationSnippetColorProps): JSX.Element {
  return (
    <div className="element-configuration-snippet-color-component">
      <HorizontalListComponent>
        <label>{caption}</label>
        <ColorPickerComponent id="horizontal-line"
          value={value}
          onColorChange={onChange}
          fixPosition={true}
        />
      </HorizontalListComponent>
    </div>
  );
}