import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";
import { Contact } from "../models/contact";

export class CreateContactRouteDef extends IRouteDefinition {
  constructor() {
    super('POST');
    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(): string {
    return 'site-pages-contacts/contacts';
  }

  public routeTemplate(): string {
    return 'site-pages-contacts/contacts';
  }
}

export class CreateContactRequestBody extends RequestBody {
  constructor(
    public contact: Contact
  ) {
    super();
  }
}