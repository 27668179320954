import { TreeNode } from "./treeNode";

export const enum TraverseTreeResultOption {
  Continue,
  Stop
};

export type TraverseTreeResult = TraverseTreeResultOption.Continue | TraverseTreeResultOption.Stop;

export class TreeNodeUtil {

  public static buidlTreeFromList<T>(
    itemsList: T[],
    isChildOf: (node: T, parentNode: T) => boolean,
    isRoot: (node: T) => boolean,
    getId: (node: T) => string,
    getName: (node: T) => string,
    getEnabled: (node: T) => boolean
  ): TreeNode<T>[] {

    const result: TreeNode<T>[] = [];

    const rootNodes = itemsList.filter((item) => isRoot(item));

    // For each route node, build a tree
    for (let curRootNode = 0; curRootNode < rootNodes.length; curRootNode++) {

      const rootNode = rootNodes[curRootNode];

      const rootTreeNode = new TreeNode(
        rootNode,
        getId(rootNode),
        getName(rootNode),
        getEnabled(rootNode));

      this.fillTreeNodeChildren(
        itemsList,
        isChildOf,
        rootTreeNode,
        rootNode,
        getId,
        getName,
        getEnabled);

      result.push(rootTreeNode);
    }

    return result;
  }

  public static traverseTree<T>(nodes: TreeNode<T>[], action: (parentNode: TreeNode<T> | undefined, node: TreeNode<T>) => TraverseTreeResult): void {

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (this.traverseNode(undefined, node, action) == TraverseTreeResultOption.Stop) {
        return;
      }
    }
  }

  public static traverseNode<T>(parentNode: TreeNode<T> | undefined, node: TreeNode<T>, action: (parentNode: TreeNode<T> | undefined, node: TreeNode<T>) => TraverseTreeResult): TraverseTreeResult {
    if (action(parentNode, node) == TraverseTreeResultOption.Stop) {
      return TraverseTreeResultOption.Stop;
    }

    node.children.forEach((child) => {
      const result = this.traverseNode(node, child, action);
      if (result == TraverseTreeResultOption.Stop) {
        return TraverseTreeResultOption.Stop;
      }
    });
    return TraverseTreeResultOption.Continue;
  }

  public static findNode<T>(nodes: TreeNode<T>[], predicate: (item: T) => boolean): TreeNode<T> | undefined {

    let result: TreeNode<T> | undefined = undefined;

    this.traverseTree(nodes, (parentNode, node) => {
      if (predicate(node.item)) {
        result = node;
        return TraverseTreeResultOption.Stop;
      }
      return TraverseTreeResultOption.Continue;
    });

    return result;
  }

  private static fillTreeNodeChildren<T>(
    itemsList: T[],
    isChildOf: (node: T, parentNode: T) => boolean,
    treeNode: TreeNode<T>,
    sourceItem: T,
    getId: (node: T) => string,
    getName: (node: T) => string,
    getEnabled: (node: T) => boolean
  ): TreeNode<T> {

    // Get the children of the current node
    const children = itemsList.filter((item) => isChildOf(item, sourceItem));

    // Fill each children with their own children
    children.forEach((child) => {
      // Create a new tree node
      const childTreeNode = new TreeNode(
        child,
        getId(child),
        getName(child),
        getEnabled(child));

      // Fill the children
      this.fillTreeNodeChildren(
        itemsList,
        isChildOf,
        childTreeNode,
        child,
        getId,
        getName,
        getEnabled);

      // Add the child to the parent
      treeNode.children.push(childTreeNode);
    });

    // Return the filled tree node
    return treeNode;
  }
}
