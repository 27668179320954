// The purpose of this class is to rebuild ocrrectly objects that were 'transmitted' through JSON.
// The problem is that when we send a Date object through JSON, it is converted to a string.
// This class is responsible for converting these strings back to Date objects.
// In the future, more types of conversions can be added here.

export class TypeRebuilding {
  public static rebuildObject<T extends object>(item: T, create: () => T): T {

    const referenceObject = create();
    const newItem = structuredClone(item);

    for (const key in referenceObject) {
      TypeRebuilding.convertDateField<T>(referenceObject, key, newItem);
      // Add more conversions here...
    }

    return newItem;
  }

  public static rebuildObjectArray<T extends object>(items: T[], create: () => T): T[] {
    return items.map(item => this.rebuildObject(item, create));
  }

  private static convertDateField<T extends object>(
    referenceObject: T,
    key: Extract<keyof T, string>,
    newItem: T) {

    if (referenceObject[(key as keyof T)] instanceof Date) {
      const value = newItem[key];
      if (typeof value === 'number' || typeof value === 'string') {
        newItem[key] = new Date(value) as any;
      }
    }
  }
}