import { useEffect, useState } from 'react';
import { DynamicPageDataProvider } from '../../../../../common-frontend/components/dynamicPages/provider/dynamicPageDataProvider';
import { SiteSettingsComponent } from '../../site/siteSettings/siteSettings';
import { BackendServerEndpointProvider } from '../../../../../common-frontend/services/api/backendServerEndpointProvider';
import { PageSettingsComponent } from '../../pages/pageSettings';

import './siteEditorMainArea.css';

interface SiteEditorMainAreaProps {
  siteId: string;
  selectedItemId: string;
  callbackPagesChanged: () => void;
}

export function SiteEditorMainAreaComponent({
  siteId,
  selectedItemId,
  callbackPagesChanged
}: SiteEditorMainAreaProps): JSX.Element {

  const [backendServerAddress, setBackendServerAddress] = useState<string>();

  useEffect(() => {
    const backendServer = BackendServerEndpointProvider.getDefaultBackendServer();
    setBackendServerAddress(backendServer.getEndpoint());
  }, []);

  return (
    <div className="site-editor-main-area-component">

      {selectedItemId === siteId &&
        <div>
          <SiteSettingsComponent
            siteId={siteId}
            key={siteId}
          />
        </div>
      }

      {selectedItemId !== siteId && backendServerAddress &&

        <DynamicPageDataProvider
          siteId={siteId}
          pageId={selectedItemId}
          backendServerAddress={backendServerAddress}
        >

          <PageSettingsComponent
            key={selectedItemId}
            siteId={siteId}
            pageId={selectedItemId}
            callbackPagesChanged={callbackPagesChanged}
          />
        </DynamicPageDataProvider>
      }
    </div>
  );
}
