import { SitePageType } from "../../../common-app/sites/enums/sitePageType";

export type SitePageTypeInfo = {
  type: SitePageType;
  name: string;
  showInSelection: boolean;
}

export class SitePageTypesInfo {

  public static GetPageTypes(): SitePageTypeInfo[] {
    return [
      { type: SitePageType.Unknown, name: 'pageUnknown', showInSelection: false },
      { type: SitePageType.Static, name: 'pageBlank', showInSelection: true },
      { type: SitePageType.Contacts, name: 'pageContacts', showInSelection: true },
      { type: SitePageType.Blog, name: 'pageBlogNews', showInSelection: true },
      { type: SitePageType.Store, name: 'pageStore', showInSelection: true },
    ];
  }

  public static GetPageTypeName(sitePageType: SitePageType): string {
    const pageTypeInfo = this.GetPageTypes().find(i => i.type === sitePageType);
    return pageTypeInfo ? pageTypeInfo.name : 'pageUnknown';
  }

}