import './imageCaption.css';

interface ImageCaptionProps {
  caption: string
}

export function ImageCaptionComponent({ caption }: ImageCaptionProps): JSX.Element {

  if (caption.length === 0) {
    return <></>;
  }

  return (
    <div className="image-caption-component">
      {caption}
    </div>
  );
}
