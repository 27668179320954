import { Product } from "../product";

export class ProductFactory {

  public static createNew(id: string, pageId: string): Product {
    return this.create(
      id,
      pageId,
      "",
      "",
      0,
      "",
      true,
      true,
      "");
  }

  public static create(
    id: string,
    pageId: string,
    name: string,
    description: string,
    price: number,
    category: string,
    available: boolean,
    visible: boolean,
    extraInfo: string
  ): Product {
    return {
      id,
      pageId,
      name,
      description,
      price,
      category,
      available,
      visible,
      extraInfo,
      galleryItems: []
    };
  }
}