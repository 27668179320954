import { useEffect } from "react";
import { Product } from "../../../../../../common-app/sitePagesStores/models/product";
import { useRichTextEditorContentManagerSite } from "../../../../../../components/sites/editor/common/richTextEditorContentManagerSite/useRichTextEditorContentManagerSite";
import { NullFnc } from "../../../../../library/events/eventUtil";
import { useRichTextEditor } from "../../../../richTextEditor/hooks/useRichTextEditor";
import { RichTextEditor } from "../../../../richTextEditor/richTextEditor";
import { useDynamicPageDataContext } from "../../../provider/hooks/useDynamicPageDataContext";

interface ProductExtraInfoProps {
  product: Product;
}

export function ProductExtraInfoComponent({ product }: ProductExtraInfoProps): JSX.Element {

  const dynamicPageContext = useDynamicPageDataContext();
  const editorConfig = useRichTextEditor(true, false);

  const contentManagerSite = useRichTextEditorContentManagerSite(
    dynamicPageContext.siteId,
    dynamicPageContext.pageId
  );

  useEffect(() => {
    editorConfig.updateInitialValue(product.extraInfo);
  }, [product]);

  return (
    <RichTextEditor
      key={editorConfig.getEditorKey()}
      features={{
        editable: editorConfig.getEditorEditable(),
        allowUserQueries: editorConfig.getEditorAllowUserQueries(),
      }}
      initialValue={editorConfig.getEditorStartupValue()}
      onChange={NullFnc}
      contentManager={contentManagerSite}
    />
  );
}