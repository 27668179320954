import { ApiService } from "../../common-frontend/services/api/apiService";
import { SitePageRenderInfo } from "../../common-app/sites/models/sitePageRenderInfo";
import { SitePageEditorList } from "../../common-app/sites/models/sitePageEditorList";
import { SitePageType } from "../../common-app/sites/enums/sitePageType";
import { SitePageEditorInfo } from "../../common-app/sites/models/sitePageEditorInfo";
import { SiteInfo } from "../../common-app/sites/models/siteInfo";
import { SitePageSelect } from "../../common-app/sites/models/sitePageSelect";
import { SitePageParentTreeNode } from "../../common-app/sites/models/sitePageParentTreeNode";
import { SitePage } from "../../common-app/sites/models/sitePage";
import { ServiceApiSitesCreationPopup } from "./sites/serviceApiSitesCreationPopup";
import { ServiceApiSitesManagement } from "./sites/serviceApiSitesManagement";
import { ServiceApiSitesInfo } from "./sites/serviceApiSitesInfo";
import { ServiceApiSitesOwnership } from "./sites/serviceApiSitesOwnership";
import { SiteOwnership } from "../../common-app/sites/models/siteOwnership/siteOwnership";
import { SitePageFeature } from "../../common-app/sites/enums/sitePageFeature";

export class ServiceApiSites extends ApiService {

  // *** Management ***

  static create1ClickSite(siteId: string, websiteName: string, siteOwner: string, language: string): Promise<boolean> {
    return ServiceApiSitesManagement.create1ClickSite(siteId, websiteName, siteOwner, language);
  }

  static getSiteInfo(siteId: string): Promise<SiteInfo | undefined> {
    return ServiceApiSitesManagement.getSiteInfo(siteId);
  }

  static getSiteDomain(siteId: string): Promise<string | undefined> {
    return ServiceApiSitesManagement.getSiteDomain(siteId);
  }

  static deleteSite(siteId: string): Promise<boolean> {
    return ServiceApiSitesManagement.deleteSite(siteId);
  }

  static updateSite(siteId: string, name: string, domain: string, homePageId: string, isActive: boolean): Promise<boolean> {
    return ServiceApiSitesManagement.updateSite(siteId, name, domain, homePageId, isActive);
  }

  static getSitePagesAvailableForHomePage(siteId: string): Promise<SitePageSelect[]> {
    return ServiceApiSitesManagement.getSitePagesAvailableForHomePage(siteId);
  }

  static getSitePages(siteId: string): Promise<SitePage[]> {
    return ServiceApiSitesManagement.getSitePages(siteId);
  }

  static getSitePageName(siteId: string, pageId: string): Promise<string | undefined> {
    return ServiceApiSitesManagement.getSitePageName(siteId, pageId);
  }

  static getSitePagesEditorList(siteId: string): Promise<SitePageEditorList[]> {
    return ServiceApiSitesManagement.getSitePagesEditorList(siteId);
  }

  static getSitePagesParentTree(siteId: string, pageId: string): Promise<SitePageParentTreeNode[]> {
    return ServiceApiSitesManagement.getSitePagesParentTree(siteId, pageId);
  }

  static getPageEditorInfo(site: string, page: string): Promise<SitePageEditorInfo | undefined> {
    return ServiceApiSitesManagement.getPageEditorInfo(site, page);
  }

  static updateSitePage(siteId: string, pageId: string, name: string, isActive: boolean): Promise<boolean> {
    return ServiceApiSitesManagement.updateSitePage(siteId, pageId, name, isActive);
  }

  static updateSitePageParent(site: string, page: string, parentPageId: string): Promise<boolean> {
    return ServiceApiSitesManagement.updateSitePageParent(site, page, parentPageId);
  }

  static addSitePage(siteId: string, pageId: string, newPageType: SitePageType, pageName: string, anchorPageId: string | undefined) {
    return ServiceApiSitesManagement.addSitePage(siteId, pageId, newPageType, pageName, anchorPageId);
  }

  static duplicateSitePage(siteId: string, existingPageId: string, newPageId: string): Promise<boolean> {
    return ServiceApiSitesManagement.duplicateSitePage(siteId, existingPageId, newPageId);
  }

  static deleteSitePage(siteId: string, pageId: string): Promise<boolean> {
    return ServiceApiSitesManagement.deleteSitePage(siteId, pageId);
  }

  static reorderSitePages(siteId: string, parentPageId: string, pageIds: string[]): Promise<boolean> {
    return ServiceApiSitesManagement.reorderSitePages(siteId, parentPageId, pageIds);
  }

  static getIsSiteDomainAvailable(siteId: string, tentativeDomain: string): Promise<boolean> {
    return ServiceApiSitesManagement.getIsSiteDomainAvailable(siteId, tentativeDomain);
  }

  static getPossibleSiteDomain(siteId: string, pageName: string): Promise<string> {
    return ServiceApiSitesManagement.getPossibleSiteDomain(siteId, pageName);
  }

  static updatePageFeatures(siteId: string, pageId: string, features: SitePageFeature[]): Promise<boolean> {
    return ServiceApiSitesManagement.updatePageFeatures(siteId, pageId, features);
  }

  static getPageFeatures(siteId: string, pageId: string): Promise<SitePageFeature[]> {
    return ServiceApiSitesManagement.getPageFeatures(siteId, pageId);
  }

  // *** Site Ownership ***

  static getSiteOwnership(siteId: string): Promise<SiteOwnership | undefined> {
    return ServiceApiSitesOwnership.getSiteOwnership(siteId);
  }

  static takeSiteOwnership(sessionId: string, siteId: string, deviceId: string): Promise<boolean> {
    return ServiceApiSitesOwnership.takeSiteOwnership(sessionId, siteId, deviceId);
  }

  static getSitesOwnedByUser(userId: string): Promise<SiteOwnership[]> {
    return ServiceApiSitesOwnership.getSiteOwnedByUser(userId);
  };

  static getUnclaimedSites(deviceId: string): Promise<SiteOwnership[]> {
    return ServiceApiSitesOwnership.getUnclaimedSites(deviceId);
  };

  // *** Info ***

  static getPageInfo(pageUrlPart: string, sessionId: string, deviceId: string): Promise<SitePageRenderInfo | undefined> {
    return ServiceApiSitesInfo.getSitePageRenderInfo(pageUrlPart, sessionId, deviceId);
  }

  // *** Creation Popup ***

  static updateSiteCreationPopupNeedsShowing(site: string, needsShowing: boolean = false): Promise<boolean> {
    return ServiceApiSitesCreationPopup.updateSiteCreationPopupNeedsShowing(site, needsShowing);
  }

  static getSiteCreationPopupNeedsShowing(site: string): Promise<boolean> {
    return ServiceApiSitesCreationPopup.getSiteCreationPopupNeedsShowing(site);
  }
}
