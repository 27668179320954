import './pageName.css';

interface PageNameProps {
  name: string;
}

export function PageNameComponent({ name }: PageNameProps): JSX.Element {
  return (
    <div className="page-name-component">

      <i className="bi bi-file-earmark-fill"></i>
      {name}
    </div>
  );
}