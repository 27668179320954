import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BlogPostAdmin } from '../../../../../../common-app/sitePagesBlogs/models/blogPostAdmin';
import { BlogPostAdminUpdate } from '../../../../../../common-app/sitePagesBlogs/models/blogPostAdminUpdate';
import { BlopPostAdminFactory } from '../../../../../../common-app/sitePagesBlogs/models/factories/blogPostAdminFactory';
import { ContainerLabeledComponent } from '../../../../../../common-frontend/components/containerLabeled/containerLabeled';
import { HorizontalListComponent } from '../../../../../../common-frontend/components/horizontalList/horizontalList';
import { ItemsListComponent } from '../../../../../../common-frontend/components/itemsList/itemsList';
import { ListAndItemComponent } from '../../../../../../common-frontend/components/listAndItem/listAndItem';
import { Spanner } from '../../../../../../common-frontend/components/micro/spanner/spanner';
import { PaginationControlsComponent } from '../../../../../../common-frontend/components/pagination/pagination';
import { VerticalListComponent } from '../../../../../../common-frontend/components/verticalList/verticalList';
import { useSelectAnItemFromList } from '../../../../../../common-frontend/hooks/items/itemSelection';
import { useForceRefresh } from '../../../../../../common-frontend/hooks/refresh/forceRefresh';
import { ListItem, ListItemHelper } from '../../../../../../common-frontend/library/list/listItem';
import { ArrayUtil } from '../../../../../../common/util/array/arrayUtil';
import { GuidUtil } from '../../../../../../common/util/guid/guid';
import { ItemsCollection } from '../../../../../../common/util/itemsCollection/itemsCollection';
import { PaginationUtil } from '../../../../../../common/util/pagination/paginationUtil';
import { BlogPostFormComponent } from '../../../../../../pages/admin/blogs/blogPostForm';
import { ServiceApiPageDataBlogs } from '../../../../../../services/api/serviceApiPageDataBlogs';
import { PagePaneProps } from '../pagePanesMapping';

import './pagePaneBlogEntries.css';

type ListItemBlogPostAdmin = ListItem<BlogPostAdmin>;

export function PagePaneBlogEntriesComponent({
  siteId,
  pageId
}: PagePaneProps): JSX.Element {

  const itemsPerPage = 10;

  const { t } = useTranslation();

  const [itemsDeleted, setItemsDeleted] = useForceRefresh();

  const [newItem, setNewItem] = useState<ListItemBlogPostAdmin | undefined>(undefined);

  const [items, setItems] = useState<ListItemBlogPostAdmin[]>([]);
  const [selectedItemKey, setSelectedItemKey] = useState<string>('');

  const [pageCount, setPageCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [selectBlogPostFromList] = useSelectAnItemFromList<ListItemBlogPostAdmin>(
    (item => item.item.id),
    selectedItemKey,
    setSelectedItemKey
  );

  const selectedItem = items.find((item) => item.item.id === selectedItemKey);

  useEffect(() => {

    ServiceApiPageDataBlogs.getBlogPostAdminList(siteId, pageId, itemsPerPage, currentPage).then((blogPosts) => {
      let blogPostListItems = ListItemHelper.WrapItems(blogPosts, true);

      if (currentPage === 1 && newItem !== undefined) {
        blogPostListItems = [newItem, ...blogPostListItems];
        blogPostListItems = blogPostListItems.slice(0, itemsPerPage);
      }

      setItems(blogPostListItems);
      selectBlogPostFromList(blogPostListItems);
    });

    ServiceApiPageDataBlogs.getBlogPostAdminCount(siteId, pageId).then((count) => {
      const countAllItems = count + (newItem ? 1 : 0);
      setPageCount(PaginationUtil.getRequiredPageCount(countAllItems, itemsPerPage));
    });

  }, [pageId, currentPage, newItem, itemsDeleted]);

  const handleCreateNewBlogPost = (): void => {

    // Check if we already have one blog post that's not saved in the backend
    if (newItem !== undefined) {
      setCurrentPage(1);
      setSelectedItemKey(newItem.item.id);
      return;
    }

    // Create new blog post
    const newBlogPost = BlopPostAdminFactory.createNew(GuidUtil.GenerateNewGuid(), pageId);
    newBlogPost.title = t('newBlogPost');

    // Create a new ListItem with the new blog post
    const newBlogPostListItem = new ListItem(newBlogPost, false);
    setNewItem(newBlogPostListItem);

    // Set this item as the selected item
    setSelectedItemKey(newBlogPostListItem.item.id);

    // Load the first page
    setCurrentPage(1);
  }

  const handleSaveBlogPost = (blogPost: BlogPostAdminUpdate): void => {

    // Get the item and the index
    const {
      index: itemIndex,
      item: blogPostListItem
    } = ArrayUtil.findIndexAndItem(items, (item) => item.item.id === blogPost.id);

    if (blogPostListItem === undefined) {
      return;
    }

    // Update the backend
    if (blogPostListItem.isSavedInTheBackend === false) {
      ServiceApiPageDataBlogs.createBlogPost(siteId, blogPost);
    }
    else {
      ServiceApiPageDataBlogs.updateBlogPost(siteId, blogPost);
    }

    // Update this item with the new content
    BlogPostAdmin.copyFromBlogPostAdminUpdate(blogPostListItem.item, blogPost);
    blogPostListItem.isSavedInTheBackend = true;

    const newItems = [...items];
    newItems[itemIndex] = blogPostListItem;
    setItems(newItems);

    // Reset the new item
    setNewItem(undefined);
  }

  const handleDeleteBlogPost = (contact: BlogPostAdmin): void => {

    // Sanity checks
    const blogPostListItem = items.find((item) => item.item.id === contact.id);
    if (blogPostListItem === undefined) {
      return;
    }

    // Pick a new item to select
    const itemToSelect = ItemsCollection.getItemToSelectAfterDeletion(items, (item) => item.item.id, selectedItemKey);

    // Remove the item from the list for immediate feedback
    const newItems = items.filter((item) => item.item.id !== contact.id);
    setItems(newItems);

    // Update the selection
    setSelectedItemKey(itemToSelect ?? '');

    // Delete the item from the backend
    if (blogPostListItem.isSavedInTheBackend) {
      // Update the backend
      ServiceApiPageDataBlogs.deleteBlogPost(siteId, contact.id).then(() => {
        // Force refresh of the list
        setItemsDeleted();
      });
    }

    // Clear the new item if it's the one being deleted
    if (newItem && newItem.item.id === contact.id) {
      setNewItem(undefined);
    }
  }

  return (
    <div className="page-pane-blog-entries-component">
      <VerticalListComponent >
        <ContainerLabeledComponent
          label={t('blogPosts')}
          uniqueId={`page-content-blog-posts-${pageId}`}
        >
          <ListAndItemComponent>
            <div
              className='blog-posts-side-panel'
            >
              <VerticalListComponent>
                <Button
                  variant='outline-primary'
                  style={{ width: "100%" }}
                  onClick={handleCreateNewBlogPost}
                >
                  {t('addBlogPost')}
                </Button>

                <div className='list-holder'>
                  <ItemsListComponent
                    items={items}
                    listName='posts'
                    getItemKey={(item) => item.item.id}
                    getItemLabel={(item) => item.item.title}
                    selectedItemKey={selectedItemKey}
                    onSelectedItemChanged={setSelectedItemKey}
                    allowSorting={false}
                  />
                </div>
                <HorizontalListComponent>
                  <Spanner />
                  <PaginationControlsComponent
                    currentPage={currentPage}
                    pageCount={pageCount}
                    onPageChange={setCurrentPage}
                    buttonsToShowCount={5}
                    showFirstAndLast={true}
                    showPreviousAndNext={false}
                    hideOnSinglePage={true}
                  />
                  <Spanner />
                </HorizontalListComponent>
              </VerticalListComponent>
            </div>

            <div>
              {
                selectedItem && (
                  <BlogPostFormComponent
                    siteId={siteId}
                    pageId={pageId}
                    blogPostToEdit={selectedItem.item}
                    isNewItem={selectedItem.isSavedInTheBackend === false}
                    onSaveBlogPost={handleSaveBlogPost}
                    onDeleteBlogPost={handleDeleteBlogPost}
                  />
                )
              }
            </div>
          </ListAndItemComponent>
        </ContainerLabeledComponent>
      </VerticalListComponent>
    </div>
  );
}
