import { Button, ButtonGroup, ButtonToolbar, Dropdown } from 'react-bootstrap';
import { Editor, Element, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { useTranslationCommonFrontEnd } from '../../../../translations/translation';
import { MenuItemComponent } from '../../../menuItems/menuItem';
import { WhiteSpaceComponent } from '../../../whiteSpace/whiteSpace';
import { ElementName } from '../../elements/information/elementName';
import { RichTextEditorContentManager } from '../../richTextEditorContentManager';
import {
  BlockType,
  BlockTypes,
  EditorUtil
} from '../../richTextEditorUtil';
import {
  CustomElement,
  CustomElementTypesEnum,
  CustomText,
} from '../../types';
import { ButtonColor } from './toolbarButtons/buttonColor/buttonColor';
import { ButtonImage } from './toolbarButtons/buttonImage';

import './richTextEditorToolbar.css';

interface RichTextEditorToolbarProps {
  editor: Editor;
  contentManager: RichTextEditorContentManager,
  allowUserQueries: boolean,

  handleInsertUserImages: (images: string[]) => void,
  handleInsertUrl: () => void,
  handleInsertYoutubeVideo: () => void,
  handleInsertYoutubeShort: () => void,
  handleInsertUserQuery: () => void,
  handleInsertHorizontalLine: () => void,
}

export function RichTextEditorToolbarComponent({
  editor,
  contentManager,
  allowUserQueries,
  handleInsertUserImages,
  handleInsertUrl,
  handleInsertYoutubeVideo,
  handleInsertYoutubeShort,
  handleInsertUserQuery,
  handleInsertHorizontalLine,
}: RichTextEditorToolbarProps) {

  const t = useTranslationCommonFrontEnd();

  const isBlockActive = (format: CustomElement['type']) => {
    const [match] = Editor.nodes(editor, {
      match: n => Element.isElement(n) && n.type === format,
    });
    return !!match;
  };

  const toggleBlock = (format: CustomElement['type']) => {
    if (!BlockTypes.includes(format as BlockType)) {
      // If the format is not a block type, don't proceed
      return;
    }

    const isActive = isBlockActive(format);

    Transforms.setNodes(
      editor,
      { type: isActive ? CustomElementTypesEnum.Paragraph : format },
      {
        match: n => Element.isElement(n) && EditorUtil.canBlockTypeBeChanged(format),
      }
    );

    // Refocus the editor to keep the selection visible
    ReactEditor.focus(editor);
  };

  const isMarkActive = (editor: Editor, format: keyof Omit<CustomText, 'text'>) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const handleToggleMark = (editor: Editor, format: keyof Omit<CustomText, 'text'>) => {
    const isActive = isMarkActive(editor, format);
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }

    // Refocus the editor to keep the selection visible
    ReactEditor.focus(editor);
  };

  const handleClearFormatting = () => {

    // Remove all marks
    Editor.removeMark(editor, 'bold');
    Editor.removeMark(editor, 'italic');
    Editor.removeMark(editor, 'underline');
    Editor.removeMark(editor, 'strikethrough');

    // Remove all block types
    Transforms.setNodes(
      editor,
      { type: CustomElementTypesEnum.Paragraph },
      {
        match: n => Element.isElement(n) && EditorUtil.canBlockTypeBeChanged(n.type),
      }
    );

    // Refocus the editor to keep the selection visible
    ReactEditor.focus(editor);
  }

  const handleSetColor = (editor: Editor, color: string | undefined) => {

    if (color !== undefined) {
      Editor.addMark(editor, 'color', color);
    }
    else {
      Editor.removeMark(editor, 'color');
    }

    // Refocus the editor to keep the selection visible
    ReactEditor.focus(editor);
  };

  return (

    <ButtonToolbar aria-label={t('editorToolbar')} className="toolbar">
      <ButtonGroup className="me-2" aria-label={t('basicFormatting')}>
        <Button onClick={() => handleToggleMark(editor, 'bold')}>
          <strong>{t('boldLetter')}</strong>
        </Button>
        <Button onClick={() => handleToggleMark(editor, 'italic')}>
          <em>{t('italicLetter')}</em>
        </Button>
        <Button onClick={() => handleToggleMark(editor, 'underline')}>
          <u>{t('underlineLetter')}</u>
        </Button>
        <Button onClick={() => handleToggleMark(editor, 'strikethrough')}>
          <div className='action-strikethrough'>{t('strikeThroughLetter')}</div>
        </Button>
      </ButtonGroup>

      <ButtonGroup className="me-2" aria-label={t('lineFormatting')}>
        <Button onClick={() => toggleBlock(CustomElementTypesEnum.HeadingOne)}>{t('headingLetter')}1</Button>
        <Button onClick={() => toggleBlock(CustomElementTypesEnum.HeadingTwo)}>{t('headingLetter')}2</Button>
        <Button onClick={() => toggleBlock(CustomElementTypesEnum.HeadingThree)}>{t('headingLetter')}3</Button>
        <Button onClick={() => toggleBlock(CustomElementTypesEnum.Paragraph)}>{t('paragraphLetter')}</Button>
      </ButtonGroup>

      <ButtonGroup className="me-2" aria-label={t('textColor')}>
        <ButtonColor
          onColorChange={(color) => {
            handleSetColor(editor, color);
          }}
        />
      </ButtonGroup>

      <ButtonGroup className="me-2" aria-label={t('resetFormatting')}>
        <Button onClick={() => handleClearFormatting()}>{t('resetFormatting')}</Button>
      </ButtonGroup>

      <ButtonGroup className="me-2" aria-label={t('images')}>
        <ButtonImage
          contentManager={contentManager}
          handleImageInsert={(images) => {
            handleInsertUserImages(images);
          }}
        />
      </ButtonGroup>

      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          {t('add')}
          <WhiteSpaceComponent ammount={2} />
        </Dropdown.Toggle>

        <Dropdown.Menu>

          <Dropdown.Item onClick={handleInsertUrl}>
            <MenuItemComponent caption={t(ElementName.getElementName(CustomElementTypesEnum.Url))} iconName='link-45deg' />
          </Dropdown.Item>

          <Dropdown.Divider />

          <Dropdown.Item onClick={handleInsertYoutubeVideo}>
            <MenuItemComponent caption={t(ElementName.getElementName(CustomElementTypesEnum.YoutubeVideo))} iconName='youtube' />
          </Dropdown.Item>

          <Dropdown.Item onClick={handleInsertYoutubeShort}>
            <MenuItemComponent caption={t(ElementName.getElementName(CustomElementTypesEnum.YoutubeShort))} iconName='youtube' />
          </Dropdown.Item>

          {allowUserQueries && (
            <>
              <Dropdown.Divider />

              <Dropdown.Item onClick={handleInsertUserQuery}>
                <MenuItemComponent caption={t(ElementName.getElementName(CustomElementTypesEnum.UserQuery))} iconName='chat-left' />
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Divider />

          <Dropdown.Item onClick={handleInsertHorizontalLine}>
            <MenuItemComponent caption={t(ElementName.getElementName(CustomElementTypesEnum.HorizontalLine))} iconName='dash-lg' />
          </Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>

    </ButtonToolbar>
  )
};
