import { Environment } from "../../environment/environment";

export interface IBackendServer {
  getEndpoint(): string;
}

class DefaultBackendServer implements IBackendServer {
  getEndpoint(): string {
    return Environment.GetDefaultServerUrl();
  }
}

class PixelVaidosoBackendServer implements IBackendServer {
  getEndpoint(): string {
    return Environment.GetPixelVaidosoServerUrl();
  }
}

export class BackendServerEndpointProvider {
  public static getDefaultBackendServer(): IBackendServer {
    return new DefaultBackendServer();
  }

  public static getPixelVaidosoServer(): IBackendServer {
    return new PixelVaidosoBackendServer();
  }
}
