export const enum StoreOrderState {
  Unknown = 'unknown',
  Received = 'received',
  MoreInfoNeeded = 'more-info-needed',
  WaitingPayment = 'waiting-payment',
  Handling = 'handling',
  Rejected = 'rejected',
  Completed = 'completed'
}

export function GetStoreOrderStateLabel(state: StoreOrderState): string {
  switch (state) {
    case StoreOrderState.Unknown:
      return 'Desconhecido';
    case StoreOrderState.Received:
      return 'Recebida';
    case StoreOrderState.MoreInfoNeeded:
      return 'Mais informação necessária';
    case StoreOrderState.WaitingPayment:
      return 'Aguarda pagamento';
    case StoreOrderState.Handling:
      return 'A tratar';
    case StoreOrderState.Rejected:
      return 'Rejeitada';
    case StoreOrderState.Completed:
      return 'Concluída';
  }
}

export function GetStoreOrderStateColor(state: StoreOrderState): string {
  switch (state) {
    case StoreOrderState.Unknown:
      return '#CFCFCF';
    case StoreOrderState.Received:
      return '#8CACF2';
    case StoreOrderState.MoreInfoNeeded:
      return '#CF9600';
    case StoreOrderState.WaitingPayment:
      return '#00CF8B';
    case StoreOrderState.Handling:
      return '#DF58E5';
    case StoreOrderState.Rejected:
      return '#F26C6F';
    case StoreOrderState.Completed:
      return '#92EB88';
  }
}