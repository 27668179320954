export class Environment {

  public static GetAppClientUrl(): string {
    return process.env.REACT_APP_CLIENT_URL || '';
  }

  public static GetDefaultServerUrl(): string {
    return process.env.REACT_APP_DEFAULT_SERVER_URL || '';
  }

  public static GetPixelVaidosoServerUrl(): string {
    return process.env.REACT_APP_PIXEL_VAIDOSO_SERVER_URL || '';
  }

  public static GetGoogleMapsApiKey(): string {
    return process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';
  }
}