import { useContext } from 'react';
import { UserSessionContext } from '../userSessionContext';

export const useUserSessionContext = () => {
  const context = useContext(UserSessionContext);
  if (!context) {
    throw new Error("useUserSessionContext must be used within a UserSessionProvider");
  }
  return context;
};
