import { Environment } from "../../environment/environment";
import './mapGoogle.css';

interface MapGoogleComponentProps {
  latitude: number;
  longitude: number;
  address: string;
}

export function MapGoogleComponent({ latitude, longitude, address }: MapGoogleComponentProps) {

  const encodedAddress = encodeURIComponent(address);

  const apiKey = Environment.GetGoogleMapsApiKey();
  const mapUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodedAddress}&center=${latitude},${longitude}&zoom=15`;

  return (
    <div className='map-google'>
      <iframe
        title="Location"
        style={{
          border: 0,
          width: '100%',  /* Set the width to 100% of the parent container */
          height: '100%', /* Set the height to 100% of the parent container or adjust as needed */
        }}
        loading="lazy"
        allowFullScreen
        src={mapUrl}
      >
      </iframe>
    </div>
  );
};