import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { BlogPost } from "../models/blogPost";

export class GetBlogPostListRouteDef extends IRouteDefinition {

  constructor() {
    super("GET");
  }

  public route(pageId: string, pageSize: number, page: number): string {
    return `blogs/blog-posts?` +
      QueryParamsUtil.build(
        {
          'page-id': pageId,
          'page-size': pageSize,
          'page': page
        });
  }

  public routeTemplate(): string {
    return 'blogs/blog-posts';
  }
}

export const enum GetBlogPostListParams {
  pageId = 'page-id',
  pageSize = 'page-size',
  page = 'page'
}

export class GetBlogPostListResponse extends RequestResponse {
  constructor(public blogPosts: BlogPost[]) {
    super();
  }
}