import { CreateRouteLogRequestBody, CreateRouteLogRouteDef } from "../../common-app/analytics/actions/createRouteLog";
import { ApiService } from "../../common-frontend/services/api/apiService";
import { BackendServerEndpointProvider } from "../../common-frontend/services/api/backendServerEndpointProvider";
import { GuidUtil } from "../../common/util/guid/guid";
import { RequestHeaderHelper } from "../requestHeaderData/requestHeaderHelper";

export class ServiceApiAnalytics extends ApiService {

  static createRouteLog(
    deviceID: string,
    route: string,
    when: Date): Promise<boolean> {

    // Send a post to the backend server
    const bodyData = new CreateRouteLogRequestBody(
      GuidUtil.GenerateNewGuid(),
      deviceID,
      route,
      when
    );

    // Create the route definition object
    const routeDef = new CreateRouteLogRouteDef();

    // Get the request options
    const requestOptions = this.getRequestOptions(routeDef, bodyData);

    // Make the request
    return this.DoRequest(
      routeDef.route(),
      requestOptions,
      BackendServerEndpointProvider.getDefaultBackendServer(),
      RequestHeaderHelper.getRequestHeaderDataNoPermissions()
    );
  }
}