import { TypeCompare } from "../../../common/util/types/typeCompare";
import { BlogPostAdmin } from "./blogPostAdmin";

export class BlogPostAdminUpdate {
  id: string = '';
  pageId: string = '';
  title: string = '';
  subTitle: string | undefined;
  content: string | undefined;
  category: string = '';
  visible: boolean = false;
  creatorId: string = '';

  public static isEqual(a: BlogPostAdmin, b: BlogPostAdminUpdate): boolean {

    return (
      a.id === b.id &&
      a.pageId === b.pageId &&
      a.title === b.title &&
      a.subTitle === b.subTitle &&
      a.content === b.content &&
      a.category === b.category &&
      TypeCompare.AreEqual(a.visible, b.visible));
  }
}