import { RequestResponse } from "../../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../../common/util/routes/queryParamsUtil";
import { SiteOwnership } from "../../models/siteOwnership/siteOwnership";


export class GetSiteOwnershipRouteDef extends IRouteDefinition {
  constructor() {
    super("GET");
  }

  public route(siteId: string): string {
    return `sites/get-site-ownership?` + QueryParamsUtil.build({ "site-id": siteId });
  }

  public routeTemplate(): string {
    return 'sites/get-site-ownership';
  }
}

export const enum GetSiteOwnershipQueryParams {
  siteId = 'site-id',
}

export class GetSiteOwnershipResponse extends RequestResponse {
  constructor(
    public siteOwnership: SiteOwnership | undefined
  ) {
    super();
  }
}
