import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";

export class GetStoreOrdersAdminCountRouteDef extends IRouteDefinition {
  constructor() {
    super('GET');
  }

  public route(pageId: string): string {
    return `site-pages-store/store-orders-admin-count?` +
      QueryParamsUtil.build(
        {
          'page-id': pageId
        }
      );
  }

  public routeTemplate(): string {
    return 'site-pages-store/store-orders-admin-count';
  }
}

export const enum GetStoreOrdersAdminCountQueryParams {
  pageId = 'page-id'
}

export class GetStoreOrdersAdminCountResponse extends RequestResponse {
  constructor(public count: number) {
    super();
  }
}