import { AppRouter } from './router';
import './App.css';
import { UserSessionProvider } from '../components/contexts/session/userSessionProvider';

export function AppComponent(): JSX.Element {
  return (
    <div className="app">
      <UserSessionProvider>
        <AppRouter />
      </UserSessionProvider>
    </div>
  );
}
