import { forwardRef, useImperativeHandle } from 'react';
import { useTranslationCommonFrontEnd } from '../../../../../translations/translation';
import { ElementEditRef } from '../../elementSettingsConfig';
import { ElementSettingsEditProps } from '../../elementSettingsEditProps';

export const ElementEditNoneComponent = forwardRef<ElementEditRef, ElementSettingsEditProps>(
  ({ element }, ref): JSX.Element => {

    const t = useTranslationCommonFrontEnd();

    useImperativeHandle(ref, () => ({
      updateElement: (element) => { return element; },
    }));

    return (
      <div className="element-edit-none-component">
        <p>
          {t('noExtraSettings')}
        </p>

        <p>
          {JSON.stringify(element)}
        </p>
      </div>
    );
  }
);
