import { useTranslation } from 'react-i18next';
import { UserQueryAdminList } from '../../../../common-app/userQueries/models/userQueryAdminList';
import { ContainerComponent } from '../../../../common-frontend/components/container/container';
import { HorizontalListComponent } from '../../../../common-frontend/components/horizontalList/horizontalList';
import { LabelAndValueComponent } from '../../../../common-frontend/components/labelAndValue/labelAndValue';
import { VerticalListComponent } from '../../../../common-frontend/components/verticalList/verticalList';

import './adminUserQueryDetail.css';

interface AdminUserQueryDetailProps {
  item: UserQueryAdminList | undefined;
}

export function AdminUserQueryDetailComponent({ item }: AdminUserQueryDetailProps): JSX.Element {
  
  const { t } = useTranslation();

  return (
    <div className="admin-user-query-detail-component">
      <ContainerComponent>
        {item &&
          <VerticalListComponent>
            <HorizontalListComponent>
              <div style={{ flexGrow: "1" }}>
                <LabelAndValueComponent
                  label={t('contact')}
                  value={item.contact}
                />
              </div>

              <div>
                <LabelAndValueComponent
                  label={t('date')}
                  value={new Date(item.creationDate).toLocaleString()}
                />
              </div>

            </HorizontalListComponent>

            <LabelAndValueComponent
              label={t('subject')}
              value={item.subject}
            ></LabelAndValueComponent>

            <LabelAndValueComponent
              label={t('message')}
              value={item.message}
            ></LabelAndValueComponent>
          </VerticalListComponent>
        }
      </ContainerComponent>
    </div >
  );
}
