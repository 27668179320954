import { useEffect, useState } from "react";
import { UserFileImage } from "../../../../../../common-app/userFiles/models/userFileImage";
import { useDynamicPageDataContext } from "../../../provider/hooks/useDynamicPageDataContext";

import "./productListItemImage.css";

interface ProductListItemImageProps {
  images: UserFileImage[];
}

export function ProductListItemImageComponent({
  images
}: ProductListItemImageProps): JSX.Element {

  const dynamicPageContext = useDynamicPageDataContext();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [nextImageIndex, setNextImageIndex] = useState(1);

  const [currentImgVisible, setCurrentImgVisible] = useState<0 | 1>(0);
  const [tick, setTick] = useState(0);

  useEffect(() => {

    if (images.length <= 1) {
      return;
    }

    const interval = setInterval(() => {
      setTick((prev) => prev + 1);
    }, 5000);

    return () => {
      clearInterval(interval);
    }
  }, [images]);

  useEffect(() => {

    if (images.length === 0) {
      return;
    }

    setCurrentImgVisible((prevImageVisible) => {

      // This is to prevent the first tick to change the image of the image in the back
      if (tick > 1) {

        // Update the index of the image of the control in the back
        if (prevImageVisible === 1) {
          setCurrentImageIndex((prevIndex) => (prevIndex + 2) % images.length);
        }
        else {
          setNextImageIndex((prevIndex) => (prevIndex + 2) % images.length);
        }
      }

      return (prevImageVisible === 0) ? 1 : 0;
    });

  }, [tick]);

  if (images.length === 0) {
    return <div />;
  }

  return <div className="image-container">
    <img
      className={`image ${currentImgVisible === 0 ? "fade-in" : "fade-out"}`}
      src={dynamicPageContext.backendServerAddress + images[currentImageIndex].publicUrl}
      alt={images[currentImageIndex].originalFileName}
      draggable={false}
    />
    {images.length > 1 &&
      <img
        className={`image ${currentImgVisible === 1 ? "fade-in" : "fade-out"}`}
        src={dynamicPageContext.backendServerAddress + images[nextImageIndex].publicUrl}
        alt={images[nextImageIndex].originalFileName}
        draggable={false}
      />
    }
  </div>;
}