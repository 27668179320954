import { forwardRef } from 'react';
import { ElementSettingsCreationProps } from '../../elementSettingsCreationProps';
import { ElementCreationRef } from '../../elementSettingsConfig';

export const ElementCreationNoneComponent = forwardRef<ElementCreationRef, ElementSettingsCreationProps>(
  (props, ref): JSX.Element => {
    return (
      <div className="element-creation-none-component">
        <p>
          This should not be visible
        </p>
      </div>
    );
  }
);
