import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";

export class DeleteUserFileRouteDef extends IRouteDefinition {

  constructor() {
    super("DELETE");
    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(id: string): string {
    return `user-files/user-file/${id}`;
  }

  public routeTemplate(): string {
    return 'user-files/user-file/:id';
  }
}

export const enum DeleteUserFileRouteParams {
  id = 'id',
}

