import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { MediaGalleryItem } from "../../../../../../../common-app/common/models/mediaGalleryItem";
import { ProductFactory } from "../../../../../../../common-app/sitePagesStores/models/factories/productFactory";
import { Product } from "../../../../../../../common-app/sitePagesStores/models/product";
import { EditableComboBoxComponent } from "../../../../../../../common-frontend/components/editableComboBox/editableComboBox";
import { ActionsSaveDiscardComponent } from "../../../../../../../common-frontend/components/form/actionsSaveDiscard/actionsSaveDiscard";
import { FormCellComponent } from "../../../../../../../common-frontend/components/form/formCell";
import { FormEditComponent } from "../../../../../../../common-frontend/components/form/formEdit/formEdit";
import { FormNumberComponent } from "../../../../../../../common-frontend/components/form/formNumber/formNumber";
import { FormSwitchComponent } from "../../../../../../../common-frontend/components/form/formSwitch/formSwitch";
import { FormTextAreaComponent } from "../../../../../../../common-frontend/components/form/formTextArea/formTextArea";
import { HorizontalListComponent } from "../../../../../../../common-frontend/components/horizontalList/horizontalList";
import { Spanner } from "../../../../../../../common-frontend/components/micro/spanner/spanner";
import { RichTextEditorDialogComponent } from "../../../../../../../common-frontend/components/richTextEditorDialog/richTextEditorDialog";
import { NumbersUtil } from "../../../../../../../common/util/numbers/numbersUtil";
import { useRichTextEditorContentManagerSite } from "../../../../common/richTextEditorContentManagerSite/useRichTextEditorContentManagerSite";
import { ButtonMediaGallerySelectComponent } from "./buttonMediaSelect/buttonMediaSelect";
import { useTranslation } from "react-i18next";

interface ProductFormProps {
  existingCategories: string[];
  siteId: string;
  pageId: string;
  productToEdit: Product;
  isNew: boolean;
  onSaveProduct: (product: Product) => void;
}

export function ProductFormComponent({
  existingCategories,
  siteId,
  pageId,
  productToEdit,
  isNew,
  onSaveProduct,
}: ProductFormProps): JSX.Element {

  const { t } = useTranslation();

  const contentManager = useRichTextEditorContentManagerSite(siteId, pageId);

  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [available, setAvailable] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [extraInfo, setExtraInfo] = useState<string>("");

  const [showExtraInfoDialog, setShowExtraInfoDialog] = useState<boolean>(false);
  const [mediaItems, setMediaItems] = useState<MediaGalleryItem[]>([]);

  useEffect(() => {
    copyProductInfoToControls(productToEdit);
  }, [productToEdit]);

  useEffect(() => {
    const currentProduct = ProductFactory.createNew(productToEdit.id, productToEdit.pageId);
    copyControlsInfoToProduct(currentProduct);
    setHasChanges(!Product.isEqual(currentProduct, productToEdit));
  }, [
    productToEdit,
    name,
    description,
    price,
    category,
    available,
    visible,
    extraInfo,
    mediaItems
  ]);

  const handleDiscardChanges = () => {
    copyProductInfoToControls(productToEdit);
  }

  const copyProductInfoToControls = (product: Product) => {
    setName(product.name);
    setDescription(product.description);
    setMediaItems(product.galleryItems);
    setPrice(NumbersUtil.floatToString(product.price));
    setCategory(product.category);
    setAvailable(product.available);
    setVisible(product.visible);
    setExtraInfo(product.extraInfo);
  }

  const copyControlsInfoToProduct = (product: Product) => {
    product.name = name;
    product.description = description;
    product.galleryItems = mediaItems;
    product.price = NumbersUtil.parseFloat(price);
    product.category = category;
    product.available = available;
    product.visible = visible;
    product.extraInfo = extraInfo;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const product = ProductFactory.createNew(productToEdit.id, productToEdit.pageId);
    copyControlsInfoToProduct(product);
    onSaveProduct(product);
  };

  return (<div>
    <Form onSubmit={handleSubmit}>

      {/* Name */}
      <FormCellComponent size="Small">
        <Form.Group
          className="mb-3"
          controlId='product-name'>
          <Form.Label>
            {t('name')}
          </Form.Label>

          <FormEditComponent
            type="text"
            value={name}
            onChange={setName}
          />
        </Form.Group>
      </FormCellComponent>

      {/* Description */}
      <FormCellComponent size="Large">
        <Form.Group
          className="mb-3"
          controlId='product-description'>
          <Form.Label>
            {t('description')}
          </Form.Label>

          <FormTextAreaComponent
            size="sm"
            lines={4}
            value={description}
            onChange={setDescription}
          />
        </Form.Group>
      </FormCellComponent>

      {/* Image Gallery */}
      <FormCellComponent size="Large">
        <Form.Group
          className="mb-5"
          controlId='product-gallery'>
          <Form.Label>
            {t('gallery')}
          </Form.Label>
          <ButtonMediaGallerySelectComponent
            itemId={productToEdit.id}
            contentManager={contentManager}
            mediaItems={mediaItems}
            updateItems={setMediaItems}
          />
        </Form.Group>
      </FormCellComponent>

      {/* Price */}
      <FormCellComponent size="Extra Small">
        <Form.Group
          className="mb-3"
          controlId="product-price">
          <Form.Label>
            {t('price')}
          </Form.Label>
          <FormNumberComponent
            size="sm"
            value={price}
            onChange={setPrice}
          />
        </Form.Group>
      </FormCellComponent>

      {/* Category */}
      <FormCellComponent size="Small">
        <Form.Group
          className="mb-5"
          controlId='product-category'>
          <Form.Label>
            {t('category')}
          </Form.Label>

          <EditableComboBoxComponent
            size="sm"
            options={existingCategories}
            value={category}
            onChange={setCategory}
          />
        </Form.Group>
      </FormCellComponent>

      {/* Available + Visible*/}
      <FormCellComponent size='Small'>
        <Form.Group
          className="mb-3"
          controlId='product-available'>
          <FormSwitchComponent
            label={t('available')}
            value={available}
            onChange={setAvailable}
          />
        </Form.Group>

        <Form.Group
          className="mb-5"
          controlId='product-visible'>
          <FormSwitchComponent
            label={t('visible')}
            value={visible}
            onChange={setVisible}
          />
        </Form.Group>
      </FormCellComponent>

      {/* Extra info */}
      <FormCellComponent size='Medium'>
        <Form.Group
          className="mb-3"
          controlId='product-extra-information'>
          <Form.Label>{t('extraInformation')}</Form.Label>
          <div>
            <Button
              id="product-extra-information"
              onClick={() => { setShowExtraInfoDialog(true) }}
            >
              {`${t('edit')}...`}
            </Button>
            <RichTextEditorDialogComponent
              show={showExtraInfoDialog}
              title={t('extraInformation')}
              contents={extraInfo}
              contentManager={contentManager}
              allowUserQueries={false}
              onClose={() => { setShowExtraInfoDialog(false) }}
              onSave={(contents) => {
                setExtraInfo(contents)
              }}
            />
          </div>
        </Form.Group>

      </FormCellComponent>

      <HorizontalListComponent>
        <Spanner />
        <div>
          <ActionsSaveDiscardComponent
            canSave={isNew || hasChanges}
            onDiscard={handleDiscardChanges}
          />
        </div>
      </HorizontalListComponent>
    </Form>
  </div>
  );
}