import { GuidUtil } from "../../../common/util/guid/guid";
import { LocalStorageAnalytics } from "../../../library/localStorage/analytics/analytics";
import { ServiceApiAnalytics } from "../../api/serviceApiAnalytics";

export class ServiceFeatureAnalytics {

    public static saveRouteVisited(route: string) {

        // Get the device ID from local storage
        const deviceId = this.getDeviceIdCreateIfDoesNotExist();

        // Get the current date and time
        const date = new Date();

        // Save the route visited
        ServiceApiAnalytics.createRouteLog(deviceId, route, date);
    }

    public static getDeviceIdCreateIfDoesNotExist(): string {
        // Get the device ID from local storage
        const deviceId = LocalStorageAnalytics.getDeviceId();
        if (deviceId === undefined) {

            // Create a new device ID
            const newDeviceId = GuidUtil.GenerateNewGuid();
            LocalStorageAnalytics.saveDeviceId(newDeviceId);
            return newDeviceId;
        }

        return deviceId;
    }
}