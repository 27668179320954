import { useTranslationCommonFrontEnd } from "../../translations/translation";
import { MenuItemComponent } from "./menuItem";

export function MenuItemDeletePageComponent(): JSX.Element {

  const t = useTranslationCommonFrontEnd();

  return (
    <MenuItemComponent
      caption={t('deletePage')}
      iconName='trash'
    />
  );
}