import { RequestResponse } from "../../../common/api/requests/response/requestResponse";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";
import { QueryParamsUtil } from "../../../common/util/routes/queryParamsUtil";
import { BlogPostAdmin } from "../models/blogPostAdmin";

export class GetBlogPostAdminListRouteDef extends IRouteDefinition {

  constructor() {
    super("GET");

    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(pageId: string, pageSize: number, page: number): string {
    return `blogs/blog-posts-admin-list?` +
      QueryParamsUtil.build(
        {
          'page-id': pageId,
          'page': page,
          'page-size': pageSize
        }
      );
  }

  public routeTemplate(): string {
    return 'blogs/blog-posts-admin-list';
  }
}

export const enum GetBlogPostAdminListParams {
  pageId = 'page-id',
  pageSize = 'page-size',
  page = 'page'
}

export class GetBlogPostAdminListResponse extends RequestResponse {
  constructor(public blogPosts: BlogPostAdmin[]) {
    super();
  }
}