export type MediaGalleryItemType = "image" | "youtube-video" | "youtube-short" | "unknown";

export class MediaGalleryItem {
  public id: string = '';
  public itemId: string = '';
  public type: MediaGalleryItemType = "unknown";
  public data: string = '';

  public static isEqual(a: MediaGalleryItem, b: MediaGalleryItem): boolean {
    return (
      a.id === b.id &&
      a.itemId === b.itemId &&
      a.type === b.type &&
      a.data === b.data
    );
  }
}
