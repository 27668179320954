import { StoreOrderLine } from "./storeOrderLine";

export class StoreOrder {
  constructor(
    public id: string,
    public siteId: string,
    public pageId: string,
    public buyer_name: string,
    public buyer_mail: string,
    public buyer_phone: string,
    public buyer_address: string,
    public lines: StoreOrderLine[]
  ) {
  }
}
