
import { ListGroup } from 'react-bootstrap';
import { LoopUtil } from '../../../../common/util/loop/loopUtil';
import { UserQueryAdminList } from '../../../../common-app/userQueries/models/userQueryAdminList';
import { AdminUserQueriesListItemComponent } from '../adminUserQueriesListItem/adminUserQueriesListItem';

import './adminUserQueriesList.css';

interface AdminUserQueriesListProps {
  items: UserQueryAdminList[],
  selectedItemId: string | undefined;
  onItemSelected: (selectedItemId: string | undefined) => void;
  onItemMarkAsUnread: (itemId: string) => void;
  itemsToShowCount: number;
}

export function AdminUserQueriesListComponent({
  items,
  selectedItemId,
  onItemSelected,
  onItemMarkAsUnread,
  itemsToShowCount
}: AdminUserQueriesListProps): JSX.Element {

  const extraItemsToCreate = itemsToShowCount - items.length;

  const handleItemSelection = (item: UserQueryAdminList | undefined): void => {
    onItemSelected(item?.id);
  }

  const handleMarkAsUnread = (itemId: string): void => {
    onItemMarkAsUnread(itemId);
  }

  const getItemBackgroundColor = (item: UserQueryAdminList): string => {

    if (item.id === selectedItemId) {
      return 'var(--color-primary)';
    }
    else {
      if (item.isUnread) {
        return 'var(--color-secondary-light)';
      }
      else {
        return 'var(--color-neutral-light)';
      }
    }
  }

  return (
    <div className="admin-user-queries-list-component">

      <ListGroup>
        {
          items.map((userQuery, index) => {
            return <ListGroup.Item
              key={index}
              onClick={() => handleItemSelection(userQuery)}
              active={userQuery.id === selectedItemId}
              className='item'
              style={{
                backgroundColor: getItemBackgroundColor(userQuery)
              }}
            >
              <AdminUserQueriesListItemComponent
                key={index}
                userQuery={userQuery}
                isSelected={userQuery.id === selectedItemId}
                onItemMarkAsUnread={() => handleMarkAsUnread(userQuery.id)}
              />
            </ListGroup.Item>
          })
        }

        {
          LoopUtil.loop(extraItemsToCreate, (index) => {
            return <ListGroup.Item
              key={items.length + index}
              className="extra-item"
              onClick={() => handleItemSelection(undefined)}
            >
            </ListGroup.Item>
          })
        }
      </ListGroup>
    </div>
  );
}
