import { CustomElement } from "./types";

export const BlockTypes = ['paragraph', 'heading-one', 'heading-two', 'heading-three'] as const;
export type BlockType = typeof BlockTypes[number];

export class EditorUtil {
  public static canBlockTypeBeChanged(format: CustomElement['type']) {
    return BlockTypes.includes(format as BlockType);
  }

  public static IsBlockTypeDeletable(format: CustomElement['type']) {
    return BlockTypes.includes(format as BlockType);
  }
}