import { useState } from "react";

export function useForceRefresh(): readonly [number, () => void] {

  const [refreshIteration, setRefreshIteration] = useState<number>(0);

  const forceRefresh = () => {
    setRefreshIteration((i) => { return i + 1; });
  }

  return [
    refreshIteration,
    forceRefresh
  ] as const
}