
interface SpacerHorizontalProps {
  width: string;
}

export function SpacerHorizontal({ width }: SpacerHorizontalProps) {
  return (
    <div style={{
      flexGrow: "1",
      maxWidth: width
    }}>
    </div>
  );
}