import { RequestBody } from "../../../common/api/requests/body/requestBody";
import { IRouteDefinition } from "../../../common/api/requests/routeDefinition";
import { RouteDefinitionPermissions } from "../../../common/api/requests/routeDefinitionPermissions";
import { Contact } from "../models/contact";

export class UpdateContactRouteDef extends IRouteDefinition {
  constructor() {
    super('PUT');
    this.permissions = new RouteDefinitionPermissions(true);
  }

  public route(contactId: string): string {
    return `site-pages-contacts/contacts/${contactId}`;
  }

  public routeTemplate(): string {
    return 'site-pages-contacts/contacts/:contactId';
  }
}

export const enum UpdateContactRouteParams {
  contactId = 'contactId'
}

export class UpdateContactRequestBody extends RequestBody {
  constructor(
    public contact: Contact
  ) {
    super();
  }
}