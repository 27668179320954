import classNames from 'classnames';
import { TreeNode } from '../../../common/util/tree/treeNode';
import { HorizontalListComponent } from '../horizontalList/horizontalList';

import './Tree.css';

interface TreeProps<T> {
  items: TreeNode<T>[];
  initialSelectedItemId: string;
  selectedItem: string | undefined;
  onSelect: (itemId: string) => void;
  renderNodeText?: (node: TreeNode<T>) => JSX.Element;
}

export function TreeComponent<T>({ items, initialSelectedItemId, selectedItem, onSelect, renderNodeText }: TreeProps<T>): JSX.Element {

  const renderNode = (node: TreeNode<T>) => {

    const className = classNames(
      "tree-node",
      {
        "tree-node-unavailable": !node.enabled,
        "tree-node-initial-selected": node.id === initialSelectedItemId,
        "tree-node-selected": node.id === selectedItem,
      }
    );

    return <HorizontalListComponent>
      <div
        className={className}
        onClick={(e) => {

          e.stopPropagation();

          if (node.enabled === false) {
            return;
          }
          onSelect(node.id);
        }}
      >
        {renderNodeText ? renderNodeText(node) : node.name}
      </div>
    </HorizontalListComponent>
  }

  const renderNodeAndChildren = (node: TreeNode<T>): JSX.Element => {

    return <div key={node.id}>
      {renderNode(node)}
      <div className="tree-node-childs">
        {node.children.map((child) => {
          return renderNodeAndChildren(child)
        })}
      </div>
    </div>
  }

  return (
    <div className="tree-component">
      {items.map((item) => {
        return renderNodeAndChildren(item)
      })}
    </div>
  );
}
